/**
 * Created by OBL on 10/12/2015.
 */
define('productOverviewTabView',[
  'module',
  'marionette',
  'jqgridView',
  'app',
  'underscore',
  'template!productOverviewTabTpl'
], function(
  module, Marionette,
  JqGridView,
  App,
  _,
  viewTpl
) {
  'use strict';

  var ProductDetailsView = Marionette.ItemView.extend({
    template: viewTpl,
    className: 'height-100',

    serializeData: function () {
      return {
        url: this.options.url
      };
    }
  });

  module.exports = ProductDetailsView;
});
