
define('template!mailCreateLayoutTpl', ['lodash'], function(_) {return function(obj) {
obj || (obj = {});
var __t, __p = '';
with (obj) {
__p += '<div class="detailed-info-form-container">\n  <div class="saving-label"></div>\n  <div class="error-label">' +
((__t = ( _.i18n('common.serverError') )) == null ? '' : __t) +
'\n      <div class="error-msg">  </div>\n  </div>\n  <div class="form-horizontal">\n    <div class="form-inputs-container" data-field-name="to">\n        <div class="form-group">\n            <label class="col-xs-3 control-label">' +
((__t = ( _.i18n('mails.to') )) == null ? '' : __t) +
'</label>\n            <div class="col-xs-9">\n                <div class="js-select-email-region"></div>\n            </div>\n        </div>\n        <div class="form-group" data-field-name="subject">\n            <label class="col-xs-3 control-label">' +
((__t = ( _.i18n('mails.subject') )) == null ? '' : __t) +
'</label>\n            <div class="col-xs-9">\n                <input type="text" class="form-control floating-label js-info-input" data-field-name="subject" placeholder="' +
((__t = ( _.i18n('mails.subject') )) == null ? '' : __t) +
'" value="">\n            </div>\n        </div>\n      <div class="form-group" data-field-name="content">\n        <label class="col-xs-3 control-label">' +
((__t = ( _.i18n('mails.content') )) == null ? '' : __t) +
'</label>\n        <div class="col-xs-9">\n          <textarea class="form-control floating-label js-info-input" rows="4" data-field-name="content" placeholder="' +
((__t = ( _.i18n('mails.content') )) == null ? '' : __t) +
'"></textarea>\n        </div>\n      </div>\n      <div class="row">\n        <div class="pull-right p-r-15">\n          <button class="btn btn-default btn-flat cancel-b js-cancel-popup">' +
((__t = ( _.i18n('common.cancel') )) == null ? '' : __t) +
'</button>\n          <button class="btn btn-primary disabled-grey js-confirm">' +
((__t = ( _.i18n('common.send') )) == null ? '' : __t) +
'</button>\n        </div>\n      </div>\n    </div>\n  </div>\n</div>';

}
return __p
};});

