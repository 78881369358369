/**
 * Created by BFR on 18/02/2020.
 */
define('dynamicTextEditView',[
    'module',
    'underscore',
    'template!dynamicTextEditTpl',
    'dialogCollectionView',
    'dynamicTextLocalizedView'
  ], function(
    module, 
    _,
    viewTpl,
    DialogCollectionView,
    dynamicTextLocalizedView
  ) {
    'use strict';
  
    var DynamicTextView = DialogCollectionView.extend({
      template: viewTpl,
      childView: dynamicTextLocalizedView,
      childViewContainer: '.translations-view',
      region: {
      },
  
      ui: {
        
      },
  
      events: {
        'click .js-cancel-popup': 'onCancelClick',
        'click .js-confirm': 'onConfirmClick'
      },

      onCancelClick: function() {
        this.model.set(this.backup);
        this.model = null;
        this.hide();
      },

      onConfirmClick: function() {
        this.model.save().done(_.bind(function() {
          this.model = null;
          this.trigger('onSave');
          this.hide();
        }, this));  
      },
  
      serializeData: function () {
        this.backup = this.model.toJSON();
        var templateData = this.model.toJSON();
        return templateData;
      }
    });
  
    module.exports = DynamicTextView;
  });
