define('entities/offers_kanban',[
  'app',
  'backbone',
  'settings',
  'jquery',
  'underscore',
  'entities/product'
], function (
  App,
  Backbone,
  Settings,
  $,
  _
) {
    'use strict';
    var Offer = Backbone.Model.extend({
      defaults: {
        endUserId: '',
        date: null,
        payedByCustomer: null,
        payedToManufacturer: null,
        poi: null,
        productName: '',
        snapshotUid: '',
        status: '',
        flag: '',
        externalNumber: '',
        priority: null,
        grouped: false,
        groupedInvoice: false,
        toInvoice: false,
        nbrPackDelivered: 0,
        nbrPackSent: 0,
        expShippingDate: null
      },
      idAttribute: 'poi'
    });

    var OfferCollection = Backbone.Collection.extend({
      model: Offer
    });

    var API = {
      getKanbanStatuses: function () {
        var statuses = new OfferCollection({}),
          params = {
            locale: Settings.get('lang'),
            tag: 'offer'
          },
          defer = $.Deferred();

        statuses.url = Settings.url('compuzz', 'commands.json/command/status/kanban', params);
        statuses.fetch({
          success: function (data) {
            defer.resolve(data);
          }
        });
        return defer.promise();
      },

      getRowCountOffers: function (params) {
        var url = Settings.url('compuzz', 'commands.json/advancedSearch/rowCount', params),
          defer = $.Deferred();

        $.ajax({
          method: 'get',
          url: url,
          success: function (rowCount) {
            defer.resolve(rowCount);
          }
        });

        return defer.promise();
      },

      getAllOffers: function (params) {
        var param = JSON.parse(JSON.stringify(params));
        var showOnlyMine = false;
        if (param.showOnlyMine && param.showOnlyMine[param.status]) {
          showOnlyMine = true;
        }
        param.showOnlyMine = showOnlyMine;
        param.types = 'offer';
        var url = Settings.url('compuzz', 'commands.json/lights', param),
          defer = $.Deferred(),
          collection = new OfferCollection();

        $.ajax({
          method: 'get',
          url: url,
          success: _.bind(function (offers) {
            var offersGrouped = [];
            var offersGroupedInvoice = [];
            _.each(offers, _.bind(function (o) {
              var item = _.find(offersGrouped, _.bind(function (offer) { return offer.endUserId &&
                  offer.endUserId === o.endUserId;
              }, this));
              if (item) {
                item.grouped = true;
                o.grouped = true;
                if (!Settings.get('offerColors')[item.endUserId]) {
                  Settings.get('offerColors')[item.endUserId] = this.getRandomColor();
                }
                o.groupedColor = item.groupedColor = Settings.get('offerColors')[item.endUserId];
                offersGrouped.splice(offersGrouped.indexOf(item) + 1, 0, o);
              } else {
                offersGrouped.push(o);
              }
              item = _.find(offersGroupedInvoice, _.bind(function (offer) { return offer.invoiceFileUid &&
                  offer.invoiceFileUid === o.invoiceFileUid;
              }, this));
              if (item) {
                  item.groupedInvoice = true;
                  o.groupedInvoice = true;
                  if (!Settings.get('offerColorsInvoice')[item.invoiceFileUid]) {
                      Settings.get('offerColorsInvoice')[item.invoiceFileUid] = this.getRandomColor();
                  }
                  o.groupedColorInvoice = item.groupedColorInvoice = Settings.get('offerColorsInvoice')[item.invoiceFileUid];
                  offersGroupedInvoice.splice(offersGroupedInvoice.indexOf(item) + 1, 0, o);
              } else {
                  offersGroupedInvoice.push(o);
              }
            }, this));
            collection.reset(offersGrouped);
            defer.resolve(collection);
          }, this)
        });

        return defer.promise();
      },

      getRandomColor: function () {
        var letters = '0123456789ABCDEF'.split('');
        var color = '#';
        for (var i = 0; i < 6; i++) {
          color += letters[Math.floor(Math.random() * 16)];
        }

        return color;
      },

      getOfferComment: function (poi) {
        var url = Settings.url('compuzz', 'commands.json/' + poi + '/comment'),
          defer = $.Deferred();
        $.ajax({
          method: 'GET',
          url: url,
          success: function (comment) {
            defer.resolve(comment);
          }
        });
        return defer.promise();
      },

      updateOfferComment: function (poi, comment) {
        var url = Settings.url('compuzz', 'commands.json/' + poi + '/comment'),
          defer = $.Deferred();
        $.ajax({
          method: 'PUT',
          url: url,
          data: JSON.stringify(comment),
          contentType: 'application/json',
          success: function (response) {
            defer.resolve(response);
          }
        });
        return defer.promise();
      },

      updateOfferCard: function (poi, status) {
        return $.ajax({
          type: 'POST',
          url: Settings.url('compuzz', 'commands.json/' + poi + '/status', {
            status: status
          }),
          contentType: false,
          dataType: false
        });
      },

      getModel: function (content) {
        return new Offer(content);
      },

      assignUser: function (poi, userSecId) {
        var defer = $.Deferred();

        $.ajax({
          url: Settings.url('compuzz', 'commands.json/' + poi + '/assign', { user: userSecId }),
          type: 'PUT',
          success: function (data) {
            defer.resolve(data);
          }
        });
        return defer.promise();
      },

      unassignUser: function (poi) {
        var defer = $.Deferred();

        $.ajax({
          url: Settings.url('compuzz', 'commands.json/' + poi + '/unassign'),
          type: 'PUT',
          success: function (data) {
            defer.resolve(data);
          }
        });
        return defer.promise();
      },

      createFromConfiguration: function (productSecId, endUserId, customerId, configuration) {
        var config = {
          quantity: configuration.quantity,
          options: [],
          printZonesColors: {}
        };

        for (var propertyName in configuration.options) {
          if (configuration.options.hasOwnProperty(propertyName)) {
            config.options.push(configuration.options[propertyName]);
          }
        }

        for (var propertyName1 in configuration.printZones) {
          if (configuration.printZones.hasOwnProperty(propertyName1)) {
            var pzConf = configuration.printZones[propertyName1];
            config.printZonesColors[pzConf.pzId] = pzConf.nbrColor;
          }
        }

        var params = {
          endUserId: endUserId,
          customerId: customerId
        };
        var defer = $.Deferred(),
          url = Settings.url('compuzz', 'commands.json/create/offer/' + productSecId, params);
        $.ajax({
          type: 'POST',
          url: url,
          data: JSON.stringify(config),
          contentType: 'application/json',
          success: function (data) {
            defer.resolve(data);
          }
        });

        return defer.promise();
      },

      getHistoryRowCount: function(purchaseOrderId, options) {
        options = options || {};
        _.defaults(options, {});
        var params = '';
        return 'commands.json/' + purchaseOrderId + '/history/rowCount' + params;
      },

      getHistory: function(purchaseOrderId, options) {
        options = options || {};
        return Settings.url('compuzz', 'commands.json/' + purchaseOrderId + '/history', options);
      }
    };

    App.reqres.setHandler('offer_kanban:statuses', function () {
      return API.getKanbanStatuses();
    });
    App.reqres.setHandler('offer_kanban:offers', function (params) {
      return API.getAllOffers(params);
    });
    App.reqres.setHandler('offer_kanban:row:count', function (params) {
      return API.getRowCountOffers(params);
    });
    App.reqres.setHandler('offer-kanban:update:table', function (poi, status) {
      return API.updateOfferCard(poi, status);
    });
    App.reqres.setHandler('offer-kanban:update:comment', function (poi, comment) {
      return API.updateOfferComment(poi, comment);
    });
    App.reqres.setHandler('offer-kanban:get:comment', function (poi) {
      return API.getOfferComment(poi);
    });
    App.reqres.setHandler('offer-kanban:new:model', function (content) {
      return API.getModel(content);
    });
    App.reqres.setHandler('offer-kanban:assign-users', function (ticketSecId, userSecId) {
      return API.assignUser(ticketSecId, userSecId);
    });
    App.reqres.setHandler('offer-kanban:unassigned-users', function (ticketSecId) {
      return API.unassignUser(ticketSecId);
    });
    App.reqres.setHandler('offer-kanban:create-from-configuration', function (productSecId, endUserId, customerId, config) {
      return API.createFromConfiguration(productSecId, endUserId, customerId, config);
    });
    App.reqres.setHandler('offer-kanban:history-get', function(purchaseOrderId, params) {
      return API.getHistory(purchaseOrderId, params);
    });
    App.reqres.setHandler('offer-kanban:history-row-count', function(purchaseOrderId, params) {
      return API.getHistoryRowCount(purchaseOrderId, params);
    });
  });
