
define('template!pricelistsTabEmptyTpl', ['lodash'], function(_) {return function(obj) {
obj || (obj = {});
var __t, __p = '';
with (obj) {
__p += '<div class="content">\n	<div class="row pricelist-select-container">\n		<div class="col-xs-5">\n			<div class="form-group col-xs-6">\n				<a class="btn add-new-pricelist-data-b add-btn-text">\n					<i class="mdi-content-add"></i>\n					<span>' +
((__t = ( _.i18n('vectorization.new') )) == null ? '' : __t) +
'</span>\n				</a>\n			</div>\n		</div>\n	</div>\n</div>';

}
return __p
};});

