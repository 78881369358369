
define('template!mailsListTpl', ['lodash'], function(_) {return function(obj) {
obj || (obj = {});
var __t, __p = '';
with (obj) {
__p += '<div class="content">\n  <div class="well jq-grid-table-page">\n  	<h2>' +
((__t = ( _.i18n('menu.mails') )) == null ? '' : __t) +
'</h2>\n    <div class="row">\n        <div class="form-group add-new-block">\n            <a class="btn new-cAccount-button add-btn-text js-add-new">\n                <i class="mdi-content-add"></i>\n                <span>' +
((__t = ( _.i18n('mails.new') )) == null ? '' : __t) +
'</span>\n            </a>\n        </div>\n        <div class="col-xs-6">\n            <div class="sub-caccounts-table">\n                <table id="jq-grid-list" class="cacountTable" role="grid"></table>\n                <div id="jq-grid-pager-list"></div>\n            </div>\n        </div>\n        <div class="col-xs-6 mail-detail">\n\n        </div>\n    </div>\n  </div>\n</div>';

}
return __p
};});

