
define('template!invitedItemTpl', ['lodash'], function(_) {return function(obj) {
obj || (obj = {});
var __t, __p = '', __j = Array.prototype.join;
function print() { __p += __j.call(arguments, '') }
with (obj) {
__p += '<div class="row">\n    <div class="col-lg-6"><span>' +
((__t = ( email )) == null ? '' : __t) +
'</span></div>\n    <div class="col-lg-3 text-center">\n        ';
 if (!subscribed) { ;
__p += '\n        <span>' +
((__t = ( _.i18n('invitations.invitationSent') )) == null ? '' : __t) +
'</span>\n        ';
 } else if (subscribed && !refill) { ;
__p += '\n        <span>' +
((__t = ( _.i18n('invitations.invitationSubscribed') )) == null ? '' : __t) +
'</span>\n        ';
 } else { ;
__p += '\n        <span>' +
((__t = ( _.i18n('invitations.invitationRefilled') )) == null ? '' : __t) +
'</span>\n        ';
 } ;
__p += '\n    </div>\n    <div class="col-lg-3 text-center"><span>' +
((__t = ( date )) == null ? '' : __t) +
'</span></div>\n</div>\n<div class="row">\n    <hr class="col-lg-12 m-t-5 m-b-5">\n</div>';

}
return __p
};});

