
define('template!rBnewAttachmentTpl', ['lodash'], function(_) {return function(obj) {
obj || (obj = {});
var __t, __p = '';
with (obj) {
__p += '<span class="badge" title="' +
((__t = ( name )) == null ? '' : __t) +
'">' +
((__t = ( name + '.' + extension )) == null ? '' : __t) +
'</span>\n';

}
return __p
};});

