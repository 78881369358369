define('cAccountTabs',[
  'underscore',
  'rolesMixin'
], function (_, RolesMixin) {
  'use strict';

  var  cAccountTabs = {
    graphicalIdTabs :
      [{
          route: 'objectnames',
          name: 'objectnames',
          role : RolesMixin.DASHBOARD_ACCOUNT_GRAPHICALID_OBJECTNAMES,
          title: _.i18n('cAccountDetail.objectNames.tabTitle')
      }, {
          route: 'fonts',
          name: 'fonts',
          role : RolesMixin.DASHBOARD_ACCOUNT_GRAPHICALID_FONTS,
          title: _.i18n('font.custom')
      } ],
    myAccountTabs :
      [{
          route: 'detail',
          name: 'detail',
          role : RolesMixin.DASHBOARD_ACCOUNT_ACCOUNT_ACTIVITY,
          title: _.i18n('cAccountDetail.detail.tabTitle')
        }, {
         route: 'profile',
         name: 'profile',
         role : RolesMixin.DASHBOARD_ACCOUNT_ACCOUNT_PROFILE,
         title: _.i18n('cAccountDetail.profile.tabTitle')
       }, {
         route: 'credits',
         name: 'credits',
         role : RolesMixin.DASHBOARD_ACCOUNT_ACCOUNT_CREDITS,
         title: _.i18n('cAccountDetail.credits.tabTitle')
       }, {
          route: 'invoices',
          name: 'invoices',
          role : RolesMixin.DASHBOARD_ACCOUNT_ACCOUNT_INVOICES,
          title: _.i18n('cAccountDetail.invoices.tabTitle')
      }, {
         route: 'administrator',
         name: 'administrator',
         role : RolesMixin.DASHBOARD_ACCOUNT_ACCOUNT_ADMINISTRATOR,
         title: _.i18n('cAccountDetail.administrator.tabTitle')
       }, {
         route: 'attachments',
         name: 'attachments',
         role: RolesMixin.DASHBOARD_ACCOUNT_ACCOUNT_ATTACHMENTS,
         title: _.i18n('cAccountDetail.attachments.tabTitle')
       }, {
         route: 'share',
         name: 'share',
         role: RolesMixin.DASHBOARD_ACCOUNT_ACCOUNT_SHARE,
         title: _.i18n('cAccountDetail.share.tabTitle')
       }, {
          route: 'orderstatus',
          name: 'orderstatus',
          role: RolesMixin.DASHBOARD_ACCOUNT_ACCOUNT_COMMANDSTATUS,
          title: _.i18n('cAccountDetail.orderstatus.tabTitle')
      },{
          route: 'offerstatus',
          name: 'offerstatus',
          role: RolesMixin.DASHBOARD_ACCOUNT_ACCOUNT_OFFERSTATUS,
          title: _.i18n('cAccountDetail.offerstatus.tabTitle')
      }
      ]
  };

  return cAccountTabs;
});

