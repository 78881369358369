
define('template!sidebarViewTpl', ['lodash'], function(_) {return function(obj) {
obj || (obj = {});
var __t, __p = '', __j = Array.prototype.join;
function print() { __p += __j.call(arguments, '') }
with (obj) {
__p += '<div>\n  <section class="vbox">\n    <section class="scrollable">\n      <div class="sidebar-scroll-container">\n        <!-- nav -->                 \n        <nav class="hidden-xs menu-in-sidebar">\n          <div class="hidden-nav-xs"></div>\n          <ul class="nav nav-main" data-ride="collapse">\n            ';
 _.each(menuItems, function (menuItem) { ;
__p += '\n              <li item-page-name="' +
((__t = ( menuItem.itemPageName )) == null ? '' : __t) +
'"  data-type="menu">\n                ';
 if (!_.isEmpty(menuItem.subMenu)) { ;
__p += '\n                  <a class="auto nav-items open-items">\n                    <i class="' +
((__t = ( menuItem.icon )) == null ? '' : __t) +
' icon open-hide-sidebar"></i>\n                     <span title="' +
((__t = ( menuItem.title )) == null ? '' : __t) +
'" class="">' +
((__t = ( menuItem.title )) == null ? '' : __t) +
'</span>\n                     <i class="mdi-hardware-keyboard-arrow-right icon-sub-main open-sub"></i>\n                     <i class="mdi-hardware-keyboard-arrow-down icon-sub-main hide-sub"></i>\n                      ';
 if(menuItem.isNew) { ;
__p += '\n                      <span class="badge">' +
((__t = ( _.i18n('menu.new') )) == null ? '' : __t) +
'</span>\n                      ';
 } ;
__p += '\n                  </a>\n                  <ul class="nav sub">\n                      <li class="sub-menu-title shadow-2">\n                        <a class="auto">                          \n                          <i class="mdi-navigation-expand-more icon-sub open-hide-sidebar pull-right"></i>\n                          <span class="sub-span">' +
((__t = ( menuItem.title )) == null ? '' : __t) +
'</span>\n                        </a>\n                      </li>\n                    ';
 _.each(menuItem.subMenu, function (subMenu) { ;
__p += '\n                      <li item-page-name="' +
((__t = ( subMenu.itemPageName )) == null ? '' : __t) +
'" data-type="sub-menu">\n                        <a href="#' +
((__t = ( subMenu.link() )) == null ? '' : __t) +
'" class="auto">\n                          <i class="mdi-image-lens icon-xs open-hide-sidebar"></i>\n                          <i class="' +
((__t = ( subMenu.icon )) == null ? '' : __t) +
' icon-sub open-hide-sidebar"></i>\n                          <span title-"' +
((__t = ( subMenu.title )) == null ? '' : __t) +
'" class="sub-span">' +
((__t = ( subMenu.title )) == null ? '' : __t) +
'</span>\n                            ';
 if(subMenu.isNew) { ;
__p += '\n                            <span class="badge">' +
((__t = ( _.i18n('menu.new') )) == null ? '' : __t) +
'</span>\n                            ';
 } ;
__p += '\n                        </a>\n                      </li>\n                    ';
 });;
__p += '\n                  </ul>\n                ';
 } else { ;
__p += '\n                  <a href="#' +
((__t = ( menuItem.link() )) == null ? '' : __t) +
'" class="auto nav-items ';
 if (menuItem.link() === 'caccount') { ;
__p += 'caccount-menu-item';
 };
__p += '">\n                    <i class="' +
((__t = ( menuItem.icon )) == null ? '' : __t) +
' icon open-hide-sidebar"></i>\n                     <span title="' +
((__t = ( menuItem.title )) == null ? '' : __t) +
'" class="">' +
((__t = ( menuItem.title )) == null ? '' : __t) +
'</span>\n                  </a>\n                  <ul class="nav sub" style="display:none;">\n                    <li item-page-name="' +
((__t = ( menuItem.itemPageName )) == null ? '' : __t) +
'"  data-type="menu">\n                      <a href="#' +
((__t = ( menuItem.link() )) == null ? '' : __t) +
'" class="auto">\n                        <span class="sub-span">' +
((__t = ( menuItem.title )) == null ? '' : __t) +
'</span>\n                      </a>\n                    </li>\n                  </ul>\n                ';
 } ;
__p += '\n              </li>\n            ';
 });;
__p += '\n          </ul>\n        </nav>\n        <!-- / nav -->\n      </div>\n    </section>\n  </section>\n  <div class="footer-switcher">\n    <i class="mdi-navigation-chevron-left icon switcher to-hide"></i>\n    <i class="mdi-navigation-chevron-right icon switcher to-show"></i>\n  </div>\n</div>';

}
return __p
};});

