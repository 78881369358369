
define('template!graphicalRequestKanbanColumnViewTpl', ['lodash'], function(_) {return function(obj) {
obj || (obj = {});
var __t, __p = '', __j = Array.prototype.join;
function print() { __p += __j.call(arguments, '') }
with (obj) {
__p += '<div class="col m-l-10 ';
 if (onlyAssign) { ;
__p += ' col-only-assign ';
 } ;
__p += '">\n  <p class="title state-title">\n    <span class="bold-text counter open counter open">' +
((__t = ( openConter )) == null ? '' : __t) +
'</span>\n    To Do\n  </p>\n  <div data-state="open" class="cards sortable-item">\n    <div data-state="open" id="todo-container" class=""></div>\n    <p data-state="open" class="loadMore open ';
 if (openConter < 50) { ;
__p += ' hide ';
 } ;
__p += ' ">\n      <a>Show more</a>\n    </p>\n  </div>\n</div>\n<div class="col ';
 if (onlyAssign) { ;
__p += ' col-only-assign ';
 } ;
__p += '">\n  <p class="title state-title">\n    <span class="bold-text counter pending">' +
((__t = ( pendingConter )) == null ? '' : __t) +
'</span>\n    Question to customer\n  </p>\n  <div data-state="pending" class="cards sortable-item">\n    <div data-state="pending" id="question-container" class=""></div>\n  </div>\n</div>\n<div class="col ';
 if (onlyAssign) { ;
__p += ' col-only-assign ';
 } ;
__p += '">\n  <p class="title state-title">\n    <span class="bold-text counter progree">' +
((__t = ( progressConter )) == null ? '' : __t) +
'</span>\n    In progress\n  </p>\n  <div data-state="progress" class="cards sortable-item">\n    <div data-state="progress" id="in-progress-container" class=""></div>    \n  </div>\n</div>\n<div class="col ';
 if (onlyAssign) { ;
__p += ' col-only-assign m-r-0 ';
 } ;
__p += '">\n  <p class="title state-title">\n    <span class="bold-text counter review">' +
((__t = ( reviewConter )) == null ? '' : __t) +
'</span>\n    To review\n  </p>\n  <div data-state="review" class="cards sortable-item">\n    <div data-state="review" id="in-review-container" class=""></div>    \n  </div>\n</div>\n<div class="col m-r-0 ';
 if (onlyAssign) { ;
__p += ' hidden ';
 } ;
__p += '">\n  <p class="title state-title">\n    <span class="bold-text counter done">' +
((__t = ( doneConter )) == null ? '' : __t) +
'</span>\n    Done\n  </p>\n  <div data-state="done" class="cards sortable-item">\n    <div data-state="done" id="done-container" class=""></div>\n    <p data-state="done" class="loadMore ';
 if (doneConter <= 5) { ;
__p += ' hide ';
 } ;
__p += '">\n      <a>Show more</a>\n    </p>\n  </div>\n</div>';

}
return __p
};});

