
define('template!customerTemplatesGroupsNewItemTpl', ['lodash'], function(_) {return function(obj) {
obj || (obj = {});
var __t, __p = '';
with (obj) {
__p += '<div>\n	<div class="col-xs-6 col-sm-4 col-lg-3">\n		<div class="panel panel-default template-group-item template-group-item-new">\n    	<div class="icon-holder">\n    		<i class="mdi-file-folder icon">\n				<span class="add-new text-in-icon">+</span>\n    		</i>\n    		\n    	</div>\n    	<div class="template-group-name">\n			' +
((__t = ( name )) == null ? '' : __t) +
'\n    	</div>\n		</div>\n	</div>\n</div>';

}
return __p
};});

