
define('template!productTabsLayoutTpl', ['lodash'], function(_) {return function(obj) {
obj || (obj = {});
var __t, __p = '', __j = Array.prototype.join;
function print() { __p += __j.call(arguments, '') }
with (obj) {
__p += '<div class="with-header">\n  <div class="tab-layout-header js-tab-layout-header">\n    <div class="tab-layout-info row">\n      <div class="logo-container col-left col-lg-2">\n        <!-- Logo -->\n        <div class="original-img-wrapper zoom-img">\n          <div class="main text-center">\n            <div class="logo-holder js-logo-holder"></div>\n          </div>\n        </div>\n      </div>\n      <div class="col-left name-holder js-name-holder m-t-5 col-lg-2">\n        <p class="p-name">' +
((__t = ( name )) == null ? '' : __t) +
'</p>\n\n        <p class="p-code">' +
((__t = ( cCode )) == null ? '' : __t) +
' (' +
((__t = ( baseCode )) == null ? '' : __t) +
')</p>\n      </div>\n\n      <span class="back-to-list js-back-to-list"><i class="mdi-content-reply"></i>' +
((__t = ( _.i18n("common.back.to.list") )) == null ? '' : __t) +
'</span>\n    </div>\n      <div class="navbar navbar-default navbar-md row">\n        <div class="navbar-collapse collapse">\n          <ul class="p-l-10 nav navbar-nav">\n            ';
 _.each(tabs, function (tab) { ;
__p += ' \n              <li class="js-tab-title tab-layout-tab js-tab-layout-tab js-' +
((__t = ( tab.name )) == null ? '' : __t) +
'"><a href="#product/' +
((__t = ( secId )) == null ? '' : __t) +
'/' +
((__t = ( tab.name )) == null ? '' : __t) +
'">' +
((__t = ( tab.title )) == null ? '' : __t) +
'</a></li>\n            ';
 }) ;
__p += '\n          </ul>\n        </div>\n      </div>\n    </div>\n  </div>\n</div>\n<div class="tab-layout-content js-tabs-region height-100"></div>\n';
 if (displayNextPrevious) { ;
__p += ' \n  <div class="clickable previous-product" style="position: absolute; top:115px; bottom: 0px; left:0px; width: 30px; display: flex; justify-content: center; align-items: center;">\n    <i class="mdi-hardware-keyboard-arrow-left" style="font-size: 60px;"></i>\n  </div>\n  <div class="clickable next-product" style="position: absolute; top:115px; bottom: 0px; right:0px; width: 30px; display: flex; justify-content: center; align-items: center;">\n    <i class="mdi-hardware-keyboard-arrow-right" style="font-size: 60px;"></i>\n  </div>\n';
 } ;


}
return __p
};});

