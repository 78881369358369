
define('template!customerDatasetsTableTpl', ['lodash'], function(_) {return function(obj) {
obj || (obj = {});
var __t, __p = '';
with (obj) {
__p += '<table class="table dataset-row-region">\n	<thead>\n		<tr>\n		  <th>Name</th>\n		  <th>Value</th>\n		  <th></th>\n		</tr>\n	</thead>\n	<tbody></tbody>\n</table>';

}
return __p
};});

