
define('template!caccountCreditsAccountFormPopupTpl', ['lodash'], function(_) {return function(obj) {
obj || (obj = {});
var __t, __p = '';
with (obj) {
__p += '<div class="col-xs-12 normal-light-compuzz">\n     <p class="billingHeader">' +
((__t = ( _.i18n('caccount.credits.billingData.text') )) == null ? '' : __t) +
'</p>\n</div>\n<div class="error-label col-md-12">\n    ' +
((__t = ( _.i18n('common.serverError') )) == null ? '' : __t) +
'\n    <div class="error-msg">  </div>\n</div>\n<form class="form-horizontal col-xs-12">\n  <div class="col-xs-12 text-center">\n  <div class="form-group" data-field-name="name">\n<!--    <label class="col-xs-3 control-label">' +
((__t = ( _.i18n('cAccountInfo.name') )) == null ? '' : __t) +
'</label>-->\n    <div class="col-xs-12">\n      <input type="text" class="form-control floating-label"\n             placeholder="' +
((__t = ( _.i18n('cAccountInfo.name') )) == null ? '' : __t) +
'" value="' +
((__t = ( name )) == null ? '' : __t) +
'"\n             data-field-name="name"/>\n    </div>\n  </div>\n  <div class="form-group" data-field-name="contactEmail">\n      <div class="col-xs-12">\n          <input type="email" class="form-control floating-label js-info-input" data-field-name="contactEmail" value="' +
((__t = ( contactEmail )) == null ? '' : __t) +
'" placeholder="' +
((__t = ( _.i18n('common.email') )) == null ? '' : __t) +
'">\n      </div>\n  </div>\n  <div class="form-group" data-field-name="address">\n<!--    <label class="col-xs-3 control-label">' +
((__t = ( _.i18n('common.street') )) == null ? '' : __t) +
'</label>-->\n    <div class="col-xs-12">\n      <input type="text" class="form-control floating-label"\n             placeholder="' +
((__t = ( _.i18n('common.street') )) == null ? '' : __t) +
'" value="' +
((__t = ( address )) == null ? '' : __t) +
'"\n             data-field-name="address"/>\n    </div>\n  </div>\n  <div class="form-group" data-field-name="zip,city">\n<!--    <label class="col-xs-3 control-label">' +
((__t = ( _.i18n('common.zip') )) == null ? '' : __t) +
'</label>-->\n    <div class="col-xs-4">\n      <input type="text" class="form-control floating-label"\n             placeholder="' +
((__t = ( _.i18n('common.zip') )) == null ? '' : __t) +
'" value="' +
((__t = ( zip )) == null ? '' : __t) +
'"\n             data-field-name="zip"/>\n    </div>\n      <div class="col-xs-8">\n          <input type="text" class="form-control floating-label"\n                 placeholder="' +
((__t = ( _.i18n('common.city') )) == null ? '' : __t) +
'" value="' +
((__t = ( city )) == null ? '' : __t) +
'"\n                 data-field-name="city"/>\n      </div>\n  </div>\n  <!--<div class="form-group" data-field-name="city">-->\n<!--    <label class="col-xs-3 control-label">' +
((__t = ( _.i18n('common.city') )) == null ? '' : __t) +
'</label>-->\n  <!--</div>-->\n  <div class="form-group js-countries">\n<!--    <label class="col-xs-3 control-label">' +
((__t = ( _.i18n('common.countryCode') )) == null ? '' : __t) +
'</label>-->\n    <div class="col-xs-12">\n      <div class="js-select-countries-region"></div>\n    </div>\n  </div>\n  <div class="form-group group-activityarea" data-field-name="activityArea">\n    <div class="col-xs-12">\n      <select id="activityarea" class="form-control activityarea" data-field-name="activityArea">\n        <option value="">' +
((__t = ( _.i18n('account.activityarea.select') )) == null ? '' : __t) +
'</option>\n        <option value="graphic-designer" ' +
((__t = ( activityArea==='graphic-designer'?'selected':'' )) == null ? '' : __t) +
'>' +
((__t = ( _.i18n('account.activityarea.graphic-designer') )) == null ? '' : __t) +
'</option>\n        <option value="photographer" ' +
((__t = ( activityArea==='photographer'?'selected':'' )) == null ? '' : __t) +
'>' +
((__t = ( _.i18n('account.activityarea.photographer') )) == null ? '' : __t) +
'</option>\n        <option value="printing-house" ' +
((__t = ( activityArea==='printing-house'?'selected':'' )) == null ? '' : __t) +
'>' +
((__t = ( _.i18n('account.activityarea.printing-house') )) == null ? '' : __t) +
'</option>\n        <option value="reseller-of-promotional-products" ' +
((__t = ( activityArea==='reseller-of-promotional-products'?'selected':'' )) == null ? '' : __t) +
'>' +
((__t = ( _.i18n('account.activityarea.reseller-of-promotional-products') )) == null ? '' : __t) +
'</option>\n        <option value="manufacturer-of-promotional-products" ' +
((__t = ( activityArea==='manufacturer-of-promotional-products'?'selected':'' )) == null ? '' : __t) +
'>' +
((__t = ( _.i18n('account.activityarea.manufacturer-of-promotional-products') )) == null ? '' : __t) +
'</option>\n        <option value="other" ' +
((__t = ( activityArea==='other'?'selected':'' )) == null ? '' : __t) +
'>' +
((__t = ( _.i18n('account.activityarea.other') )) == null ? '' : __t) +
'</option>\n      </select>\n    </div>\n  </div>\n  <div class="form-group checkbox-group" data-field-name="subjectToVat">\n      <div class="col-xs-1 checkbox checkbox-primary">\n          <label>\n              <input type="checkbox" id="subject-to-vat" class="js-keep-logged-in subjectToVat">\n          </label>\n      </div>\n      <span class="col-xs-8">' +
((__t = ( _.i18n('common.subjectToVat') )) == null ? '' : __t) +
'</span>\n  </div>\n  <div class="form-group" id="vat-area" data-field-name="vatNumber">\n\n    <div class="col-xs-12">\n      <input type="text" class="form-control floating-label uppercase-input"\n             placeholder="' +
((__t = ( _.i18n('common.vatNumberWithEntry') )) == null ? '' : __t) +
'" value="' +
((__t = ( vatNumber )) == null ? '' : __t) +
'"\n             data-field-name="vatNumber"/>\n    </div>\n  </div>\n</div>\n</form>\n<div class="row">\n  <div class="pull-right p-r-10 ">\n    <button class="btn btn-default btn-flat cancel-b js-cancel">' +
((__t = ( _.i18n('common.cancel') )) == null ? '' : __t) +
'</button>\n    <button class="btn btn-primary js-confirm">' +
((__t = ( _.i18n('common.confirm') )) == null ? '' : __t) +
'</button>\n  </div>\n</div>';

}
return __p
};});

