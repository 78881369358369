
define('template!customerDatasetsItemTpl', ['lodash'], function(_) {return function(obj) {
obj || (obj = {});
var __t, __p = '', __j = Array.prototype.join;
function print() { __p += __j.call(arguments, '') }
with (obj) {
__p += '<td>';
 if (data.objectName) { ;
__p +=
((__t = ( data.objectName.name )) == null ? '' : __t);
 } ;
__p += '</td>\n<td>' +
((__t = ( data.value )) == null ? '' : __t) +
'</td>\n<td class="icon-in-table">\n	';
 if (data.secId) { ;
__p += '\n		<i class="mdi-content-create js-cell-action clickable bold-text"></i>\n	';
 } else { ;
__p += '\n		<i class="mdi-content-add js-cell-action clickable bold-text"></i>\n	';
 } ;
__p += '\n</td>';

}
return __p
};});

