
define('template!caccountShareTpl', ['lodash'], function(_) {return function(obj) {
obj || (obj = {});
var __t, __p = '', __j = Array.prototype.join;
function print() { __p += __j.call(arguments, '') }
with (obj) {
__p += '<div>\n	<div class="well jq-grid-table-page">\n		<div class="loading-block js-loading-block">\n			<span class="loading-message js-loading-message label"></span>\n		</div>\n		<div class="vectorization-table">\n			';
 if (data.type === 'caccounts' || data.type === 'subcaccounts') { ;
__p += '\n				<div class="form-group add-new-block">\n					<div class="btn js-add-share add-btn-text">\n						<i class="mdi-content-add icon"></i>\n            <span>' +
((__t = ( _.i18n('share.new') )) == null ? '' : __t) +
'</span>\n					</div>\n				</div> \n			';
 } ;
__p += '\n			<table id="jq-grid-share-' +
((__t = ( share )) == null ? '' : __t) +
'" role="grid"></table>\n			<div id="jq-grid-pager-share-' +
((__t = ( share )) == null ? '' : __t) +
'"></div>\n		</div>\n	</div>\n</div>';

}
return __p
};});

