/**
 * Created by BFR on 04/03/2020.
 */
define('shareOptionCategoryView',[
    'module',
    'marionette',
    'app',
    'underscore',
    'template!shareOptionCategoryTpl',
    'shareOptionsView',
    'entities/product_share'
  ], function(
    module,
    Marionette,
    App,
    _,
    viewTpl,
    ShareOptionsView
  ) {
    'use strict';
  
    var ShareOptionCategoryView =  Marionette.LayoutView.extend({
      template: viewTpl,
  
      regions: {
        shareOptions: '.share-options'
      },

      events: {
        'change .cbx-optioncategory': 'onSelectionChange'
      },

      onSelectionChange: function(event) {
        this.trigger('item:selection:change');
        var cbx = $(event.target);
        if (cbx.is(':checked')) {
          _.each(this.model.get('productOptions').models, function(pz) {
            if(!pz.get('option')) {
              pz.set('option', { name: pz.get('parentOption').get('name') });
            }
          });
          this.model.set('optionCategory', { });
        } else {
          _.each(this.model.get('productOptions').models, function(pz) {
            if(pz.get('option')) {
              pz.set('option', null);
            }
          });
          this.model.set('optionCategory', null);
        }
        this.shareOptionsView.render();
      },

      onShow: function () {
        this.shareOptionsView = new ShareOptionsView({ collection: this.model.get('productOptions')});
        this.shareOptionsView.listenTo(this.shareOptionsView, 'item:selection:change', _.bind(this.onItemSelectionChange, this));
        this.getRegion('shareOptions').show(this.shareOptionsView);
      },

      onItemSelectionChange: function() {
        this.trigger('item:selection:change');
      },
  
      serializeData: function () {
        var templateData = this.options.model.toJSON();
        templateData.checked = templateData.optionCategory?true:false;
        return templateData;
      }
    });
  
    module.exports = ShareOptionCategoryView;
  });
