
define('template!timetrackerFiltersTpl', ['lodash'], function(_) {return function(obj) {
obj || (obj = {});
var __t, __p = '', __j = Array.prototype.join;
function print() { __p += __j.call(arguments, '') }
with (obj) {
__p += '<!-- Date from -->\n<div class="form-group col-xs-3">\n  <div class="input-group">\n      <span class="input-group-addon">' +
((__t = ( _.i18n("common.fromDate") )) == null ? '' : __t) +
'</span>\n      <input type="text" class="form-control date-timetracker date-from" placeholder="' +
((__t = ( dateFrom )) == null ? '' : __t) +
'" value="' +
((__t = ( dateFrom )) == null ? '' : __t) +
'">\n      <span class="input-group-btn">\n          <span class="caret pull-right m-t-5"></span>\n      </span>\n  </div>\n</div>\n\n<!-- Date to -->\n<div class="form-group col-xs-3 date-to-form">\n  <div class="input-group">\n      <span class="input-group-addon">' +
((__t = ( _.i18n("common.toDate") )) == null ? '' : __t) +
'</span>\n      <input type="text" class="form-control date-timetracker date-to" placeholder="' +
((__t = ( dateTo )) == null ? '' : __t) +
'" value="' +
((__t = ( dateTo )) == null ? '' : __t) +
'">\n      <span class="input-group-btn">\n          <span class="caret pull-right m-t-5"></span>\n      </span>\n  </div>\n  <label class="date-validation-label"></label>\n</div>\n\n<!-- In Day/week/month -->\n<div class="col-xs-2">\n  <div class="dropdown">\n    <button class="form-control dropdown-toggle" type="button" id="calendarDropDownMenu" data-toggle="dropdown" aria-haspopup="true" aria-expanded="true">\n      <span class="pull-left">' +
((__t = ( _.i18n("common.inFormat") )) == null ? '' : __t) +
'</span>\n      <span class="filter-by-type-tt" data-type="Day">' +
((__t = ( _.i18n("common.days") )) == null ? '' : __t) +
'</span>\n      <span class="caret pull-right m-t-5"></span>\n    </button>\n    <ul class="dropdown-menu col-xs-12" aria-labelledby="calendarDropDownMenu">\n      <li class="item-type-tt" data-type="Day"><a>' +
((__t = ( _.i18n("common.days") )) == null ? '' : __t) +
'</a></li>\n      <li class="item-type-tt" data-type="Week"><a>' +
((__t = ( _.i18n("common.weeks") )) == null ? '' : __t) +
'</a></li>\n      <li class="item-type-tt" data-type="Month"><a>' +
((__t = ( _.i18n("common.months") )) == null ? '' : __t) +
'</a></li>\n    </ul>\n  </div>\n</div>\n\n<!-- User -->\n<div class="col-xs-3">\n  <div class="dropdown">\n    <button class="form-control dropdown-toggle user-chooser" type="button" id="userDropdownMenu" data-toggle="dropdown" aria-haspopup="true" aria-expanded="true">\n      <span class="pull-left">' +
((__t = ( _.i18n("common.user") )) == null ? '' : __t) +
'</span>\n      <span class="filter-by-user-tt" data-logonId="all">' +
((__t = ( _.i18n("common.all") )) == null ? '' : __t) +
'</span>\n      <span class="caret pull-right m-t-5 user-span"></span>\n      <span class="mdi-navigation-close hidden"></span>\n    </button>\n    <ul class="dropdown-menu col-xs-12 users-dropdown-data" aria-labelledby="userDropdownMenu">\n      <li class="user-filter-item" data-logonId="all"><a>' +
((__t = ( _.i18n("common.all") )) == null ? '' : __t) +
'</a></li>\n      ';
 _.each(users, function(user) { ;
__p += '\n        <li class="user-filter-item" data-logonId="' +
((__t = ( user.logonId )) == null ? '' : __t) +
'">\n          <a>\n            ';
 if (user.firstName && user.lastName) { ;
__p += '\n              ' +
((__t = ( user.firstName )) == null ? '' : __t) +
' ' +
((__t = ( user.lastName )) == null ? '' : __t) +
'\n            ';
 } else { ;
__p += '\n              ' +
((__t = ( user.logonId )) == null ? '' : __t) +
'\n            ';
 } ;
__p += '\n          </a>\n        </li>\n      ';
 }); ;
__p += '\n    </ul>\n  </div>\n</div>';

}
return __p
};});

