/**
 * Created by BFR on 06/12/2019.
 */
define('trackingDetailView',[
  'module',
  'trackingCreateEditLayoutView',
  'underscore',
  'marionette',
  'template!trackingDetailTabTpl'
], function (
  module,
  CreateEditLayoutView,
  _,
  Marionette,
  viewTpl
) {
  'use strict';

  var TrackingDetailView = Marionette.ItemView.extend({
    template: viewTpl,

    modelEvents: {
      change: 'render'
    },

    events: {
      'click .js-edit': '_onEdit'
    },

    onShow: function() {
      $.material.init();
    },

    serializeData: function () {
      var templateData = this.model.toJSON();
      return templateData;
    },

    _onEdit: function() {
      var createEditView = new CreateEditLayoutView({
        trackingModel: this.model
      });
      createEditView.show({
        title: _.i18n('tracking.edit')
      });
    }

  });

  module.exports = TrackingDetailView;
});
