/**
 * Created by BFR on 04/03/2020.
 */
define('shareOptionView',[
    'module',
    'marionette',
    'app',
    'underscore',
    'template!shareOptionTpl',
    'entities/product_share'
  ], function(
    module,
    Marionette,
    App,
    _,
    viewTpl
  ) {
    'use strict';
  
    var ShareOptionView =  Marionette.LayoutView.extend({
      template: viewTpl,
  
      regions: {
      },

      onShow: function () {
      },

      events: {
        'change .cbx-option': 'onSelectionChange'
      },

      onSelectionChange: function(event) {
        this.trigger('item:selection:change');
        var cbx = $(event.target);
        if (cbx.is(':checked')) {
          this.model.set('option', { });
        } else {
          this.model.set('option', null);
        }
      },

      serializeData: function () {
        var templateData = this.options.model.toJSON();
        templateData.checked = templateData.option?true:false;
        return templateData;
      }
    });
  
    module.exports = ShareOptionView;
  });
