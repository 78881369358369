
define('template!messagesAllTpl', ['lodash'], function(_) {return function(obj) {
obj || (obj = {});
var __t, __p = '', __e = _.escape, __j = Array.prototype.join;
function print() { __p += __j.call(arguments, '') }
with (obj) {
__p += '<div class="loading-block js-loading-block">\n	<span class="loading-message js-loading-message label"></span>\n</div>\n<div class="row tickets-message-container">\n	';
 if (direction === 'from') { ;
__p += '\n		<div class="col-xs-3 col-sm-1 avatar-section p-r-0">\n			';
 if (fileUrl) { ;
__p += '\n				<div class="avatar-in-msg" style="background-image: url(' +
((__t = ( fileUrl )) == null ? '' : __t) +
')"></div>\n			';
 } else { ;
__p += '\n				<i class="mdi-action-account-circle avatar-messages-block"></i>\n			';
 } ;
__p += '\n			';
 if (userPid && userPid.logonId) { ;
__p += '\n				<p class="first-name hidden-xs" title="' +
((__t = ( userPid.logonId )) == null ? '' : __t) +
'" data-toggle="tooltip" data-placement="right">\n					' +
((__t = ( userPid.logonId )) == null ? '' : __t) +
'\n				</p>\n			';
 } ;
__p += '\n		</div>\n		<div class="col-xs-8 col-sm-7 message-section p-l-0">\n			<div class="row">\n				<div class="col-xs-12 date-container">\n					<i class="mdi-action-alarm"></i>\n					<span class="m-time">' +
((__t = ( time )) == null ? '' : __t) +
'</span>\n					<i class="mdi-action-today"></i>\n					<span class="m-day">' +
((__t = ( day )) == null ? '' : __t) +
'</span>\n				</div>\n			</div>\n			<div class="row">\n				<div class="col-xs-12">\n					<div class="well message-container">\n                    ';
 if (vectorizator && content) { ;
__p += '\n                        ';
 if (showCustomerMsg && !csMsg) { ;
__p += '\n                        <a  tabindex="0" role="button" class="btn pull-right btn-xs btn-flat btn-duplicate-msg"><i class="mdi-content-content-copy"></i></a>\n                        ';
 } ;
__p += '\n                      <a  tabindex="0" role="button" class="btn pull-right btn-xs btn-flat translation" data-to-translate="' +
__e( content ) +
'"><i class="mdi-action-translate"></i></a>\n                        ';
 } ;
__p += '\n                      <p>' +
__e( content.replace(/<[^>]br>|<br[^>]*>/g, '\n') ) +
'</p>\n						<div class="row">\n							';
 _.each(attachements, function(item, index) { ;
__p += '\n							<div class="attach-block">\n								<div class="attachment-container">\n									<div class="attached-image-container">\n										<div data-name="' +
((__t = ( item.fileName )) == null ? '' : __t) +
'" class="js-image-cell js-' +
((__t = ( index )) == null ? '' : __t) +
' attachment-in-m attach-' +
((__t = ( index )) == null ? '' : __t) +
'"></div>\n									</div>\n									<div><span class="attach-filename">' +
((__t = ( item.fileName )) == null ? '' : __t) +
'</span></div>\n                                    ';
 if (vectorizator) { ;
__p += '\n                  <div class="attachment-file-link js-attachment-file js-' +
((__t = ( index )) == null ? '' : __t) +
'">\n                    <a href="' +
((__t = ( item.downloadLink)) == null ? '' : __t) +
'">' +
((__t = ( item.fileName )) == null ? '' : __t) +
'</a>\n                  </div>\n\n									    <a class="btn btn-primary download-btn-text btn-sm  attach-' +
((__t = ( index )) == null ? '' : __t) +
'" href="' +
((__t = ( item.downloadLink)) == null ? '' : __t) +
'">\n\n                    <i class="mdi-action-get-app"></i>\n                    <span>' +
((__t = ( _.i18n('common.download') )) == null ? '' : __t) +
'</span>\n									</a>\n                                    ';
 } ;
__p += '\n\n									';
 if (vectorizator) { ;
__p += '\n									<!-- Upload btn -->\n										<a href="' +
((__t = ( item.vectorizedLink )) == null ? '' : __t) +
'" class="btn add-btn-text file-btn btn-sm load-vectorized-pdf" target="_blank">\n											<i class="mdi-file-file-upload icon"></i>\n										</a>\n									';
 } ;
__p += '\n\n								</div>\n							</div>\n							';
 }); ;
__p += '\n						</div>\n					</div>\n				</div>\n			</div>\n		</div>\n	';
 } else { ;
__p += '\n		<div class="col-xs-offset-1 col-sm-offset-4 col-xs-8 col-sm-7 message-section p-r-0">\n			<div class="row">\n				<div class="col-xs-12 date-container">\n					<i class="mdi-action-alarm"></i>\n					<span class="m-time">' +
((__t = ( time )) == null ? '' : __t) +
'</span>\n					<i class="mdi-action-today"></i>\n					<span class="m-day">' +
((__t = ( day )) == null ? '' : __t) +
'</span>\n				</div>\n			</div>\n			<div class="row">\n				<div class="col-xs-12">\n					<div class="well message-container">\n                    ';
 if (showCustomerMsg && !csMsg) { ;
__p += '\n                    <a  tabindex="0" role="button" class="btn pull-right btn-xs btn-flat btn-duplicate-msg"><i class="mdi-content-content-copy"></i></a>\n                    ';
 } ;
__p += '\n                      <p>' +
__e( content.replace(/<[^>]br>|<br[^>]*>/g, '\n') ) +
'</p>\n						<div class="row">\n							';
 _.each(attachements, function(item, index) { ;
__p += '							\n							<div data-fileid="' +
((__t = ( item.fileIds )) == null ? '' : __t) +
'" class="attach-block">\n								';
 if (csMsg) { ;
__p += '\n									<i data-fileid="' +
((__t = ( item.fileIds )) == null ? '' : __t) +
'" class="fa fa-remove fa-fw js-remove-attachment clickable"></i>\n								';
 } ;
__p += '\n								<div class="attachment-container">									\n									<div class="attached-image-container">\n										<div data-name="' +
((__t = ( item.fileName )) == null ? '' : __t) +
'" class="js-image-cell js-' +
((__t = ( index )) == null ? '' : __t) +
' attachment-in-m attach-' +
((__t = ( index )) == null ? '' : __t) +
'"></div>										\n									</div>\n									<div><span class="attach-filename">' +
((__t = ( item.fileName )) == null ? '' : __t) +
'</span></div>\n                  <div class="attachment-file-link js-attachment-file js-' +
((__t = ( index )) == null ? '' : __t) +
'">\n                    <a href="' +
((__t = ( item.download)) == null ? '' : __t) +
'">' +
((__t = ( item.fileName )) == null ? '' : __t) +
'</a>\n                  </div>\n									<a class="btn btn-primary download-btn-text btn-sm attach-' +
((__t = ( index )) == null ? '' : __t) +
'" href="' +
((__t = ( item.download)) == null ? '' : __t) +
'">\n										<i class="mdi-action-get-app"></i>\n                    <span>' +
((__t = ( _.i18n('common.download') )) == null ? '' : __t) +
'</span>\n									</a>\n\n									';
 if (vectorizator) { ;
__p += '\n										<!-- Upload btn -->\n										<a href="' +
((__t = ( item.vectorizedLink )) == null ? '' : __t) +
'" class="btn add-btn-text file-btn btn-sm load-vectorized-pdf" target="_blank">\n											<i class="mdi-file-file-upload icon"></i>\n										</a>\n									';
 } ;
__p += '\n\n								</div>\n							</div>\n							';
 }); ;
__p += '\n						</div>\n            <hr>\n            <iframe src="https://www.facebook.com/plugins/like.php?href=https%3A%2F%2Fwww.facebook.com%2FCompuzzcom-579478545536642&width=450&layout=standard&action=like&size=small&show_faces=true&share=false&height=80&appId=156694311047146" width="450" height="80" style="border:none;overflow:hidden" scrolling="no" frameborder="0" allowTransparency="true"></iframe>\n          </div>\n				</div>\n			</div>\n		</div>\n		<div class="col-xs-3 avatar-section p-l-0 col-sm-1">\n			';
 if (fileUrl) { ;
__p += '\n				<div class="avatar-in-msg" style="background-image: url(' +
((__t = ( fileUrl )) == null ? '' : __t) +
')"></div>\n			';
 } else { ;
__p += '\n				<i class="mdi-action-account-circle avatar-messages-block"></i>\n			';
 } ;
__p += '\n			';
 if (userPid && userPid.firstName) { ;
__p += '\n				<p class="first-name hidden-xs" title="' +
((__t = ( userPid.firstName )) == null ? '' : __t) +
'" data-toggle="tooltip" data-placement="right">\n					' +
((__t = ( userPid.firstName )) == null ? '' : __t) +
'\n				</p>\n			';
 } ;
__p += '\n		</div>\n	';
 } ;
__p += '\n</div>';

}
return __p
};});

