
define('template!autocompleteTpl', ['lodash'], function(_) {return function(obj) {
obj || (obj = {});
var __t, __p = '', __j = Array.prototype.join;
function print() { __p += __j.call(arguments, '') }
with (obj) {
__p += '<input\n  ';
 if (inputType === 'dropdown') { ;
__p += 'type="search"';
 } ;
__p += '\n  class="';
 if (inputType === 'dropdown') { ;
__p += 'btn btn-dropdown';
 } ;
__p += ' autocomplete form-control js-form-input floating-label"\n  data-field-name="' +
((__t = ( fieldName )) == null ? '' : __t) +
'"\n  placeholder="' +
((__t = ( placeholder )) == null ? '' : __t) +
'"\n  value="' +
((__t = ( value )) == null ? '' : __t) +
'">\n';
 if (inputType === 'dropdown') { ;
__p += '\n  <i class="mdi-navigation-arrow-drop-down autocomplete-dropdown js-dropdown-icon" style="cursor: pointer"></i>\n';
 } ;


}
return __p
};});

