
define('template!timetrackerTableMonthTpl', ['lodash'], function(_) {return function(obj) {
obj || (obj = {});
var __t, __p = '', __j = Array.prototype.join;
function print() { __p += __j.call(arguments, '') }
with (obj) {
__p += '<div class="dateTimeWrapper table-responsive">\n  <table cellspacing="0" cellpadding="0" class="date-table-tt">\n    <thead class="">\n      <tr>\n        <th class="tt-caption-table tt-user-caption" colspan="2">' +
((__t = ( _.i18n("common.user") )) == null ? '' : __t) +
'</th>\n        ';
 _.each(calendar, function(item) { ;
__p += '\n        <th class="tt-caption-table">          \n          <div class="dateHolder">            \n            <div class ="month dateCurrent">' +
((__t = ( item.name )) == null ? '' : __t) +
'</div>\n          </div>\n        </th>\n        ';
 }); ;
__p += '\n        <th class="tt-total-per-user-caption tt-caption-table">' +
((__t = ( _.i18n("common.total") )) == null ? '' : __t) +
'</th>\n        <th class="tt-total-per-user-caption tt-caption-table">' +
((__t = ( _.i18n("timetracking.totalDone") )) == null ? '' : __t) +
'</th>\n        <th class="tt-total-per-user-caption tt-caption-table">' +
((__t = ( _.i18n("timetracking.totalProgress") )) == null ? '' : __t) +
'</th>\n        <th class="tt-total-per-user-caption tt-caption-table">' +
((__t = ( _.i18n("timetracking.totalOther") )) == null ? '' : __t) +
'</th>\n      </tr>\n    </thead>\n    <tbody>\n    ';
 if (_.size(times) !== 0) { ;
__p += '\n    ';
 _.each(times, function(user, userIndex) { ;
__p += '\n    <tr data-index="' +
((__t = ( userIndex )) == null ? '' : __t) +
'">\n      <td class="user-name tt-logo-td tt-time-val" data-user="' +
((__t = ( user.logonId )) == null ? '' : __t) +
'" data-index="' +
((__t = ( userIndex )) == null ? '' : __t) +
'">\n        ';
 if (user.logo) { ;
__p += '\n        <img class="img-circle" src="' +
((__t = ( user.logoUrl )) == null ? '' : __t) +
'"/>\n        ';
 } else { ;
__p += '\n        <i class="mdi-action-account-circle"></i>\n        ';
 } ;
__p += '\n        <div class="user-name__status">\n          ';
 if (user.online == true) { ;
__p += '\n          <i class="user-name__status--check-green mdi-action-check-circle"></i>\n          ';
 } else { ;
__p += '\n          <i class="user-name__status--unchecked-green mdi-toggle-radio-button-off"></i>\n          ';
 } ;
__p += '\n        </div>\n      </td>\n      <td class="user-name tt-time-val" data-user="' +
((__t = ( user.logonId )) == null ? '' : __t) +
'" data-index="' +
((__t = ( userIndex )) == null ? '' : __t) +
'">\n        ';
 if (user.user !== " ") { ;
__p += '\n          ' +
((__t = ( user.user )) == null ? '' : __t) +
'\n        ';
 } else { ;
__p += '\n          ' +
((__t = ( user.logonId )) == null ? '' : __t) +
'\n        ';
 } ;
__p += '\n      </td>\n      ';
 _.each(calendar, function(item) { ;
__p += '\n        <td class="tt-time-val totals-val"> \n          ';
 _.each(user.totals, function(total) { ;
__p += '               \n            ';
 if (total.day === item.date) { ;
__p += '\n              <div class ="date-total-val" data-day="' +
((__t = ( total.day )) == null ? '' : __t) +
'" data-user="' +
((__t = ( user.logonId )) == null ? '' : __t) +
'">' +
((__t = ( total.value )) == null ? '' : __t) +
'</div>\n                <!--<div class="progress">\n                    <div data-toggle="tooltip" title="' +
((__t = ( total.activity )) == null ? '' : __t) +
'%" class="clickable progress-bar ' +
((__t = ( total.activity>50?'progress-bar-success':total.activity>25?'progress-bar-warning':'progress-bar-danger' )) == null ? '' : __t) +
'" role="progressbar"\n                         aria-valuenow="' +
((__t = ( total.activity )) == null ? '' : __t) +
'" aria-valuemin="0" aria-valuemax="100" style="width:' +
((__t = ( total.activity )) == null ? '' : __t) +
'%">\n                        ' +
((__t = ( total.activity )) == null ? '' : __t) +
'%\n                    </div>\n                </div>-->\n            ';
 } ;
__p += '\n          ';
 }); ;
__p += '\n        </td>\n      ';
 }); ;
__p += '\n        ';
 _.each(totalPerUser, function(perUser) { ;
__p += '\n        ';
 if (perUser.logonId === user.logonId) { ;
__p += '\n        <td class="total-per-user tt-time-val" data-user="' +
((__t = ( user.logonId )) == null ? '' : __t) +
'" data-index="' +
((__t = ( userIndex )) == null ? '' : __t) +
'">' +
((__t = ( perUser.mlsec )) == null ? '' : __t) +
'</td>\n        <td class="total-per-user' +
((__t = ( (perUser.ratio>100?"-red":"") )) == null ? '' : __t) +
' tt-time-val" data-user="' +
((__t = ( user.logonId )) == null ? '' : __t) +
'">' +
((__t = ( perUser.usedTime )) == null ? '' : __t) +
'/' +
((__t = ( perUser.estimatedTime )) == null ? '' : __t) +
' (' +
((__t = ( perUser.ratio )) == null ? '' : __t) +
'%)</td>\n        <td class="total-per-user tt-time-val" data-user="' +
((__t = ( user.logonId )) == null ? '' : __t) +
'">' +
((__t = ( perUser.usedTimeInProgress )) == null ? '' : __t) +
'/' +
((__t = ( perUser.estimatedTimeInProgress )) == null ? '' : __t) +
'</td>\n        <td class="total-per-user tt-time-val" data-user="' +
((__t = ( user.logonId )) == null ? '' : __t) +
'">' +
((__t = ( perUser.totalOther )) == null ? '' : __t) +
'</td>\n        ';
 } ;
__p += '\n        ';
 }); ;
__p += '\n    </tr>\n    ';
 }); ;
__p += '\n    ';
 } else { ;
__p += '\n      <tr>\n          <td class="empty-row-tt" colspan="' +
((__t = ( _.size(calendar) + 2 )) == null ? '' : __t) +
'">' +
((__t = ( _.i18n("common.noData") )) == null ? '' : __t) +
'</td>\n        </tr>\n    ';
 } ;
__p += '\n    </tbody>\n  </table>\n</div>';

}
return __p
};});

