define( 'entities/price/price_billing_table',[
  'app',
  'backbone',
  'settings'
], function(
  App,
  Backbone,
  Settings
) {
  'use strict';

   /**
   * Model
   */
  var BillingCodesModel = Backbone.Model.extend({
      defaults: {
        code: '',
        name: '',
        billingCodeType: '',
        secId: ''
      }
  });

  var BillingPriceDetailModel = Backbone.Model.extend({
    defaults: {
      secId: '', 
      quantity: null,
      costPrice1:null,
      leadTime1:null,
      rsPrice1:null,
      editable: false,
      tabName: ''
    }
  });

  var PriceListsModel = Backbone.Model.extend({
    defaults: {
      id:null,
      code:'',
      name:'',
      validityFrom: null,
      validityTo: null,
      prices: [],
      priceAlgo: '',
      algoParam1: null
    }
  });
  
  /**
   * REST API
   */
  var API = {
    postBillingCodeData: function(data, listCode) {
      var defer = $.Deferred(),
        _listCode = listCode ? listCode : null;

      $.ajax({
        type: 'POST',
        url: Settings.url('compuzz', 'v2/billingcodes', {listCode: _listCode}),
        data: JSON.stringify(data),
        processData: false,
        contentType: 'application/json',
        success: function(resp) {
          defer.resolve(resp);
        },
        error: function (resp) {
          defer.reject(resp);
        }
      });

      return defer.promise();
    },

    sendDeleteBillingCode: function(secId) {
      var defer = $.Deferred();

      $.ajax({
        type: 'DELETE',
        url: Settings.url('compuzz', 'v2/billingcodes/' + secId),
        success: function () {
          defer.resolve();
        }
      });

      return defer.promise();
    },

    postBillingDetailsData: function(data, secId) {
      var defer = $.Deferred();

      $.ajax({
        type: 'POST',
        url: Settings.url('compuzz', 'v2/billingcodes/' + secId + '/prices'),
        data: JSON.stringify(data),
        processData: false,
        contentType: 'application/json',
        success: function(resp) {
          defer.resolve(resp);
        },
        error: function (resp) {
          defer.reject(resp);
        }
      });

      return defer.promise();
    },

    importExcelFile: function(fileInfo, listCode) {
      var defer = $.Deferred(),
        formdata =  new FormData(),
        url = Settings.url('compuzz', 'prices.json/priceItems/excel', {listCode: listCode});

      formdata.append('file', fileInfo.base64Value);
      formdata.append('filename', fileInfo.fileName);

      $.ajax({
        type: 'POST',
        url: url,
        data: formdata,
        processData: false,
        contentType: false,
        success: function(resp) {
          defer.resolve(resp);
        }
      });

      return defer.promise();
    },

    sendDeletePriceList: function(listSecId, secId) {
      var defer = $.Deferred();

      $.ajax({
        type: 'DELETE',
        url: Settings.url('compuzz', 'v2/billingcodes/' + secId + '/prices/' + listSecId),
        success: function () {
          defer.resolve();
        }
      });

      return defer.promise();
    },

    sendUpdateBillingCode: function(data, listCode) {
      var defer = $.Deferred(),
        _listCode = listCode ? listCode : null;

      $.ajax({
        type: 'PUT',
        url: Settings.url('compuzz', 'v2/billingcodes', {listCode: _listCode}),
        data: JSON.stringify(data),
        processData: false,
        contentType: 'application/json',
        success: function () {
          defer.resolve();
        },
        error: function (data) {
          defer.reject(data);
        }
      });

      return defer.promise();
    },

    sendUpdatePriceItem: function(data, secId, listCode) {
      var defer = $.Deferred(),
        _listCode = listCode ? listCode : null;

      $.ajax({
        type: 'PUT',
        url: Settings.url('compuzz', 'v2/billingcodes/' + secId + '/prices', {listCode: _listCode}),
        data: JSON.stringify(data),
        processData: false,
        contentType: 'application/json',
        success: function () {
          defer.resolve();
        }
      });

      return defer.promise();
    },

    getPriceModel: function(params) {
      if (params) {
        return new BillingPriceDetailModel(params);
      } else {
        return new BillingPriceDetailModel();
      }
    },

    getCodeModel: function(params) {
        if (params) {
          return new BillingCodesModel(params);
        } else {
          return new  BillingCodesModel();
        }        
    },

    getPriceListModel: function(params) {
      if (params) {
        return new  PriceListsModel(params);
      } else {
        return new  PriceListsModel();
      }
    }
  };

  /**
   * Request communications
   */
  
  App.reqres.setHandler('billingcodes:get-collection-url', function(listCode) {
    var params = {};

    params.listCode = listCode ? listCode : null;
    return Settings.url('compuzz', 'v2/billingcodes', params);
  });
  App.reqres.setHandler('billingcodes:row-count-url', function() {
    return 'v2/billingcodes/rowCount';
  });
  App.reqres.setHandler('billingdetails:get-collection-url', function(id, listCode) {
    if (listCode) {
      var params = {};

      params.listCode = listCode ? listCode : null;
      params.searchValue = id;
      return Settings.url('compuzz', 'prices.json/priceItems', params);
    } else {
      return Settings.url('compuzz', 'v2/billingcodes/'+id+'/prices');
    }

  });
  App.reqres.setHandler('billingdetails:row-count-url', function(id, listCode) {
    if (listCode) {
      return 'prices.json/priceItems/rowCount?' + id;
    } else {
      return 'v2/billingcodes/'+id+'/prices/rowCount?';
    }
  });
  App.reqres.setHandler('billingcode:send-post', function(data, listCode) {
    return API.postBillingCodeData(data, listCode);
  });
  App.reqres.setHandler('billingcode:send-delete', function(secId) {
    return API.sendDeleteBillingCode(secId);
  });
  App.reqres.setHandler('pricelists:send-delete', function(listSecId, secId) {
    return API.sendDeletePriceList(listSecId, secId);
  });
  App.reqres.setHandler('billingcode:send-update', function(data, listCode) {
    return API.sendUpdateBillingCode(data, listCode);
  });
  App.reqres.setHandler('pricelists:send-update', function(data, secId, listCode) {
    return API.sendUpdatePriceItem(data, secId, listCode);
  });
  App.reqres.setHandler('billingdetails:send-post', function(data, secId) {
    return API.postBillingDetailsData(data, secId);
  });
  App.reqres.setHandler('billingcode:get-model', function(params) {
    return API.getCodeModel(params);
  });
  App.reqres.setHandler('billingdetails:get-model', function(params) {
    return API.getPriceModel(params);
  });
  App.reqres.setHandler('billingcodes:upload-excel', function(fileInfo, listCode) {
    return API.importExcelFile(fileInfo, listCode);
  });
  App.reqres.setHandler('pricelist:get-price-model', function(params) {
    return API.getPriceListModel(params);
  });
  App.reqres.setHandler('pricelist:get-collection-url', function() {
    var defer = $.Deferred();

      $.ajax({
        type: 'GET',
        url: Settings.url('compuzz', 'priceLists.json', {first: '0', pageSize: 45}),
        success: function (data) {
          defer.resolve(data);
        }
      });

      return defer.promise();
  });
  App.reqres.setHandler('pricelist:get-count-url', function() {
    return 'priceLists.json/rowCount';
  });
  App.reqres.setHandler('pricedlist:send-deleteitem', function(pricelistId) {
    var defer = $.Deferred();

    $.ajax({
      type: 'DELETE',
      url: Settings.url('compuzz', 'priceLists.json/' + pricelistId),
      complete: function () {
        defer.resolve();
      }
    });

    return defer.promise();
  });
  
  App.reqres.setHandler('pricelist:post-new-price', function(data) {
    var defer = $.Deferred();

      $.ajax({
        type: 'POST',
        url: Settings.url('compuzz', 'priceLists.json'),
        data: JSON.stringify(data),
        processData: false,
        contentType: 'application/json',
        success: function(resp) {
          defer.resolve(resp);
        },
        error: function(jqXHR, textStatus, errorThrown) {
          defer.reject(jqXHR, textStatus, errorThrown);
        }
      });

      return defer.promise();
  });
  App.reqres.setHandler('pricelist:update-pricedropdown', function(data) {
    var defer = $.Deferred();

      $.ajax({
        type: 'PUT',
        url: Settings.url('compuzz', 'priceLists.json'),
        data: JSON.stringify(data),
        processData: false,
        contentType: 'application/json',
        success: function(resp) {
          defer.resolve(resp);
        }
      });

      return defer.promise();
  });
});
