
define('template!translationCatTpl', ['lodash'], function(_) {return function(obj) {
obj || (obj = {});
var __t, __p = '';
with (obj) {
__p += '<div class="row">\n    <div class="col-xs-12 p-b-10">\n        <div class="col-xs-6">\n            <div class="col-xs-4 text-right">' +
((__t = ( code )) == null ? '' : __t) +
'</div>\n            <div class="col-xs-8 name-region"></div>\n        </div>\n        <div class="translation-options col-xs-6">\n        </div>\n    </div>\n</div>';

}
return __p
};});

