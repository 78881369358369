
define('template!customerTemplatesFiltersTpl', ['lodash'], function(_) {return function(obj) {
obj || (obj = {});
var __t, __p = '', __j = Array.prototype.join;
function print() { __p += __j.call(arguments, '') }
with (obj) {
__p += '<div class="form-group">\n	<div class="templates-filters">\n		<div class="templates-filters-label">\n			<h2>' +
((__t = ( _.i18n('template.filteringBy') )) == null ? '' : __t) +
'</h2>\n		</div>\n\n		<div class="col-xs-2 name-filter ">\n			<div class="row">\n				<div class="col-xs-9 p-l-0">\n					<div class="form-control-wrapper">\n						<input type="text" class="form-control js-info-input lowercase-input filters-input empty" id="" data-field-name="name" value="">\n						<div class="floating-label">' +
((__t = ( _.i18n('common.name') )) == null ? '' : __t) +
'</div>\n						<span class="material-input"></span>\n						<div class="filters-search-clear" headers="">\n							<a title="Reset Filer Value" class="reset-icon clearsearchclass">x</a>\n							<i class="search-icon mdi-action-search"></i>\n						</div>\n					</div>\n				</div>\n			</div>\n		</div>\n\n		<div class="col-xs-2 standard-sizes-filter ">\n			<div class="row">\n				<div class="col-xs-11 p-l-0">\n					<select class="form-control filters-selector" data-field-name="standardSizeSecId" value="">\n						<option value="all" selected>' +
((__t = ( _.i18n('common.dropdownAll') + " " + _.i18n('template.standardSizes') + " (" + allCount + ")" )) == null ? '' : __t) +
'</option>\n						';
 _.each(filters, function(filter){ ;
__p += '\n							<option value="' +
((__t = ( filter['0'].secId )) == null ? '' : __t) +
'">' +
((__t = ( filter[0].nameTranslated + " (" + filter['1'] + ")" )) == null ? '' : __t) +
'</option>\n						';
 }); ;
__p += '	              \n			    	</select>\n				</div>\n		    </div>\n	    </div>\n	    <div class="col-xs-2 raster-filter ">\n	    	<div class="row">\n				<div class="col-xs-11 p-l-0">\n					<select class="form-control filters-selector" data-field-name="hasRasterImages" value="">\n						<option value="all" selected>' +
((__t = ( _.i18n('template.withAndWithoutRasterImage') )) == null ? '' : __t) +
'</option>\n						<option value="true">' +
((__t = ( _.i18n('template.withRasterImage') )) == null ? '' : __t) +
'</option>\n						<option value="false">' +
((__t = ( _.i18n('template.withoutRasterImage') )) == null ? '' : __t) +
'</option>\n			    	</select>\n				</div>\n		    </div>\n	    </div>\n	    <div class="col-xs-2 text-filter ">\n	    	<div class="row">\n				<div class="col-xs-9 p-l-0">\n					<div class="form-control-wrapper">\n						<input type="text" class="form-control js-info-input lowercase-input filters-input empty" id="" data-field-name="texts" value="">\n						<div class="floating-label">' +
((__t = ( _.i18n('template.text') )) == null ? '' : __t) +
'</div>\n						<span class="material-input"></span>\n						<div class="filters-search-clear" headers="">\n							<a title="Reset Filer Value" class="reset-icon clearsearchclass">x</a>\n							<i class="search-icon mdi-action-search"></i>\n						</div>\n					</div>\n				</div>\n		    </div>\n	    </div>\n	    <div class="col-xs-2 font-filter ">\n	    	<div class="row">\n				<div class="col-xs-9 p-l-0">\n					<div class="form-control-wrapper">\n						<input type="text" class="form-control js-info-input lowercase-input filters-input empty" id="" data-field-name="fonts" value="">\n						<div class="floating-label">' +
((__t = ( _.i18n('template.font') )) == null ? '' : __t) +
'</div>\n						<span class="material-input"></span>\n						<div class="filters-search-clear" headers="">\n							<a title="Reset Filer Value" class="reset-icon clearsearchclass">x</a>\n							<i class="search-icon mdi-action-search"></i>\n						</div>\n					</div>\n				</div>\n		    </div>\n	    </div>\n	    <div class="col-xs-2 color-filter ">\n	    	<div class="row">\n				<div class="col-xs-9 p-l-0">\n					<div class="form-control-wrapper">\n						<input type="text" class="form-control js-info-input lowercase-input filters-input empty" id="" data-field-name="colors" value="">\n						<div class="floating-label">' +
((__t = ( _.i18n('template.color') )) == null ? '' : __t) +
'</div>\n						<span class="material-input"></span>\n						<div class="filters-search-clear" headers="">\n							<a title="Reset Filer Value" class="reset-icon clearsearchclass">x</a>\n							<i class="search-icon mdi-action-search"></i>\n						</div>\n					</div>\n				</div>\n		    </div>\n	    </div>\n\n	</div>\n	<div class="h-divider"></div>\n</div>';

}
return __p
};});

