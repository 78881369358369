/**
 * Created by BFR on 26/12/2019.
 */
define('orderUtils',[
  'module',
  'underscore',
  'app',
  'settings',
  'jquery',
  'historyPopupView',
  'contextMenu',
  'entities/orders',
  'entities/orders_kanban'
], function(
  module,
  _,
  App,
  Settings,
  $,
  HistoryPopupView
) {
  'use strict';

  var OrderUtils = {

      printOurselfPrice: {
        mumuku: {
          costSetup: 10,
          setup: 20,
          costUnit: 1,
          unit: 2
        },
        flex: {
          costSetup: 15,
          setup: 25,
          costUnit: 1.25,
          unit: 2.25
        },
        flexDigital: {
          surface: [{
            from: 0,
            to: 20.88,
            scale: [{
                qty : 10,  
                costSetup: 0,
                costUnit: 1.23
              },{
                qty: 15,
                costSetup: 0,
                costUnit: 1.05
              },{
                qty: 20,
                costSetup: 0,
                costUnit: 0.76
              },{
                qty: 30,
                costSetup: 0,
                costUnit: 0.65
              },{
                qty: 40,
                costSetup: 0,
                costUnit: 0.51
              },{
                qty: 50,
                costSetup: 0,
                costUnit: 0.47
              },{
                qty: 60,
                costSetup: 0,
                costUnit: 0.43
              },{
                qty: 70,
                costSetup: 0,
                costUnit: 0.40
              },{
                qty: 80,
                costSetup: 0,
                costUnit: 0.36
              },{
                qty: 90,
                costSetup: 0,
                costUnit: 0.35
              },{
                qty: 100,
                costSetup: 0,
                costUnit: 0.34
              },{
                qty: 150,
                costSetup: 0,
                costUnit: 0.28
              },{
                qty: 200,
                costSetup: 0,
                costUnit: 0.25
              },{
                qty: 250,
                costSetup: 0,
                costUnit: 0.23
              },{
                qty: 300,
                costSetup: 0,
                costUnit: 0.22
              },{
                qty: 350,
                costSetup: 0,
                costUnit: 0.17
              },{
                qty: 400,
                costSetup: 0,
                costUnit: 0.15
              },{
                qty: 450,
                costSetup: 0,
                costUnit: 0.15
              },{
                qty: 500,
                costSetup: 0,
                costUnit: 0.15
              },{
                qty: 600,
                costSetup: 0,
                costUnit: 0.13
              },{
                qty: 700,
                costSetup: 0,
                costUnit: 0.13
              },{
                qty: 800,
                costSetup: 0,
                costUnit: 0.12
              },{
                qty: 900,
                costSetup: 0,
                costUnit: 0.12
              },{
                qty: 1000,
                costSetup: 0,
                costUnit: 0.11
              },{
                qty: 1200,
                costSetup: 0,
                costUnit: 0.11
              },{
                qty: 1500,
                costSetup: 0,
                costUnit: 0.11
              },{
                qty: 2000,
                costSetup: 0,
                costUnit: 0.11
              },{
                qty: 2500,
                costSetup: 0,
                costUnit: 0.11
              },{
                qty: 3000,
                costSetup: 0,
                costUnit: 0.1
              },{
                qty: 3500,
                costSetup: 0,
                costUnit: 0.1
              },{
                qty: 4000,
                costSetup: 0,
                costUnit: 0.1
              },{
                qty: 4500,
                costSetup: 0,
                costUnit: 0.1
              }
            ]
          },{
            from: 20.88,
            to: 51.62,
            scale: [{
                qty : 10,  
                costSetup: 0,
                costUnit: 1.59
              },{
                qty: 15,
                costSetup: 0,
                costUnit: 1.37
              },{
                qty: 20,
                costSetup: 0,
                costUnit: 1.06
              },{
                qty: 30,
                costSetup: 0,
                costUnit: 0.91
              },{
                qty: 40,
                costSetup: 0,
                costUnit: 0.76
              },{
                qty: 50,
                costSetup: 0,
                costUnit: 0.72
              },{
                qty: 60,
                costSetup: 0,
                costUnit: 0.65
              },{
                qty: 70,
                costSetup: 0,
                costUnit: 0.62
              },{
                qty: 80,
                costSetup: 0,
                costUnit: 0.58
              },{
                qty: 90,
                costSetup: 0,
                costUnit: 0.55
              },{
                qty: 100,
                costSetup: 0,
                costUnit: 0.53
              },{
                qty: 150,
                costSetup: 0,
                costUnit: 0.47
              },{
                qty: 200,
                costSetup: 0,
                costUnit: 0.39
              },{
                qty: 250,
                costSetup: 0,
                costUnit: 0.35
              },{
                qty: 300,
                costSetup: 0,
                costUnit: 0.27
              },{
                qty: 350,
                costSetup: 0,
                costUnit: 0.22
              },{
                qty: 400,
                costSetup: 0,
                costUnit: 0.20
              },{
                qty: 450,
                costSetup: 0,
                costUnit: 0.20
              },{
                qty: 500,
                costSetup: 0,
                costUnit: 0.19
              },{
                qty: 600,
                costSetup: 0,
                costUnit: 0.18
              },{
                qty: 700,
                costSetup: 0,
                costUnit: 0.17
              },{
                qty: 800,
                costSetup: 0,
                costUnit: 0.16
              },{
                qty: 900,
                costSetup: 0,
                costUnit: 0.15
              },{
                qty: 1000,
                costSetup: 0,
                costUnit: 0.15
              },{
                qty: 1200,
                costSetup: 0,
                costUnit: 0.15
              },{
                qty: 1500,
                costSetup: 0,
                costUnit: 0.14
              },{
                qty: 2000,
                costSetup: 0,
                costUnit: 0.14
              },{
                qty: 2500,
                costSetup: 0,
                costUnit: 0.14
              },{
                qty: 3000,
                costSetup: 0,
                costUnit: 0.13
              },{
                qty: 3500,
                costSetup: 0,
                costUnit: 0.13
              },{
                qty: 4000,
                costSetup: 0,
                costUnit: 0.13
              },{
                qty: 4500,
                costSetup: 0,
                costUnit: 0.12
              }
            ]
          },{
            from: 51.62,
            to: 117.48,
            scale: [{
                qty : 10,  
                costSetup: 0,
                costUnit: 2.23
              },{
                qty: 15,
                costSetup: 0,
                costUnit: 1.91
              },{
                qty: 20,
                costSetup: 0,
                costUnit: 1.59
              },{
                qty: 30,
                costSetup: 0,
                costUnit: 1.37
              },{
                qty: 40,
                costSetup: 0,
                costUnit: 1.21
              },{
                qty: 50,
                costSetup: 0,
                costUnit: 1.12
              },{
                qty: 60,
                costSetup: 0,
                costUnit: 1.04
              },{
                qty: 70,
                costSetup: 0,
                costUnit: 1.02
              },{
                qty: 80,
                costSetup: 0,
                costUnit: 0.92
              },{
                qty: 90,
                costSetup: 0,
                costUnit: 0.82
              },{
                qty: 100,
                costSetup: 0,
                costUnit: 0.74
              },{
                qty: 150,
                costSetup: 0,
                costUnit: 0.57
              },{
                qty: 200,
                costSetup: 0,
                costUnit: 0.53
              },{
                qty: 250,
                costSetup: 0,
                costUnit: 0.41
              },{
                qty: 300,
                costSetup: 0,
                costUnit: 0.33
              },{
                qty: 350,
                costSetup: 0,
                costUnit: 0.31
              },{
                qty: 400,
                costSetup: 0,
                costUnit: 0.28
              },{
                qty: 450,
                costSetup: 0,
                costUnit: 0.27
              },{
                qty: 500,
                costSetup: 0,
                costUnit: 0.26
              },{
                qty: 600,
                costSetup: 0,
                costUnit: 0.26
              },{
                qty: 700,
                costSetup: 0,
                costUnit: 0.23
              },{
                qty: 800,
                costSetup: 0,
                costUnit: 0.22
              },{
                qty: 900,
                costSetup: 0,
                costUnit: 0.21
              },{
                qty: 1000,
                costSetup: 0,
                costUnit: 0.21
              },{
                qty: 1200,
                costSetup: 0,
                costUnit: 0.20
              },{
                qty: 1500,
                costSetup: 0,
                costUnit: 0.20
              },{
                qty: 2000,
                costSetup: 0,
                costUnit: 0.19
              },{
                qty: 2500,
                costSetup: 0,
                costUnit: 0.19
              },{
                qty: 3000,
                costSetup: 0,
                costUnit: 0.19
              },{
                qty: 3500,
                costSetup: 0,
                costUnit: 0.18
              },{
                qty: 4000,
                costSetup: 0,
                costUnit: 0.18
              },{
                qty: 4500,
                costSetup: 0,
                costUnit: 0.17
              }
            ]
          },{
            from: 117.48,
            to: 256.08,
            scale: [{
                qty : 10,  
                costSetup: 0,
                costUnit: 3.35
              },{
                qty: 15,
                costSetup: 0,
                costUnit: 2.87
              },{
                qty: 20,
                costSetup: 0,
                costUnit: 2.55
              },{
                qty: 30,
                costSetup: 0,
                costUnit: 2.18
              },{
                qty: 40,
                costSetup: 0,
                costUnit: 2.06
              },{
                qty: 50,
                costSetup: 0,
                costUnit: 1.92
              },{
                qty: 60,
                costSetup: 0,
                costUnit: 1.77
              },{
                qty: 70,
                costSetup: 0,
                costUnit: 1.49
              },{
                qty: 80,
                costSetup: 0,
                costUnit: 1.2
              },{
                qty: 90,
                costSetup: 0,
                costUnit: 1.16
              },{
                qty: 100,
                costSetup: 0,
                costUnit: 1.1
              },{
                qty: 150,
                costSetup: 0,
                costUnit: 0.70
              },{
                qty: 200,
                costSetup: 0,
                costUnit: 0.63
              },{
                qty: 250,
                costSetup: 0,
                costUnit: 0.58
              },{
                qty: 300,
                costSetup: 0,
                costUnit: 0.54
              },{
                qty: 350,
                costSetup: 0,
                costUnit: 0.52
              },{
                qty: 400,
                costSetup: 0,
                costUnit: 0.49
              },{
                qty: 450,
                costSetup: 0,
                costUnit: 0.41
              },{
                qty: 500,
                costSetup: 0,
                costUnit: 0.40
              },{
                qty: 600,
                costSetup: 0,
                costUnit: 0.37
              },{
                qty: 700,
                costSetup: 0,
                costUnit: 0.35
              },{
                qty: 800,
                costSetup: 0,
                costUnit: 0.33
              },{
                qty: 900,
                costSetup: 0,
                costUnit: 0.32
              },{
                qty: 1000,
                costSetup: 0,
                costUnit: 0.31
              },{
                qty: 1200,
                costSetup: 0,
                costUnit: 0.31
              },{
                qty: 1500,
                costSetup: 0,
                costUnit: 0.29
              },{
                qty: 2000,
                costSetup: 0,
                costUnit: 0.29
              },{
                qty: 2500,
                costSetup: 0,
                costUnit: 0.29
              },{
                qty: 3000,
                costSetup: 0,
                costUnit: 0.28
              },{
                qty: 3500,
                costSetup: 0,
                costUnit: 0.28
              },{
                qty: 4000,
                costSetup: 0,
                costUnit: 0.27
              },{
                qty: 4500,
                costSetup: 0,
                costUnit: 0.26
              }
            ]
          },{
            from: 256.08,
            to: 545.14,
            scale: [{
                qty : 10,  
                costSetup: 0,
                costUnit: 5.35
              },{
                qty: 15,
                costSetup: 0,
                costUnit: 4.59
              },{
                qty: 20,
                costSetup: 0,
                costUnit: 4.33
              },{
                qty: 30,
                costSetup: 0,
                costUnit: 3.71
              },{
                qty: 40,
                costSetup: 0,
                costUnit: 2.52
              },{
                qty: 50,
                costSetup: 0,
                costUnit: 2.32
              },{
                qty: 60,
                costSetup: 0,
                costUnit: 2.05
              },{
                qty: 70,
                costSetup: 0,
                costUnit: 1.72
              },{
                qty: 80,
                costSetup: 0,
                costUnit: 1.48
              },{
                qty: 90,
                costSetup: 0,
                costUnit: 1.41
              },{
                qty: 100,
                costSetup: 0,
                costUnit: 1.33
              },{
                qty: 150,
                costSetup: 0,
                costUnit: 1.14
              },{
                qty: 200,
                costSetup: 0,
                costUnit: 1.05
              },{
                qty: 250,
                costSetup: 0,
                costUnit: 1.01
              },{
                qty: 300,
                costSetup: 0,
                costUnit: 0.95
              },{
                qty: 350,
                costSetup: 0,
                costUnit: 0.85
              },{
                qty: 400,
                costSetup: 0,
                costUnit: 0.78
              },{
                qty: 450,
                costSetup: 0,
                costUnit: 0.66
              },{
                qty: 500,
                costSetup: 0,
                costUnit: 0.64
              },{
                qty: 600,
                costSetup: 0,
                costUnit: 0.59
              },{
                qty: 700,
                costSetup: 0,
                costUnit: 0.56
              },{
                qty: 800,
                costSetup: 0,
                costUnit: 0.54
              },{
                qty: 900,
                costSetup: 0,
                costUnit: 0.51
              },{
                qty: 1000,
                costSetup: 0,
                costUnit: 0.49
              },{
                qty: 1200,
                costSetup: 0,
                costUnit: 0.49
              },{
                qty: 1500,
                costSetup: 0,
                costUnit: 0.47
              },{
                qty: 2000,
                costSetup: 0,
                costUnit: 0.46
              },{
                qty: 2500,
                costSetup: 0,
                costUnit: 0.46
              },{
                qty: 3000,
                costSetup: 0,
                costUnit: 0.45
              },{
                qty: 3500,
                costSetup: 0,
                costUnit: 0.44
              },{
                qty: 4000,
                costSetup: 0,
                costUnit: 0.44
              },{
                qty: 4500,
                costSetup: 0,
                costUnit: 0.42
              }
            ]
          },{
            from: 545.14,
            to: 1135.24,
            scale: [{
                qty : 10,  
                costSetup: 0,
                costUnit: 9.1
              },{
                qty: 15,
                costSetup: 0,
                costUnit: 7.8
              },{
                qty: 20,
                costSetup: 0,
                costUnit: 5.3
              },{
                qty: 30,
                costSetup: 0,
                costUnit: 4.3
              },{
                qty: 40,
                costSetup: 0,
                costUnit: 3.1
              },{
                qty: 50,
                costSetup: 0,
                costUnit: 2.8
              },{
                qty: 60,
                costSetup: 0,
                costUnit: 2.6
              },{
                qty: 70,
                costSetup: 0,
                costUnit: 2.5
              },{
                qty: 80,
                costSetup: 0,
                costUnit: 2.4
              },{
                qty: 90,
                costSetup: 0,
                costUnit: 2.3
              },{
                qty: 100,
                costSetup: 0,
                costUnit: 2.2
              },{
                qty: 150,
                costSetup: 0,
                costUnit: 2
              },{
                qty: 200,
                costSetup: 0,
                costUnit: 1.9
              },{
                qty: 250,
                costSetup: 0,
                costUnit: 1.75
              },{
                qty: 300,
                costSetup: 0,
                costUnit: 1.6
              },{
                qty: 350,
                costSetup: 0,
                costUnit: 1.45
              },{
                qty: 400,
                costSetup: 0,
                costUnit: 1.33
              },{
                qty: 450,
                costSetup: 0,
                costUnit: 1.12
              },{
                qty: 500,
                costSetup: 0,
                costUnit: 1.08
              },{
                qty: 600,
                costSetup: 0,
                costUnit: 1
              },{
                qty: 700,
                costSetup: 0,
                costUnit: 0.95
              },{
                qty: 800,
                costSetup: 0,
                costUnit: 0.91
              },{
                qty: 900,
                costSetup: 0,
                costUnit: 0.86
              },{
                qty: 1000,
                costSetup: 0,
                costUnit: 0.84
              },{
                qty: 1200,
                costSetup: 0,
                costUnit: 0.83
              },{
                qty: 1500,
                costSetup: 0,
                costUnit: 0.8
              },{
                qty: 2000,
                costSetup: 0,
                costUnit: 0.79
              },{
                qty: 2500,
                costSetup: 0,
                costUnit: 0.78
              },{
                qty: 3000,
                costSetup: 0,
                costUnit: 0.77
              },{
                qty: 3500,
                costSetup: 0,
                costUnit: 0.75
              },{
                qty: 4000,
                costSetup: 0,
                costUnit: 0.74
              },{
                qty: 4500,
                costSetup: 0,
                costUnit: 0.71
              }
            ]
          }]
        },
        flexThermo: {
          costSetup: 25,
          setup: 35,
          costUnit: 3,
          unit: 5
        }
      },

      loadTotalPrintSurface: function (orderModel) {
        var surface = 0;
        var defer = $.Deferred();
        var i, results = [],
        deferred,
        deferreds = [], url, comp;
        // 1. Make multiple AJAX calls to a Server
        for (i=0; i < orderModel.get('compositions').length; i++) {
          comp = orderModel.get('compositions')[i];
          url = Settings.url('x2png', 'compositions/' + comp.secId + '/getMeasure');
          /*jshint loopfunc:true */
          deferred = $.ajax({
            type: 'GET',
            url: url,
            success: function(result) {
              // 2. Store the results in an array
              results.push(result);
            }
          });
          deferreds.push(deferred);
        }

        $.when.apply($, deferreds).then(function() {
          for (var j = 0 ; j < results.length ; j++) {
            var result = results[j];
            surface += Number(result.width) * Number(result.height);
          }
          defer.resolve(surface / 100);
        });
        return defer.promise();
      },

      getPrintOurselfPrice: function(quantity, it, surface) {
        var price = OrderUtils.printOurselfPrice[it],
        currentSurface, currentScale, previousScale,
        currentPrice;

        if (price.surface) {
          for (var i = 0 ; i < price.surface.length ; i++) {
            currentSurface = price.surface[i];
            if (currentSurface.from < surface && currentSurface.to >= surface) {
              for (var j = 0 ; j < currentSurface.scale.length ; j++) {
                currentScale = currentSurface.scale[j];
                if (currentScale.qty > quantity) {
                  currentPrice = previousScale;
                  break;
                }
                previousScale = currentScale;
              }
              break;
            }
          }
        }

        if (currentPrice) {
          currentPrice.setup = currentPrice.costSetup * 1.21;
          currentPrice.unit = currentPrice.costUnit * 1.21;
        }

        return currentPrice;
      },

      _initContextMenu: function(menu) {
          var that = this;
          menu.$el.contextMenu({
              selector: '.context-menu',
              items: {
                  poi: {
                      name: menu.model.get('poi'),
                      callback: _.bind(function() {
                          that.copyToClipboard(menu.model.get('poi'));
                      }, menu)
                  },
                  sep: '---------',
                  detail: {
                      name: _.i18n('common.details'),
                      callback: function() {
                          var items = $(this.parent().parent()).find('.selected');
                          if (items && items.length) {
                              _.each(items, function (item) {
                                  window.open(window.origin + '/#orders/' + $(item).data('id'));
                              });
                          } else {
                              window.open(window.origin + '/#orders/' + $(this).data('id'));
                          }
                      }
                  },
                  sep0: '---------',
                  cancel: {
                      name: _.i18n('common.cancel'),
                      callback: function() {
                          var items = $(this.parent().parent()).find('.selected');
                          if (items && items.length) {
                              _.each(items, function (item) {
                                  $.when(App.request('order-kanban:update:table', $(item).data('id'), 'CANCELLED')).done(function () {
                                      $(item).remove();
                                  });
                              });
                          } else {
                              var item = $(this);
                              var secId = item.attr('data-id');
                              $.when(App.request('order-kanban:update:table', secId, 'CANCELLED')).done(function () {
                                  item.remove();
                              });
                          }
                      }
                  },
                  sep1: '---------',
                  declined: {
                      name: _.i18n('common.declined'),
                      callback: function() {
                          var items = $(this.parent().parent()).find('.selected');
                          if (items && items.length) {
                              _.each(items, function (item) {
                                  $.when(App.request('order-kanban:update:table', $(item).data('id'), 'DECLINED')).done(function () {
                                      $(item).remove();
                                  });
                              });
                          } else {
                              var item = $(this);
                              var secId = item.attr('data-id');
                              $.when(App.request('order-kanban:update:table', secId, 'DECLINED')).done(function () {
                                  item.remove();
                              });
                          }
                      }
                  },
                  sep2: '---------',
                  duplicate: {
                      name: _.i18n('common.duplicate'),
                      callback: function() {
                          var secId = $(this).attr('data-id');
                          var model = App.request('order:model', { purchaseOrderId: secId });
                          model.duplicate().done(_.bind(function(newOffer){
                              App.navigate('orders/' + newOffer.value, {trigger: true});
                          }, this));
                      }
                  },
                  sep3: '---------',
                  history: {
                      name: _.i18n('common.history'),
                      callback: function() {
                          var secId = $(this).attr('data-id');
                          that._showHistoryPopup(secId);
                      }
                  },
                  sep4: '---------',
                  payedByCustomer: {
                      name: _.i18n('offerList.payedByCustomer'),
                      callback: function() {
                          var items = $(this.parent().parent()).find('.selected');
                          if (items && items.length) {
                              _.each(items, function (item) {
                                  $.when(App.request('order:update', {
                                      purchaseOrderId: $(item).data('id'),
                                      payedByCustomer: true
                                  }));
                              });
                          } else {
                              var poi = $(this).attr('data-id');
                              $.when(App.request('order:update', {
                                  purchaseOrderId: poi,
                                  payedByCustomer: true
                              }));
                          }
                      }
                  },
                  sep5: '---------',
                  flag: {
                      name: _.i18n('offerList.flag'),
                      callback: function() {
                          var flag = 'E';
                          if (menu.model.get('flag') === flag) {
                              flag = null;
                          }
                          var items = $(this.parent().parent()).find('.selected');
                          if (items && items.length) {
                              _.each(items, function (item) {
                                  $.when(App.request('order:update', {
                                      purchaseOrderId: $(item).data('id'),
                                      flag: flag
                                  }));
                              });
                          } else {
                              var poi = $(this).attr('data-id');
                              $.when(App.request('order:update', {
                                  purchaseOrderId: poi,
                                  flag: flag
                              }));
                          }
                      }
                  },
                  sep6: '---------',
                  prio1: {
                      name: _.i18n('offerList.prio1'),
                      callback: function() {
                          var prio = 1;
                          if (menu.model.get('priority') === prio) {
                              prio = null;
                          }
                          var items = $(this.parent().parent()).find('.selected');
                          if (items && items.length) {
                              _.each(items, function (item) {
                                  $.when(App.request('order:update', {
                                      purchaseOrderId: $(item).data('id'),
                                      priority: prio
                                  }));
                              });
                          } else {
                              var poi = $(this).attr('data-id');
                              $.when(App.request('order:update', {
                                  purchaseOrderId: poi,
                                  priority: prio
                              }));
                          }
                      }
                  },
                  sep7: '---------',
                  prio2: {
                      name: _.i18n('offerList.prio2'),
                      callback: function() {
                          var prio = 2;
                          if (menu.model.get('priority') === prio) {
                              prio = null;
                          }
                          var items = $(this.parent().parent()).find('.selected');
                          if (items && items.length) {
                              _.each(items, function (item) {
                                  $.when(App.request('order:update', {
                                      purchaseOrderId: $(item).data('id'),
                                      priority: prio
                                  }));
                              });
                          } else {
                              var poi = $(this).attr('data-id');
                              $.when(App.request('order:update', {
                                  purchaseOrderId: poi,
                                  priority: prio
                              }));
                          }
                      }
                  },
                  sep8: '---------',
                  toInvoice: {
                      name: _.i18n('offerList.toInvoice'),
                      callback: function() {
                          var items = $(this.parent().parent()).find('.selected');
                          if (items && items.length) {
                              _.each(items, function (item) {
                                  $.when(App.request('order:update', {
                                      purchaseOrderId: $(item).data('id'),
                                      toInvoice: true
                                  }));
                              });
                          } else {
                              var poi = $(this).attr('data-id');
                              $.when(App.request('order:update', {
                                  purchaseOrderId: poi,
                                  toInvoice: true
                              }));
                          }
                      }
                  }
              },
              trigger: 'right'
          });
      },

      _showHistoryPopup: function(purchaseOrderId) {
          var popupView = new HistoryPopupView({
              url: App.request('order-kanban:history-get', purchaseOrderId),
              rowCountUrl: App.request('order-kanban:history-row-count', purchaseOrderId)
          });

          popupView.show({
              title: _.i18n('common.history'),
              className: 'popupHistory'
          });
      },

      copyToClipboard: function(str) {
          var el = document.createElement('textarea');
          el.value = str;
          el.setAttribute('readonly', '');
          el.style.position = 'absolute';
          el.style.left = '-9999px';
          document.body.appendChild(el);
          el.select();
          document.execCommand('copy');
          document.body.removeChild(el);
      }
  };

  module.exports = OrderUtils;
});

