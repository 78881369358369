
define('template!googleContactsPopupTpl', ['lodash'], function(_) {return function(obj) {
obj || (obj = {});
var __t, __p = '';
with (obj) {
__p += '<div>\n    <a href="#" class="link-select-all">' +
((__t = ( _.i18n('common.selectAll') )) == null ? '' : __t) +
'</a>\n    <a href="#" class="link-unselect-all">' +
((__t = ( _.i18n('common.unSelectAll') )) == null ? '' : __t) +
'</a>\n    <div class="contacts-list">\n\n    </div>\n</div>';

}
return __p
};});

