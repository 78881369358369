
define('template!orderprocessViewTpl', ['lodash'], function(_) {return function(obj) {
obj || (obj = {});
var __t, __p = '', __j = Array.prototype.join;
function print() { __p += __j.call(arguments, '') }
with (obj) {
__p += '<div class="content full-height">\n  <div class="well full-height">\n    ' +
((__t = ( _.i18n('common.orderProcess') )) == null ? '' : __t) +
': <strong>' +
((__t = ( productCode )) == null ? '' : __t) +
'</strong> ' +
((__t = (productName )) == null ? '' : __t) +
'<br>\n    ';
 if (poi) { ;
__p +=
((__t = ( _.i18n('orderDetail.poi') )) == null ? '' : __t) +
': <strong>' +
((__t = ( poi )) == null ? '' : __t) +
'</strong>';
 } ;
__p += '\n    <iframe class="iframeCompuzz" id="iframeCompuzz" src="' +
((__t = ( url )) == null ? '' : __t) +
'" frameborder="0" seamless>\n    </iframe>\n  </div>\n</div>';

}
return __p
};});

