
define('template!glossaryPopupTpl', ['lodash'], function(_) {return function(obj) {
obj || (obj = {});
var __t, __p = '';
with (obj) {
__p += '<div class="detailed-info-form-container">\n    <div class="form-horizontal">\n        <div class="glossary-auto-complete">\n\n        </div>\n        <div class="row">\n            <div class="pull-right p-r-15">\n                <button class="btn btn-default btn-flat cancel-b js-cancel-popup">' +
((__t = ( _.i18n('common.cancel') )) == null ? '' : __t) +
'</button>\n                <button disabled class="btn btn-primary disabled-grey js-confirm">' +
((__t = ( _.i18n('common.confirm') )) == null ? '' : __t) +
'</button>\n            </div>\n        </div>\n    </div>\n</div>';

}
return __p
};});

