define('orderCompositionTabController',[
  'module',
  'app',
  'underscore',
  'backbone',
  'orderCompositionTabView',
  'compuzzProUtils'
], function(
  module,
  App,
  _,
  Backbone,
  CompositionTabView,
  CompuzzProUtils
) {
  'use strict';

  var CompositionTabController = {
    showCompositionTab: function(region, orderModel) {

      var product = App.request('products:model');

      product.fetchByCode(orderModel.get('productCode')).done(_.bind(function() {
          var that = this;
          this.region = region;
          this.orderModel = orderModel;
          var coll = that.getCompositionCollection(orderModel);
          coll.orderModel = orderModel;
          this.view = new CompositionTabView({
              collection: coll,
              productModel: product
          });
          this.view.orderModel = orderModel;

          CompuzzProUtils.getInstance().addListener(this.view, CompuzzProUtils.events.COMPUZZ_PRO_REFRESH_COMPOSITION,
              _.bind(function() {
                  this.orderModel.fetch().done(_.bind(function() {
                      this.showCompositionTab(this.region, this.orderModel);
                  }, this));
              }, this));

          region.show(this.view);
      }, this));
    },

    getCompositionCollection: function(orderModel) {
      var compositionCollection = new Backbone.Collection(orderModel.get('compositions'));

      return compositionCollection;
    }
  };

  module.exports = CompositionTabController;
});
