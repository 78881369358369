
define('template!myWebSiteViewTpl', ['lodash'], function(_) {return function(obj) {
obj || (obj = {});
var __t, __p = '', __j = Array.prototype.join;
function print() { __p += __j.call(arguments, '') }
with (obj) {
__p += '<div class="content">\n  <div class="well my-website-form-container">\n    <form class="form-horizontal my-website-form">\n      <fieldset>        \n        <h2 class="my-website-title">' +
((__t = ( _.i18n('mywebsite.title') )) == null ? '' : __t) +
'</h2>\n        <!-- Upload block -->\n        <div class="form-group">\n          <div class="col-lg-12">\n            <div class="logo-m-ws">\n              <div class="my-ws-img-container transparent-bg">\n                <div class="my-web-site-upload-img" style="';
 if (fileUrl) { ;
__p += 'background-image: url(' +
((__t = ( fileUrl )) == null ? '' : __t) +
'); ';
 } ;
__p += '"></div>\n              </div>\n              <label for="uploadInputFile" class="upload-title"><i class="mdi-file-file-upload"></i></label>\n              <input id="uploadInputFile" data-key="file" multiple type="file" name="filename" class="hidden js-change-logo f-my-ws" accept="application/pdf, application/postscript, image/*"/>\n            </div>\n          </div>\n        </div>\n        <!-- Set-color block -->\n        <div class="set-colors">\n          <div class="form-group color-input-item col-lg-12">\n          <!-- primary color -->\n            <div class="col-xs-4 p-l-0 p-r-0">\n              <label class="color-name-title">' +
((__t = ( _.i18n('mywebsite.primary.color') )) == null ? '' : __t) +
'</label>\n              <div class="input-group primary-color">\n                  <span class="hidden"></span>\n                  <label class="btn btn-default btn-flat btn-color colorpick" data-key="primaryColor">\n                    <span class="btn-group">\n                        <div class="color-block add-on" style="background-color:#' +
((__t = ( primaryColor )) == null ? '' : __t) +
';"></div>\n                    </span>\n                    <input value="#' +
((__t = ( primaryColor)) == null ? '' : __t) +
'">\n                  </label>\n              </div>\n            </div>\n\n            <!-- accent color -->\n            <div class="col-xs-4 p-l-0 p-r-0">\n              <label class="color-name-title">' +
((__t = ( _.i18n('mywebsite.accent.color') )) == null ? '' : __t) +
'</label>\n              <div class="input-group accent-color">\n                  <label class="btn btn-default btn-flat btn-color colorpick" data-key="accentColor">\n                    <span class="btn-group">\n                        <div class="color-block add-on" style="background-color:#' +
((__t = ( accentColor )) == null ? '' : __t) +
';"></div>\n                    </span>\n                    <input value="#' +
((__t = ( accentColor )) == null ? '' : __t) +
'">\n                  </label>\n              </div>\n            </div>\n\n            <!-- body color -->\n            <div class="col-xs-4 p-l-0 p-r-0">\n              <label class="color-name-title">' +
((__t = ( _.i18n('mywebsite.body.color') )) == null ? '' : __t) +
'</label>\n              <div class="input-group body-color">\n                  <span class="hidden"></span>\n                  <label class="btn btn-default btn-flat btn-color colorpick" data-key="bodyColor">\n                    <span class="btn-group">\n                        <div class="color-block add-on" style="background-color:#' +
((__t = ( bodyColor )) == null ? '' : __t) +
';"></div>\n                    </span>\n                    <input value="#' +
((__t = ( bodyColor )) == null ? '' : __t) +
'">\n                  </label>\n              </div>\n            </div>\n\n          </div>\n        </div>\n        <!-- Subdomain block -->\n        <div class="sub-domain-block">\n          <div class="form-group col-lg-12">\n            <div class="input-group m-t-10">\n                <span class="input-group-addon p-l-0">' +
((__t = ( urlPrefix )) == null ? '' : __t) +
'</span>\n                <input type="text" class="form-control f-my-ws subdomain  floating-label" value="' +
((__t = ( subdomain )) == null ? '' : __t) +
'" data-key="subdomain" placeholder="' +
((__t = ( _.i18n('mywebsite.subdomain') )) == null ? '' : __t) +
'">\n                <span class="input-group-addon">\n                    <label>' +
((__t = ( urlPostfix )) == null ? '' : __t) +
'</label>\n                </span>\n            </div>\n          </div>\n        </div>\n\n        <!-- Email block -->\n        <div class="support-email">\n          <div class="form-group form-email col-lg-12">\n            <input type="text" class="form-control floating-label f-my-ws" id="emailFocusedInput"\n            placeholder="' +
((__t = ( _.i18n('common.support.email') )) == null ? '' : __t) +
'" value="' +
((__t = ( supportEmail )) == null ? '' : __t) +
'" data-key="supportEmail">\n          </div>\n        </div>\n        <!-- Phone block -->\n        <div class="phone-number">\n          <div class="form-group col-lg-12">\n            <input type="text" class="form-control floating-label phone-input f-my-ws" id="phoneFocusedInput"\n            placeholder="' +
((__t = ( _.i18n('common.phone') )) == null ? '' : __t) +
'" value="' +
((__t = ( phone )) == null ? '' : __t) +
'" data-key="phone">\n          </div>\n        </div>\n\n        <!--Advanced Properties bloc -->\n        <div class="panel-group" id="accordion" role="tablist" aria-multiselectable="true">\n          <div class="panel panel-default form-group col-lg-12">\n            <div class="panel-heading image-centered-block" role="tab" id="headingOne">\n              <h4 class="panel-title">\n                <a role="button" data-toggle="collapse" data-parent="#accordion" href="#collapseOne" aria-expanded="true" aria-controls="collapseOne">\n                  ' +
((__t = ( _.i18n('mywebsite.advancedProperties') )) == null ? '' : __t) +
'\n                </a>\n              </h4>\n            </div>\n            <div id="collapseOne" class="panel-collapse collapse" role="tabpanel" aria-labelledby="headingOne">\n              <div class="panel-body">\n                  <!-- Secured logotool -->\n                  <div class="secured-logotool">\n                      <div class="form-group col-lg-12">\n                          <div class="checkbox checkbox-primary m-0 p-0">\n                              <label>\n                                  <input type="checkbox" class="securedLogotool" id="focusedInput" data-key="securedLogotool" ' +
((__t = ( securedLogotool ? 'checked' : '' )) == null ? '' : __t) +
' />\n                              </label>\n                              <span class="p-l-5">' +
((__t = ( _.i18n('mywebsite.secured.logotool') )) == null ? '' : __t) +
'</span>\n                          </div>\n                      </div>\n                  </div>\n                  <!--display welcome-->\n                  <div class="display-welcome-block">\n                      <div class="form-group col-lg-12">\n                          <div class="checkbox checkbox-primary m-0 p-0">\n                              <label>\n                                  <input type="checkbox" class="displayWelcome" data-key="displayWelcome" ' +
((__t = ( advancedProperties.displayWelcome ? 'checked' : '' )) == null ? '' : __t) +
' />\n                              </label>\n                              <span class="p-l-5">' +
((__t = ( _.i18n('mywebsite.advancedProperties.displayWelcome') )) == null ? '' : __t) +
'</span>\n                          </div>\n                      </div>\n                  </div>\n\n                  <!--display request offer-->\n                  <div class="display-welcome-block">\n                      <div class="form-group col-lg-12">\n                          <div class="checkbox checkbox-primary m-0 p-0">\n                              <label>\n                                  <input type="checkbox" class="displayRequestOffer" data-key="displayRequestOffer" ' +
((__t = ( advancedProperties.displayRequestOffer ? 'checked' : '' )) == null ? '' : __t) +
' />\n                              </label>\n                              <span class="p-l-5">' +
((__t = ( _.i18n('mywebsite.advancedProperties.displayRequestOffer') )) == null ? '' : __t) +
'</span>\n                          </div>\n                      </div>\n                  </div>\n\n                <!--displayExpertButton-->\n                <div class="display-expert-block">\n                  <div class="form-group col-lg-12">\n                    <div class="checkbox checkbox-primary m-0 p-0">\n                      <label>\n                        <input type="checkbox" class="displayExpertButton" data-key="displayExpertButton" ' +
((__t = ( advancedProperties.displayExpertButton ? 'checked' : '' )) == null ? '' : __t) +
' />\n                      </label>\n                      <span class="p-l-5">' +
((__t = ( _.i18n('mywebsite.advancedProperties.displayExpertButton') )) == null ? '' : __t) +
'</span>\n                    </div>\n                  </div>\n                </div>\n\n                <!--displayUploadTemplate-->\n                <div class="display-upload-block">\n                  <div class="form-group col-lg-12">\n                    <div class="checkbox checkbox-primary m-0 p-0">\n                      <label>\n                        <input type="checkbox" class="displayUploadTemplate" data-key="displayUploadTemplate" ' +
((__t = ( advancedProperties.displayUploadTemplate ? 'checked' : '' )) == null ? '' : __t) +
' />\n                      </label>\n                      <span class="p-l-5">' +
((__t = ( _.i18n('mywebsite.advancedProperties.displayUploadTemplate') )) == null ? '' : __t) +
'</span>\n                    </div>\n                  </div>\n                </div>\n\n                <!--displayTerms-->\n                <div class="display-terms-block">\n                  <div class="form-group col-lg-12">\n                    <div class="checkbox checkbox-primary m-0 p-0">\n                      <label>\n                        <input type="checkbox" class="displayTerms" data-key="displayTerms" ' +
((__t = ( advancedProperties.displayTerms ? 'checked' : '' )) == null ? '' : __t) +
' />\n                      </label>\n                      <span class="p-l-5">' +
((__t = ( _.i18n('mywebsite.advancedProperties.displayTerms') )) == null ? '' : __t) +
'</span>\n                    </div>\n                  </div>\n                </div>\n\n                <!--displayHeader-->\n                <div class="display-header-block">\n                  <div class="form-group col-lg-12">\n                    <div class="checkbox checkbox-primary m-0 p-0">\n                      <label>\n                        <input type="checkbox" class="displayHeader" data-key="displayHeader" ' +
((__t = ( advancedProperties.displayHeader ? 'checked' : '' )) == null ? '' : __t) +
' />\n                      </label>\n                      <span class="p-l-5">' +
((__t = ( _.i18n('mywebsite.advancedProperties.displayHeader') )) == null ? '' : __t) +
'</span>\n                    </div>\n                  </div>\n                </div>\n\n                <!--displayPrice-->\n                <div class="display-price-block">\n                  <div class="form-group col-lg-12">\n                      <div class="checkbox checkbox-primary m-0 p-0">\n                          <label>\n                              <input type="checkbox" class="displayPrice" data-key="displayPrice" ' +
((__t = ( (advancedProperties.displayPrice===undefined || advancedProperties.displayPrice) ? 'checked' : '' )) == null ? '' : __t) +
' />\n                          </label>\n                          <span class="p-l-5">' +
((__t = ( _.i18n('mywebsite.advancedProperties.displayPrice') )) == null ? '' : __t) +
'</span>\n                      </div>\n                  </div>\n                </div>\n\n                <!--displayVat-->\n                <div class="display-vat-block">\n                  <div class="form-group col-lg-12">\n                    <div class="checkbox checkbox-primary m-0 p-0">\n                      <label>\n                        <input type="checkbox" class="displayVat" data-key="displayVat" ' +
((__t = ( advancedProperties.displayVat ? 'checked' : '' )) == null ? '' : __t) +
' />\n                      </label>\n                      <span class="p-l-5">' +
((__t = ( _.i18n('mywebsite.advancedProperties.displayVat') )) == null ? '' : __t) +
'</span>\n                    </div>\n                  </div>\n                </div>\n\n                <!--displayShippingCost-->\n                <div class="display-shipping-cost-block">\n                  <div class="form-group col-lg-12">\n                    <div class="checkbox checkbox-primary m-0 p-0">\n                      <label>\n                        <input type="checkbox" class="displayShippingCost" data-key="displayShippingCost" ' +
((__t = ( advancedProperties.displayShippingCost ? 'checked' : '' )) == null ? '' : __t) +
' />\n                      </label>\n                      <span class="p-l-5">' +
((__t = ( _.i18n('mywebsite.advancedProperties.displayShippingCost') )) == null ? '' : __t) +
'</span>\n                    </div>\n                  </div>\n                </div>\n\n                <!--displayNotPrinted-->\n                <div class="display-not-printed-block">\n                  <div class="form-group col-lg-12">\n                    <div class="checkbox checkbox-primary m-0 p-0">\n                      <label>\n                        <input type="checkbox" class="displayNotPrinted" data-key="displayNotPrinted" ' +
((__t = ( advancedProperties.displayNotPrinted ? 'checked' : '' )) == null ? '' : __t) +
' />\n                      </label>\n                      <span class="p-l-5">' +
((__t = ( _.i18n('mywebsite.advancedProperties.displayNotPrinted') )) == null ? '' : __t) +
'</span>\n                    </div>\n                  </div>\n                </div>\n                <!--enableCompulogo-->\n                <div class="display-not-printed-block">\n                  <div class="form-group col-lg-12">\n                    <div class="checkbox checkbox-primary m-0 p-0">\n                      <label>\n                        <input type="checkbox" class="enableCompulogo" data-key="enableCompulogo" ' +
((__t = ( advancedProperties.enableCompulogo ? 'checked' : '' )) == null ? '' : __t) +
' />\n                      </label>\n                      <span class="p-l-5">' +
((__t = ( _.i18n('mywebsite.advancedProperties.enableCompulogo') )) == null ? '' : __t) +
'</span>\n                    </div>\n                  </div>       \n                </div>\n                <!--hide header and footer-->\n                <div class="hide-headerfooter-block">\n                    <div class="form-group col-lg-12">\n                        <div class="checkbox checkbox-primary m-0 p-0">\n                            <label>\n                                <input type="checkbox" class="displayHeaderFooterLogotool" data-key="displayHeaderFooterLogotool" ' +
((__t = ( advancedProperties.displayHeaderFooterLogotool ? 'checked' : '' )) == null ? '' : __t) +
' />\n                            </label>\n                            <span class="p-l-5">' +
((__t = ( _.i18n('mywebsite.advancedProperties.displayHeaderFooterLogotool') )) == null ? '' : __t) +
'</span>\n                        </div>\n                    </div>\n                </div>\n                <!--displayBookInCart-->\n                <div class="display-book-in-cart-block">\n                  <div class="form-group col-lg-12">\n                      <div class="checkbox checkbox-primary m-0 p-0">\n                          <label>\n                              <input type="checkbox" class="displayBookInCart" data-key="displayBookInCart" ' +
((__t = ( advancedProperties.displayBookInCart ? 'checked' : '' )) == null ? '' : __t) +
' />\n                          </label>\n                          <span class="p-l-5">' +
((__t = ( _.i18n('mywebsite.advancedProperties.displayBookInCart') )) == null ? '' : __t) +
'</span>\n                      </div>\n                  </div>\n                </div>\n                <!--displayPersoQuestion-->\n                <div class="display-book-in-cart-block">\n                  <div class="form-group col-lg-12">\n                      <div class="checkbox checkbox-primary m-0 p-0">\n                          <label>\n                              <input type="checkbox" class="displayPersoQuestion" data-key="displayPersoQuestion" ' +
((__t = ( advancedProperties.displayPersoQuestion ? 'checked' : '' )) == null ? '' : __t) +
' />\n                          </label>\n                          <span class="p-l-5">' +
((__t = ( _.i18n('mywebsite.advancedProperties.displayPersoQuestion') )) == null ? '' : __t) +
'</span>\n                      </div>\n                  </div>\n                </div>\n              </div>\n            </div>\n          </div>\n        </div>\n\n\n        <!-- Btns block -->\n        <div class="footer-btns-block col-lg-12">\n          <div class="form-group pull-right p-r-15 m-b-0">\n            <a class="btn btn-default btn-flat preview-b">' +
((__t = ( _.i18n('common.preview') )) == null ? '' : __t) +
'</a>\n            <a class="btn btn-primary my-website-save">' +
((__t = ( _.i18n('common.save') )) == null ? '' : __t) +
'</a>\n          </div>\n        </div>\n      </fieldset>\n    </form>\n  </div>\n</div>';

}
return __p
};});

