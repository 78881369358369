define( 'entities/product_translation',[
  'app',
  'jquery',
  'settings',
  'backbone',
  'underscore',
  'backboneRelational'
], function(
  App,
  $,
  Settings,
  Backbone,
  _
) {
  'use strict';
  var app = { ns: {}};
  Backbone.Relational.store.addModelScope(app.ns);

  app.ns.DynamicTextLocalized = Backbone.RelationalModel.extend({
    idAttribute: 'secId'
  });

  app.ns.DynamicTextLocalizedCollection = Backbone.Collection.extend({
    model: 'DynamicTextLocalized',

    isOverridden: function(lang) {
      return (_.find(this.models, function(model) { return model.get('locale') === lang; })?true:false);
    },

    setTextInLocale: function(lang, value) {
      var currentValue = _.find(this.models, function(model) { return model.get('locale') === lang; });
      if (currentValue) {
        currentValue.set('stdtext', value);
      } else {
        this.add(new app.ns.DynamicTextLocalized({locale: lang, stdtext: value}));
      }
    },

    getTextInLocale: function(lang) {
      var currentValue = _.find(this.models, function(model) { return model.get('locale') === lang; });
      if (currentValue) {
        return currentValue.get('stdtext');
      }
    },

    revertText: function(lang) {
      var currentValue = _.find(this.models, function(model) { return model.get('locale') === lang; });
      if (currentValue) {
        this.remove(currentValue);
      }
    }
  });

  app.ns.Glossary = Backbone.RelationalModel.extend({
    idAttribute: 'secId',

    relations: [{
      type: Backbone.HasOne,
      key: 'refDynamicText',
      relatedModel: 'DynamicText' 
    }],

    url: function() {
      return Settings.url('compuzz', 'dynamictexts.json/glossaries/' + this.get('secId'));
    }
  });

  app.ns.DynamicText = Backbone.RelationalModel.extend({
    relations: [{
      type: Backbone.HasMany,
      key: 'dynamicTextLocalizeds',
      relatedModel: 'DynamicTextLocalized',
      collectionType: 'DynamicTextLocalizedCollection'
    },{
      type: Backbone.HasOne,
      key: 'refDynamicText',
      relatedModel: 'DynamicText'
    },{
      type: Backbone.HasOne,
      key: 'refGlossary',
      relatedModel: 'Glossary'
    }],

    idAttribute: 'secId',

    url: function() {
      return Settings.url('compuzz', 'dynamictexts.json/' + this.get('secId'));
    },

    setTextInLocale: function(lang, value) {
      this.get('dynamicTextLocalizeds').setTextInLocale(lang, value);
    },

    getTextInLocale: function(lang) {
      if (this.get('refGlossary')) {
        return this.get('refGlossary').get('refDynamicText').getTextInLocale(lang);
      } else {
        var text = this.get('dynamicTextLocalizeds').getTextInLocale(lang);
        if (text !== undefined) {
          return text;
        } else if (text === undefined && this.get('refDynamicText')) {
          return this.get('refDynamicText').getTextInLocale(lang);
        } else {
          return '';
        }
      }
    },

    isUseGlossary: function () {
      if (this.get('refGlossary') && !this.get('readyOnly')) {
        return true;
      } else if (this.get('refDynamicText') && !this.get('refDynamicText').get('readyOnly')) {
        return this.get('refDynamicText').isUseGlossary();
      } else {
        return false;
      }
    },

    isUseParentGlossary: function () {
      if (this.isUseGlossary() && !this.get('refGlossary')) {
        return true;
      } else {
        return false;
      }
    },

    getGlossary: function () {
      return this.get('refGlossary')?this.get('refGlossary'):(this.get('refDynamicText')?this.get('refDynamicText').getGlossary():null);
    },

    isOverridden: function(lang) {
      if (this.get('refDynamicText')) {
        return this.get('dynamicTextLocalizeds').isOverridden(lang);
      } else {
        return false;
      }
    },

    revertText: function(lang) {
      this.get('dynamicTextLocalizeds').revertText(lang);
    },

    getTranslationCollection: function() {
      var array = [];

      var langs = Settings.get('currentCAccount').languageSelectionJson.languages;
      _.each(langs, _.bind(function(lang) {
        array.push(new Backbone.Model({
          lang: lang.locale,
          value: this.getTextInLocale(lang.locale),
          dt: this
        }));
      }, this));
      return new Backbone.Collection(array);
    }
  });

  app.ns.ProductOptionTrans = Backbone.RelationalModel.extend({
    relations: [{
      type: Backbone.HasOne,
      key: 'name',
      relatedModel: 'DynamicText'
    }],
    idAttribute: 'secId'
  });

  app.ns.ProductOptionTransCollection = Backbone.Collection.extend({
    model: 'ProductOptionTrans',
    comparator: 'code'
  });

  app.ns.ProductOptionCategoryTrans = Backbone.RelationalModel.extend({
    relations: [{
      type: Backbone.HasOne,
      key: 'name',
      relatedModel: 'DynamicText'
    }, {
      type: Backbone.HasMany,
      key: 'productOptions',
      relatedModel: 'ProductOptionTrans',
      collectionType: 'ProductOptionTransCollection'
    }],
    idAttribute: 'secId'
  });

  app.ns.ProductOptionCategoryTransCollection = Backbone.Collection.extend({
    model: 'ProductOptionCategoryTrans',
    comparator: 'code'
  });

  app.ns.PrintZoneTrans = Backbone.RelationalModel.extend({
    relations: [{
      type: Backbone.HasOne,
      key: 'name',
      relatedModel: 'DynamicText'
    },{
      type: Backbone.HasOne,
      key: 'itName',
      relatedModel: 'DynamicText'
    }],
    idAttribute: 'secId'
  });

  app.ns.PrintZoneTransCollection = Backbone.Collection.extend({
    model: 'PrintZoneTrans',
    comparator: 'code'
  });

  app.ns.PrintAreaTrans = Backbone.RelationalModel.extend({
    relations: [{
      type: Backbone.HasOne,
      key: 'name',
      relatedModel: 'DynamicText'
    }, {
      type: Backbone.HasMany,
      key: 'printZones',
      relatedModel: 'PrintZoneTrans',
      collectionType: 'PrintZoneTransCollection'
    }],
    idAttribute: 'secId'
  });

  app.ns.PrintAreaTransCollection = Backbone.Collection.extend({
    model: 'PrintAreaTrans',
    comparator: 'code'
  });

  app.ns.ProductTranslation = Backbone.RelationalModel.extend({
    relations: [{
      type: Backbone.HasOne,
      key: 'name',
      relatedModel: 'DynamicText'
    }, {
      type: Backbone.HasMany,
      key: 'productOptionCategories',
      relatedModel: 'ProductOptionCategoryTrans',
      collectionType: 'ProductOptionCategoryTransCollection'
    }, {
      type: Backbone.HasMany,
      key: 'printAreas',
      relatedModel: 'PrintAreaTrans',
      collectionType: 'PrintAreaTransCollection'
    }],

    url: function() {
      return Settings.url('compuzz', 'products.json/' + this.get('secId') + '/translation');
    },

    idAttribute: 'secId'
  });

  App.reqres.setHandler('products-translation:model', function (productTranslationJson) {
    var tt = Backbone.Relational.store.find(app.ns.ProductTranslation, productTranslationJson.secId );
    if (tt) {
      return tt;
    } else {
      return new app.ns.ProductTranslation(productTranslationJson);
    }
  });

  App.reqres.setHandler('products-translation:glossary-model', function (glossaryJson) {
    var tt = Backbone.Relational.store.find(app.ns.Glossary, glossaryJson.secId );
    if (tt) {
      return tt;
    } else {
      return new app.ns.Glossary(glossaryJson);
    }
  });
});
