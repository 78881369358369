
define('template!customerTemplatesGroupsLayoutTpl', ['lodash'], function(_) {return function(obj) {
obj || (obj = {});
var __t, __p = '';
with (obj) {
__p += '<div class="hbox folders-container">\n	<aside class="groups-column-container">\n		<div id="groups-column" class="groups-column"></div>\n	</aside>\n	<section class="elements-column-container">\n		<div id="filters-column" class="filters-column vbox filters-max-height"></div>\n		<div id="elements-column" class="elements-column vbox"></div>\n	</section>\n</div>';

}
return __p
};});

