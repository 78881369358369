
define('template!filterTypeTpl', ['lodash'], function(_) {return function(obj) {
obj || (obj = {});
var __t, __p = '', __j = Array.prototype.join;
function print() { __p += __j.call(arguments, '') }
with (obj) {
__p += '<div class="row">\n    <div class="col-xs-12">\n        ';
 if (selected) { ;
__p += ' \n            <i class="col-xs-1 mdi-av-play-arrow"></i>\n        ';
 } else { ;
__p += '\n            <span class="col-xs-1"></span> \n        ';
 } ;
__p += '\n        <span class="col-xs-10 col-md-6 col-lg-4 filter-type clickable">' +
((__t = ( filterType.name )) == null ? '' : __t) +
'</span>\n        <div class="col-xs-1 col-md-5 col-lg-7"><i class="delete-filter-type mdi-action-delete"></i></div>\n    </div>\n</div>';

}
return __p
};});

