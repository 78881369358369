/**
 * Created by GSP on 4/08/2015.
 */
define('contentShowView',[
  'module',
  'marionette',
  'app',
  'underscore',
  'settings',
  'template!contentLayoutTpl',
  'tawlkToBehavior',
  'plivoUtils',
  'bootbox'
], function(
  module,
  Marionette,
  App,
  _,
  Settings,
  layoutTpl,
  TawlkToBehavior,
  PlivoUtils,
  bootbox
) {
  'use strict';

  var ContentLayoutView =  Marionette.LayoutView.extend({
    template: layoutTpl,

    regions: {
      header: '#header-region',
      sidebar: '#sidebar-region',
      content: '#content-region',
      zoom: '#gallery-zoom'
    },

    ui: {
      aside: '.aside-md',
      btnAnswer: '.btn-answer',
      btnReject: '.btn-reject',
      btnHangup: '.btn-hangup',
      btnMute: '.btn-mute',
      btnUnMute: '.btn-unmute',
      btnPhoneNumber: '.btn-phone-number',
      btnForward: '.btn-forward'
    },  

    behaviors: {
      SmartSupp: {
        behaviorClass: TawlkToBehavior,
        visible: true
      }
    },

    events: {
      'click @ui.btnAnswer': 'onBtnAnswerClick',
      'click @ui.btnReject': 'onBtnRejectClick',
      'click @ui.btnHangup': 'onBtnHangupClick',
      'click @ui.btnMute': 'onBtnMuteClick',
      'click @ui.btnUnMute': 'onBtnUnMuteClick',
      'click @ui.btnPhoneNumber': 'onBtnPhoneNumberClick',
      'click @ui.btnForward': 'onBtnForwardClick'
    },

    serializeData: function() {
      return {
        displayPhone: (Settings.get('plivoUser') && Settings.get('plivoPassword'))
      };
    },

    onShow: function() {
      PlivoUtils.refreshInterface();
    },

    onBtnAnswerClick: function() {
      PlivoUtils.answer();
    },

    onBtnRejectClick: function() {
      PlivoUtils.reject();
    },

    onBtnHangupClick: function() {
      PlivoUtils.hangup();
    },

    onBtnMuteClick: function() {
      PlivoUtils.mute();
    },

    onBtnUnMuteClick: function() {
      PlivoUtils.unmute();
    },

    onBtnPhoneNumberClick: function(event) {
      event.preventDefault();
      event.stopImmediatePropagation();
      PlivoUtils.dtmf(event.currentTarget.innerText);
    },

    onSidebarShow: function() {
      if (this.ui.aside.hasClass('nav-off-screen')) {
        this.ui.aside
          .removeClass('nav-off-screen')
          .hide();
      } else {
        this.ui.aside
          .addClass('nav-off-screen')
          .show();
      }
    },

    onResizeSidebar: function() {
      if (this.ui.aside.hasClass('nav-xs')) {
        this.ui.aside.removeClass('nav-xs');
      } else {
        this.ui.aside.addClass('nav-xs');
      }
    },

    onBtnForwardClick: function () {
      PlivoUtils.findConnectedUsers().then(function(users) {
        var select = '<select class="forward-ddl">';
        for (var i = 0 ; i < users.length ; i++) {
          var user = users[i];
          select += '<option value="'+user.plivoUser+'">' + user.firstName + ' ' + user.lastName + '</option>';
        }
        select += '</select>';

        bootbox.dialog({
          title: _.i18n('call.forward'),
          message: select,
          buttons: {
            success: {
              label: _.i18n('call.forward') ,
              className: 'btn-success',
              callback: function() {
                PlivoUtils.forwardCall(this.find('select').val());
              }
            }
          }
        });
      });
    },

    onResizeWindow: function () {
      var width = $(document.body).width();

      if (width > 768) {
        this.ui.aside
          .removeClass('nav-off-screen')
          .show();
      }
      if (width < 767) {
        this.ui.aside.removeClass('nav-xs');
      }
    }
  });

  module.exports = ContentLayoutView;
});
