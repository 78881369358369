
define('template!graphicalRequestKanbanViewTpl', ['lodash'], function(_) {return function(obj) {
obj || (obj = {});
var __t, __p = '';
with (obj) {
__p += '<div class="content">\n  <div class="well vectorization-container kanban-board">\n    <h3>Requests for graphical services</h3>\n    <div id="kanban-filters-region"></div>\n    <div id="kanban-board-region"></div>\n  </div>\n</div>';

}
return __p
};});

