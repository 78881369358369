define ('notificationCenter',[
    'module',
    'atmosphere',
    'settings',
    'underscore',
    'jquery',
    'logger',
    'marionette',
    'rolesMixin',
    'notify'
], function(module, Atmosphere, Settings, _, $, Logger, Marionette, RolesMixin) {
    'use strict';

    var events = {
        CHANGE_INFO_ORDER: 'notificationCenter:changeInfoOrder',
        CHANGE_INFO_TICKET: 'notificationCenter:changeInfoTicket',
        NEW_VECTORISATION_TICKET_MESSAGE: 'notificationCenter:newVectorizeTicketMessage',
        NEW_VECTORISATION_TICKET: 'notificationCenter:newVectorizeTicket',
        NEW_CONVERSION_TICKET_MESSAGE: 'notificationCenter:newConversionTicketMessage',
        NEW_CONVERSION_TICKET: 'notificationCenter:newConversionTicket'
    };

    var instance = null;

    var NotificationCenter = Marionette.Object.extend({

        cAccountSecId : null,
        request : null,

        subscribe: function(cAccountSecId) {
            if (this.cAccountSecId !== cAccountSecId) {
                this.cAccountSecId = cAccountSecId;

                var url = Settings.url('compuzz', 'atmo/notification/' + cAccountSecId, null, true),
                    webSocketUrl = Settings.compuzzAwsWs+ '?cAccountUid=' + cAccountSecId + '&user=' + Settings.get('currentUser').logonId;

                /*if (url.indexOf('localhost') !== -1) {
                    url = 'http://localhost:8085/compuzz/rest/atmo/notification/' + cAccountSecId;
                    webSocketUrl = 'wss://notification.compuzz.com/beta?cAccountUid=' + cAccountSecId;
                }*/
                this.unSubscribe();

                if (window.Notification) {
                    if (window.Notification.permission !== 'granted' && window.Notification.permission !== 'denied') {
                        window.Notification.requestPermission(function () {
                        });
                    }
                }

                this.request = {
                    url: url,
                    webSocketUrl: webSocketUrl,
                    transport : 'websocket',
                    fallbackTransport: 'long-polling',
                    maxStreamingLength: 1000000000,
                    timeout: 0
                };

                this.request.onClientTimeout = _.bind(this.onClientTimeout, this);

                this.request.onMessage = _.bind(this.onMessage, this);

                this.request.onOpen = _.bind(this.onMessage, this);

                this.request.onError = function() {
                    Logger.log(module, 'onError');
                };

                Atmosphere.subscribe(this.request);
            }
        },

        unSubscribe: function() {
            if (this.request) {
                Atmosphere.unsubscribe(this.request);
            }
        },

        onClientTimeout: function() {
            Atmosphere.subscribe(this.request);
        },

        onMessage: function(response) {
            try {
                var message = JSON.parse(response.responseBody);
                if (this._events && this._events['notificationCenter:' + message.type]) {
                    this.triggerMethod('notificationCenter:' + message.type, message);
                } else {
                    if (!Settings.get('cAccountRoles')[RolesMixin.VECTORIZATOR] ||
                        Settings.get('cAccountRoles')[RolesMixin.VECTORIZATOR_VIEW_ALL]) {
                        this.processNotification(message);
                    }
                }
            } catch(e) {
            }
        },

        processNotification: function(notification) {
            switch (notification.type) {
                case 'newVectorizeTicket':
                    this.displayNotification(_.i18n('notification.newvectorizeticket'), notification.content.name,
                        '#vectorization/' + notification.content.secId);
                    break;
                case 'newVectorizeTicketMessage':
                    this.displayNotification(_.i18n('notification.newvectorizeticketmessage'), notification.content.content,
                        '#vectorization/' + notification.content.ticketSecId);
                    break;
                case 'newConversionTicket':
                  this.displayNotification(_.i18n('notification.newconversionticket'), notification.content.name,
                    '#vectorization/' + notification.content.secId);
                  break;
                case 'newConversionTicketMessage':
                  this.displayNotification(_.i18n('notification.newconversionticketmessage'), notification.content.content,
                    '#vectorization/' + notification.content.ticketSecId);
                  break;
                case 'MissingBillingInfo':
                    /*
                    this.displayNotification(_.i18n('notification.missingBillingInfo.title'),
                        _.i18n('notification.missingBillingInfo.message'),
                        '#caccount/' +  Settings.get('currentCAccount').secId, 5000, 'danger', true);
                    */
                    break;
            }
        },

        addListener: function(view, event, callback) {
            this.on(event, callback, view);
            view.listenTo(view, 'before:destroy', _.bind(function() {
                this.off(event, callback);
            }, this));
        },

        displayNotification: function(title, message, urlFromFragment, hideDelay, type, forceJsNotification) {
            var fullUrl = document.location.origin + document.location.pathname + urlFromFragment;
            if ( _.isUndefined(hideDelay) || !_.isNumber(hideDelay)) {
              hideDelay = 5000;
            }
            if (window.Notification && window.Notification.permission === 'granted' && !forceJsNotification) {
                var options = {
                    body: message?message.replace(new RegExp('<br>', 'g'), ' '):'',
                    icon: Settings.url('base', Settings.get('defaultLogo'))
                };

                var notification = new window.Notification(title, options);
                notification.onclick = function () {
                    window.location = fullUrl;
                    window.focus();
                };
                notification.onshow = function() {
                  window.setTimeout(function(){
                    notification.close();
                  }, hideDelay);
                };
            } else {
                $.notify({
                    title: title,
                    message: message,
                    url: fullUrl,
                    target: '_self',
                    autoHide: true,
                    autoHideDelay: hideDelay
                },{
                    type: type || 'info',
                    placement: {
                        from: 'top',
                        align: 'center'
                    }
                });
            }
        }
    });

    var getInstance = function() {
        if (!instance) {
            instance = new NotificationCenter();
        }
        return instance;
    };

    module.exports = NotificationCenter;
    module.exports.getInstance = getInstance;
    module.exports.events = events;
});

