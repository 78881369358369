define('productPicturesTabPictureItemView',[
  'module',
  'marionette',
  'template!productPicturesTabPictureItemTpl',
  'bootbox',
  'app',
  'underscore'
], function(
  module,
  Marionette,
  pictureItemTpl,
  bootbox,
  App,
  _
) {
  'use strict';

  var PictureItemView = Marionette.ItemView.extend({
    template: pictureItemTpl,
    className: 'picture-elements',

    ui: {
      copy: '.js-copy',
      edit: '.js-edit',
      print: '.js-print',
      'delete': '.js-delete'
    },

    events: {
      'click @ui.copy': '_copy',
      'click @ui.edit': '_edit',
      'click @ui.print': '_print',
      'click @ui.delete': '_delete'
    },

    serializeData: function() {
      return {
        pictureImageUrl: this.model.getImageUrl(160, 120, this.options.productCode)
      };
    },

    _copy: function() {
      throw 'Copy functionality not yet implemented';
    },

    _edit: function() {
      this._navigateToFlex('editProductPicture');
    },

    _print: function() {
      this._navigateToFlex('placePrintArea');
    },

    _delete: function() {
      var model = this.model;
      bootbox.confirm(_.i18n('common.confirmDelete'), function(result) {
        if(result) {
          model.destroy();
        }
      });
    },

    _navigateToFlex: function(action) {
      App.navigate('product/' + this.options.productSecId + '/pictures/' + this.model.get('secId') + '/' + action,
        {trigger: true});
    }
  });

  module.exports = PictureItemView;
});
