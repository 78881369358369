/**
 * Created by RKL on 13/08/2015.
 */
define('caccountInvoicesTabView',[
  'module',
  'marionette',
  'underscore',
  'app',
  'settings',
  'dateUtils',
  'jqgridView',
  'template!caccountInvoicesTabTpl'
], function (
  module,
  Marionette,
  _,
  App,
  Settings,
  dateConverter,
  JqGridView,
  viewTpl
) {
  'use strict';

  var CAccountInvoicesTabView = JqGridView.extend({
    template: viewTpl,
    filtersName: 'invoicesFilters',

    triggers: {
    },

    ui: function() {
      return _.extend({
        jqGrid: '#jq-grid-invoices',
        jqGridPager: '#jq-grid-pager-invoices'
      }, this.gridUi);
    },

    events: function() {
      return _.extend({}, this.gridEvents);
    },

    gridOptions: function(data) {
      var that = this,
        defaultsOptions, options;

      this.sidx = 'creatDate';
      this.sord = 'desc';
      defaultsOptions = this.gridInitOptions(data);
      options = {
        colModel: [
          {
            label: '',
            name: 'secId',
            key: true,
            hidden: true
          }, {
            label: _.i18n('common.date'),
            name: 'date',
            classes: 'invoices-link',
            formatter: this.dateFormatter,
            search: false,
            index: 'creatDate',
            sorttype: 'date',
            searchrules: {date: true}, 
            searchoptions: {
              dataInit: function(element) {
                $(element).datepicker({
                  id: 'validityFrom_datePicker',
                  dateFormat: 'dd/mm/yy',
                  changeYear: true,
                  showOn: 'focus',
                  prevText: '<i class="mdi-hardware-keyboard-arrow-left"></i>',
                  nextText: '<i class="mdi-hardware-keyboard-arrow-right"></i>'
                });
              }
            },
            width: 60
          }, {
            label: _.i18n('invoice.number'),
            name: 'invoiceNumber',
            classes: 'invoices-link',
            formatter: this.defaultFormatter,
            search: false,
            index: 'invoiceNumber',
            width: 60
          }, {
            label: _.i18n('invoices.download'),
            name: 'fileUid',
            align: 'left',
            classes: 'invoices-link',
            formatter: this.downloadFormater,
            search: false,
            index: 'fileUid'
          }
        ],

        serializeGridData: function(postData) {
          var date;

          if (postData.creatDate) {
            date = new Date(dateConverter.toMDYFormat(postData.creatDate));
            postData.dateFrom = date.getTime();
            postData.dateTo = Date.now();
          } else if (!that.sorting) {
            delete postData.dateFrom;
            delete postData.dateTo;
          }
          that.sorting = false;

          if (postData.type === that.allString) {
            delete postData.type;
          }
          postData.sortField = postData.sidx;
          defaultsOptions.serializeGridData.apply(this, arguments);

          return postData;
        }
      };

      options = _.defaults(options, defaultsOptions);

      return options;
    },

    downloadFormater: function(cellValue) {
        var link = '<a class="btn btn-primary download-btn-text btn-sm" target="_blank" href="' + Settings.url('file', cellValue) +'">' +
                    '<i class="mdi-action-get-app"></i>' +
                    '<span>' + _.i18n('common.download') + '</span>' +
                    '</a>';
        return link;
    },


    onGridFilterChange: function() {
      var that = this,
        filters = {},
        value, date;
      
      _.each(this.colModel, function(column) {
        if (column.search) {
          if (column.stype === 'select') {
            value = that.$('select[name="' + column.index + '"]').val();
            filters[column.index] = value === that.allString ? '' : value;
          } else if (column.sorttype === 'date') {
            value = that.$('input[name="' + column.index + '"]').val();
            date = new Date(dateConverter.toMDYFormat(value));
            filters.dateFrom = date.getTime();
            filters.dateTo = Date.now();
          } else {
            value = that.$('input[name="' + column.index + '"]').val();
            filters[column.index] = value;
          }
        }
      });

      this.filters = filters;
      this.filterChange = true;
      this.updatePagination();
    }

  });

  module.exports = CAccountInvoicesTabView;
});
