/**
 * Created by BFR on 13/03/2020.
 */
define('productsSupplierListView',[
  'module',
  'jqgridView',
  'settings',
  'underscore',
  'template!productsSupplierListTpl',
  'datePickerPopoverMixin',
  'bootbox'
], function(
  module,
  JqGridView,
  Settings,
  _,
  viewTpl,
  datePickerPopoverMixin,
  bootbox
) {
  'use strict';

  var ProductsView =  JqGridView.extend({
    template: viewTpl,
    filtersName: 'productsListFilters',

    ui: function() {
      return _.extend({
        jqGrid: '#jq-grid-list',
        jqGridPager: '#jq-grid-pager-list'
      }, this.gridUi);
    },

    events: function() {
      return _.extend({}, this.gridEvents);
    },

    serializeData: function() {
      this.selectedProducts = Settings.get('selectedProducts') || [];
      return {type: 'product'};
    },

    onShow: function() {
    },

    onGridFilterChange: function(e) {
        var that = this,
            filters = {},
            value;

        this.triggerClear(e);

        _.each(this.colModel, function(column) {
            if (column.search) {
                if (column.stype === 'select') {
                    value = that.$('select[name="' + column.index + '"]').val();
                    filters[column.index] = value === that.selectDefault ? '' : value;
                } else if (column.sorttype === 'date') {
                  value = that.$('input[name="' + column.index + '"]').val();
                  if (column.index === 'creatDate' && value) {
                    filters.dateFrom = datePickerPopoverMixin.parseDateFrom(value);
                    filters.dateTo = datePickerPopoverMixin.parseDateTo(value);
                  }
                } else {
                    value = that.$('input[name="' + column.index + '"]').val();
                    filters[column.index] = value;
                }
            }
        });

        this.filters = filters;
        this.filterChange = true;
        this.updatePagination();
    },

    gridOptions: function(data) {
       var defaultsOptions, options, that = this;

      defaultsOptions = this.gridInitOptions(data);
      options = {
        colModel: [
          {label: '', name: 'secId', key: true, hidden: true},
          {
            label: ' ',
            name: 'cataloguePictureUid',
            align: 'left',
            search: false,
            classes: 'product-link image-cell',
            formatter: this.pictureUidFormatter
          }, {
            label: _.i18n('orderList.orderCreatDate'),
            name: 'creatDate',
            classes: 'context-menu order-link',
            formatter: this.dateFormatter,
            search: true,
            index: 'creatDate',
            sorttype: 'date', 
            searchrules: {date: true},
            searchoptions: {
              dataInit: datePickerPopoverMixin.jqGridDataInit
            },
            width: 130
          },
          {
            label: _.i18n('productList.productCode'),
            name: 'cCode',
            search: true,
            index: 'productCode',
            sorttype: 'integer',
            classes: 'product-link',
            formatter: this.defaultFormatter
          },
          {
            label: _.i18n('productList.productName'),
            name: 'name',
            search: true,
            index: 'productName',
            classes: 'product-link',
            formatter: this.defaultFormatter
          },
          {
            label: _.i18n('productList.productManufacturer'),
            name: 'manufacturerCode',
            search: true,
            index: 'manufacturer'/*, stype: 'select'*/,
            classes: 'product-link',
            formatter: this.defaultFormatter
          },
          {
            label: _.i18n('productList.status'),
            name: 'status',
            search: true,
            index: 'status',
            classes: 'product-link',
            formatter: this.defaultFormatter
          }
        ],

        beforeSelectRow: function(rowid, event) {
          // check if order selection
          var grid = $(this);
          if (event.ctrlKey || event.altKey) {  // alt instead ctrl for mac os
            that.manageCtrlKey(grid, rowid);
          } else if (event.shiftKey) {
            that.manageShiftKey(grid, rowid);
          } else {
            return true;
          } 
          return false;
        },

        serializeGridData: function(postData) {
          if (postData.productCode) {
            postData.code = postData.productCode;
          }

          if (postData.creatDate) {
            postData.dateFrom = datePickerPopoverMixin.parseDateFrom(postData.creatDate);
            postData.dateTo = datePickerPopoverMixin.parseDateTo(postData.creatDate);
          } else {
            delete postData.dateFrom;
            delete postData.dateTo;
          } 
          
          if (postData.productName) {
            postData.name = postData.productName;
          }

          defaultsOptions.serializeGridData.apply(this, arguments);

          return postData;
        }
      };

      options = _.defaults(options, defaultsOptions);

      return options;
    },

    manageCtrlKey: function(grid, rowid) {
      var maxSelectedOrdersNumber = 100,
          $row = grid.find('#' + rowid);
      if ($row.hasClass('selected')) {
        $row.removeClass('selected');
        this.onRowMultiselect(rowid, false);
      } else {
        if (this.selectedProducts.length === maxSelectedOrdersNumber) {
          this.showWarningSelectedNumberOutOfRange();
        } else {
          $row.addClass('selected');
          this.onRowMultiselect(rowid, true);
        }
      }
    },

    manageShiftKey: function(grid, rowid) {
      var firstSelectedOrder = this.getFirstSelectedOrder(),
          lastSelectedRow = grid.find('#' + rowid),
          gridRows = grid.find('tr'),
          lastIndex = gridRows.index(lastSelectedRow),
          lastSelectedOrder = {
            rowid: rowid,
            page: this.pagination.currentPage,
            index: lastIndex
          };

      if (!firstSelectedOrder) firstSelectedOrder = lastSelectedOrder;
      if (firstSelectedOrder.page > lastSelectedOrder.page) {
        this.swapOrders(firstSelectedOrder, lastSelectedOrder);
      } else if (firstSelectedOrder.page === lastSelectedOrder.page){
        if (firstSelectedOrder.index > lastSelectedOrder.index) {
          this.swapOrders(firstSelectedOrder, lastSelectedOrder);
        }
      }
      if (this.checkIfOnlyCurrentPageSelection(firstSelectedOrder, lastSelectedOrder)) {
        this.selectWithoutRequest(firstSelectedOrder, lastSelectedOrder);
      } else {
        this.getCollectionToPage(firstSelectedOrder, lastSelectedOrder);
      }
    },

    checkIfOnlyCurrentPageSelection: function (first, last) {
      return first.page === last.page;
    },

    selectWithoutRequest: function (first, last) {
      var grid = this.ui.jqGrid,
          list = grid.find('tr');

      this.selectedProducts = [];
      list.each(_.bind(function(index, row){
        var $row = $(row),
            rowid = $row.attr('id');
        if (index >= first.index && 
            index <= last.index) {
          this.selectedProducts.push({
            rowid: rowid,
            page: this.pagination.currentPage,
            index: index
          });
        }
      }, this));
      this.checkOrdersSelection();
    },

    checkOrdersSelection: function() {
      var $list = this.ui.jqGrid.find('tr');
      $list.each(_.bind(function (index, item) {
        var $item = $(item);
        if (_.some(this.selectedProducts, {rowid: $item.attr('id')})) {
          $item.addClass('selected');
        } else {
          $item.removeClass('selected');
        }
      }, this));
      this.onSelectedOrdersChanged();
    },

    swapOrders: function(first, last) {
      for (var key in last) {
        var temp = first[key];
        first[key] = last[key];
        last[key] = temp;
      }
    },

    getFirstSelectedOrder: function() {
      var firstSelected,
          lastPage = this.getLastPage(), 
          firstIndex = this.pagination.pageSize;
      if (this.selectedProducts.length === 0) {
        return null;
      } else {
        _.each(this.selectedProducts, function(order){
           if (order.page < lastPage) {
            lastPage = order.page;
            firstIndex = order.index;
            firstSelected = order;
           } else if (order.page === lastPage) {
            if (order.index < firstIndex) {
              firstSelected = order;
              firstIndex = order.index;
            }
           }
        });
        return firstSelected;
      }
    },

    getLastPage: function() {
      var lastPage;
      if (this.selectedProducts.length > 0) {
        lastPage = _.max(this.selectedProducts, function(order){ 
          return order.page; 
        }).page;
      } else {
        lastPage = this.pagination.currentPage;
      }
      return lastPage;
    },

    getCollectionToPage: function (first, last) {
      var firstOrderIndex = (first.page - 1 ) * this.pagination.pageSize + (first.index - 1),
          lastOrderIndex = (last.page - 1 ) * this.pagination.pageSize + last.index,
          size = lastOrderIndex - firstOrderIndex || 1,
          params = {
            pageSize: size,
            first: firstOrderIndex,
            _search: false,
            nd: new Date().getTime(),
            rows: size,
            page: 1,
            sord: 'asc'
          };
      if (size > 100) {
        this.showWarningSelectedNumberOutOfRange();
      } else {
        $('#content-blinder').show();
        this.trigger('onLoadSelectedOrders', first, last, params);
      }
    },

    showWarningSelectedNumberOutOfRange: function() {
      bootbox.alert(_.i18n('common.errorOccuredSelectedItemsNumberOutOfRange'), function () {
        return;
      });
    },

    onSelectOrders: function(first, last, orders) {
      var pageSize = this.pagination.pageSize,
          page, orderIndex;

      this.selectedProducts = [];
      _.each(orders, _.bind(function(order, index) {
        page = Math.floor((index + first.index - 1) / pageSize);
        orderIndex = index + first.index - page * pageSize;
        this.selectedProducts.push({
          rowid: order,
          page: page + first.page,
          index: orderIndex
        });
      }, this));
      this.checkOrdersSelection();
    },

    onRowMultiselect: function(rowid, select) {
      var index,
          grid = this.ui.jqGrid,
          gridList = grid.find('tr'),
          row = grid.find('#' + rowid),
          order = {
            rowid: rowid,
            page: this.pagination.currentPage,
            index: gridList.index(row)
          };
      if (select) {
        this.selectedProducts.push(order);
      } else {
        index = this.selectedProducts.indexOf(_.findWhere(this.selectedProducts, {rowid: rowid}));
        this.selectedProducts.splice(index, 1);
      }
      this.onSelectedOrdersChanged();
    },

    onSelectedOrdersChanged: function() {
      /*if (this.selectedProducts.length > 0) {
        this.actionsButtonView.setButtonsState({
          'proofsheet': true,
          'export': true
        });
      } else {
        this.actionsButtonView.setButtonsState({
          'proofsheet': false,
          'export': false
        });
      }*/
      Settings.set('selectedProducts', this.selectedProducts);
    }
  });

  module.exports = ProductsView;
});
