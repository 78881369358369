/**
 * Created by OLD on 23/11/2015.
 */
define('priceBillingTabView',[
  'module',  
  'marionette',
  'underscore',
  'app',
  'imageUtils',
  'template!billingTabTpl'
], function(
  module,
  Marionette,
  _,
  App,
  imageUtils,
  viewTpl
) {
  'use strict';

  var BillingTabView =  Marionette.LayoutView.extend({
    template: viewTpl,

    regions: {
      billingCodeTable: '.billing-price-table',
      billingDetailTable: '.billing-detail-table'
    },

    ui: {
      importBtn: '.billing-import',
      exportBtn: '.billing-export',
      addNew: '.add-new-code-b',
      addNewPrice: '.add-new-pricelist-b',
      uploadPrices: '.js-upload-prices'
    },

    events: {
      'click @ui.addNewPrice' : 'onAddNewPrice',
      'click @ui.addNew' : 'onShowAddNewCodePopup',
      'change @ui.uploadPrices': 'onUploadPrices'
    },

    onShowAddNewCodePopup: function() {
      this.trigger('add-new-code:open');
    },

    onAddNewPrice: function() {
      this.trigger('add-new-pricelist:open');
    },

    onShow: function() {
      $.material.init();
    },

    onUploadPrices: function(e) {
      var that = this,
        target = e.target,
        fileInfo;

      if (e && e.target && e.target.files && e.target.files[0]) {
        fileInfo = imageUtils.getFileInfo(target, 0);

        if (fileInfo) {
          imageUtils.encodeInputFileToBase64(target, 0, _.bind(function(imgBytes) {
            fileInfo.base64Value = imgBytes;
            that.uploadExcel(fileInfo);
          }, this));
        }
      }
    },

    uploadExcel: function(fileInfo) {
      this.ui.uploadPrices.val('');
      this.trigger('billingcodes:import-excel', fileInfo);
    }
  });

  module.exports = BillingTabView;
});
