/**
 * Created by BFR on 02/03/2020.
 */
define('productShareTabController',[
  'module',
  'underscore',
  'app',
  'productShareTabView',
  'entities/product',
  'entities/product_share'
], function(
  module,
  _,
  App,
  View
) {

    'use strict';
  var ProductTranslationsController = {
    showDetails: function (region, pm) {
      var product = App.request('products:model', {secId: pm.get('secId')});
      this.model = product;

      var productShare = App.request('products-share:model', { secId: pm.get('secId') });
      productShare.fetch().done(_.bind(function() {
        this.view = new View({ model: productShare });
        region.show(this.view);
      }, this));
    }
  };

  module.exports = ProductTranslationsController;
});
