define('productConfigDialogView',[
    'module',
    'underscore',
    'backbone',
    'app',
    'settings',
    'template!productConfigDialogTpl',
    'dialogView',
    'optionMixEditCollectionView'
], function(
    module,
    _,
    Backbone,
    App,
    Settings,
    viewTpl,
    DialogView,
    OptionMixEditCollectionView
) {
    'use strict';

    var ConfigProductView = DialogView.extend({
        template: viewTpl,

        events: {
            'click .dropdown-menu-printzone a': 'onChangePrinZone',
            'click .dropdown-menu-option a': 'onChangeOption',
            'click .js-confirm': 'onConfigurationSelected',
            'change .input-quantity': 'onQuantityChange',
            'click .add-mix-line': 'onAddMixLineClick'
        },

        regions: {
            mixOptionRegion: '.mix-table-body'
        },

        serializeData: function() {
            var templateData = { quantity: 50 };
            if (this.options.product) {
                templateData.product = this.options.product.toJSON();
                var mixable = false;
                if (templateData.product.productOptionCategories) {
                    for (var i = 0 ; i < templateData.product.productOptionCategories.length ; i++) {
                        if (templateData.product.productOptionCategories[i].mixable) {
                            mixable = true;
                        }
                    }
                }
                this.mixable = templateData.mixable = mixable;
                this.model = this.options.product;
                templateData.quantityOnly = false;
            } else {
                this.purchaseOrderId = this.options.purchaseOrderId;
                templateData.quantity = this.quantity = this.options.quantity;
                templateData.quantityOnly = true;
            }
            return templateData;
        },

        onShow: function() {
            $.material.init();
            this.currentConfiguration = {
                quantity: 50,
                printZones: {},
                options: {}
            };
            if (this.purchaseOrderId) {
                this.currentConfiguration.purchaseOrderId = this.purchaseOrderId;
            } else {
                if (this.mixable) {
                    var collection = new Backbone.Collection();
                    this.productJson = this.options.product.toJSON();
                    var defaultModel = new Backbone.Model({
                        
                        productOptionCategories: this.productJson.productOptionCategories,
                        quantity: 100
                    });
                    collection.push(defaultModel);
                    this.optionMixEditCollectionView = new OptionMixEditCollectionView({
                        collection: collection,
                        product: this.productJson
                    });
                    this.getRegion('mixOptionRegion').show(this.optionMixEditCollectionView);
                }
            }
        },

        onAddMixLineClick: function() {
            var defaultModel = new Backbone.Model({
                productOptionCategories: this.productJson.productOptionCategories,
                quantity: 100
            });
            this.optionMixEditCollectionView.collection.push(defaultModel);
        },

        onQuantityChange: function(e) {
            e.preventDefault();
            var target = $(e.target);
            this.currentConfiguration.quantity = target.val();
        },

        onChangePrinZone: function(e){
            e.preventDefault();
            var target = $(e.target),
                index = target.data('index'),
                pzId = target.data('pzId'),
                nbrColor = target.data('nb-color');
            this.$el.find('.printzone-'+ index +'-label').text(target.text());
            if (pzId === -1) {
                delete this.currentConfiguration.printZones[index];
            } else {
                this.currentConfiguration.printZones[index] = {
                    pzId: pzId,
                    nbrColor: nbrColor
                };
            }
        },

        onChangeOption: function(e){
            e.preventDefault();
            var target = $(e.target),
                index = target.data('index'),
                optionId = target.data('optionId');
            this.$el.find('.option-'+ index +'-label').text(target.text());
            this.currentConfiguration.options[index] = optionId;
        },

        onConfigurationSelected: function() {
            if (this.mixable) {
                this.currentConfiguration.optionsMix = this.optionMixEditCollectionView.getMixesConfiguration();
                this.currentConfiguration.quantity = 0;
                _.each(this.currentConfiguration.optionsMix, _.bind(function(mix) {
                    this.currentConfiguration.quantity += mix.quantity;
                }, this));
            }
            this.trigger('product-configured', this.currentConfiguration);
        }
    });
    module.exports = ConfigProductView;
});
