
define('template!translationsTableFiltersTpl', ['lodash'], function(_) {return function(obj) {
obj || (obj = {});
var __t, __p = '';
with (obj) {
__p += '<div class="row">\n  <div class="col-md-3">\n    <div class="form-group">\n      <div class="input-group">\n        <input class="form-control floating-label js-code-filter" type="text" placeholder="' +
((__t = ( _.i18n('translations.filter.code') )) == null ? '' : __t) +
'">\n        <span class="input-group-addon input-search-icon"><i class="mdi-action-search"></i></span>\n      </div>\n    </div>\n  </div>\n  <div class="col-md-3">\n    <div class="form-group">\n      <div class="input-group">\n        <input class="form-control floating-label js-text-filter" type="text" placeholder="' +
((__t = ( _.i18n('translations.filter.text') )) == null ? '' : __t) +
'">\n        <span class="input-group-addon input-search-icon"><i class="mdi-action-search"></i></span>\n      </div>\n    </div>\n  </div>\n  <div id="translations-filters-languages-region" class="col-left"></div>\n</div>';

}
return __p
};});

