define( 'entities/timetracker',[
  'app',
  'jquery',
  'backbone',
  'underscore',
  'settings',
  'moment',
  'dateUtils'
], function(
  App,
  $,
  Backbone,
  _,
  Settings,
  moment,
  dateUtils
) {
  'use strict';
  /**
   * Model
   */
  var Timetracker = Backbone.Model.extend( {
    url: function() {
      return Settings.url('compuzz', 'timetracking.json');
    }
  });

  /**
   * REST API
   */
  var API = {

    getTimetrackerFromTicket: function(secId) {
      var timetracker = new Timetracker(),
        defer = $.Deferred();

      timetracker.url = Settings.url('compuzz', 'tickets/' + secId + '/timetracking');
      timetracker.fetch( {
        success: function(data) {
          defer.resolve(data.toJSON());
        }
      });

      return defer.promise();
    },

    getTimetrackerData: function(timeF, timeT, logonId) {
      var that = this,
        timetracker = new Timetracker(),
        defer = $.Deferred(),
        params = {
          dateFrom: timeF,
          dateTo: timeT,
          logonId: logonId
        };

      timetracker.url = Settings.url('compuzz', 'timetracking.json', params);
      timetracker.fetch( {
        success: function(data) {
          defer.resolve(that.convertData(data.toJSON(), timeF, timeT));
        }
      });

      return defer.promise();
    },

    getTimetrackerTime: function(dateFrom, dateTo, logonId, type) {
      var that = this, 
        defer = $.Deferred(),
        typeOf = type ? type : 'Day',
        userId = logonId ? logonId : null,
        params = {
          dateFrom: dateFrom,
          dateTo: dateTo,
          logonId: userId,
          type: typeOf
        };
      $.ajax({
        url: Settings.url('compuzz', 'timetracking.json/times', params),
        complete: function(data) {
          defer.resolve(that.parseCallendarData(data.responseJSON));
        }
      });

      return defer.promise();
    },

    getUsersForTimetracker: function() {
      var defer = $.Deferred(),
        params = {
          pageSize: 150,
          first: 0
        };

      $.ajax({
        url: Settings.url('compuzz', 'v2/userpid', params),
        complete: function(data) {
          defer.resolve(data.responseJSON);
        }
      });

      return defer.promise();
    },

    deleteSreenTimetracker: function(userSecId, screenSecId) {
      var defer = $.Deferred();
      
      $.ajax({
        url: Settings.url('compuzz', 'timetracking.json/' + userSecId + '/' + screenSecId),
        type: 'DELETE',
        complete: function() {
          defer.resolve();
        }
      });

      return defer.promise();
    },

    convertData: function(dataCollection, timeF, timeT) {
      return _.compact(_.map(dataCollection, function (item) {
        if (item.timeTrackingSnapshotJsons.length !== 0) {
          if (moment(item.fromDate).isBetween(timeF, timeT) || 
              moment(item.fromDate).isSame(timeF) || 
              moment(item.fromDate).isSame(timeT)) {
            return {
              user: item.useerpidJson,
              start: moment(item.fromDate).startOf('day').format('DD/MM/YYYY, HH:mm:ss'),
              end: moment(item.toDate).endOf('day').format('DD/MM/YYYY, HH:mm:ss'),
              snaptshots: item.timeTrackingSnapshotJsons,
              task: item.comment,
              secId: item.secId,
              ticketSecId: item.ticketSecId,
              comment: item.comment?decodeURI(item.comment).replace(/\+/g, ' '):''
            };
          }
        }
      }));
    },

    parseCallendarData: function(times) {
      times = this.filterTotals(times);
      return _.map(times, function(time) {
        var totals = _.map(time.totals, function(total) {
          return {
            seconds: total.value,
            value: dateUtils.toHHMM(total.value, 'seconds'),
            day: moment(total.date).format('DD/MM/YYYY'),
            miliseconds: moment.duration(total.value, 'seconds').asMilliseconds(),
            activity: total.activity
          };       
        });
        return {
          user: time.user.firstName  + ' ' + time.user.lastName,
          online: time.user.online,
          logonId: time.user.logonId,
          logo: time.user.logo,
          logoUrl: Settings.url('file', time.user.logo),
          totals: totals,
          secId: time.user.secId,
          lastTicketSecId: time.lastTicketSecId,
          ttVersion: time.ttVersion,
          estimatedTime: time.estimatedTime,
          estimatedTimeInProgress: time.estimatedTimeInProgress,
          usedTime: time.usedTime,
          usedTimeInProgress: time.usedTimeInProgress
        };
      });
    },

    filterTotals: function(times) {
      var newTimes = [];

      _.each(times, function(time) {        
        var newData = _.chain(time.totals)
          .groupBy('date')
          .map(function(value, key) {
              var act = 0;
              if (value.length > 0) {
                  _.each(value, function(val) {
                      act += val.activity;
                  });
                  act /= value.length;
              }
              return {
                  date: parseInt(key, 10),
                  value: _.pluck(value, 'value').reduce(function(a, b){
                    return a + b;
                  }),
                  activity: act
              };
          }).value();
        newTimes.push({
          totals: newData,
          user: time.user,
          lastTicketSecId: time.lastTicketSecId,
          ttVersion: time.ttVersion,
          estimatedTime: time.estimatedTime,
          estimatedTimeInProgress: time.estimatedTimeInProgress,
          usedTime: time.usedTime,
          usedTimeInProgress: time.usedTimeInProgress
        });
      });
      return newTimes;
    }
  };

  /**
   * Request communications
   */
  App.reqres.setHandler('timetracker:get-data', function(timeF, timeT, logonId) {
    return API.getTimetrackerData(timeF, timeT, logonId);
  });
  App.reqres.setHandler('timetracker:get-time', function(dateFrom, dateTo, logonId, type) {
    return API.getTimetrackerTime(dateFrom, dateTo, logonId, type);
  });
  App.reqres.setHandler('timetracker:get-user', function() {
    return API.getUsersForTimetracker();
  });
  App.reqres.setHandler('timetracker:delete-screen', function(userSecId, screenSecId) {
    return API.deleteSreenTimetracker(userSecId, screenSecId);
  });
  App.reqres.setHandler('timetracker:get-from-ticket', function(secId) {
    return API.getTimetrackerFromTicket(secId);
  });
});
