define('entities/sharerequest',[
  'app',
  'backbone',
  'settings'
], function(App, Backbone, Settings) {
  'use strict';

  var ShareRequest = Backbone.Model.extend({
    idAttribute: 'secId'
  });

  var ShareRequestCollection = Backbone.Collection.extend({
    url: function() {
      return Settings.url('compuzz', 'v2/sharerequest');
    },
    model: ShareRequest
  });

  var API = {

    deleteShareRequest: function(model) {
      var defer = $.Deferred();
      model.url = Settings.url('compuzz', 'v2/sharerequest/' + model.get('secId'));
      model.destroy({
        success: function(resp) {
          defer.resolve(resp);
        }
      });

      return defer.promise();
    },

    getShareRequest: function(first, pageSize) {
      var shareRequests = new ShareRequestCollection({}),
          defer = $.Deferred(),
          params = {
            first: first,
            pageSize: pageSize
          };

      shareRequests.url = Settings.url('compuzz', 'v2/sharerequest/', params);
      shareRequests.fetch( {
        success: function(data) {
          defer.resolve(data);
        }
      } );

      return defer.promise();
    },

    getShareRequestCount: function() {
      var defer = $.Deferred();

      $.ajax({
        url: Settings.url('compuzz', 'v2/sharerequest/rowCount'),
        success: function(resp) {
          defer.resolve(resp);
        }
      });

      return defer.promise();
    },

    getInvitationsStatus: function(first, pageSize) {
      var shareRequests = new ShareRequestCollection({}),
          defer = $.Deferred(),
          params = {
            first: first,
            pageSize: pageSize
          };

      shareRequests.url = Settings.url('compuzz', 'v2/sharerequest/invitationstatus', params);
      shareRequests.fetch( {
        success: function(data) {
          defer.resolve(data);
        }
      } );

      return defer.promise();
    },

    countInvitationsStatus: function() {
      var defer = $.Deferred();

      $.ajax({
        url: Settings.url('compuzz', 'v2/sharerequest/invitationstatus/rowCount'),
        success: function(resp) {
          defer.resolve(resp);
        }
      });

      return defer.promise();
    },

    sendInvitations: function(emails) {
      var defer = $.Deferred();

      $.ajax({
        method: 'POST',
        url: Settings.url('compuzz', 'v2/sharerequest/inviteEmails'),
        data: JSON.stringify(emails),
        contentType: 'application/json',
        success: function(resp) {
          defer.resolve(resp);
        }
      });

      return defer.promise();
    }
  };

  App.reqres.setHandler('shareRequest:count', function() {
    return API.getShareRequestCount();
  });

  App.reqres.setHandler('shareRequest:entities', function() {
    return API.getShareRequest(0, 100);
  });

  App.reqres.setHandler('shareRequest:delete', function(secId) {
    return API.deleteShareRequest(secId);
  });

  App.reqres.setHandler('shareRequest:sendInvitations', function(emails) {
    return API.sendInvitations(emails);
  });

  App.reqres.setHandler('shareRequest:getInvitationsStatus', function(first, pageSize) {
    return API.getInvitationsStatus(first, pageSize);
  });

  App.reqres.setHandler('shareRequest:countInvitationsStatus', function() {
    return API.countInvitationsStatus();
  });
});
