define( 'entities/caccounts/credits',[
  'app',
  'jquery',
  'backbone',
  'settings',
  'rolesMixin'
], function(
  App,
  $,
  Backbone,
  Settings,
  RolesMixin
) {
  'use strict';

  /**
   * Model
   */
  var Credit = Backbone.Model.extend({
      defaults: {
          quantity: 200
      }
  });
  
  /**
   * REST API
   */
  var API = {
    getRowCountUrl: function(cAccountSecId) {
        var caccountIdParam = cAccountSecId ? '?cAccountSecId='+cAccountSecId : '';
        return 'credits.json/rowCount' + caccountIdParam;
    },

    getCreditsUrl: function(cAccountSecId) {
      var caccountIdParam = cAccountSecId ? '?cAccountSecId='+cAccountSecId : '';
      return Settings.url('compuzz', 'credits.json'+caccountIdParam);
    },

    getModel: function() {
        return new  Credit();
    },

    refundWithPaypal: function(data){
        var ca = Settings.get('currentCAccount'),
            origin = window.location.origin + window.location.pathname,
            urlStart = origin + '#caccount/'+ca.secId;

          var parm = {
              amount: data.get('quantity'),
              successURL: encodeURIComponent(urlStart + '/credits/payment/success'),
              cancelURL: encodeURIComponent(urlStart +'/credits/payment/cancel'),
              errorURL: encodeURIComponent(urlStart +'/credits/payment/error')
          };

          var defer = $.Deferred(),
              url = Settings.url('compuzz', 'credits.json/refund/paypal', parm);

          $.ajax({
              type: 'POST',
              dataType: 'text',
              contentType: false,
              url: url,
              success: function(resp) {
                  defer.resolve(resp);
              }
          });

          return defer.promise();
    },

    placePayment: function(data){
          var parms = {
              creditUid : data.creditId,
              payerID: data.payerId,
              paypalToken : data.paypalTokenId
          };

          var defer = $.Deferred(),
              url = Settings.url('compuzz', 'credits.json/place/paypal', parms);

          $.ajax({
              type: 'POST',
              dataType: 'text',
              contentType: false,
              url: url,
              success: function(resp) {
                  defer.resolve(resp);
              }
          });

          return defer.promise();
    },

    getCreditsBalance: function(cAccountSecId) {
      var caccountIdParam = cAccountSecId ? '?cAccountSecId='+cAccountSecId : '';
      var defer = $.Deferred();
      if (cAccountSecId && !Settings.get('cAccountRoles')[RolesMixin.VECTORIZATOR] &&
          !Settings.get('cAccountRoles')[RolesMixin.DASHBOARD_SUPERADMIN_ACCOUNT_CREDITS] &&
          !Settings.get('cAccountRoles')[RolesMixin.VECTORIZATOR_ONLY_ASSIGN]) {
          setTimeout(defer.resolve, 100);
      } else {
             var url = Settings.url('compuzz', 'v2/stats/count/credits' + caccountIdParam);
          $.ajax({
              url: url,
              success: function(resp) {
                  defer.resolve(resp);
              }
          });
      }
      return defer.promise();
    },

    invoiceSample: function() {
          return Settings.url('compuzz', 'v2/invoices/sample');
    },

    addCredits: function(data,cAccountSecId) {

       var caccountIdParam = cAccountSecId ? '?cAccountSecId='+cAccountSecId : '';
       var parm = {
            amount: data.get('quantity')
       };

      var defer = $.Deferred(),
        url = Settings.url('compuzz', 'credits.json/refund/manual' + caccountIdParam, parm);

      $.ajax({
        url: url,
        type: 'POST',
        dataType: 'text',
        contentType: false,
        success: function(resp) {
          defer.resolve(resp);
        }
      });

      return defer.promise();
    },

      /*jshint camelcase: false */
      refundStripe2: function(payment_method_id, amount) {

          var defer = $.Deferred(),
              formdata =  new FormData(),
              url = Settings.url('compuzz', 'credits.json/refund/stripe2');

          formdata.append('amount', amount);
          formdata.append('payment_method_id', payment_method_id);

          $.ajax({
              type: 'POST',
              url: url,
              data: formdata,
              processData: false,
              contentType: false,
              success: function(resp) {
                  defer.resolve(resp);
              }
          });

          return defer.promise();
      },

    refundStripe: function(source, amount) {

        var defer = $.Deferred(),
            formdata =  new FormData(),
            url = Settings.url('compuzz', 'credits.json/refund/stripe');

        formdata.append('amount', amount);
        formdata.append('source', source);

        $.ajax({
            type: 'POST',
            url: url,
            data: formdata,
            processData: false,
            contentType: false,
            success: function(resp) {
                defer.resolve(resp);
            }
        });

        return defer.promise();
    },

    getPriceDetail: function(amount) {

      var defer = $.Deferred(),
          url = Settings.url('compuzz', 'credits.json/priceDetail', { amount: amount});

        $.ajax({
            type: 'GET',
            url: url,
            success: function (data) {
                defer.resolve(data);
            }
        });

      return defer.promise();
    },

    placeStipe: function(creditUid, clientSecret, source) {
        var defer = $.Deferred(),
            url = Settings.url('compuzz', 'credits.json/place/stripe', {
                creditUid: creditUid,
                /*jshint camelcase: false */
                client_secret: clientSecret,
                source: source
            });

        $.ajax({
            type: 'GET',
            url: url,
            success: function () {
                defer.resolve();
            },
            error: function () {
                defer.reject();
            }
        });

        return defer.promise();
    },

    placeStripe2: function(payment_intent_id) {
      var defer = $.Deferred(),
          url = Settings.url('compuzz', 'credits.json/place/stripe2', {
              payment_intent_id: payment_intent_id
          });

      $.ajax({
          type: 'GET',
          url: url,
          success: function (response) {
              defer.resolve(response);
          },
          error: function (response) {
              defer.reject(response);
          }
      });

      return defer.promise();
    }
  };

  /**
   * Request communications
   */

  App.reqres.setHandler('caccount-credits:row-count-url', function(secId) {
    return API.getRowCountUrl(secId);
  });

  App.reqres.setHandler('caccount-credits:refund-paypal', function(data){
    return API.refundWithPaypal(data);
  });

  App.reqres.setHandler('caccount-credits:place-paypal', function(data){
    return API.placePayment(data);
  });

  App.reqres.setHandler('caccount-credits:get-url', function(secId) {
    return API.getCreditsUrl(secId);
  });

  App.reqres.setHandler('caccount-credits:get-balance', function(secId) {
    return API.getCreditsBalance(secId);
  });

  App.reqres.setHandler('caccount-credits:get-model', function() {
        return API.getModel();
  });

  App.reqres.setHandler('caccount-credits:invoice-sample', function() {
        return API.invoiceSample();
  });

  App.reqres.setHandler('caccount-credits:add-credits', function(model, secId) {
      return API.addCredits(model, secId);
  });

  App.reqres.setHandler('caccount-credits:refundStripe', function(source, amount) {
    return API.refundStripe(source, amount);
  });

    App.reqres.setHandler('caccount-credits:refundStripe2', function(payment_method_id, amount) {
        return API.refundStripe2(payment_method_id, amount);
    });

  App.reqres.setHandler('caccount-credits:getPriceDetail', function(amount) {
    return API.getPriceDetail(amount);
  });

  App.reqres.setHandler('caccount-credits:placeStipe', function(creditUid, clientSecret, source) {
    return API.placeStipe(creditUid, clientSecret, source);
  });

    App.reqres.setHandler('caccount-credits:placeStripe2', function(payment_intent_id) {
        return API.placeStripe2(payment_intent_id);
    });

  return ;
});
