define('shareRequestCollectionView',[
    'module',
    'backbone',
    'marionette',
    'shareRequestView'
], function(
    module,
    Backbone,
    Marionette,
    ShareRequestView
) {
    'use strict';

    var ShareRequestCollectionView = Marionette.CollectionView.extend({
        childView: ShareRequestView,
        collection: new Backbone.Collection(),

        childEvents: {
            'catalogue:share:add': function (view) {
                this.trigger('catalogue:share:add', view.model);
            },
            'share-request:delete': function (view) {
                this.trigger('share-request:delete', view.model);
            }
        }
    });

    module.exports = ShareRequestCollectionView;
});
