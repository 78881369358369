
define('template!customerTemplatesPopupAddNewTpl', ['lodash'], function(_) {return function(obj) {
obj || (obj = {});
var __t, __p = '', __j = Array.prototype.join;
function print() { __p += __j.call(arguments, '') }
with (obj) {
__p += '<div>\n	<form>\n		<div class="container-fluid add-new-template-container">\n			<div class="row form-group text-center">\n				<div class="col-sm-6 p-l-5 p-r-5">\n					<button type="button" class="btn btn-primary js-from-empty">' +
((__t = ( _.i18n("common.fromEmpty") )) == null ? '' : __t) +
'</button>\n				</div>\n\n				<div class="col-sm-6 p-l-5 p-r-5">\n					<input type="file" class="filestyle js-from-upload hidden" multiple="multiple" id="aprofile-image-input" accept="image/*,application/pdf,application/ai,application/eps,.cpzc"/>\n          <div class="bootstrap-filestyle">\n            <label for="aprofile-image-input" class="btn btn-primary">\n              <span>' +
((__t = ( _.i18n("common.fromUpload") )) == null ? '' : __t) +
'</span>\n            </label>\n          </div>\n				</div>\n\n				<div class="col-sm-6 p-l-5 p-r-5">\n					<button type="button" class="btn btn-primary js-from-attach">' +
((__t = ( _.i18n("common.fromAttachment") )) == null ? '' : __t) +
'</button>\n				</div>\n\n        <div class="col-sm-6 p-l-5 p-r-5">\n					<button type="button" class="btn btn-primary js-from-template">' +
((__t = ( _.i18n("common.fromTemplates") )) == null ? '' : __t) +
'</button>\n				</div>\n        ';
 if(showFromProduct) { ;
__p += '\n          <div class="col-sm-6 col-sm-offset-3">\n            <a target="_blank" href="' +
((__t = ( logotoolUrl )) == null ? '' : __t) +
'" type="button" class="btn btn-primary js-from-product">' +
((__t = ( _.i18n("common.fromProduct") )) == null ? '' : __t) +
'</a>\n          </div>\n        ';
 } ;
__p += '\n			</div>\n		</div>\n	</form>\n</div>';

}
return __p
};});

