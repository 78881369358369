/**
 * Created by Matteo on 11/07/2015.
 */
define('headerShowController',[
  'module',
  'underscore',
  'app',
  'backbone',
  'settings',
  'headerShowView',
  'headerPopupView',
  'imageUtils',
  'notificationCenter',
  'entities/caccounts',
  'entities/caccounts/userpids',
  'entities/session',
  'entities/sharerequest',
  'entities/catalogues'
], function(
  module,
  _,
  App,
  Backbone,
  Settings,
  View,
  PopupView,
  ImageUtils,
  NotificationCenter
) {
  'use strict';

  var HeaderController = {
    showHeader: function() {
      var view = new View();

      this.view = view;
      view.listenTo(view, 'onLogout', _.bind(this.onLogout, this));
      view.listenTo(view, 'onShowSidebar', _.bind(this.onShowSidebar, this));
      view.listenTo(view, 'switchCaccount', _.bind(this.onSwitch, this));
      view.listenTo(
        view,
        'getAvailableCaccounts',
        _.bind(this.getAvailableCaccounts, this)
      );
      view.listenTo(view, 'navigate-to:account-profile', _.bind(this.onAccountProfileShow, this));
      view.listenTo(view, 'onEdit', _.bind(this.onEditAccount, this));
      view.listenTo(view, 'changeLanguage', _.bind(this.onChangeLanguage, this));
      view.listenTo(view, 'countShareRequests', _.bind(this.countShareRequests, this));
      view.listenTo(view, 'getShareRequests', _.bind(this.getShareRequests, this));
      view.listenTo(view, 'catalogue:share:add', _.bind(this.onAcceptShareRequest, this));
      view.listenTo(view, 'share-request:delete', _.bind(this.onDeleteShareRequest, this));

      App.off('header:update-caccount');
      App.on('header:update-caccount', _.bind(this.onUpdateCaccount, this));

      App.regions.getRegion('main').currentView.getRegion('header').show(view);
    },

    onLogout: function() {
      Settings.set('tokenId', null);
      Settings.set('currentUser', null);
      Settings.set('currentUserCookie', null);
      Settings.set('currentCAccountCookie', null);
      Settings.set('currentCAccount', null);
      NotificationCenter.getInstance().unSubscribe();
      App.trigger('login:show');
    },

    onAcceptShareRequest: function (shareRequest) {
      App.request('catalogue:acceptShare', shareRequest.get('sharedObject').secId)
          .done(_.bind(function(){
            this.view.shareRequestCollectionView.collection.remove(shareRequest);
            App.trigger('catalogues:shared:show');
          }, this));
    },

    onDeleteShareRequest: function(shareRequest) {
      var shareRequestDelete = App.request('shareRequest:delete', shareRequest);

      $.when(shareRequestDelete).done(_.bind(function() {
        this.countShareRequests();
      }, this));
    },

    onShowSidebar: function() {
      this.view.onShowMenu('hide');
      App.trigger('sidebar:switch');
    },

    onAccountProfileShow: function() {
      var caSecId = Settings.get('currentCAccount').secId;
      
      App.navigate('/caccount/'+ caSecId + '/profile', {trigger: true});
    },

    getAvailableCaccounts: function() {
      var that = this,
        cAccountCount = App.request('caccount:count');

      $.when(cAccountCount).done(function(count) {
        that.view.checkAvailableCaccounts(count);
      });
    },

    countShareRequests: function() {
      var that = this,
          shareRequestCount = App.request('shareRequest:count');

      $.when(shareRequestCount).done(function(count) {
        that.view.checkAvailableShareRequest(count);
      });
    },

    getShareRequests: function() {
      var that = this,
          shareRequestGet = App.request('shareRequest:entities');

      $.when(shareRequestGet).done(function(shareRequests) {
        that.view.shareRequestCollectionView.collection.reset(shareRequests.models);
      });
    },

    onUpdateCaccount: function() {
      this.view.displayCurrentCaccountInfo();
    },

    onUpdateUserPidInfo: function() {
      this.view.displayCurrentUserPidInfo();
    },

    onSwitch: function (caccount) {
      Settings.clearMemory();
      App.trigger('switchCaccount', caccount);
    },

    onEditAccount: function() {
      if (!this.model ||
          this.model.get('secId') !== Settings.get('currentUser').secId) {
        this.model = new Backbone.Model(Settings.get('currentUser'));
      }

      this.formData = new FormData();
      this.popupView = new PopupView({model: this.model});
      this.popupView.show({
        title: _.i18n('account.edit.popup.title'),
        className: 'headerPopupeditData'
      });

      this.popupView.listenTo(
        this.popupView, 
        'onChangeLogo',
        _.bind(this.onChangeLogo, this)
      );
      this.popupView.listenTo(
        this.popupView, 
        'onSaveClick',
        _.bind(this.onSaveClick, this)
      );
      this.popupView.listenTo(
        this.popupView, 
        'onChangePassword',
        _.bind(this.onChangePassword, this)
      );
    },

    onChangeLanguage: function(lang){
        Settings.set('lang',lang);

        var update = App.request('caccount:changeLanguage', Settings.get('currentCAccount').secId, lang);
        $.when(update).done(_.bind(function(result){
            Settings.set('currentCAccount', result);
            window.location.reload();
        }, this));
    },

    onChangePassword: function() {
      var that = this,
          model = App.request('session:change:password');

      this.onShowMessage('primary', _.i18n('common.sending'));
      model.save({}, {
        success: function(model, response) {
          that.popupView.hide();
          App.navigate('reset_password' + '?tokenfp=' + response.value, {trigger: true});
        },
        error: function() {
          that.onShowMessage('danger', _.i18n('common.request.error'));
        }
      });
    },

    onShowMessage: function(type, message) {
      this.popupView.triggerMethod('showMessage', type, message);
      this.popupView.triggerMethod('delayMessage', 5000);
    },

    onChangeLogo: function() {
      var file = this.popupView.ui.upload[0].files[0];
      this.formData.append('file', file);
      ImageUtils.encodeFileToBase64(file,
          _.bind(this.onPreviewFile, this));      
    },

    onPreviewFile: function(base64File) {
      this.popupView.ui.preview
        .attr('style', 'background-image: url(' + base64File + ')');
    },

    onSaveClick: function() {
      var that = this,
        postRequest;
      
      if (this.validate()) {
        this.formData.append('secId', this.model.get('secId'));
        this.formData.append('firstName', this.model.get('firstName'));
        this.formData.append('lastName', this.model.get('lastName'));
        postRequest = App.request('caccount-admins:post-item', this.formData);
        $.when(postRequest).done(function(data) {
          Settings.set('currentUserCookie', JSON.stringify(data));
          Settings.set('currentUser', data);
          that.onUpdateUserPidInfo();
          that.popupView.hide();
        });
      }
    },

    validate: function() {
      var fieldsToValidate = [
        {name: 'firstName', type: 'required'},
        {name: 'lastName', type: 'required'}
      ];

      return this.popupView.validate(fieldsToValidate);
    }
  };

  module.exports = HeaderController;
});
