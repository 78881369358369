/**
 * Created by RKL on 21/08/2015.
 */
define('productsListController',[
  'module',
  'logger',
  'underscore',
  'app',
  'productsListView',
  'settings',
  'entities/product'
], function(
  module,
  Logger,
  _,
  App,
  View
) {
  'use strict';

  var ProductsController = {
    showProducts: function() {
      var view = new View();
      this.view = view;

      view.listenTo(view, 'onGridLoaded', _.bind(this.onGridLoaded, this));
      App.regions.getRegion('main').currentView.getRegion('content').show(view);
    },

    onGridLoaded: function() {
      var data = {
        url: App.request('products:get-collection-url'),
        rowCountUrl: App.request('products:row-count-url')
      };

      this.view.displayGrid(data);
    }
  };

  module.exports = ProductsController;
});
