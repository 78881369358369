
define('template!headerPopupTpl', ['lodash'], function(_) {return function(obj) {
obj || (obj = {});
var __t, __p = '', __j = Array.prototype.join;
function print() { __p += __j.call(arguments, '') }
with (obj) {
__p += '<div class="user-information-container">\n	<div class="container-fluid">\n		<div class="row">\n			<div class="loading-block js-loading-block">\n				<span class="loading-message js-loading-message label"></span>\n			</div>\n		</div>\n		<form class="form-horizontal col-xs-12" role="form">\n			<div class="form-group">\n				<label class="col-xs-3 control-label">' +
((__t = ( _.i18n("common.avatar") )) == null ? '' : __t) +
'</label>\n				<div class="avatar-change-container col-xs-9">\n					<div class="logo-user-edit-block">\n						<div class="user-avatar-img" style="';
 if (fileUrl) { ;
__p += 'background-image: url(' +
((__t = ( fileUrl )) == null ? '' : __t) +
');';
 } ;
__p += '"></div>\n						<label for="uploadUserAvatar" class="upload-title"><i class="mdi-file-file-upload"></i></label>\n						<input id="uploadUserAvatar" data-key="file" multiple type="file" name="filename" class="hidden upload-user-avatar" accept="application/pdf, application/postscript, image/*"/>\n						<a class="change-password js-change-password pull-right">' +
((__t = ( _.i18n("login.changePassword") )) == null ? '' : __t) +
'</a>\n					</div>\n				</div>\n			</div>\n			<div class="form-group" data-field-name="logonId">\n				<label class="col-xs-3 overflow-hidden control-label">' +
((__t = ( _.i18n("common.username") )) == null ? '' : __t) +
'</label>\n				<div class="col-xs-9">\n					<input\n						type="logonId"\n						class="form-control logonId js-form-input"\n						name="logonId"\n						data-field-name="logonId"\n						placeholder="' +
((__t = ( logonId )) == null ? '' : __t) +
'"\n						value="' +
((__t = ( logonId )) == null ? '' : __t) +
'"\n						disabled>\n				</div>\n			</div>\n			<div class="form-group" data-field-name="firstName">\n				<label class="col-xs-3 overflow-hidden control-label">' +
((__t = ( _.i18n("login.firstName") )) == null ? '' : __t) +
':</label>\n				<div class="col-xs-9">\n					<input\n						type="logonId"\n						class="form-control firstName js-form-input"\n						name="firstName"\n						data-field-name="firstName"\n						placeholder="' +
((__t = ( firstName )) == null ? '' : __t) +
'"\n						value="' +
((__t = ( firstName )) == null ? '' : __t) +
'">\n				</div>\n			</div>\n			<div class="form-group" data-field-name="lastName">\n				<label class="col-xs-3 overflow-hidden control-label">' +
((__t = ( _.i18n("login.lastName") )) == null ? '' : __t) +
':</label>\n				<div class="col-xs-9">\n					<input\n						type="logonId"\n						class="form-control lastName js-form-input"\n						name="lastName"\n						data-field-name="lastName"\n						placeholder="' +
((__t = ( lastName )) == null ? '' : __t) +
'"\n						value="' +
((__t = ( lastName )) == null ? '' : __t) +
'">\n				</div>\n			</div>\n		</form>\n	</div>\n\n	<!-- SAVE / CANCEL -->\n	<div class="buttons-container">\n		<div class="form-group pull-right p-r-20">\n			<a class="btn btn btn-s-md-save btn-primary">' +
((__t = ( _.i18n("common.save") )) == null ? '' : __t) +
'</a>\n			<a class="btn btn btn-s-md-cancel btn-primary" data-dismiss="modal">' +
((__t = ( _.i18n("common.cancel") )) == null ? '' : __t) +
'</a>\n		</div>\n	</div>\n</div>';

}
return __p
};});

