define('productPicturesTabLayoutView',[
  'module',
  'marionette',
  'template!productPicturesTabLayoutTpl',
  'emptyView',
  'productPicturesTabCollectionView',
  'addNewPictureButtonView',
  'productAddNewPicturePopupView',
  'underscore',
  'entities/product_picture'
], function(
  module,
  Marionette,
  picturesTabLayoutTpl,
  EmptyView,
  PictureCollectionView,
  AddNewPictureButtonView,
  AddNewPicturePopupView,
  _
) {
  'use strict';

  var CustomAddNewPictureButton = AddNewPictureButtonView.extend({
    events: {
      click: '_showPopup'
    },

    _showPopup: function() {
      var addNewPicturePopupView = new AddNewPicturePopupView({
        productSecId: this.options.product.get('secId')
      });

      addNewPicturePopupView.show({
        title: _.i18n('common.createPicture')
      });
    }
  });

  var ProductPicturesTabLayoutView = Marionette.LayoutView.extend({
    template: picturesTabLayoutTpl,

    regions: {
      newPicture: '.js-new-picture-region',
      pictures: '.js-pictures-region',
      loader: '.js-loader-region'
    },

    collectionEvents: {
      request: '_showLoader',
      sync: '_loadMoreAfterTimeOut',
      'first:loaded': '_showPictureCollection',
      'all:loaded': '_finishLoading'
    },

    events: {
      scroll: '_onScroll'
    },

    className: 'content height-100 scrollable',

    _loaderView: undefined,

    onShow: function() {
      this.getRegion('newPicture').show(new CustomAddNewPictureButton({
        productSecId: this.options.product.get('secId')
      }));
      this._loaderView = new EmptyView();
      this.getRegion('loader').show(this._loaderView);
      this.collection.fetchByProduct(this.options.product.get('secId'), true);
    },

    _loadMore: _.throttle(function() {
      if(this._shouldLoadMore()) {
        this.collection.fetchByProduct(this.options.product.get('secId'));
      } else {
        this._hideLoader();
      }
    }, 500),

    _loadMoreAfterTimeOut: function() {
      setTimeout(_.bind(this._loadMore, this), 10);
    },

    _onScroll: function() {
      this._showLoader();
      this._loadMore();
    },

    _showPictureCollection: function() {
      var pictureCollectionView = new PictureCollectionView({
        collection: this.collection,
        productSecId: this.options.product.get('secId'),
        productCode: this.options.product.get('code')
      });

      this.getRegion('pictures').show(pictureCollectionView);
    },

    _showLoader: function() {
      this._loaderView.$el.show();
    },

    _hideLoader: function() {
      this._loaderView.$el.fadeOut();
    },

    _finishLoading: function() {
      this.$el.off('scroll');
      this._loaderView.destroy();
      this._loadingFinished = true;
    },

    _shouldLoadMore: function() {
      if(!this._loadingFinished) {
        var scrollContainer = this.$el,
          height = scrollContainer.height() || 75,
          scrollBlock, top;

        scrollBlock = this._loaderView.$el;
        top = scrollBlock.offset().top - scrollContainer.offset().top;

        if (top < height) {
          return true;
        }
      }
    }
  });

  module.exports = ProductPicturesTabLayoutView;
});
