define('catalogueMineProductsTabController',[
    'module',
    'catalogueMineProductsTabView',
    'underscore',
    'app',
    'jquery',
    'productSelectDialogView',
    'entities/product'
], function (module,
             ProductsListView,
             _,
             App,
             $,
             ProductSelectDialog) {
    'use strict';

    var ProductsTabController = {
        showProductsTab: function (region, catalogueModel) {
            this.view = new ProductsListView({
                model: catalogueModel
            });
            this.view.listenTo(this.view, 'onGridLoaded', _.bind(this.onGridLoaded, this));
            this.view.listenTo(this.view, 'addNewProduct', _.bind(this.onAddNewProduct, this));
            this.view.listenTo(this.view , 'deleteProduct', _.bind(this.onDeleteFromCatalogue, this));
            App.on('catalogue:product:selected', _.bind(function(rowId){this.selectedProductSecId = rowId;}, this));
            region.show(this.view);
        },

        onGridLoaded: function () {
            var data = {
                url: App.request('products:get-collection-url'),  //The catalogSecId query param will be set in the view.serializeGridData
                rowCountUrl: App.request('products:row-count-url-by-catalogue', this.view.model.get('secId'))
            };
            this.view.displayGrid(data);
        },

        onAddNewProduct: function () {
            this.productSelectDialog = new ProductSelectDialog();
            this.productSelectDialog.listenTo(this.productSelectDialog, 'products-selected', _.bind(this.onProductsSelected, this));
            this.productSelectDialog.listenTo(this.productSelectDialog, 'onLoadSelectedProducts', 
                _.bind(this.onLoadSelectedProducts, this));
            this.productSelectDialog.show({title: _.i18n('dialog.product.select')});
        },

        onLoadSelectedProducts: function(first, last, params) {
            $('#dialog-blinder').show();
            App.request('products:collection').fetch(params)
                .done(_.bind(function(products) {
                    var secIds = _.pluck(products, 'secId');
                    this.productSelectDialog.triggerMethod('selectProducts', first, last, secIds);
                    $('#dialog-blinder').hide();
                }, this))
                .fail(function() {
                    $('#dialog-blinder').hide();
                });
        },

        onProductsSelected: function (products) {
            products = _.pluck(products, 'rowid');

            var request = App.request('products:add-multiple-products-to-catalogue', products, this.view.model.get('secId'));
                $.when(request).done(_.bind(function () {
                    this.view.updatePagination('reloadGrid');
                }, this));
        },

        onDeleteFromCatalogue: function () {
            var request = App.request('catalogue:item:delete', this.view.model.get('secId'), 'product', this.selectedProductSecId);
            $.when(request).done(_.bind(function () {
                this.view.updatePagination('reloadGrid');
            }, this));
        }
    };

    module.exports = ProductsTabController;
});
