/**
 * Created by RKL on 07/09/2015.
 */
define('customersShowController',[
  'module',
  'underscore',
  'app',
  'bootbox',
  'customersShowView',
  'emptyView',
  'customerInfoController',
  'customerProfileTabController',
  'customerLogosImagesGroupsController',
  'customerDatasetsTabController',
  'customerProdTemplatesTabController',
  'customerTemplatesTabController',
  'customerOrdersTabController',
  'customerOffersTabController',
  'customerDesignElementsTabController',
  'caccountAttachsTabController',
  'caccountFontsTabController',
  'customerContactsTabController',
  'customerCallsTabController',
  'editEndUserAttachmentsController',
  'mailsController',
  'rolesMixin',
  'settings',
  'customersTabs',
  'entities/caccounts'
], function(
  module,
  _,
  App,
  bootbox,
  View,
  EmptyView,
  InfoShowController,
  profileTabController,
  logosImagesTabController,
  datasetsTabController,
  productTemplatesTabController,
  templatesTabController,
  ordersTabController,
  offersTabController,
  designElementsController,
  attachmentsTabController,
  fontsTabController,
  contactsTabController,
  callsTabController,
  EditEndUserAttachmentsController,
  mailsController,
  RolesMixin,
  Settings,
  CustomersTabs
) {
  'use strict';

  var CustomersDetailsController = {
    fetchCustomer: function(data) {
      var that = this,
        emptyView = new EmptyView(),
        contentRegion = App.regions.getRegion('main').currentView.getRegion('content');

      contentRegion.show(emptyView);

      this.data = data;
      if (this.model && this.model.get('secId') === data.id) {
        this.showCustomer(data);
      } else {
        this.model = App.request('customers:entity', data.id);
        data.model = this.model;
        if (data.id === 'new') {
          that.showCustomer(data);
        } else {
          this.model.fetch().done(function() {
            if (contentRegion) {
              that.showCustomer(data);
            }
          });
        }
      }
    },

    showCustomer: function(data) {
      var view = new View({model: this.model, data: data});

      this.view = view;

      App.regions.getRegion('main').currentView.getRegion('content').show(view);

      this.displayTabs(data);

      view.listenTo(view, 'onViewMore', _.bind(this.onViewMore, this));
      view.listenTo(view, 'onDelete', _.bind(this.onDelete, this));
      view.listenTo(view, 'click:tab', _.bind(this.onTabClick, this));
      view.listenTo(App, 'customer:header:rerender', _.bind(this.onHeaderChange, this));
    },

    displayTabs: function(data) {
      var roles = Settings.get('cAccountRoles' ),
       controllers = {};

      controllers[RolesMixin.DASHBOARD_CUSTOMERS_CUSTOMERS_PROFILE] = profileTabController;
      controllers[RolesMixin.DASHBOARD_CUSTOMERS_CUSTOMERS_LOGOSIMAGES] = logosImagesTabController;
      controllers[RolesMixin.DASHBOARD_CUSTOMERS_CUSTOMERS_DATASETS] = datasetsTabController;
      controllers[RolesMixin.DASHBOARD_CUSTOMERS_CUSTOMERS_PRODUCTTEMPLATES] = productTemplatesTabController;
      controllers[RolesMixin.DASHBOARD_CUSTOMERS_CUSTOMERS_TEMPLATES] = templatesTabController;
      controllers[RolesMixin.DASHBOARD_CUSTOMERS_CUSTOMERS_ORDERS] = ordersTabController;
      controllers[RolesMixin.DASHBOARD_CUSTOMERS_CUSTOMERS_OFFERS] = offersTabController;
      controllers[RolesMixin.DASHBOARD_CUSTOMERS_CUSTOMERS_ATTACHMENTS] = attachmentsTabController;
      controllers[RolesMixin.DASHBOARD_CUSTOMERS_CUSTOMERS_FONTS] = fontsTabController;
      controllers[RolesMixin.DASHBOARD_CUSTOMERS_CUSTOMERS_DESIGNELEMENTS] = designElementsController;
      controllers[RolesMixin.DASHBOARD_CUSTOMERS_CUSTOMERS_CONTACTS] = contactsTabController;
      controllers[RolesMixin.DASHBOARD_CUSTOMERS_CUSTOMERS_CALLS] = callsTabController;
      controllers[RolesMixin.DASHBOARD_CUSTOMERS_CUSTOMERS_MAILS] = mailsController;

      data.model = this.model;

      if (data.id === 'new') {
        profileTabController.showTab(data);
        return;
      } else if (data.tab && data.tab.name === 'proofsheets') {
        EditEndUserAttachmentsController.showPlugin({
            customerSecId: data.id
        });
        return;
      }
      if (data.tab) {
        controllers[data.tab.role].showTab(data);
      } else if (roles) {
        _.some(CustomersTabs, function (c, index) {
          data.tab = CustomersTabs[index];
          if (roles[data.tab.role] === true) {
            controllers[data.tab.role].showTab(data);
            return true;
          }
        });
      } else {
        logosImagesTabController.showTab(data);
      }

      this.tab = data.tab;
    },

    onViewMore: function() {
      InfoShowController.showCustomerInfo(this.data);
    },

    onHeaderChange: function (customer) {
      this.model.set({
        companyName: customer.companyName,
        tel: customer.tel,
        email: customer.email,
        logoUid: customer.logoUid,
        code: customer.code,
        refDeliveryAddress: customer.refDeliveryAddress
      });
      this.view.onHeaderChange(customer);
    },

    onDelete: function() {
      var that = this;

      bootbox.confirm('Delete Customer?', function (result) {
        if (result) {
          that.model.url = that.model.deleteUrl();
          that.model.destroy().done(function() {
            App.trigger('customers:show');
          });
        }
      });
    },

    onTabClick: function(tab) {
      if (this.data.tab !== tab || this.data.secId) {
        this.data.tab = tab;
        this.data.secId = null;

        this.displayTabs(this.data);
        App.navigate('customers/' + this.model.get('secId') + '/' + tab.route);
      }
    }
  };

  module.exports = CustomersDetailsController;
});
