
define('template!productViewSwitcherTpl', ['lodash'], function(_) {return function(obj) {
obj || (obj = {});
var __t, __p = '';
with (obj) {
__p += '<i class="mdi-action-view-list js-table ' +
((__t = ( currentView === 'grid' ? 'clickable' : 'active' )) == null ? '' : __t) +
'"></i>\n<i class="mdi-action-view-module js-grid ' +
((__t = ( currentView === 'grid' ? 'active' : 'clickable' )) == null ? '' : __t) +
'"></i>';

}
return __p
};});

