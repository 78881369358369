/**
 * Created by BFR on 25/05/2018.
 */
define('productPriceTabView',[
  'module',
  'marionette',
  'jqgridView',
  'app',
  'jquery',
  'underscore',
  'template!productPriceTabTpl'
], function(
  module, Marionette,
  JqGridView,
  App,
  $,
  _,
  viewTpl
) {
  'use strict';

  var ProductPriceView = Marionette.ItemView.extend({
    template: viewTpl,

    currentConfiguration: {},

    ui: {
      'lblRsSetupCost': '.lbl-rs-setup-cost',
      'lblRsUnitCost': '.lbl-rs-unit-cost',
      'lblRsTotal': '.lbl-rs-total',
      'lblSetupCost': '.lbl-setup-cost',
      'lblUnitCost': '.lbl-unit-cost',
      'lblTotal': '.lbl-total',
      'lblTotalUnit': '.lbl-total-unit',
      'lblShippingCost': '.lbl-shipping-cost',
      'lblMgTotal': '.lbl-mg-total',
      'tableScale': '.table-scale',
      'blockLoading': '.js-loading-block',
      'inputMargins': '.input-margin',
      'lblProductCost': '.lbl-product-cost',
      'lblRsProduct': '.lbl-rs-product-cost',
      'lblTotalProduct': '.lbl-total-product',
      'lblTotalPrintCost': '.lbl-total-print',
      'lblRsTotalProduct': '.lbl-rs-total-product',
      'lblRsTotalPrint': '.lbl-rs-total-print',
      'lblRsShippingCost': '.lbl-rs-shipping-cost',
      'lblUnitCostMargin': '.lbl-unit-cost-margin',
      'lblSetupMargin': '.lbl-setup-margin',
      'lblMgTotalPrint': '.lbl-mg-total-print',
      'lblShippingMargin': '.lbl-shipping-margin',
      'lblProductMargin': '.lbl-product-margin',
      'lblMgTotalProduct': '.lbl-mg-total-product',
      'lblMgTotalNotPrinted': '.lbl-mg-total-notprinted'
    },

    events: {
      'click .dropdown-menu-printzone a': 'onChangePrinZone',
      'click .dropdown-menu-option a': 'onChangeOption',
      'click .js-confirm': 'onConfirmClick',
      'change .input-quantity': 'onQuantityChange'
    },

    onShow: function () {
      this.currentConfiguration = {
          quantity: 50,
          printZones: {},
          options: {}
      };
    },

    serializeData: function () {
      var templateData = this.options.model.toJSON();
      return templateData;
    },

    onQuantityChange: function(e) {
      var target = $(e.target);
      this.currentConfiguration.quantity = target.val();
    },

    onChangePrinZone: function(e){
      e.preventDefault();
      var target = $(e.target),
          index = target.data('index'),
          pzId = target.data('pzId'),
          nbrColor = target.data('nb-color');
      this.$el.find('.printzone-'+ index +'-label').text(target.text());
      if (pzId === -1) {
        delete this.currentConfiguration.printZones[index];
      } else {
        this.currentConfiguration.printZones[index] = {
          pzId: pzId,
          nbrColor: nbrColor
        };
      }
    },

    onChangeOption: function(e){
      e.preventDefault();
      var target = $(e.target),
          index = target.data('index'),
          optionId = target.data('optionId');
      this.$el.find('.option-'+ index +'-label').text(target.text());
        this.currentConfiguration.options[index] = optionId;
    },

    onConfirmClick: function() {
      this.clearElements();
      this.ui.blockLoading.show();
      this.trigger('get-price-for-configuration', this.currentConfiguration);
    },

    clearElements: function() {
      this.ui.tableScale.empty();
      this.ui.lblRsSetupCost.text('');
      this.ui.lblRsUnitCost.text('');
      this.ui.lblRsTotal.text('');
      this.ui.lblSetupCost.text('');
      this.ui.lblUnitCost.text('');
      this.ui.lblTotal.text('');
      this.ui.lblShippingCost.text('');
      this.ui.lblMgTotal.text('');
      this.ui.lblProductCost.text('');
      this.ui.lblRsProduct.text('');
      this.ui.lblTotalProduct.text('');
      this.ui.lblTotalPrintCost.text('');
      this.ui.lblRsTotalProduct.text('');
      this.ui.lblRsTotalPrint.text('');
      this.ui.lblRsShippingCost.text('');
      this.ui.lblUnitCostMargin.text('');
      this.ui.lblSetupMargin.text('');
      this.ui.lblMgTotalPrint.text('');
      this.ui.lblShippingMargin.text('');
      this.ui.lblProductMargin.text('');
      this.ui.lblMgTotalProduct.text('');
      this.ui.lblMgTotalNotPrinted.text('');
    },

    refreshRsPrice: function(price) {
        this.ui.lblRsShippingCost.text(parseFloat(price.shippingCost).toFixed(2) + ' € = ' + ((parseFloat(price.totalSetupCost) +
            parseFloat(price.totalUnitCost)) / Number(price.quantity)).toFixed(2) + ' €/pc');
        this.ui.lblRsProduct.text(parseFloat(price.productPrice).toFixed(2) + ' € = ' +
            (parseFloat(price.productPrice) / Number(price.quantity)).toFixed(2) + ' €/pc');
        this.ui.lblRsTotalProduct.text((parseFloat(price.productPrice) + parseFloat(price.shippingCost)).toFixed(2) + ' € = ' +
            ((parseFloat(price.productPrice) + parseFloat(price.shippingCost)) / Number(price.quantity)).toFixed(2) + ' €/pc');
        this.ui.lblRsUnitCost.text(parseFloat(price.totalUnitCost).toFixed(2) + ' € = ' +
            (parseFloat(price.totalUnitCost) / Number(price.quantity)).toFixed(2) + ' €/pc');
        this.ui.lblRsSetupCost.text(parseFloat(price.totalSetupCost).toFixed(2) + ' € = ' +
            (parseFloat(price.totalSetupCost) / Number(price.quantity)).toFixed(2) + ' €/pc');
        this.ui.lblRsTotalPrint.text((parseFloat(price.totalUnitCost) + parseFloat(price.totalSetupCost)).toFixed(2) + ' € = ' +
            ((parseFloat(price.totalUnitCost) + parseFloat(price.totalSetupCost)) / Number(price.quantity)).toFixed(2) + ' €/pc');
        this.ui.lblRsTotal.text(parseFloat(price.total).toFixed(2) + ' € = ' +
            (parseFloat(price.total).toFixed(2) / Number(price.quantity)).toFixed(2) + ' €/pc');
        this.ui.blockLoading.hide();
    },

    refreshPrice: function(price) {
      this.ui.lblTotalPrintCost.text((parseFloat(price.totalSetupCost) +
          parseFloat(price.totalUnitCost)).toFixed(2) + ' € = ' + ((parseFloat(price.totalSetupCost) +
          parseFloat(price.totalUnitCost)) / Number(price.quantity)).toFixed(2) + ' €/pc');
      this.ui.lblTotalProduct.text((parseFloat(price.productPrice) + parseFloat(price.shippingCost)).toFixed(2) + ' € = ' +
          ((parseFloat(price.productPrice) + parseFloat(price.shippingCost)) / Number(price.quantity)).toFixed(2) + ' €/pc');
      this.ui.lblProductCost.text(parseFloat(price.productPrice).toFixed(2) + ' € = ' +
          (parseFloat(price.productPrice) / Number(price.quantity)).toFixed(2) + ' €/pc');
      this.ui.lblSetupCost.text(parseFloat(price.totalSetupCost).toFixed(2) + ' € = ' +
          (parseFloat(price.totalSetupCost) / Number(price.quantity)).toFixed(2) + ' €/pc');
      this.ui.lblUnitCost.text(parseFloat(price.totalUnitCost).toFixed(2) + ' € = ' +
          (parseFloat(price.totalUnitCost) / Number(price.quantity)).toFixed(2) + ' €/pc');
      this.ui.lblTotal.text(parseFloat(price.total).toFixed(2) + ' € = ' +
          (parseFloat(price.total) / Number(price.quantity)).toFixed(2) + ' €/pc');
      this.ui.lblShippingCost.text(parseFloat(price.shippingCost).toFixed(2) + ' € = ' +
          (parseFloat(price.shippingCost) + Number(price.quantity)).toFixed(2) + ' €/pc');
      this.ui.blockLoading.hide();
    },

    refreshMargPrice: function(marg) {
      this.ui.lblMgTotalProduct.text(parseFloat(marg.totalTotalProductMarg).toFixed(2) + ' €' + ' = ' +
          parseFloat(marg.unitTotalProductMarg).toFixed(2) + ' €/pc');
      this.ui.lblProductMargin.text(parseFloat(marg.totalProductMarg).toFixed(2) + ' €' + ' = ' +
          parseFloat(marg.unitProductMarg).toFixed(2) + ' €/pc');
      this.ui.lblShippingMargin.text(parseFloat(marg.totalShippingMarg).toFixed(2) + ' €' + ' = ' +
          parseFloat(marg.unitShippingMarg).toFixed(2) + ' €/pc' );
      this.ui.lblMgTotalPrint.text((parseFloat(marg.totalUnitMarg) + parseFloat(marg.totalSetupMarg)).toFixed(2) +
          ' €' + ' = ' + (parseFloat(marg.unitUnitMarg) + parseFloat(marg.unitSetupMarg)).toFixed(2) + ' €/pc' );
      this.ui.lblSetupMargin.text(parseFloat(marg.totalSetupMarg).toFixed(2) + ' €' + ' = ' +
          parseFloat(marg.unitSetupMarg).toFixed(2) + ' €/pc' );
      this.ui.lblUnitCostMargin.text(parseFloat(marg.totalUnitMarg).toFixed(2) + ' €' + ' = ' +
          parseFloat(marg.unitUnitMarg).toFixed(2) + ' €/pc' );
      this.ui.lblMgTotal.text(parseFloat(marg.totalMarg).toFixed(2) + ' €' + ' = ' +
          parseFloat(marg.unitMarg).toFixed(2) + ' €/pc' );
      this.ui.lblMgTotalNotPrinted.text(parseFloat(marg.totalNotPrintMarg).toFixed(2) + ' €' + ' = ' +
          parseFloat(marg.unitNotPrintMarg).toFixed(2) + ' €/pc');
      this.ui.blockLoading.hide();
    },

    refreshScales: function(rsPrice, price) {
      this.ui.tableScale.empty();
      _.each(rsPrice.scale, _.bind(function(scale, index) {
        var scaleHtml = '<tr>' +
            '<td class="">' + scale.quantity + '</td>' +
            '<td class="">' + price.scale[index].total + ' €</td>' +
            '<td class="">' + parseFloat(price.scale[index].totalUnit).toFixed(2) + ' €</td>' +
            '<td class="">' + parseFloat(scale.total).toFixed(2) + ' €</td>' +
            '<td class="">' + parseFloat(scale.totalUnit).toFixed(2) + ' €</td>' +
            '<td class="">' + (Number(scale.total) - Number(price.scale[index].total)).toFixed(2) + ' €</td>' +
            '</tr>';
        this.ui.tableScale.append($(scaleHtml));
      }, this));
      this.ui.blockLoading.hide();
    }
  });

  module.exports = ProductPriceView;
});
