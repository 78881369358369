
define('template!productOverviewTabTpl', ['lodash'], function(_) {return function(obj) {
obj || (obj = {});
var __t, __p = '';
with (obj) {
__p += '<iframe class="iframeCompuzz" id="iframeCompuzz" src="' +
((__t = ( url )) == null ? '' : __t) +
'" frameborder="0" seamless>\n</iframe>';

}
return __p
};});

