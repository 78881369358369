/**
 * Created by OLD on 30/10/2015.
 */
define('invitationsController',[
    'module',
    'app',
    'underscore',
    'jquery',
    'bootbox',
    'invitationsLayoutView',
    'rewardConditionsPopupView',
    'googleContactsPopupView',
    'validate'
], function(
    module,
    App,
    _,
    $,
    bootbox,
    View,
    RewardConditionsPopupView,
    GoogleContactsPopupView,
    Validate
) {
    'use strict';

    var InvitationsController = {
        showInvitations: function() {
            var view;
            view = new View();
            this.view = view;
            this.view.listenTo(this.view , 'checkAndSendEmails', _.bind(this.checkAndSendEmails, this));
            this.view.listenTo(this.view , 'checkGoogleContactsEmails', _.bind(this.checkGoogleContactsEmails, this));
            this.view.listenTo(this.view , 'rewardConditionsClicked', _.bind(this.rewardConditionsClicked, this));
            this.view.listenTo(this.view , 'showMoreInvited', _.bind(this.showMoreInvited, this));
            App.regions.getRegion('main').currentView.getRegion('content').show(view);

            var request = App.request('shareRequest:getInvitationsStatus', 0, 10);
            $.when(request).done(_.bind(function(collection) {
                var request = App.request('shareRequest:countInvitationsStatus');
                $.when(request).done(_.bind(function(count) {
                    this.view.showInvitedList(collection, count);
                }, this));
            }, this));
        },

        showMoreInvited: function(first) {
            var request = App.request('shareRequest:getInvitationsStatus', first, 10);
            $.when(request).done(_.bind(function(collection) {
                this.view.addMoreInvitedToCollection(collection.models);
            }, this));
        },

        rewardConditionsClicked: function() {
            var rewardConditionsPopupView = new RewardConditionsPopupView();
            rewardConditionsPopupView.show({
                title: _.i18n('rewardConditions.popup.title'),
                className: 'vectorizationAddNew'
            });
        },

        checkAndSendEmails: function(emails) {
            if (emails) {
                var emailList = emails.split(','),
                    emailObjects = [];
                _.each(emailList, function (email) {
                    if (Validate.checkEmail(email)) {
                        emailObjects.push({
                            email:email
                        });
                    }
                });

                var request = App.request('shareRequest:sendInvitations', emailObjects);
                $.when(request).done(_.bind(function() {
                    bootbox.alert(_.i18n('invitations.emailsSent'), _.bind(function () {
                        this.view.clearEmailsInput();
                    }, this));
                }, this));
            }
        },

        closePopupAndSendEmails: function(emails) {
            var request = App.request('shareRequest:sendInvitations', emails);
            $.when(request).done(_.bind(function() {
                this.googleContactsPopupView.hide();
                bootbox.alert(_.i18n('invitations.emailsSent'), _.bind(function () {
                }, this));
            }, this));
        },

        checkGoogleContactsEmails: function(emails) {
            if (emails && emails.length) {
                var that = this,
                    googleContactsPopupView = new GoogleContactsPopupView({
                    emails: emails
                });
                that.googleContactsPopupView = googleContactsPopupView;
                googleContactsPopupView.show({
                    title: _.i18n('googleContacts.popup.title'),
                    className: 'googleContactsPopup',
                    buttons: {
                        cancel: {
                            label: _.i18n('common.cancel'),
                            className: 'btn-default',
                            callback: function() { return true; }
                        },
                        main: {
                            label: _.i18n('common.send'),
                            className: 'btn btn-primary',
                            callback: function() {
                                var emailsToSend = [];
                                _.each (that.googleContactsPopupView.collectionViewGoogleContacts.collection.models,
                                        function(model) {
                                    if (model.get('selected')) {
                                        emailsToSend.push(model.toJSON());
                                    }
                                });
                                that.closePopupAndSendEmails(emailsToSend);
                                return true;
                            }
                        }
                    }
                });
                googleContactsPopupView.listenTo(googleContactsPopupView, 'closePopupAndSendEmails',
                    _.bind(this.closePopupAndSendEmails, this));
            }
        }
    };

    module.exports = InvitationsController;
});

