/**
 * Created by Matteo on 11/07/2015.
 */
define('headerShowView',[
  'module',
  'logger',
  'marionette',
  'underscore',
  'app',
  'jquery',
  'settings',
  'imageUtils',
  'autocompleteView',
  'template!headerViewTpl',
  'template!headerCaccountAutocompleteTpl',
  'shareRequestCollectionView',
  'plivoUtils'
], function(
  module,
  Logger,
  Marionette,
  _,
  App,
  $,
  Settings,
  imageUtils,
  AutocompleteView,
  viewTpl,
  caccountAutocompleteItemTpl,
  ShareRequestCollectionView,
  PlivoUtils
) {
  'use strict';

  var HeaderView =  Marionette.LayoutView.extend({
    template: viewTpl,

    triggers: {
      'click @ui.logout' : 'onLogout',
      'click @ui.sidebar' : 'onShowSidebar',
      'click @ui.editLogin' : 'onEdit'
    },

    ui: {
      logout : '.logout',
      sidebar: '.show-hide-sidebar',
      user: '.show-hide-user',
      navbar: '.nav-user',
      navbarShareRequest: '.nav-share-request',
      avatar: '.avatar-dropdown',
      logo: '.avatar-in-header',
      userName: '.js-user-name',
      caccountName: '.js-caccount-name',
      caccountLogo: '.js-caccount-logo',
      caret: '.brand-title .caret',
      dropdown: '.caccount-hover-dropdown',
      availableCAccount: '.available-caccount',
      searchCaccount: '.search-caccount',
      editLogin: '.edit-ac',
      accountSwitch: '.js-account-switch',
      accountSwitchDropdownContainer: '.caccounts-dropdown-container',
      accountSwitchDropdown: '.js-account-switch-dropdown',
      shareRequest: '.shareRequest',
      nbrShareRequest: '.nbrShareRequest',
      languageItem: '.language-item',
      inputPhoneNumber: '.input-phone-number',
      btnPhoneNumber: '.btn-header-phone-number',
      btnDialNumber: '.btn-header-phone-number.on-the-phone',
      btnPhoneCall: '.btn-call-phone-number',
      validationPhoneMsg: '.validate-phone-message'
    },

    events: {
      'click @ui.availableCAccount': 'onSwitchCaccount',
      'click @ui.accountSwitch': 'onShowDropdown',
      'click @ui.accountSwitchDropdownContainer': 'onAccountContainerClick',
      'click @ui.shareRequest': 'getShareRequests',
      'click @ui.languageItem': 'changeLanguage',
      'click @ui.inputPhoneNumber': 'onEnterPhoneNumber',
      'change @ui.inputPhoneNumber': 'onChangePhoneNumber',
      'keydown @ui.inputPhoneNumber': 'onCheckPhoneInputValue',
      'focus @ui.inputPhoneNumber': 'onClearValidation',
      'click @ui.btnPhoneNumber': 'onBtnPhoneNumberClick',
      'click @ui.btnPhoneCall' : 'onPhoneCall',
      'click @ui.btnDialNumber': 'onBtnDialNumberClick'
    },

    regions: {
      caccountAutocomplete: '.js-caccounts-autocomplete',
      shareRequestList: '.shareRequestList'
    },

    _caccountAutocompleteView: undefined,

    serializeData: function() {
      return {
        displayPhone: (Settings.get('plivoUser') && Settings.get('plivoPassword'))
      };
    },

    changeLanguage: function(event){
      var lang = this.$el.find(event.currentTarget).children().attr('lang');
      this.setCurrentLanguage(lang);
      this.trigger('changeLanguage',lang);
    },

    setCurrentLanguage: function(language){
      var $selected = this.$el.find('.language-head');
      $selected.attr('lang', language);
      $selected.text(language);
    },

    getShareRequests: function() {
      this.trigger('getShareRequests');
    },

    getAccountsAutocompleteOptions: function() {
      return {
        type: 'remote',
        alwaysOpen: true,
        remote: Settings.url('compuzz', 'sessions.json/token/byuser/caccounts', {env: Settings.compuzzEnv}),
        valueKey: 'code',
        apiKey: 'secId',
        placeholder: _.i18n('selectaccount.placeholder'),
        value: null,
        name: 'accounts',
        onPressKey: true,
        callback: _.bind(this.onSwitchCaccount, this),
        childTemplate: caccountAutocompleteItemTpl,
        childViewSerializeData: this._autocompleteViewSerializeData,
        lazyLoad: true,
        keys: {
          query: 'caccountCode',
          limit: 'pageSize'
        }
      };
    },    

    onEnterPhoneNumber: function(event) {
      event.preventDefault();
      event.stopImmediatePropagation();
    },

    onChangePhoneNumber: function(event) {
      event.target.value = event.target.value.replace(/\s/g, '');
    },

    onCheckPhoneInputValue: function(event) {      
      if (event.which === 32) return false;
    },

    onBtnPhoneNumberClick: function(event) {
      if ($(event.currentTarget).hasClass('on-the-phone')) return;
      event.preventDefault();
      event.stopImmediatePropagation();      
      var prevPhoneValue = this.ui.inputPhoneNumber.val();
      var currentPhoneValue = $(event.currentTarget).data('dial-value');
      this.ui.inputPhoneNumber.val(prevPhoneValue + currentPhoneValue);
    },

    onBtnDialNumberClick: function(event) {
      event.preventDefault();
      event.stopImmediatePropagation();
      PlivoUtils.dtmf($(event.currentTarget).data('dial-value'));
    },

    onClearValidation: function() {
      this.ui.validationPhoneMsg.hide();
    },

    onPhoneCall: function(event) {
      event.preventDefault();
      event.stopImmediatePropagation();
      if (this._validatePhoneNumber()) {
        PlivoUtils.callNumber(this.ui.inputPhoneNumber.val());
      } else {
        this.ui.validationPhoneMsg.show();
      }
    },

    _validatePhoneNumber: function() {
      var regex = /^\+(?:[\d]*)$/;
      return regex.test(this.ui.inputPhoneNumber.val());
    },

    onRender: function () {
      this.displayCurrentUserPidInfo();
      this.trigger('getAvailableCaccounts');
      this.trigger('countShareRequests');
      this.setCurrentLanguage(Settings.get('lang'));
    },

    onShow: function () {
      $.material.init();
      this.showShareRequestsView();
    },

    onShowMenu: function(target) {
      if (this.ui.navbar.hasClass('open') || target === 'hide') {
        this.ui.navbar.removeClass('open');
      } else {
        this.ui.navbar.addClass('open');
      }
    },

    showShareRequestsView: function() {
      this.shareRequestCollectionView = new ShareRequestCollectionView();
      this.shareRequestCollectionView.listenTo(this.shareRequestCollectionView, 'catalogue:share:add',
        _.bind(this.onAcceptShareRequest, this));
      this.shareRequestCollectionView.listenTo(this.shareRequestCollectionView, 'share-request:delete',
        _.bind(this.onDeleteShareRequest, this));
      this.getRegion('shareRequestList' ).show(this.shareRequestCollectionView);
    },

    onDeleteShareRequest: function (shareRequest) {
      this.trigger('share-request:delete', shareRequest);
    },

    onAcceptShareRequest: function (shareRequest) {
      this.trigger('catalogue:share:add', shareRequest);
    },

    checkAvailableCaccounts: function (count) {
      if (count > 1) {
        this.ui.caret.show();
        this.renderCaccountsDropdown();
        this.displayDropdown();
      } else {
        this.ui.caret.hide();
        this.displayCurrentCaccountInfo();
      }
    },

    checkAvailableShareRequest: function (count) {
      if (count > 0) {
        this.ui.shareRequest.show();
        this.ui.nbrShareRequest.text(count);
      } else {
        this.ui.shareRequest.hide();
      }
    },

    renderCaccountsDropdown: function () {
      this._caccountAutocompleteView = new AutocompleteView(this.getAccountsAutocompleteOptions());

      this.getRegion('caccountAutocomplete').show(this._caccountAutocompleteView);
      this._caccountAutocompleteView.$('.ac-suggestions.dropdown-menu').removeClass('dropdown-menu ac-suggestions').
        addClass('caccount-hover-dropdown');
      this.displayCurrentCaccountInfo(Settings.get('currentCAccount'));
    },

    displayCurrentCaccountInfo: function(account) {
      var currentCAccount = account || Settings.get('currentCAccount'),
        refMainCAccountJson = currentCAccount.refMainCAccountJson,
        logo, brandLogo;
      
      if (currentCAccount) {
        this.currentCAccount = currentCAccount;
        this.ui.caccountName.text(currentCAccount.code);
        if (refMainCAccountJson ) {
          brandLogo  = imageUtils.getLogo(
            refMainCAccountJson.logo,
            refMainCAccountJson.code, {
              action: 'res',
              params: 't'
            }
          );
          this.$('.compuzz_general_logo' ).attr('src', brandLogo );
        } else {
          this.$('.compuzz_general_logo' ).attr('src', Settings.url('base', Settings.get('compuzzLogo')) );
        }
        logo = imageUtils.getLogo(
          currentCAccount.logo,
          currentCAccount.code, {
            action: 'res',
            params: 't'
          }
        );
        this.ui.caccountLogo.attr('src', logo);
        $('title').html('Compuzz ' + currentCAccount.code + ' Dashboard');
      }
    },

    displayCurrentUserPidInfo: function() {
      var currentUser = Settings.get('currentUser'),
        fileUrl;

      if (currentUser) {
        fileUrl = imageUtils.getLogo(
          currentUser.logo,
          currentUser.firstName + ' ' + currentUser.lastName, {
            action: 'res',
            params: 't'
          }, 'rect'
        );
        this.ui.userName.text(currentUser.logonId);
        this.ui.logo.attr('style', 
          'background-image: url(' + fileUrl + ')');
      } else {
        this.ui.userName.text('Please Log in!');
      }
    },

    onShowDropdown: function() {
      var that = this;

      setTimeout(function() {
        if (that._caccountAutocompleteView) {
          that._caccountAutocompleteView.ui.autocomplete.focus();
        }
      }, 10);
    },

    onAccountContainerClick: function(e) {
      e.stopImmediatePropagation();
    },

    _onCloseDropdown: function() {
      this.ui.accountSwitchDropdown.removeClass('open');
    },

    displayDropdown: function() {
      this.$('.navbar-brand').removeClass('disabled');
    },

    onSwitchCaccount: function(fieldName, cAccount) {
      App.trigger('switchCaccount', cAccount.toJSON());
    },

    _autocompleteViewSerializeData: function() {
      var templateData = this.model.toJSON();

      templateData.url = imageUtils.getLogo(this.model.get('logo'), this.model.get('code'), {
        action: 'res',
        params: 't'
      });

      return templateData;
    }
  });

  module.exports = HeaderView;
});
