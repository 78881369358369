/**
 * Created by OBL on 10/12/2015.
 */
define('productOverviewTabController',[
  'module',
  'underscore',
  'app',
  'productOverviewTabView',
  'settings',
  'entities/product'
], function(
  module,
  _,
  App,
  View, Settings
) {
  'use strict';

  var ProductDetailsController = {
    showDetails: function (region, productSecId) {
      var param, productUrl;

      if(!productSecId) {
        param = {
          newProduct: true,
          returnUrl : encodeURIComponent(Settings.serverUrl + '#products'),
          cAccountSecId : Settings.currentCAccount.secId
        };
      } else {
        param = {
          cAccountSecId: Settings.currentCAccount.secId,
          productSecId: productSecId
        };
      }
      productUrl = Settings.url('compuzzProductPlugin', '', param);

      region.show(new View({url: productUrl}));
    },

    showModifyPictureTab: function(region, productSecId, pictureSecId, action) {
      var params = {
        cAccountSecId: Settings.get('currentCAccount').secId,
        productSecId: productSecId,
        action: action
      };
      if(pictureSecId !== 'new') {
        params.pictureSecId = pictureSecId;
      }

      params.returnUrl = encodeURIComponent('/#product/' + productSecId + '/pictures');

      region.show(new View({url: Settings.url('compuzzProductPlugin', '', params)}));
    }
  };

  module.exports = ProductDetailsController;
});
