
define('template!offersViewTpl', ['lodash'], function(_) {return function(obj) {
obj || (obj = {});
var __t, __p = '';
with (obj) {
__p += '<div class="content">\n  <div class="well jq-grid-table-page">\n  	<h2>' +
((__t = ( _.i18n('menu.offerManagement') )) == null ? '' : __t) +
'</h2>\n    <div class="sub-caccounts-table">\n        <div class="form-group add-new-block" ' +
((__t = ( displayCreateBtn?'':'style="display: none;"' )) == null ? '' : __t) +
'>\n          <a target="_self" class="btn new-offer-button add-btn-text">\n            <i class="mdi-content-add"></i>\n            <span>' +
((__t = ( _.i18n('offers.new') )) == null ? '' : __t) +
'</span>\n          </a>\n          <a class="download offers-export btn-link" href="">\n            <i class="mdi-file-file-download alligned-middle"></i>\n            <span class="action-label alligned-middle">' +
((__t = ( _.i18n("common.download") )) == null ? '' : __t) +
'</span>\n          </a>\n          <a class="download offers-pdf-download" href="" style="visibility:hidden;" download></a>\n          <div class="js-action-buttons-region offers-action-button f-r"></div>\n        </div>\n      <table id="jq-grid-list" class="cacountTable" role="grid"></table>\n      <div id="jq-grid-pager-list"></div>\n    </div>\n  </div>\n</div>';

}
return __p
};});

