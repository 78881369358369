
define('template!offersKanbanItemCardTpl', ['lodash'], function(_) {return function(obj) {
obj || (obj = {});
var __t, __p = '', __j = Array.prototype.join;
function print() { __p += __j.call(arguments, '') }
with (obj) {
__p += '<li data-id="' +
((__t = ( poi )) == null ? '' : __t) +
'" data-enduserid="' +
((__t = ( endUserId )) == null ? '' : __t) +
'" class="js-card-item card-item p-t-0 p-l-0 context-menu" ' +
((__t = ( hasComment?'style="background-color: #ffffa0;"':'' )) == null ? '' : __t) +
'>\n  <div class="row">\n    <div class="col-xs-12 enduserid-btn kanban-description-1st-line p-r-10">\n      <span class="small-regular-compuzz text-right p-l-10">' +
((__t = ( endUserId )) == null ? '' : __t) +
'</span>\n    </div>\n    <div class="col-xs-4 p-r-0">\n      <span>\n        <img data-link="' +
((__t = ( fileUrl )) == null ? '' : __t) +
'" class="jq-picture js-image-cell lazyload" data-src="' +
((__t = ( fileUrl )) == null ? '' : __t) +
'"/>\n      </span>\n    </div>\n    <div class="col-xs-8 p-l-0 p-r-10">\n      <div class="card-description">\n        <div class="kanban-description-1st-line">\n          <span class="kanban-description-date text-right">\n            ';
 if (expShippingDate) { ;
__p += '\n              <span style="color: ' +
((__t = ( dateColor )) == null ? '' : __t) +
'">\n                  <i class="mdi-maps-local-shipping" style="font-size:17px; vertical-align: bottom;"></i>\n                  ' +
((__t = ( expShippingDate )) == null ? '' : __t) +
'\n              </span>\n            ';
 } else { ;
__p += '\n              ' +
((__t = ( date )) == null ? '' : __t) +
'\n            ';
 } ;
__p += '\n          </span>\n        </div>\n        <div class="kanban-description-1st-line">\n          <span class=" text-right">\n            ' +
((__t = ( productName )) == null ? '' : __t) +
'\n          </span>\n        </div>\n        <div class="kanban-description-1st-line">\n            <span class="text-left">\n                ' +
((__t = ( externalNumber )) == null ? '' : __t) +
'\n            </span>\n        </div>\n        <div class="bold-text text-right">\n          ' +
((__t = ( quantityPrice )) == null ? '' : __t) +
'\n        </div>\n      </div>\n      <div class="assign-container">\n        <span class="cards-checkbox-container">\n          ';
 if (payedByCustomer) { ;
__p += '\n            <i class="mdi-editor-attach-money" title="' +
((__t = ( _.i18n('offerList.payedByCustomer') )) == null ? '' : __t) +
'" aria-hidden="true"></i>\n          ';
 } ;
__p += '\n          ';
 if (payedToManufacturer) { ;
__p += '\n            <i class="fa fa-check-circle" aria-hidden="true"></i>\n          ';
 } ;
__p += '\n          ';
 if (toInvoice) { ;
__p += '\n            ';
 if (invoiceFileUidLink) { ;
__p += '\n                <a href="' +
((__t = ( invoiceFileUidLink )) == null ? '' : __t) +
'" target="_blank">\n                    <i class="mdi-file-folder" title="' +
((__t = ( _.i18n('offerList.payedByCustomer') )) == null ? '' : __t) +
'" aria-hidden="true"\n                        ' +
((__t = ( groupedInvoice?'style="color:' + groupedColorInvoice + ';"':'' )) == null ? '' : __t) +
'></i>\n                </a>\n            ';
 } else { ;
__p += '\n                <i class="mdi-file-folder-open" title="' +
((__t = ( _.i18n('offerList.payedByCustomer') )) == null ? '' : __t) +
'" aria-hidden="true"></i>\n            ';
 } ;
__p += '\n          ';
 } ;
__p += '\n          ';
 if (flag) { ;
__p += '\n            <span>\n              ';
 if (!design2) { ;
__p += '\n                <i class="mdi-content-flag" style="position: absolute;" aria-hidden="true"></i>\n                ';
 } else { ;
__p += '\n                <i class="fa fa-circle-thin" style="position: absolute;" aria-hidden="true"></i>\n                <span class="p-l-6 p-t-2" style="position: absolute;">' +
((__t = ( flag )) == null ? '' : __t) +
'</span>\n                ';
 } ;
__p += '\n            </span>\n          ';
 } ;
__p += '\n        </span>\n        <span data-id="' +
((__t = ( poi )) == null ? '' : __t) +
'" class="pull-right assigned-face-block clickable js-assign-to">\n          ';
 if (userImg && userImg.indexOf('data:image') === -1) { ;
__p += '\n          <div class="assigned-user-logo" style="background-image:url(' +
((__t = ( userImg )) == null ? '' : __t) +
')"></div>\n          ';
 } else if (userImg && userImg.indexOf('data:image') !== -1) { ;
__p += '\n          <img class="logo-holder js-assigned-user-no-logo" src="' +
((__t = ( userImg )) == null ? '' : __t) +
'"/>\n          ';
 } else { ;
__p += '\n          <i class="mdi-action-face-unlock"></i>\n          ';
 } ;
__p += '\n        </span>\n      </div>\n    </div>\n  </div>\n  <div class="left-status-marker ' +
((__t = ( priority === 2 ? 'urgent' : (priority ===1 ? 'medium' : 'normal') )) == null ? '' : __t) +
'"></div>\n  ';
 if(grouped) { ;
__p += '\n    <div class="right-status-marker medium" style="background-color: ' +
((__t = ( groupedColor )) == null ? '' : __t) +
';"></div>\n  ';
 } ;
__p += '\n    <span class="empty-circle item-selector-circle multi-selector">\n      <i class="mdi-action-check-circle icon"></i>\n    </span>\n</li>\n<div class="multi-select-container"></div>';

}
return __p
};});

