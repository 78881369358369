
define('template!historyTablePopupViewTpl', ['lodash'], function(_) {return function(obj) {
obj || (obj = {});
var __t, __p = '';
with (obj) {
__p += '<div id="dialog-blinder" class="dialog-blinder"></div>\n<div>\n    <div class="history-region"></div>\n    <div class="user-action-buttons-block">\n        <a class="btn leave-button pull-right">\n            <span>' +
((__t = ( _.i18n('common.ok') )) == null ? '' : __t) +
'</span>\n            <div class="ripple-wrapper"></div>\n        </a>\n    </div>\n</div>';

}
return __p
};});

