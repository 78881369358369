
define('template!caccountDetailsTabTpl', ['lodash'], function(_) {return function(obj) {
obj || (obj = {});
var __t, __p = '', __j = Array.prototype.join;
function print() { __p += __j.call(arguments, '') }
with (obj) {
__p += '<div class="account-detail-statistic">	\n	<div class="row">\n		<!-- left-part -->\n		<div class="col-lg-6 left-statistic">\n			';
 if (orderRole) { ;
__p += '\n			<div class="col-xs-4 p-l-0">\n				<div class="well shadow-1 customers-st">\n					<div class="col-xs-3 left-well-area">\n						<i class="mdi-social-group"></i>\n					</div>\n					<div class="col-xs-9">\n						<p class="count">' +
((__t = ( customers )) == null ? '' : __t) +
'</p>\n						<label class="st-caption">' +
((__t = ( _.i18n('stats.customers') )) == null ? '' : __t) +
'</label>\n					</div>\n				</div>\n			</div>\n			';
 if (offerRole) { ;
__p += '\n			<div class="col-xs-4 p-l-0">\n				<div class="well shadow-1 customers-st">\n					<div class="col-xs-3 left-well-area">\n						<i class="mdi-social-group"></i>\n					</div>\n					<div class="col-xs-9">\n						<p class="count">' +
((__t = ( offersCount )) == null ? '' : __t) +
'</p>\n						<label class="st-caption">' +
((__t = ( _.i18n('stats.offers') )) == null ? '' : __t) +
'</label>\n					</div>\n				</div>\n			</div>\n			';
 } ;
__p += '\n			<div class="col-xs-4 p-l-0">\n				<div class="well shadow-1 customers-st">\n					<div class="col-xs-3 left-well-area">\n						<i class="mdi-social-group"></i>\n					</div>\n					<div class="col-xs-9">\n						<p class="count">' +
((__t = ( ordersCount )) == null ? '' : __t) +
'</p>\n						<label class="st-caption">' +
((__t = ( _.i18n('stats.orders') )) == null ? '' : __t) +
'</label>\n					</div>\n				</div>\n			</div>\n			';
 } ;
__p += '\n			<div class="col-xs-4 p-l-0">\n				<div class="well shadow-1 vectors-st">\n					<div class="col-xs-3 left-well-area">\n						<i class="mdi-image-panorama"></i>\n					</div>\n					<div class="col-xs-9">\n						<p class="count">' +
((__t = ( vectors )) == null ? '' : __t) +
'</p>\n						<label class="st-caption">' +
((__t = ( _.i18n('stats.requests') )) == null ? '' : __t) +
'</label>\n					</div>\n				</div>\n			</div>\n			<div class="col-xs-4 p-l-0 p-r-0">\n				<div class="well shadow-1 credits-st">\n					<div class="col-xs-3 left-well-area">\n						<i class="mdi-action-payment"></i>\n					</div>\n					<div class="col-xs-9">\n						<p class="count">' +
((__t = ( credits )) == null ? '' : __t) +
'</p>\n						<label class="st-caption">' +
((__t = ( _.i18n('stats.credits') )) == null ? '' : __t) +
'</label>\n					</div>\n				</div>\n			</div>\n			<div class="col-xs-12 well shadow-1 canvas-container">\n				<p class="title-st">' +
((__t = ( _.i18n("stats.lastRequests") )) == null ? '' : __t) +
'</p>\n				<div id="requests-graph-region"></div>\n			</div>\n			';
 if (orderRole) { ;
__p += '\n			<div class="col-xs-12 well shadow-1 canvas-container">\n				<p class="title-st">' +
((__t = ( _.i18n("stats.orders") )) == null ? '' : __t) +
'</p>\n				<div id="orders-graph-region"></div>\n			</div>\n			';
 } ;
__p += '\n		</div>\n\n		<!-- right part -->\n		<div class="col-lg-6 right-statistic">\n			';
 if (orderRole) { ;
__p += '\n			<div class="col-xs-12 well shadow-1 canvas-container month-activity">\n				<p class="p-l-0 col-xs-12 title-st">' +
((__t = ( _.i18n("stats.monthActivity") )) == null ? '' : __t) +
'</p>\n				<div class="p-0 col-xs-4">\n					<label class="p-0 col-xs-9 progress-title-st">\n						' +
((__t = ( _.i18n("stats.newOrders") )) == null ? '' : __t) +
'\n					</label>\n					<label class="col-xs-3 progress-label-st p-0">\n						' +
((__t = ( newOrder )) == null ? '' : __t) +
'&#37\n						<i class="icon-per mdi-action-trending-up"></i>\n					</label>\n					<div class="p-l-0 col-xs-12">\n						<div class="progress new-orders-st">\n							<div class="progress-bar primary-bg" style="width:' +
((__t = ( newOrder )) == null ? '' : __t) +
'%"></div>\n						</div>\n					</div>\n					<label class="p-0 col-xs-9 progress-title-st">\n						' +
((__t = ( _.i18n("stats.ordersInProgress") )) == null ? '' : __t) +
'\n					</label>\n					<label class="col-xs-3 progress-label-st p-0">\n						' +
((__t = ( ordersInProgress )) == null ? '' : __t) +
'&#37\n						<i class="icon-per st-d mdi-action-trending-down"></i>\n					</label>\n					<div class="p-l-0 col-xs-12">\n						<div class="progress orders-in-progress-st">\n							<div class="progress-bar action-bg" style="width:' +
((__t = ( ordersInProgress )) == null ? '' : __t) +
'%"></div>\n						</div>\n					</div>\n				</div>\n				<div class="col-xs-5 p-0">\n                    <div id="month-graph-region"></div>\n				</div>\n				<div class="col-xs-3 p-0">\n					<div class="col-xs-12 p-0">\n						<i class="mdi-av-stop primary-cl"></i>\n						<label class="legend-st">\n							' +
((__t = ( month.done )) == null ? '' : __t) +
'(' +
((__t = ( newOrder )) == null ? '' : __t) +
')&#37\n						</label>\n					</div>\n					<div class="col-xs-12 p-0">\n						<i class="mdi-av-stop action-cl"></i>\n						<label class="legend-st">\n							' +
((__t = ( month.inProgress )) == null ? '' : __t) +
'(' +
((__t = ( ordersInProgress )) == null ? '' : __t) +
')&#37\n						</label>\n					</div>\n				</div>\n			</div>\n			<div class="col-xs-12 well shadow-1">\n				<p class="p-l-0 col-xs-12 title-st">' +
((__t = ( _.i18n("stats.topproducts") )) == null ? '' : __t) +
'</p>\n				<table class="table table-striped table-hover">\n					<thead>\n						<tr>\n							<th>' +
((__t = ( _.i18n("product.code") )) == null ? '' : __t) +
'</th>\n							<th>' +
((__t = ( _.i18n("product.name") )) == null ? '' : __t) +
'</th>\n							<th>' +
((__t = ( _.i18n("stats.topproducts.count") )) == null ? '' : __t) +
'</th>\n						</tr>\n					</thead>\n					<tbody>\n						';
 _.each(products, function(item) { ;
__p += '\n						<tr>\n							<td>' +
((__t = ( item.code )) == null ? '' : __t) +
'</td>\n							<td>' +
((__t = ( item.name )) == null ? '' : __t) +
'</td>\n							<td>' +
((__t = ( item.count )) == null ? '' : __t) +
'</td>\n						</tr>\n						';
 }); ;
__p += '					\n					</tbody>\n				</table>\n			</div>\n			';
 } ;
__p += '\n		</div>\n	</div>\n</div>';

}
return __p
};});

