
define('template!customersContactsInfoTabTpl', ['lodash'], function(_) {return function(obj) {
obj || (obj = {});
var __t, __p = '';
with (obj) {
__p += '<div class="content">\n	<div class="well customer-profile">\n		<h1>' +
((__t = ( _.i18n('customerContact.info.title') )) == null ? '' : __t) +
'</h1>\n		<hr class="well-separator"/>\n		<div class="detailed-info-form-container">\n			<div class="row m-b-15">\n				<div class="col-md-6">\n					<div class="col-xs-4">' +
((__t = ( _.i18n('common.email') )) == null ? '' : __t) +
'</div>\n					<div class="col-xs-8">' +
((__t = ( email )) == null ? '' : __t) +
'</div>\n				</div>\n			</div>\n			<div class="row m-b-15">\n				<div class="col-md-6">\n					<div class="col-xs-4">' +
((__t = ( _.i18n('common.first.name') )) == null ? '' : __t) +
'</div>\n					<div class="col-xs-8">' +
((__t = ( firstName ? firstName : '-' )) == null ? '' : __t) +
'</div>\n				</div>\n				<div class="col-md-6">\n					<div class="col-xs-4">' +
((__t = ( _.i18n('common.last.name') )) == null ? '' : __t) +
'</div>\n					<div class="col-xs-8">' +
((__t = ( lastName ? lastName : '-' )) == null ? '' : __t) +
'</div>\n				</div>\n			</div>\n			<div class="row m-b-15">\n				<div class="col-md-6">\n					<div class="col-xs-4">' +
((__t = ( _.i18n('common.addressL1') )) == null ? '' : __t) +
'</div>\n					<div class="col-xs-8">' +
((__t = ( addressL1 ? addressL1 : '-' )) == null ? '' : __t) +
'</div>\n				</div>\n				<div class="col-md-6">\n					<div class="col-xs-4">' +
((__t = ( _.i18n('common.addressL2') )) == null ? '' : __t) +
'</div>\n					<div class="col-xs-8">' +
((__t = ( addressL2 ? addressL2 : '-' )) == null ? '' : __t) +
'</div>\n				</div>\n			</div>			\n			<div class="row m-b-15">\n				<div class="col-md-6">\n					<div class="col-xs-4">' +
((__t = ( _.i18n('common.countryCode') )) == null ? '' : __t) +
'</div>\n					<div class="col-xs-8">' +
((__t = ( countryCode ? countryCode : '-' )) == null ? '' : __t) +
'</div>\n				</div>\n				<div class="col-md-6">\n					<div class="col-xs-4">' +
((__t = ( _.i18n('common.zip') )) == null ? '' : __t) +
' / ' +
((__t = ( _.i18n('common.city') )) == null ? '' : __t) +
'</div>\n					<div class="col-xs-8">' +
((__t = ( zip ? zip : '-' )) == null ? '' : __t) +
' / ' +
((__t = ( city ? city : '-' )) == null ? '' : __t) +
'</div>\n				</div>\n			</div>\n			<div class="row m-b-15">\n				<div class="col-md-6">\n					<div class="col-xs-4">' +
((__t = ( _.i18n('customerContact.phone') )) == null ? '' : __t) +
':</div>\n					<div class="col-xs-8 phone-number clickable">' +
((__t = ( tel ? tel : '-' )) == null ? '' : __t) +
'</div>\n				</div>\n				<div class="col-md-6">\n					<div class="col-xs-4">' +
((__t = ( _.i18n('common.fax') )) == null ? '' : __t) +
'</div>\n					<div class="col-xs-8">' +
((__t = ( fax ? fax : '-' )) == null ? '' : __t) +
'</div>\n				</div>\n			</div>\n			<div class="row m-b-15">\n				<div class="col-md-6">\n					<div class="col-xs-4">' +
((__t = ( _.i18n('customerContact.comment') )) == null ? '' : __t) +
':</div>\n					<div class="col-xs-8">' +
((__t = ( comment ? comment : '-' )) == null ? '' : __t) +
'</div>\n				</div>\n			</div>\n			<div class="row m-b-15">\n				<div class="pull-right p-r-15">\n					<button class="btn btn-primary js-edit">' +
((__t = ( _.i18n('common.edit') )) == null ? '' : __t) +
'</button>\n				</div>\n			</div>\n		</div>\n	</div>\n</div>';

}
return __p
};});

