
define('template!dropdownEmptyStateTpl', ['lodash'], function(_) {return function(obj) {
obj || (obj = {});
var __t, __p = '';
with (obj) {
__p += '<li>' +
((__t = (_.i18n('common.no.item') )) == null ? '' : __t) +
'</li>';

}
return __p
};});

