/**
 * Created by OLD on 28/05/2019.
 */
define('offersKanbanCardsView',[
  'module',
  'underscore',
  'backbone',
  'marionette',
  'app',
  'jquery',
  'settings',
  'offerKanbanItemCardView',
  'template!offersKanbanCardsTpl',
  'gmailUtils',
  'entities/customers/customers'
], function (
  module,
  _,
  Backbone,
  Marionette,
  App,
  $,
  Settings,
  ItenView,
  viewTpl,
  GmailUtils
) {
    'use strict';

    var OffersKanbanCardsView = Marionette.CompositeView.extend({
      template: viewTpl,
      childViewContainer: '.cards-container',
      childView: ItenView,

      events: {
        'change .showOnlyMyItems': 'onShowOnlyMyItemsChange'
      },

      collectionEvents: {
          'add': 'modelNumberChange',
          'remove': 'modelNumberChange'
      },

      modelNumberChange: function() {
          var counter = $('.counter[data-state="' + this.options.status + '"]');
          counter.html(this.collection.length);
          var counterMoney = $('.counter-money[data-state="' + this.options.status + '"]');
          var counterQty = $('.counter-qty[data-state="' + this.options.status + '"]');
          var total = 0;
          var totalPcs = 0;
          for (var i = 0 ; i < this.collection.length ; i++) {
            var m = this.collection.models[i];
            if (m.get('price')) {
              total += m.get('price');
            }
            if (m.get('quantity')) {
              totalPcs += m.get('quantity');
            }
          }
          counterMoney.html(total.toFixed(2) + ' €');
          counterQty.html(totalPcs + ' pcs');
      },

      onShowOnlyMyItemsChange: function (event) {
        Settings.set('showOnlyMine' + this.options.status, event.target.checked, true);
        this.trigger('offer-kanban:refresh:cards', this.options.status, event.target.checked);
      },

      initialize: function () {
        Settings.set('card-' + this.options.status, this.collection);
        this.users = this.options.users;
        this.collectionJson = this.collection.toJSON();
      },

      serializeData: function () {
        return {
          state: this.options.status,
          showOnlyMine: this.options.showOnlyMine
        };
      },

      childViewOptions: function () {
        return {
          offers: this.collectionJson,
          state: this.options.status,
          users: this.users
        };
      },

      onShow: function () {
        $.material.init();
        this.modelNumberChange();
      },

      onAttach: function () {
        this._initSortableBoard();
        this._calculateBoardHeight();
      },

      _initSortableBoard: function () {
        var that = this,
          startState, receivedState, cardId, endUserId, params;

        $('.sortable-item, .cards').sortable({
          connectWith: '.sortable-item, .cards',
          placeholder: 'highlight',
          opacity: 0.8,
          cursor: 'move',
          start: function (event, ui) {
            var item = $($(ui.item).children()[0]);
            item.addClass('selected');

            $(ui.item.parent().find('.selected')).not(item).each(function () {
              $($(this).parent()).appendTo($(ui.item).find('.multi-select-container')[0]);
            });
          },
          over: function () {
            $(this).parent().find('.cards').addClass('columnHover');
          },
          out: function () {
            $(this).parent().find('.cards').removeClass('columnHover');
          },
          receive: function (event, ui) {
            var idsSameCustomer = {};
            startState = $(ui.sender).attr('data-state');
            receivedState = $(event.target).attr('data-state');
            params = decodeURI($(event.target).attr('data-params'));
            cardId = $(ui.item).find('li').attr('data-id');
            endUserId = $(ui.item).find('li').attr('data-enduserid');
            that.trigger('offer-kanban:update:card', cardId, receivedState);
            idsSameCustomer[endUserId] = [cardId];
            $($(ui.item).find('.multi-select-container')[0]).find('li').each(function () {
              var poi = $(this).attr('data-id');
              that.trigger('offer-kanban:update:card', poi, receivedState);
              if (!idsSameCustomer[$(this).attr('data-enduserid')]) {
                  idsSameCustomer[$(this).attr('data-enduserid')] = [];
              }
              idsSameCustomer[$(this).attr('data-enduserid')].push(poi);
            });

            if (params && params[0] === '{') {
              try {
                params = JSON.parse(params);
                if (params.type === 'sendMail') {
                    that.gmailUtils = GmailUtils.getInstance();
                    for (var eu in idsSameCustomer) {
                        var endUser = App.request('customers:entity:end-user-id', eu);
                        /*jshint loopfunc:true */
                        endUser.fetch().done(function(user) {
                            var po = idsSameCustomer[user.endUserId];
                            var email = user.email || user.endUserId;
                            var pfUrl = '';
                            for (var i = 0 ; i < po.length ; i++) {
                                pfUrl += 'https://www.zaprinta.com/'+
                                    endUser.getLanguage(user.locales).toLowerCase()+'/proofsheet/' + po[i] + '\n';
                            }
                            var body = _.i18n(params.params.body);
                            body = body.replace('{prfUrl}', pfUrl);
                            that.gmailUtils.openNewEmailInGmail(email, _.i18n(params.params.subject), body);
                        });
                    }
                }
              } catch(err) {
                console.log(err);
              }
            }
          },
          stop: function (event, ui) {
            $(ui.item.parent().find('.selected')).each(function () {
              $(this).removeClass('selected', { duration: 700 });
            });

            $(ui.item).find('.multi-select-container').children().each(function () {
            }).appendTo($(ui.item).parent()[0]);
          }
        });
      },

      _calculateBoardHeight: function () {
        var padding = 100; //add padding bottom

        this.boardHeight = $('#states-offer-container').height();
        if (this.boardHeight < this.$el.parent().height()) {
          this.boardHeight = this.$el.parent().height();
        }
        $('.cards').css('height', this.boardHeight + padding + 'px');
        $('#states-offer-container').css('height', this.boardHeight + padding + 'px');
      }
    });

    module.exports = OffersKanbanCardsView;
  });
