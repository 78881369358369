define('graphicalRequestKanbanColumnView',[
  'module',
  'logger',
  'marionette',
  'settings',
  'underscore',
  'app',
  'moment',
  'graphicalRequestKanbanColumnCardView',
  'template!graphicalRequestKanbanColumnViewTpl',
  'imageUtils',
  'rolesMixin'
], function(
  module,
  Logger,
  Marionette,
  Settings,
  _,
  App,
  moment,
  KanbanBoardCardView,
  viewTpl,
  imageUtils,
  RolesMixin
) {
  'use strict';

  var KanbanBoardColumnView =  Marionette.LayoutView.extend({
    template: viewTpl,

    events: {
      'click @ui.loadMore': 'onShowMore',
      'click @ui.startTrackBtn': 'onStartTrack'
    },

    regions: {
      open: '#todo-container',
      pending: '#question-container',
      progress: '#in-progress-container',
      review: '#in-review-container',
      done: '#done-container'
    },

    ui: {
      loadMore: '.loadMore',
      stateTitle: '.state-title',
      startTrackBtn: '.start-track-btn'
    },

    initialize: function() {
      this.users = this.options.users;
      this.tickets = this.options.tickets;
    },

    serializeData: function() {
      return {
        openConter: this.tickets.open.length,
        pendingConter: this.tickets.pending.length,
        progressConter: this.tickets.progress.length,
        reviewConter: this.tickets.review.length,
        doneConter: this.tickets.done.length,
        onlyAssign: Settings.get('cAccountRoles')[RolesMixin.VECTORIZATOR_ONLY_ASSIGN]
      };
    },

    onStartTrack: function(target) {
      this.trackTime($(target.currentTarget).data('ticketsecid'));
    },

    getTicketsFromCollection: function(collection) {
      var tickets = [];
      collection.each(_.bind(function(model) {
        tickets.push(this.getTicketObject(model));
      }, this));
      return tickets;
    },

    getTicketObject: function(model) {
      var ticket = {
        id: model.get('secId'),
        type: model.get('type'),
        date: moment(model.get('date')).format('DD/MM HH:mm'),
        name: model.get('name'),
        urgent: model.get('urgent'),
        ext: model.get('configuration') ? model.get('configuration').outputType?
        model.get('configuration').outputType.split(' ')[0] : '-' : '-',
        userName: model.get('userName') ? model.get('userName') : null,
        userImg: this._getUserUrl(model),
        tariffScale: model.get('tariffScale'),
        flagged: model.get('flagged'),
        state: model.get('state'),
        accountCreatedDate: moment(model.get('accountCreatedDate')).format('DD/MM hh:mm'),
        olderThan2Days: this._checkDateisOlderThan2Days(moment(model.get('accountCreatedDate')).format('DD/MM/YYYY')),
        accountCode: model.get('accountCode'),
        notEnoughCredit: model.get('notEnoughCredit')
      };
      return ticket;
    },

    _checkDateisOlderThan2Days: function(date) {
      var day = parseInt(moment(date, 'DD/MM/YYYY').format('x'), 10),
        to = parseInt(moment().format('x'), 10),
        from = parseInt(moment().subtract(2, 'days').format('x'), 10);

        return moment(day).isBetween(from, to, null, '[]');
    },

    _getUserUrl: function(model) {
      if (model.get('userImg')) {
        return Settings.url('file', model.get('userImg'));
      } else if (model.get('userName')) {
        return imageUtils.getLogo(null, model.get('userName'), 'circle');
      } else {
        return null;
      }
    },

    onShow: function() {      
      $.material.init();
      var that = this;
      
      this.cardViews = _.object(['open', 'pending', 'progress', 'review', 'done']);
      _.each(this.cardViews, function(view, key) {
        view = new KanbanBoardCardView({
          tickets: that.getTicketsFromCollection(that.tickets[key]), 
          state: key, 
          allTickets:that.tickets,
          users: that.users
        });
        switch(key) {
          case 'open': that.openView = view; break;
          case 'done': that.doneView = view; break;
        }
        view.listenTo(view, 'ticketView:onrefreshBoard', _.bind(that.onRefreshBoard, that));
        view.listenTo(view, 'ticketView:moveToTop', _.bind(that.onMoveOnTop, that));
        view.listenTo(view, 'ticketView:cancel', _.bind(that.onCancel, that));
        that.getRegion(key).show(view);
        that.heightTimeout = setTimeout(_.bind(that._calculateColumnHeight, that), 1000);
      });
      //this.trackerTimeout = setTimeout(_.bind(this.enableTrackingButton, this), 1000);
    },

    _calculateColumnHeight: function() {
      if (!this.boardHeight) {
        this.boardHeight = this.$el.parent().height();
      } else if (this.boardHeight < this.$el.parent().height()) {
        this.boardHeight = this.$el.parent().height();
      }
      this.$el.find('.cards').css('height', this.boardHeight + 'px');
    },

    trackTime: function(issueKey) {
      this.trigger('column:start-track-time', issueKey);
    },

    enableTrackingButton: function() {
      $.ajax({
        type: 'GET',
        url: 'https://local.compuzz.com:8000/isAlive',
        success: _.bind(function (response) {
          var btns = this.$('.start-track-btn');
          //Enable all buttons
          btns.attr('disabled', false);

          //Remove button color of all buttons
          btns.find('i').css('color', '');

          if (response !== 'OK') {
            this.$('.start-track-btn[data-ticketsecid=\''+response+'\']').find('i').css('color', 'green');
            //This means we are currently TimeTracking a task, make the corresponding button green
            //$("body").find('div[data-issue-key="' + response + '"]')
            // .find('input[class="'+ inputClass +'"]').css('background-color', 'green');
          }
        }, this),
        error: _.bind(function () {
          var btns = this.$('.start-track-btn');
          //Disable all buttons
          btns.attr('disabled', true);

          //Remove button color of all buttons
          btns.find('i').css('color', '#FF0000');
        }, this)
      });
      this.trackerTimeout = setTimeout(_.bind(this.enableTrackingButton, this), 3000);
    },

    onCancel: function(secId) {
      this.trigger('column:cancel', secId);
    },

    onMoveOnTop: function(secId) {
      this.trigger('column:moveToTop', secId);
    },

    onRefreshBoard: function(startState, receivedState, ticketId) {
      var startCounter = this.ui.stateTitle.find('.counter.' + startState);
      var receivedCounter = this.ui.stateTitle.find('.counter.' + receivedState);

      startCounter.html(parseInt(startCounter.text(), 10) - 1);
      receivedCounter.html(parseInt(receivedCounter.text(), 10) + 1);
      if (startState !== receivedState) {
        var ticketStartState = Settings.get('ticket-' + startState),
          ticketReceivedState = Settings.get('ticket-' + receivedState),
          ticket = _.findWhere(ticketStartState, {id: ticketId});
        ticket.state = receivedState;
        ticketReceivedState.push(ticket);
        this.trigger('column:on-refresh', ticketId, receivedState);
      }      
    },

    onShowMore: function(event) {
      var state = $(event.currentTarget).attr('data-state');

      if (state === 'open') {
        this.openView.onShowMore(event);
        if (this.openView.$el.height() > this.boardHeight) {
          this.boardHeight = this.openView.$el.height() + 50; // plus margin bottom
        }
      } else {
        this.doneView.onShowMore(event);
        if (this.doneView.$el.height() > this.boardHeight) {
          this.boardHeight = this.doneView.$el.height() + 50; // plus margin bottom
        }
      }
      this._calculateColumnHeight();
    },

    onDestroy: function() {
      clearTimeout(this.trackerTimeout);
      clearTimeout(this.heightTimeout);
    }
  });

  module.exports = KanbanBoardColumnView;
});
