
define('template!superAdminLayoutTpl', ['lodash'], function(_) {return function(obj) {
obj || (obj = {});
var __t, __p = '', __j = Array.prototype.join;
function print() { __p += __j.call(arguments, '') }
with (obj) {
__p += '<div class="content superadmin-page">\n	<div class="superadmin-tabs-container row">\n		<div class="navbar navbar-default navbar-md row">\n			<div class="navbar-header">\n				<a class="btn btn-link navbar-toggle" data-toggle="collapse" data-target=".navbar-collapse">\n					<span class="icon-bar"></span>\n					<span class="icon-bar"></span>\n					<span class="icon-bar"></span>\n				</a>\n			</div>\n			<div class="navbar-collapse collapse">\n				<ul class="p-l-10 nav navbar-nav" id="superadmin-tabs">\n					<li class="js-tab-title overview-tab ';
 if (tab === 'overview') {;
__p += 'active';
 } ;
__p += '" data-tab="overview">\n						<a href="#overview-tab" data-toggle="tab">' +
((__t = ( _.i18n("superAdmin.overview.tabTitle") )) == null ? '' : __t) +
'</a>\n					</li>\n				</ul>\n			</div>\n		</div>\n	</div>\n	<div class="superadmin-container" id="js-superadmin-content-region"></div>\n</div>';

}
return __p
};});

