
define('template!ordersKanbanBoardTpl', ['lodash'], function(_) {return function(obj) {
obj || (obj = {});
var __t, __p = '', __j = Array.prototype.join;
function print() { __p += __j.call(arguments, '') }
with (obj) {
__p += '<div style="width:' +
((__t = ( boardWidth )) == null ? '' : __t) +
'px;" \n  class="container p-r-0 p-l-0" id="states-order-container">\n  ';
 _.each(statuses, function(state) { ;
__p += '\n    <div class="col m-l-10">\n      <p class="title state-title ' +
((__t = ( design2?'':'p-t-5' )) == null ? '' : __t) +
'">\n        <span class="col-xs-10 p-l-0 p-r-0 ellipsis">' +
((__t = ( state.status )) == null ? '' : __t) +
'</span>\n        <span data-state="' +
((__t = (state.statusConst)) == null ? '' : __t) +
'" class="col-xs-2 p-l-0 p-r-0 bold-text counter pull-right text-right"></span>\n        <span data-state="' +
((__t = (state.statusConst)) == null ? '' : __t) +
'"\n            class="col-xs-6 p-l-0 p-r-0 bold-text counter-qty text-left"></span>\n        <span data-state="' +
((__t = (state.statusConst)) == null ? '' : __t) +
'"\n            class="col-xs-6 p-l-0 p-r-0 bold-text counter-money pull-right text-right"></span>\n      </p>\n      <div data-state="' +
((__t = ( state.statusConst )) == null ? '' : __t) +
'" class="cards sortable-item">\n        <div data-state="' +
((__t = ( state.statusConst )) == null ? '' : __t) +
'" id="' +
((__t = ( state.statusConstId )) == null ? '' : __t) +
'-container"></div>\n        <!-- <p data-state="' +
((__t = ( state.statusConst )) == null ? '' : __t) +
'" class="loadMore">\n          <a>Show more</a>\n        </p> -->\n      </div>\n    </div>\n  ';
 }); ;
__p += '\n</div>\n<button class="js-move-left move-btn ';
 if (!isScrollable) { ;
__p += ' hide ';
 } ;
__p += '">\n  <i class="mdi-hardware-keyboard-arrow-left"></i>\n</button>\n<button class="js-move-right move-btn ';
 if (!isScrollable) { ;
__p += ' hide ';
 } ;
__p += '">\n  <i class="mdi-hardware-keyboard-arrow-right"></i>\n</button>';

}
return __p
};});

