
define('template!timetrackerFromTicketTpl', ['lodash'], function(_) {return function(obj) {
obj || (obj = {});
var __t, __p = '', __j = Array.prototype.join;
function print() { __p += __j.call(arguments, '') }
with (obj) {
__p += '<div class="content">\n  <div class="ticket-snapshots-container">\n  	<span class="back-to-ticket"><i class="mdi-content-reply"></i>' +
((__t = ( _.i18n('ticket.backToTicket') )) == null ? '' : __t) +
'</span>\n  	<div class="col-xs-12">\n	  ';
 if (_.size(screens) !== 0) { ;
__p += '\n	  <p class="tt-popup-user-title p-l-5">\n	    <span>' +
((__t = ( _.i18n("common.user") )) == null ? '' : __t) +
': ' +
((__t = ( screens[0].user.firstName )) == null ? '' : __t) +
' ' +
((__t = ( screens[0].user.lastName )) == null ? '' : __t) +
'</span>\n	  </p>\n	  ';
 _.each(screens, function(screen) { ;
__p += '\n	    <p class="task-label-tt">\n	      <span>' +
((__t = ( _.i18n("common.task") )) == null ? '' : __t) +
': ' +
((__t = ( screen.task )) == null ? '' : __t) +
'</span>\n	      <span class="p-l-15">' +
((__t = ( _.i18n("common.date") )) == null ? '' : __t) +
': ' +
((__t = ( screen.start )) == null ? '' : __t) +
' - ' +
((__t = ( screen.end )) == null ? '' : __t) +
'</span>\n	    </p>\n	  ';
 _.each(screen.snaptshots, function(snaptshot) { ;
__p += '\n	    <div class="thumbnail timetracking-image-in-ticket-page" data-uid="' +
((__t = ( snaptshot.fileUid )) == null ? '' : __t) +
'">\n	      <a class="btn json-calendar-image" data-toggle="modal-popover" data-file-uid="' +
((__t = ( snaptshot.fileUid )) == null ? '' : __t) +
'">\n	        <img src="' +
((__t = ( snaptshot.fileUrl )) == null ? '' : __t) +
'" class="to-popover-timetracking-show">\n	      </a>\n	      <div class="caption">\n	        <p class="text-ellipsis m-b-none">\n					' +
((__t = ( snaptshot.dateTime )) == null ? '' : __t) +
'\n					';
 if (!vectorizator) { ;
__p += '\n	        <span class="tt-delete-screen" data-uid="' +
((__t = ( snaptshot.fileUid )) == null ? '' : __t) +
'" data-secid="' +
((__t = ( screen.secId )) == null ? '' : __t) +
'"><i class="mdi-action-delete"></i></span>\n					';
 } ;
__p += '\n				</p>\n	      </div>\n	    </div>\n	  ';
 }); ;
__p += '\n	  ';
 }); ;
__p += '\n	  ';
 } else { ;
__p += '\n	  <span>' +
((__t = ( _.i18n("common.noData") )) == null ? '' : __t) +
'</span>\n	  ';
 } ;
__p += '\n	</div>\n  </div>\n</div>';

}
return __p
};});

