
define('template!addNewPictureButtonTpl', ['lodash'], function(_) {return function(obj) {
obj || (obj = {});
var __t, __p = '';
with (obj) {
__p += '<i class="mdi-action-perm-media"></i>\n<i class="mdi-content-add"></i>';

}
return __p
};});

