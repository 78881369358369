
define('template!emptyViewTpl', ['lodash'], function(_) {return function(obj) {
obj || (obj = {});
var __t, __p = '';
with (obj) {
__p += '<div class="content">\n  <!-- loader block -->\n  <div class="loader  empty-page-loader">Loading...</div>\n</div>';

}
return __p
};});

