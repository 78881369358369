
define('template!graphicalRequestKanbanAssignUserFaceTpl', ['lodash'], function(_) {return function(obj) {
obj || (obj = {});
var __t, __p = '', __j = Array.prototype.join;
function print() { __p += __j.call(arguments, '') }
with (obj) {
__p += '<!-- tpl -->\n';
 if (fileUrl) { ;
__p += '\n  <div class="selected-user-logo" style="background-image:url(' +
((__t = ( fileUrl )) == null ? '' : __t) +
')"></div>\n';
 } else { ;
__p += '\n  <i class="fa fa-user"></i>\n';
 } ;
__p += ' ';

}
return __p
};});

