
define('template!billingDetailTableTpl', ['lodash'], function(_) {return function(obj) {
obj || (obj = {});
var __t, __p = '';
with (obj) {
__p += '<div class="content m-r-0 m-l-0">\n	<div class="billing-detail-table jq-grid-table-page">\n	  <div class="loading-block js-loading-block">\n	    <span class="loading-message js-loading-message label"></span>\n	  </div>			      \n	  <table id="jq-grid-biiling-detail" class="billingDetailTable" role="grid"></table>\n	  <div id="jq-grid-pager-biiling-detail"></div>\n	</div>\n</div>';

}
return __p
};});

