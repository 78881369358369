
define('template!catalogueMineOverviewTabTpl', ['lodash'], function(_) {return function(obj) {
obj || (obj = {});
var __t, __p = '';
with (obj) {
__p += '<div class="panel panel-default catalogue-mine-overview">\n  <div class="panel-body ">\n    <img class="js-catalogue-image" src="' +
((__t = ( catalogueImage )) == null ? '' : __t) +
'"/>\n    <div class="upload-button js-upload-button-region"></div>\n  </div>\n  <div class="panel-footer">\n    <strong>' +
((__t = ( code )) == null ? '' : __t) +
'</strong><br/>\n    ' +
((__t = ( name )) == null ? '' : __t) +
'\n    <br/>\n    <br/>\n    <br/>\n    <br/>\n    <img src="' +
((__t = ( cAccountLogoUrl )) == null ? '' : __t) +
'"/> ' +
((__t = ( cAccountName )) == null ? '' : __t) +
'\n    <i class="mdi-editor-mode-edit clickable js-edit"></i>\n  </div>\n</div>';

}
return __p
};});

