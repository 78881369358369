
define('template!offersKanbanCommentPopupTpl', ['lodash'], function(_) {return function(obj) {
obj || (obj = {});
var __t, __p = '';
with (obj) {
__p += '<div class="content">\n  <div class="my-vector-popup-container">\n      <div class="form-group" data-field-name="comment">\n          <label class="col-xs-3 control-label">' +
((__t = ( _.i18n('common.comment') )) == null ? '' : __t) +
'</label>\n          <div class="col-xs-9">\n              <textarea class="form-control floating-label js-info-input" rows="4" data-field-name="comment" placeholder="' +
((__t = ( _.i18n('common.comment') )) == null ? '' : __t) +
'">' +
((__t = ( comment )) == null ? '' : __t) +
'</textarea>\n          </div>\n      </div>\n  </div>\n</div>';

}
return __p
};});

