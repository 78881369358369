
define('template!elementsCollectionTpl', ['lodash'], function(_) {return function(obj) {
obj || (obj = {});
var __t, __p = '';
with (obj) {
__p += '<div>\r\n	<div class="row">\r\n		<div class="loading-block js-loading-block">\r\n			<span class="loading-message js-loading-message label"></span>\r\n		</div>\r\n	</div>\r\n\r\n	<div class="scroll-container scrollable">\r\n		<div class="elements-container row"></div>\r\n		<div class="lazy-load-block">\r\n			<!-- loader block -->\r\n			<div class="loader lazy-load-loader">\r\n			<svg class="circular">\r\n				<circle class="path" cx="50" cy="50" r="20" fill="none" stroke-width="2" stroke-miterlimit="10"/>\r\n			</svg>\r\n		  </div>\r\n		</div>\r\n	</div>\r\n</div>';

}
return __p
};});

