define('trackingCreateEditLayoutView',[
  'module',
  'marionette',
  'backbone',
  'jquery',
  'dialogFormView',
  'template!trackingCreateEditLayoutTpl',
  'savingBehavior',
  'bootbox',
  'underscore',
  'app'
], function(
  module,
  Marionette,
  Backbone,
  $,
  DialogFormView,
  createEditLayoutTpl,
  SavingBehavior,
  bootbox,
  _,
  App
) {
  'use strict';

  var CreateEditLayoutView = DialogFormView.extend({
    template: createEditLayoutTpl,

    ui: {
      input: '.js-info-input',
      cancel: '.js-cancel',
      confirm: '.js-confirm',
      displayInKanban: '#displayInKanban',
      validatedStatus: '#validatedStatus',
      commentStatus: '#commentStatus',
      assignUserToOrder: '#assignUserToOrder'
    },

    events: {
      'click .js-cancel-popup': 'hide',
      'focus @ui.input' : 'onFocus',
      'change @ui.input': 'onInputChange',
      'click .js-confirm': 'onConfirm'
    },

    regions: {
    },

    className: 'tracking-create-edit',

    behaviors: {
      Saving: {
        behaviorClass: SavingBehavior
      }
    },

    modelEvents: {
      sync: 'onSave',
      error: 'onSaveError'
    },

    fieldsToValidate: [
      {name: 'carrier', type: 'required'},
      {name: 'trackingNumber', type: 'required'}
    ],

    initialize: function(options) {
      this.model = options.trackingModel.clone();
      this.poi = options.poi;
    },

    serializeData: function() {
      var templateData = this.model.toJSON();
      templateData.newItem = this.options.newItem;
      return templateData;
    },

    onShow: function() {
      $.material.init();
    },

    onConfirm: function() {
      if(this.validate(this.fieldsToValidate)) {
        this.model.save(this.poi);
      }
    },

    onSave: function(model) {
      var modelJSON = model.toJSON();

      this.options.trackingModel.set(modelJSON);
      this.triggerMethod('saved');
      App.trigger('tracking:header:rerender', modelJSON);
      App.navigate('orders/' + this.options.trackingModel.get('refCommandLine').poi, {trigger: false});
      App.navigate('orders/' + this.options.trackingModel.get('refCommandLine').poi + '/tracking', {trigger: true});
      this.hide();
    },

    onSaveError: function(model, response) {
      if(response.status === 409) {
        this.onDuplicateCode();
        this.triggerMethod('hide:label');
      }
    },

    onDuplicateCode: function() {
      this.ui.endUserIdInput.popover('show');
      this.ui.endUserIdInput.addClass('invalid');
      this.ui.endUserIdInput.closest('div[data-field-name=endUserId').addClass('has-error');
    }

  });

  module.exports = CreateEditLayoutView;
});

