
define('template!caccountAttachsNewItemTpl', ['lodash'], function(_) {return function(obj) {
obj || (obj = {});
var __t, __p = '';
with (obj) {
__p += '<div class="main text-center">\r\n  <div class="image-holder js-image-holder pgallery_item">\r\n    <input type="file" class="filestyle js-upload-attachment hidden" multiple="multiple" id="upload-attachment" accept="image/*,application/pdf,application/ai,application/eps"/>\r\n    <div class="bootstrap-filestyle">\r\n      <label for="upload-attachment" class="">\r\n        <i class="mdi-action-perm-media icon"></i>\r\n        <i class="mdi-content-add abs-icon-addon"></i>\r\n      </label>\r\n    </div>\r\n  </div>\r\n</div>';

}
return __p
};});

