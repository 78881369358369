define('orderDetailsTabView',[
  'module',
  'marionette',
  'template!orderDetailsTabTpl',
  'underscore',
  'settings',
  'app',
  'envHandler',
  'backbone',
  'dateUtils',
  'colorUtils',
  'imageUtils',
  'imageSliderView',
  'compuzzProUtils',
  'ordersKanbanCommentPopupView',
  'rolesMixin',
  'entities/customers/product_templates',
  'entities/orders_kanban'
], function(
  module,
  Marionette,
  detailsTabTpl,
  _,
  Settings,
  App,
  EnvironmentHandler,
  Backbone,
  DateUtil,
  colorUtils,
  imageUtils,
  ImageSliderView,
  CompuzzProUtils,
  CommentPopupView,
  RolesMixin
) {
  'use strict';

  var DetailsTabView = Marionette.LayoutView.extend({
    template: detailsTabTpl,

    triggers: {
        'click .btn-edit-name': 'onEditName',
        'click .btn-edit-exp-shipping-date': 'onEditExpShippingDate',
        'click #order-btn-edit-status': 'openChangeStatusPopup',
        'click #order-btn-edit-type': 'openChangeTypePopup',
        'click .btn-edit-quantity': 'onEditQuantity'
    },

    events: {
      'click #order-btn__sent-prf': 'onClickBtnSentProofSheet',
      'click #order-btn__edit': 'onClickBtnEditOrder',
      'click .product-link': 'onClickProductLink',
      'click .btn-add-comment': 'onClickAddComment',
      'click .btn-edit-comment': 'onClickEditComment',
      'click .btn-delete-comment': 'onClickDeleteComment',
      'click .btn-edit-product': 'onClickEditProduct'
    },

    ui: {
      comment: '.p-comment',
      expShippingDate: '.exp-shipping-date',
      lblName: '.order-name'
    },

    regions: {
      imagesSlider: '.js-images-slider-region'
    },

    modelEvents: {
      'image:urls:loaded': '_showImageSlider'
    },

    refreshName: function() {
        this.ui.lblName.text(this.model.get('name'));
    },

    refreshExpDate: function() {
        this.ui.expShippingDate.text(DateUtil.toDateFormatString(this.model.get('expShippingDate')));
    },

    serializeData: function() {
      this.prOptions = this._parseOptions();
      var templateData = JSON.stringify(this.model.toJSON());
      templateData = JSON.parse(templateData);

      templateData.status = this.options.statuses.find(_.bind(function(item){
          return item.get('statusConst') === this.model.get('status');
      }, this));

      if (!templateData.status) {
        templateData.status =_.i18n('commandstatus.'+status.toLowerCase());
      } else {
        templateData.status = templateData.status.get('status');
      }

      templateData.type = this.options.types.find(_.bind(function(item){
        return item.name === this.model.get('commandType');
      }, this));

      if (templateData.type) {
        templateData.type = templateData.type.name;
      }

      if (Settings.get('cAccountRoles')[RolesMixin.DASHBOARD_ORDERS_DESIGN2]) {
        templateData.notDesign2 = false;
      } else {
        templateData.notDesign2 = true;
      }

      templateData.productStatus = this.options.product.get('status');

      templateData.options = this.prOptions;
      templateData.createDate = DateUtil.toMDYHHMMFormat(this.model.get('createDate'));

      if (this.model.get('expShippingDate')) {
          templateData.expShippingDate = DateUtil.toDateFormatString(this.model.get('expShippingDate'));
      }

      var pfParam = {
          poi: this.model.get('purchaseOrderId'),
          docType: 'prf'
      };
      try {
          if (templateData.commandData && Settings.compuzzEnv === 'igo') {
              var parser, xmlDoc, senderId;
              if (window.DOMParser)
              {
                  parser = new window.DOMParser();
                  xmlDoc = parser.parseFromString(templateData.commandData, 'text/xml');
              }
              else // Internet Explorer
              {
                  xmlDoc = new window.ActiveXObject('Microsoft.XMLDOM');
                  xmlDoc.async = false;
                  xmlDoc.loadXML(templateData.commandData);
              }
              if (xmlDoc) {
                  senderId = xmlDoc.getElementsByTagName('SenderId')[0].childNodes[0].nodeValue;
                  if(senderId) {
                      pfParam.ccode = senderId;
                  }
              }
          }
      } catch(e) {}

      templateData.proofSheetLink = Settings.url('pdf', '', pfParam, true);
      templateData.sentProofShettetLink = 'mailto:?subject=' + this.model.get('caccount' ).code +
                                          ' - ' + _.i18n('common.proofsheet') + ' n° ' +
                                          this.model.get('purchaseOrderId') +
                                          '&body=' + _.i18n('proofsheet.mail.body').replace(/[\r\n]/g, '%0D%0A') + ':%0D%0A' +
                                                    encodeURIComponent(Settings.url('pdf', '', {
                                                    poi: this.model.get('purchaseOrderId'),
                                                    docType: 'prf'
                                                    }, true)) + '%0D%0A%0D%0A' +
                                                   _.i18n('credit.popup.transfer.line5') + ',%0D%0A%0D%0A' +
                                                   this.model.get('caccount' ).code + '%0D%0A' +
                                                   this.model.get('caccount' ).contactEmail + '%0D%0A' +
                                                   this.model.get('caccount' ).phone + '%0D%0A';
      templateData.displaySingleOptions = ((templateData.options.single.length > 0) ? true : false);
      templateData.displayMixedOptions = ((templateData.options.mixed.values.length > 0) ? true : false);
      templateData.imageUrl = imageUtils.getLogo(this.model.get('snapshotsUids'));

      templateData.comments.sort(function(a, b) {
        var dateA = new Date(a.msgDate), dateB = new Date(b.msgDate);
        return dateB - dateA;
      });

      if (templateData.comments) {
        for (var i = 0 ; i < templateData.comments.length ; i++) {
          templateData.comments[i].msgDate = DateUtil.toMDYHHMMFormat(templateData.comments[i].msgDate);
        }
      }

      return templateData;
    },

    onClickEditProduct: function(e) {
        e.preventDefault();
        e.stopPropagation();
        this.trigger('onEditProduct');
    },

    onClickDeleteComment: function() {
      var comments = this.model.get('comments');
      comments.pop();
      App.request('order-kanban:update:comment',
          this.model.get('purchaseOrderId'),
          comments).then(_.bind(function() {
          this.render();
      }, this));
    },

    onClickEditComment: function(event) {
      this.onClickAddComment(event, true);
    },

    onClickAddComment: function(event, edit) {
      var comments = this.model.get('comments');
        var commentPopupView = new CommentPopupView({
            model: new Backbone.Model({comment: edit?comments[comments.length -1].content:''})
        });
        commentPopupView.show({
            title: _.i18n('common.comment'),
            buttons: {
                ok: {
                    label: _.i18n('common.ok'),
                    className: 'btn-default',
                    callback: _.bind(function() {
                        var currentUserPid = Settings.get('currentUser' ),
                            title = commentPopupView.model.get('title'),
                            commentContent = commentPopupView.model.get('comment');

                        var comment = {
                            content: commentContent?commentContent:'',
                            externalId: '',
                            externalUserId: currentUserPid.externalId?currentUserPid.externalId:'',
                            msgDate: new Date().getTime(),
                            title: title?title:'',
                            user: currentUserPid.secId,
                            notSend: true
                        };

                        if (edit) {
                            comments[comments.length - 1] = comment;
                        } else {
                            this.model.get('comments').push(comment);
                        }

                        App.request('order-kanban:update:comment',
                            this.model.get('purchaseOrderId'),
                            this.model.get('comments')).then(_.bind(function() {
                            this.render();
                        }, this));
                        return true;
                    }, this)
                }
            }
        });
    },

    onClickProductLink: function() {
      App.navigate('product/' + this.options.product.get('secId'), {trigger: true});
    },

    onClickBtnEditOrder: function() {
      //start edit product template plugin if we are on product template view
      if (App.getCurrentRoute().indexOf('producttemplates') !== -1) {
          App.navigate(
            'orderprocess/' +  this.model.get('productBaseCode') + '/' + this.model.get('customerId') + '/template/' +
            this.model.get('purchaseOrderId'),
            {trigger: true});
      } else {
      App.request('session:create-session-from-poi', {webServiceUrl: 'compuzz', returnUrl: location.href, issue: true},
          this.model.get('purchaseOrderId')).done(_.bind(function(session) {
        window.location.href = EnvironmentHandler.getValue(Settings.get('environment'),
            'editOrderUrl')+ '#customization/oloSessionId/' +
             session.sessionId+'/productCode/' +
             this.model.get('productBaseCode') +
             '/purchaseOrderId/' + this.model.get('purchaseOrderId');
      }, this));
      }
    },

    onShow: function() {
      this.model.getImageUrls(this.options.product);
      this._createMixedColorOption( this.prOptions.single);
      this._createMixedColorOption( this.prOptions.mixed.values);

      CompuzzProUtils.getInstance().addListener(this, CompuzzProUtils.events.COMPUZZ_PRO_REFRESH_COMPOSITION, this.onRefreshComposition);
    },

    onRefreshComposition: function() {
        this.model.getImageUrls(this.options.product);
    },

    _showImageSlider: function(imageUrls) {
      this.getRegion('imagesSlider').show(new ImageSliderView({
        imageUrls: imageUrls
      }));
    },

    _parseOptions: function() {
      var optionsMixed = {
          headers: [],
          values: []
        },
        options = [];

      if (!this.model.get('optionsMix') || this.model.get('optionsMix').length === 0) {
          this._parseOption(this.model.get('options'), options);
      } else {
          _.each(this.model.get('optionsMix'), function(optionMix) {
              if(optionMix.quantity > 0) {
                  this._parseMixedOptions(optionMix, optionsMixed);
              } else {
                  this._parseOption(optionMix.options, options);
              }
          }, this);
      }
      return {
        single: options,
        mixed: optionsMixed
      };
    },

    _parseOption: function(optionsList, options) {
      var rowData = {};
      _.each(optionsList, function(option) {
        rowData = {};
        rowData.header = option.categoryName;
        if(option.color1 !== -1) {
          rowData.objId = Math.round(Math.random() * new Date().getTime());
          rowData.color1 = colorUtils.decimalToHexa(option.color1);
          rowData.color2 = option.color2 !== -1 ? colorUtils.decimalToHexa(option.color2) : option.color2;
          rowData.color3 = option.color3 !== -1 ? colorUtils.decimalToHexa(option.color3) : option.color3;
          rowData.color4 = option.color4 !== -1 ? colorUtils.decimalToHexa(option.color4) : option.color4;
          rowData.colorName = option.name;
        } else {
          rowData.value = option.code;
        }
        options.push(rowData);
      });
    },

    _parseMixedOptions: function(optionMix, optionsMixed) {
      var  rowData = [];
      _.each(optionMix.options, function(option) {
        if(! _.find(optionsMixed.headers, function(header) {return header === option.categoryName;})) {
          optionsMixed.headers.push(option.categoryName);
        }
        if(option.color1 !== -1) {
          rowData.push({
            objId: Math.round(Math.random() * new Date().getTime()),
            color1: colorUtils.decimalToHexa(option.color1),
            color2: option.color2 !== -1 ? colorUtils.decimalToHexa(option.color2) : option.color2,
            color3: option.color3 !== -1 ? colorUtils.decimalToHexa(option.color3) : option.color3,
            color4: option.color4 !== -1 ? colorUtils.decimalToHexa(option.color2) : option.color4,
            colorName: option.name
          });
        } else {
          rowData.push({value: option.code});
        }
      });
      rowData.push({value: optionMix.quantity.toString()});
      var quantityLabel = _.i18n('orderDetail.detail.quantity');
      if(! _.find(optionsMixed.headers, function(header) {return header === quantityLabel;})) {
        optionsMixed.headers.push( quantityLabel);
      }
      optionsMixed.values.push(rowData);
    },

    _createMixedColorOption: function(options) {
      var $optionColor = this.$('.color');
      if(options && options.length) {
        $optionColor.each(function(index, item) {
          var $color = $(item);
          var opt, founded;
          founded = _.some(options, function(option) {
            opt = _.findWhere(option, {objId: parseInt($color.attr('id'), 10)});
            return opt ? true : false;
          }, this);
          if(!founded) {
            opt = _.findWhere(options, {objId: parseInt($color.attr('id'), 10)});
          }
          if (opt) {
            $color.css('backgroundColor', opt.color1);
            $color.show();

            if (opt.color1 === '#ffffff') {
              $color.addClass('color-white');
            } else {
              $color.removeClass('color-white');
            }

            if (opt.color1 !== -1 && opt.color2 !== -1 &&
                opt.color3 === -1 && opt.color4 === -1) {
              $color.addClass('colorClass2');
              $color.css('border-color', 'transparent transparent ' +
                                         opt.color2 + ' transparent');
            } else if (opt.color1 !== -1 && opt.color2 !== -1 &&
                       opt.color3 !== -1 && opt.color4 === -1) {
              $color.addClass('colorClass3');
              $color.css('border-color', 'transparent transparent ' +
                                         opt.color2 + ' ' + opt.color3);
            } else if (opt.color1 !== -1 && opt.color2 !== -1 &&
                       opt.color3 !== -1 && opt.color4 !== -1) {
              $color.addClass('colorClass4');
              $color.css('border-color', 'transparent ' + opt.color2 +
                                         ' ' + opt.color3 + ' ' + opt.color4);
            } else if (opt.color1 !== -1 && opt.color2 === -1 &&
                       opt.color3 === -1 && opt.color4 === -1) {
              $color.css('background-color', opt.color1);
            }
          }
        });
      }
    }

  });

  module.exports = DetailsTabView;
});
