/**
 * Created by OLD on 28/05/2019.
 */
define('offersKanbanRouter',[
  'require',
  'exports',
  'module',
  'logger',
  'marionette',
  'app',
  'itemPagesNameMixin'
], function (
  require,
  exports,
  module,
  Logger,
  Marionette,
  App,
  ItemPagesNameMixin
) {
    'use strict';

    var OffersKanbanRouter = Marionette.AppRouter.extend({
      appRoutes: {
        'offers_kanban': 'showView'
      }
    });

    var API = {
      showView: function () {
        require([
          'offersKanbanController',
          'contentShowController'
        ], function (ShowController, ContentShowController) {
          ContentShowController.showContent().done(function () {
            App.startSubModule('offersKanbanMain');
            ShowController.showOffersKanban();
            App.execute('set:active:nav:item', ItemPagesNameMixin.OFFERS_BOARD);
          });
        });
      }
    };

    App.on('offers_kanban:show', function () {
      App.navigate('offers_kanban');
      API.showView();
    });

    App.on('before:start', function () {
      new OffersKanbanRouter({
        controller: API
      });
    });

    module.exports = OffersKanbanRouter;
  });
