
define('template!caccountFontsTabTpl', ['lodash'], function(_) {return function(obj) {
obj || (obj = {});
var __t, __p = '';
with (obj) {
__p += '<div>\n	<div class="well caccount-font-container jq-grid-table-page">\n		<!-- <span class="loading-message js-loading-message label label-primary"></span> -->\n		<div class="loading-block js-loading-block">\n			<span class="loading-message js-loading-message label"></span>\n		</div>\n		<div class="vectorization-table">\n			<div class="form-group add-new-block">\n				<input type="file" class="filestyle js-upload-font hidden" multiple="multiple" id="upload-font"\n               accept="application/x-font-ttf,application/x-font-truetype,application/x-font-opentype,.otf,.OTF,.ttf,TTF" />\n				<div class="btn add-font-button add-btn-text">\n					<label for="upload-font" class="upload-label-btn">\n						<i class="mdi-content-add icon"></i>\n            <span>' +
((__t = ( _.i18n('fonts.new') )) == null ? '' : __t) +
'</span>\n					</label>\n				</div>\n			</div>      \n			<table id="jq-grid-fonts" class="vectorTable" role="grid"></table>\n			<div id="jq-grid-pager-fonts"></div>\n		</div>\n	</div>\n</div>';

}
return __p
};});

