
define('template!translationsFilterLanguageListTpl', ['lodash'], function(_) {return function(obj) {
obj || (obj = {});
var __t, __p = '', __j = Array.prototype.join;
function print() { __p += __j.call(arguments, '') }
with (obj) {
__p += '<li class="js-language">\n  <div class="form-group checkbox-group">\n    <div class="checkbox checkbox-primary">\n      <label>\n        <input type="checkbox"\n               data-abbreviation="all"\n               ' +
((__t = ( allChecked ? 'checked' : '' )) == null ? '' : __t) +
'/> ' +
((__t = ( _.i18n('common.all') )) == null ? '' : __t) +
'\n      </label>\n    </div>\n  </div>\n</li>\n';
 _.each(items, function(language){ ;
__p += '\n  <li class="js-language">\n    <div class="form-group checkbox-group">\n      <div class="checkbox checkbox-primary">\n        <label>\n          <input type="checkbox"\n                 data-abbreviation="' +
((__t = ( language.abbreviation )) == null ? '' : __t) +
'"\n                 ' +
((__t = ( language.checked ? 'checked' : '' )) == null ? '' : __t) +
'/> ' +
((__t = ( language.name + '(' + language.abbreviation + ')' )) == null ? '' : __t) +
'\n        </label>\n      </div>\n    </div>\n  </li>\n';
 }) ;


}
return __p
};});

