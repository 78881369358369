/**
 * Created by RKL on 21/08/2015.
 */
define('offersShowController',[
  'module',
  'logger',
  'underscore',
  'app',
  'offersShowView',
  'jquery',
  'controllerMixin',
  'productSelectDialogView',
  'productConfigDialogView',
  'entities/offers'
], function (
  module,
  Logger,
  _,
  App,
  View,
  $,
  ControllerMixin,
  ProductSelectDialog,
  ProductConfigDialog
) {
    'use strict';

    var OffersController = _.extend({
      showOffers: function (data, ViewInstance) {
        this.createView(data, ViewInstance || View);
        this.subscribeEvents();
        this.showRegion();
      },

      createView: function (data, ViewInstance) {
        var params = data ? {
          endUserSecId: data.model.get('secId')
        } : undefined;
        var view = new ViewInstance(params);
        this.view = view;
        this.data = data;
      },

      showRegion: function () {
        App.regions.getRegion('main').currentView.getRegion('content').show(this.view);
      },

      subscribeEvents: function () {
        this.view.listenTo(this.view, 'addNewOffer', _.bind(this.onAddNewOffer, this));
        this.view.listenTo(this.view, 'onGridLoaded', _.bind(this.onGridLoaded, this));
        this.view.listenTo(this.view, 'onLoadSelectedOffers', _.bind(this.onLoadSelectedOffers, this));
        this.view.listenTo(this.view, 'offer:update', _.bind(this.onOfferUpdate, this));
      },

      onAddNewOffer: function () {
        this.productSelectDialog = new ProductSelectDialog({
          multiSelect: false,
          displayLeaveButton: false
        });
        this.productSelectDialog.listenTo(this.productSelectDialog, 'products-selected', _.bind(this.onProductsSelected, this));
        this.productSelectDialog.show({ title: _.i18n('dialog.offer.selectProduct') });
      },

      onProductsSelected: function (products) {
        products = _.pluck(products, 'rowid');
        var selectedProduct = App.request('products:model', { secId: products[0] });
        selectedProduct.fetch().done(_.bind(function () {
          selectedProduct.fullFetch(selectedProduct.get('code')).done(_.bind(function () {
            this.productConfigDialog = new ProductConfigDialog({product:selectedProduct});
            this.productSelectDialog.hide();
            this.productConfigDialog.show({ title: _.i18n('dialog.offer.selectProduct') });
            this.productConfigDialog.listenTo(this.productConfigDialog, 'product-configured', _.bind(this.onProductConfigured, this));
          }, this));
        }, this));
      },

      onProductConfigured: function (configuration) {
        //endUserId, customerId
        App.request('offer:create-from-configuration',
          this.productConfigDialog.model.get('secId'),
          this.data ? this.data.model.get('endUserId') : '',
          this.data ? this.data.model.get('code') : '',
          configuration
        ).then(_.bind(function () {
          this.view.updatePagination();
        }, this));
        this.productConfigDialog.hide();
      },

      onGridLoaded: function () {
        var fetchStatuses = App.request('offer:statuses', true);
        $.when(fetchStatuses).done(_.bind(function (statuses) {
          this.initializeGrid(this.formatedStatus(statuses));
        }, this));
      },

      formatedStatus: function (statuses) {
        var selectDefault = this.view.selectDefault,
          gridStatuses = selectDefault + ':' + selectDefault;

        statuses.each(function (status) {
          gridStatuses += ';' + status.get('statusConst') + ':' +
            status.get('status');
        });

        return gridStatuses;
      },

      initializeGrid: function (gridStatuses) {
        var data = {
          url: App.request('offer:get-collection-url'),
          rowCountUrl: App.request('offer:get-count-url'),
          statuses: gridStatuses
        };
        this.view.displayGrid(data);
      },

      onLoadSelectedOffers: function (first, last, params) {
        $.when(App.request('offer:get-collection-to-page', params))
          .done(_.bind(function (offers) {
            this.view.triggerMethod('selectOffers', first, last, offers);
            $('#content-blinder').hide();
          }, this))
          .fail(function () {
            $('#content-blinder').hide();
          });
      },

      onOfferUpdate: function (data) {
        $.when(App.request('offer:update', data));
      }
    }, ControllerMixin);

    module.exports = OffersController;
  });
