
define('template!unauthorizedViewTpl', ['lodash'], function(_) {return function(obj) {
obj || (obj = {});
var __t, __p = '';
with (obj) {
__p += '<div>\n    <h1>' +
((__t = ( _.i18n("common.unauthorized") )) == null ? '' : __t) +
'</h1>\n</div>';

}
return __p
};});

