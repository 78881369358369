
define('template!billingTabTpl', ['lodash'], function(_) {return function(obj) {
obj || (obj = {});
var __t, __p = '';
with (obj) {
__p += '<div class="content">\n	<div class="row billing-tab-container">\n		<div class="col-md-6 left-area col-sm-12">\n			<div class="form-group">\n				<a class="btn add-btn-text btn-sm add-new-code-b">\n					<i class="mdi-content-add"></i>\n					<span>' +
((__t = ( _.i18n("price.billingCode.add") )) == null ? '' : __t) +
'</span>\n				</a>\n				<input type="file"\n                    id="price-excel-input"\n                    class="hidden js-upload-prices" data-icon="true"\n                    multiple="multiple"\n                    accept="application/vnd.ms-excel,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"/>\n				<label for="price-excel-input" class="btn add-btn-text btn-sm billing-import">\n				  <i class="mdi-content-add"></i>\n				  <span>' +
((__t = ( _.i18n("price.import") )) == null ? '' : __t) +
'</span>\n				</label>\n				<!-- <a href="" class="btn add-btn-text btn-sm billing-export disabled hidden" target="_blanck">\n					<i class="mdi-content-add"></i>\n					<span>' +
((__t = ( _.i18n("price.export") )) == null ? '' : __t) +
'</span>\n				</a> -->\n			</div>\n			<div class="form-group">\n				<div class="billing-price-table"></div>\n			</div>\n		</div>\n		<div class="col-md-6 right-area col-sm-12">\n			<div class="form-group">\n				<a class="btn add-btn-text btn-sm add-new-pricelist-b">\n					<i class="mdi-content-add"></i>\n					<span>' +
((__t = ( _.i18n("price.add") )) == null ? '' : __t) +
'</span>\n				</a>\n			</div>\n			<div class="form-group">\n				<div class="billing-detail-table"></div>\n			</div>\n		</div>\n	</div>\n</div>';

}
return __p
};});

