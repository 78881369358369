/**
 * Created by BFR on 02/03/2020.
 */
define('sharePrintAreaView',[
    'module',
    'marionette',
    'app',
    'underscore',
    'template!sharePrintAreaTpl',
    'sharePrintZonesView',
    'entities/product_share'
  ], function(
    module,
    Marionette,
    App,
    _,
    viewTpl,
    SharePrintZonesView
  ) {
    'use strict';
  
    var SharePrintAreaView =  Marionette.LayoutView.extend({
      template: viewTpl,
  
      regions: {
        sharePrintZones: '.share-printzones'
      },

      events: {
        'change .cbx-printarea': 'onSelectionChange'
      },

      onSelectionChange: function(event) {
        this.trigger('item:selection:change');
        var cbx = $(event.target);
        if (cbx.is(':checked')) {
          _.each(this.model.get('printZones').models, function(pz) {
            if(!pz.get('printZone')) {
              pz.set('printZone', { name: pz.get('parentPrintZone').get('name') });
            }
          });
          this.model.set('printArea', { });
        } else {
          _.each(this.model.get('printZones').models, function(pz) {
            if(pz.get('printZone')) {
              pz.set('printZone', null);
            }
          });
          this.model.set('printArea', null);
        }
        this.sharePrintZonesView.render();
      },

      onShow: function () {
        this.sharePrintZonesView = new SharePrintZonesView({ collection: this.model.get('printZones')});
        this.sharePrintZonesView.listenTo(this.sharePrintZonesView, 'item:selection:change', _.bind(this.onItemSelectionChange, this));
        this.getRegion('sharePrintZones').show(this.sharePrintZonesView);
      },

      onItemSelectionChange: function() {
        this.trigger('item:selection:change');
      },
  
      serializeData: function () {
        var templateData = this.options.model.toJSON();
        templateData.checked = templateData.printArea?true:false;
        return templateData;
      }
    });
  
    module.exports = SharePrintAreaView;
  });
