
define('template!customerInfoTpl', ['lodash'], function(_) {return function(obj) {
obj || (obj = {});
var __t, __p = '', __j = Array.prototype.join;
function print() { __p += __j.call(arguments, '') }
with (obj) {
__p += '<div class="with-header">\n	<div class="caccount-details-header view-more">\n		<div class="caccount-info-container row">\n			<div class="logo-container col-left col-lg-2">\n			<!-- Logo -->\n				<div class=\'original-img-wrapper zoom-img\'>\n					<div class="main text-center">\n						<div class="logo-holder js-logo-holder"></div>\n					</div>\n				</div>\n			</div>\n			<div class="col-left name-holder js-name-holder m-t-5 col-lg-2">\n				<p class="p-name">' +
((__t = ( companyName )) == null ? '' : __t) +
'</p>\n				<p class="p-code">' +
((__t = ( code )) == null ? '' : __t) +
'</p>\n			</div>\n\n			<div class="vertical-divider col-lg-1"></div>\n\n			<div class="col-left m-t-10 col-lg-2">\n				<i class="mdi-file-file-upload i-primary holder-i"></i>\n				<div class="bootstrap-filestyle">\n					<input type="file" class="hidden js-change-logo" id="change-logo-input"  accept="image/*"/>\n					<label for="change-logo-input" class="change-logo">\n						<span>' +
((__t = ( _.i18n('common.change.logo') )) == null ? '' : __t) +
'</span>\n					</label>\n				</div>\n			</div>\n\n			<div class="vertical-divider col-lg-1"></div>\n\n			<div class="col-left m-t-10 col-lg-2 remove-logo js-remove-logo">\n				<i class="mdi-navigation-close icon holder-i"></i>\n				<span>' +
((__t = ( _.i18n('common.remove') )) == null ? '' : __t) +
'</span>\n			</div>\n\n			';
 if (viewLess) { ;
__p += '\n				<div class="vertical-divider col-lg-1"></div>\n\n				<div class="col-left m-t-10 col-lg-1 view-less js-view-less">\n					<i class="mdi-content-reply icon holder-i"></i>\n					<span>' +
((__t = ( _.i18n('common.return') )) == null ? '' : __t) +
'</span>\n				</div>\n			';
 } ;
__p += '\n\n			<div class="vertical-divider col-lg-1"></div>\n\n			<div class="col-left delete-button js-delete-button m-t-10 col-lg-2">\n				<i class="mdi-action-delete icon grey-i"></i>\n				<span class="p-email">' +
((__t = ( _.i18n('common.delete') )) == null ? '' : __t) +
'</span>\n			</div>\n		</div>\n	</div>\n	<div class="well caccount-detailed-info-container row content">\n		<div class="detailed-info-form-container">\n			<span class="loading-message js-loading-message label label-primary"></span>\n			<div class="form-horizontal">\n				<h2>' +
((__t = ( _.i18n('common.detail') )) == null ? '' : __t) +
'</h2>\n				<div class="form-inputs-container">\n					<div class="col-xs-6">\n						<div class="form-group" data-field-name="code">\n							<div class="col-xs-12">\n								<input type="text" class="form-control floating-label js-info-input" data-field-name="code" value="' +
((__t = ( code )) == null ? '' : __t) +
'" placeholder="' +
((__t = ( _.i18n('common.code') )) == null ? '' : __t) +
' ';
 if (newItem) { ;
__p += ' *';
 } ;
__p += '" ';
 if (!newItem) { ;
__p += 'disabled';
 } ;
__p += '>\n							</div>\n						</div>\n						<div class="row">\n							<div class="form-group col-xs-4 p-r-0" data-field-name="houseNumber">\n								<div class="col-xs-12 p-r-0">\n									<input type="text" class="form-control floating-label js-info-input" data-field-name="houseNumber" placeholder="' +
((__t = ( _.i18n('common.house.number') )) == null ? '' : __t) +
'" value="' +
((__t = ( houseNumber )) == null ? '' : __t) +
'">\n								</div>\n							</div>\n							<div class="form-group col-xs-8 pull-right p-l-0" data-field-name="street">\n								<div class="col-xs-12 p-l-0">\n									<input type="text" class="form-control floating-label js-info-input" data-field-name="street" placeholder="' +
((__t = ( _.i18n('common.street') )) == null ? '' : __t) +
'" value="' +
((__t = ( street )) == null ? '' : __t) +
'">\n								</div>\n							</div>\n						</div>\n						<div class="form-group">\n							<div class="col-xs-12">\n								<div class="select-countries"></div>\n							</div>\n						</div>\n						<div class="form-group" data-field-name="email">\n							<div class="col-xs-12">\n								<input type="text" id="emailInput" class="form-control floating-label js-info-input" data-field-name="email" value="' +
((__t = ( email )) == null ? '' : __t) +
'" placeholder="' +
((__t = ( _.i18n('common.email') )) == null ? '' : __t) +
'">\n							</div>\n						</div>\n						<div class="form-group" data-field-name="emailCustomerService">\n							<div class="col-xs-12">\n								<input type="text"  id="customerEmailInput"  class="form-control floating-label js-info-input" data-field-name="emailCustomerService" value="' +
((__t = ( emailCustomerService )) == null ? '' : __t) +
'" placeholder="' +
((__t = ( _.i18n('userInfo.emailCustomerService') )) == null ? '' : __t) +
'">\n							</div>\n						</div>\n						<div class="form-group" data-field-name="firstName">\n							<div class="col-xs-12">\n								<input type="text" class="form-control floating-label js-info-input" data-field-name="firstName" placeholder="' +
((__t = ( _.i18n('common.first.name') )) == null ? '' : __t) +
'" value="' +
((__t = ( firstName )) == null ? '' : __t) +
'">\n							</div>\n						</div>\n					</div>\n\n					<div class="col-xs-6">\n						<div class="form-group" data-field-name="companyName">\n							<div class="col-xs-12">\n								<input type="text" class="form-control floating-label js-info-input" data-field-name="companyName" placeholder="' +
((__t = ( _.i18n('common.company.name') )) == null ? '' : __t);
 if (newItem) { ;
__p += ' *';
 } ;
__p += '" value="' +
((__t = ( companyName )) == null ? '' : __t) +
'">\n							</div>\n						</div>\n						<div class="row">\n							<div class="form-group col-xs-4 p-r-0" data-field-name="zip">\n								<div class="col-xs-12 p-r-0">\n									<input type="text" class="form-control floating-label js-info-input" data-field-name="zip" placeholder="' +
((__t = ( _.i18n('common.zip') )) == null ? '' : __t) +
'" value="' +
((__t = ( zip )) == null ? '' : __t) +
'">\n								</div>\n							</div>\n							<div class="form-group col-xs-8 pull-right p-l-0" data-field-name="city">\n								<div class="col-xs-12 p-l-0">\n									<input type="text" class="form-control floating-label js-info-input" data-field-name="city" placeholder="' +
((__t = ( _.i18n('common.city') )) == null ? '' : __t) +
'" value="' +
((__t = ( city )) == null ? '' : __t) +
'">\n								</div>\n							</div>\n						</div>\n						<div class="form-group" data-field-name="fax">\n							<div class="col-xs-12">\n								<input type="text" class="form-control floating-label js-info-input" data-field-name="fax" placeholder="' +
((__t = ( _.i18n('common.fax') )) == null ? '' : __t) +
'" value="' +
((__t = ( fax )) == null ? '' : __t) +
'">\n							</div>\n						</div>\n						<div class="form-group" data-field-name="webSite">\n							<div class="col-xs-12">\n								<input type="text" class="form-control floating-label js-info-input" data-field-name="webSite" placeholder="' +
((__t = ( _.i18n('common.webSite') )) == null ? '' : __t) +
'" value="' +
((__t = ( webSite )) == null ? '' : __t) +
'">\n							</div>\n						</div>\n						<div class="form-group" data-field-name="tel">\n							<div class="col-xs-12">\n								<input type="text" class="form-control floating-label js-info-input" data-field-name="tel" placeholder="' +
((__t = ( _.i18n('common.company.number') )) == null ? '' : __t) +
'" value="' +
((__t = ( tel )) == null ? '' : __t) +
'">\n							</div>\n						</div>\n						<div class="form-group" data-field-name="lastName">\n							<div class="col-xs-12">\n								<input type="text" class="form-control floating-label js-info-input" data-field-name="lastName" placeholder="' +
((__t = ( _.i18n('common.last.name') )) == null ? '' : __t) +
'" value="' +
((__t = ( lastName )) == null ? '' : __t) +
'">\n							</div>\n						</div>\n						\n					</div>\n				</div>\n				<div class="footer">\n					<div class="col-lg-12">\n						<div class="form-group pull-right">\n							<a class="btn btn-default btn-flat cancel-b js-cancel">' +
((__t = ( _.i18n('common.cancel') )) == null ? '' : __t) +
'</a>\n							<a class="btn btn-primary js-confirm">' +
((__t = ( _.i18n('common.confirm') )) == null ? '' : __t) +
'</a>\n						</div>\n					</div>\n				</div>\n			</div>\n		</div>\n	</div>\n</div>';

}
return __p
};});

