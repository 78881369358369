define('orderCompositionTabView',[
  'module',
  'marionette',
  'settings',
  'app',
  'jquery',
  'noItemsView',
  'template!orderCompositionTabTpl',
  'template!orderCompositionItemTpl',
  'compuzzProUtils',
  'underscore',
  'rolesMixin',
  'entities/orders'
], function(
  module,
  Marionette,
  Settings,
  App,
  $,
  NoItemsView,
  compositionTabTpl,
  compositionItemTpl,
  CompuzzProUtils,
  _,
  RolesMixin
) {
  'use strict';

  var CompositionItemView = Marionette.ItemView.extend({
    letters: ['A', 'B', 'C', 'D', 'E', 'F', 'G', 'H', 'I', 'J', 'K', 'L', 'M', 'N', 'O', 'P', 'Q',
        'R', 'S', 'T', 'U', 'V', 'W', 'X', 'Y', 'Z'],
    template: compositionItemTpl,

    ui: {
      compositionImg: '.composition-img',
      useBackgroundColor: '.useBackgroundColor'
    },

    events: {
      'click .btn-edit-compuzzpro': 'onCompuzzProEditClick',
      'change @ui.useBackgroundColor' : 'onChangeUseBackgroundColor',
      'click .btn-edit-property': 'onClickEditProperty',
      'click .btn-connect-compuzzpro': 'onBtnConnectCompuzzProClick'
    },

    onBtnConnectCompuzzProClick: function(event) {
      var target = $(event.target);
      var currentHref = target.attr('href');
      if (currentHref.indexOf(':v2') !== -1) {
        target.attr('href', currentHref.replace(':v2', '-v2'));
      } else {
        target.attr('href', currentHref.replace('-v2', ':v2'));
      }
    },

    onChangeUseBackgroundColor: function () {
      $('.loading-block').show();
      App.request('order:update-use-background-color', this.model.collection.orderModel.get('purchaseOrderId'), this.model.get('secId'),
          this.ui.useBackgroundColor.prop('checked')).then(_.bind(function() {
        CompuzzProUtils.getInstance().trigger('notificationCenter:compuzzProRefreshComposition');
        $('.loading-block').hide();
      }, this));
    },

    onShow: function () {
      $.material.init();
      var useBackgroundColor = this.model.get('useBackgroundColor') || false;
      this.ui.useBackgroundColor.prop('checked', useBackgroundColor);
    },

    serializeData: function() {
      var templateData = this.model.toJSON();
      templateData.tokenId = Settings.get('tokenId');
      if (Settings.compuzzAwsWsSend) {
          templateData.tokenId += ':v2';
      }
      templateData.customData = this.model.get('customData');
      templateData.prinAreaImgUrl = this.getPrintAreaImgUrl();
      templateData.printAreaName = this.model.get('paName') ? (this.getPrintAreaCode() + ' - ' +
      this.model.get('paName')) : this.getPrintAreaCode();

      templateData.compuzzProProtocol = 'cpzp';
      if (Settings.compuzzProProtocol) {
        templateData.compuzzProProtocol = Settings.compuzzProProtocol;
      }
      return templateData;
    },

    getPrintAreaCode: function() {
      if (Settings.get('currentCAccount').displayPaCodeLetter) {
        var letter = this.letters[parseInt(this.model.get('paCode'), 10) - 1];
        return letter?letter:this.model.get('paCode');
      } else {
        return this.model.get('paCode');
      }
    },

    onCompuzzProEditClick: function() {
        CompuzzProUtils.getInstance().sendData({
            value: 'edit compo',
            type: 'compuzzProEditComposition',
            tokenId: Settings.get('tokenId'),
            compositionSecId: this.model.get('secId')
        });
    },

    getPrintAreaImgUrl: function() {
      var params = {};
      if (!this.model.get('useBackgroundColor')) {
        params.nobg = true;
      }
      if (Settings.get('cAccountRoles')[RolesMixin.DASHBOARD_ORDERS_DESIGN2]) {
          return Settings.url('x2png', 'compositions/' + this.model.get('secId') + '/snapshot', params);
      } else {
          return Settings.url('x2png', 'compositions/' + this.model.get('secId') + '/snapshot/withMeasure', params);
      }
    }
  });

  var CompositionCollectionView = Marionette.CollectionView.extend({
    childView: CompositionItemView
  });

  var CompositionTabView = Marionette.LayoutView.extend({
    template: compositionTabTpl,

    regions: {
      prinAreaItem: '.js-print-area-container-region'
    },

    events: {
      'click .js-delete-custom-proofsheet': 'onDeleteCustomProofsheet',
      'click .js-delete-custom-export': 'onDeleteCustomExport'
    },

    serializeData: function() {
      var templateData = this.orderModel.toJSON();
      if (templateData.proofsheetFileUid) {
          templateData.customProofSheetUrl = Settings.url('file', templateData.proofsheetFileUid) + '&cmds=on';
      }
      if (templateData.exportFileUid) {
          templateData.customExportUrl = Settings.url('file', templateData.exportFileUid) + '&cmds=on';
      }
      templateData.comment = this.options.productModel.get('comment');
      if (!templateData.comment) {
        templateData.comment = '';
      }
      return templateData;
    },

    onShow: function() {
      if (this.collection.models.length !== 0) {
        this.getRegion('prinAreaItem').show(new CompositionCollectionView({collection: this.collection}));
      } else {
        this.getRegion('prinAreaItem').show(new NoItemsView());
      }
    },

    onDeleteCustomProofsheet: function() {
        $('.loading-block').show();
        App.request('order:delete-custom-proofsheet', this.orderModel.get('purchaseOrderId')).then(_.bind(function() {
          this.orderModel.set('proofsheetFileUid', null);
          CompuzzProUtils.getInstance().trigger('notificationCenter:compuzzProRefreshComposition');
          $('.loading-block').hide();
      }, this));
    },

    onDeleteCustomExport: function() {
        $('.loading-block').show();
        App.request('order:delete-custom-export', this.orderModel.get('purchaseOrderId')).then(_.bind(function() {
            this.orderModel.set('exportFileUid', null);
            CompuzzProUtils.getInstance().trigger('notificationCenter:compuzzProRefreshComposition');
            $('.loading-block').hide();
        }, this));
    }
  });

  module.exports = CompositionTabView;
});
