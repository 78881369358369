/**
 * Created by BFR on 09/07/2018.
 */
define('mailsListController',[
  'module',
  'logger',
  'underscore',
  'app',
  'jquery',
  'mailsListView',
  'detailMailView',
  'entities/mails'
], function(
  module,
  Logger,
  _,
  App,
  $,
  View,
  DetailMailView
) {
  'use strict';

  var MailsController = _.extend({
    showMails: function(data) {
      this.createView(data, View);
      this.subscribeEvents();
      this.showRegion();
    },

    createView: function(data, ViewInstance) {
      var params = data ? {
        endUserSecId: data.model.get('secId')
      } : undefined; 
      var view = new ViewInstance(params);
      this.view = view;
      this.data = data;
    },

    showRegion: function() {
      App.regions.getRegion('main').currentView.getRegion('content').show(this.view);
    },

    subscribeEvents: function() {  
      this.view.listenTo(this.view, 'onGridLoaded', _.bind(this.onGridLoaded, this));
      this.view.listenTo(this.view, 'mailslist:select', _.bind(this.onMailSelected, this));
    },

    onGridLoaded: function() {
      this.initializeGrid();
    },

    initializeGrid: function() {
      var data = {
          url: App.request('mails:get-collection-url'),
          rowCountUrl: App.request('mails:get-count-url')
        };
        this.view.displayGrid(data);
    },

    onMailSelected: function(secId) {
        var emailRequest = App.request('mail:model', { secId: secId });
        $.when(emailRequest.fetch()).done(_.bind(function() {
          this.detailMailView = new DetailMailView({
              model: emailRequest
          });

          var region = this.view.getRegion('mailDetail');
          if (region){
              region.show(this.detailMailView);
          }
        }, this));
    }

  });

  module.exports = MailsController;
});
