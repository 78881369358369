
define('template!customerTemplatesPopupEditTpl', ['lodash'], function(_) {return function(obj) {
obj || (obj = {});
var __t, __p = '';
with (obj) {
__p += '<div>\n	<div class="container-fluid">\n	  <div class="row selected-template-group">\n      <div class="col-sm-12" class="form-group-standardsizes">\n\n        <form class="form-horizontal" role="form">\n\n          <div class="form-group">\n            <label class="col-sm-3 control-label overflow-hidden">' +
((__t = ( _.i18n("common.templateGroup.standardSizes") )) == null ? '' : __t) +
'</label>\n            <div class="col-sm-9">\n              <div class="select-standardsizes"></div>\n            </div>\n          </div>\n\n\n          <div class="form-group">\n            <label class="col-sm-3 control-label overflow-hidden">' +
((__t = ( _.i18n("common.displayOrder") )) == null ? '' : __t) +
'</label>\n            <div class="col-sm-4">\n              <input type="number" class="form-control js-display-order js-form-input" data-field-name="displayOrder" value="' +
((__t = ( displayOrder )) == null ? '' : __t) +
'"/>\n            </div>\n          </div>\n\n          <div class="form-group">\n            <label class="col-sm-3 control-label overflow-hidden">' +
((__t = ( _.i18n("common.name") )) == null ? '' : __t) +
'</label>\n            <div class="col-sm-4">\n              <input type="text" class="form-control js-name js-form-input" data-field-name="name" value="' +
((__t = ( name )) == null ? '' : __t) +
'"/>\n            </div>\n          </div>\n\n          <div class="form-group">\n            <label class="col-sm-3 control-label overflow-hidden">' +
((__t = ( _.i18n("template.originalFileName") )) == null ? '' : __t) +
'</label>\n            <div class="col-sm-4">\n              <input type="text" class="form-control js-originalFileName js-form-input" disabled="true" data-field-name="originalFileName" value="' +
((__t = ( originalFileName )) == null ? '' : __t) +
'"/>\n            </div>\n          </div>\n\n          <!-- Template Filters -->\n          <div class="form-group">\n            <label class="col-sm-3 control-label">' +
((__t = ( _.i18n('template.templateFilters') )) == null ? '' : __t) +
'</label>\n          </div>\n\n          <div class="form-group">\n            <div class="col-sm-offset-1 col-sm-2">' +
((__t = ( _.i18n("template.language") )) == null ? '' : __t) +
'</div>\n            <div class="col-sm-9 select-languages"></div>\n          </div>\n          <div class="form-group">\n            <div class="col-sm-offset-1 col-sm-2">' +
((__t = ( _.i18n("template.country") )) == null ? '' : __t) +
'</div>\n            <div class="col-sm-9 select-countries"></div>\n          </div>\n        </form>\n      </div>\n	  </div>\n\n	  <div class="text-center create-template-btn-in-popup">\n			<button type="button" class="btn btn-primary js-save-template">' +
((__t = ( _.i18n("common.saveTemplate") )) == null ? '' : __t) +
'</button>\n	  </div>\n	</div>\n</div>';

}
return __p
};});

