
define('template!customerDatasetsTabTpl', ['lodash'], function(_) {return function(obj) {
obj || (obj = {});
var __t, __p = '';
with (obj) {
__p += '<div>\n  <div class="well dataset-container">\n    <div class="dataset-row-region"></div>\n  </div>\n</div>';

}
return __p
};});

