define( 'entities/kanban_tickets',[
  'app',
  'jquery',
  'backbone',
  'settings',
  'underscore'
], function(
  App,
  $,
  Backbone,
  Settings,
  _
) {
  'use strict';

  var pagination = {
    open: {
      first: 0,
      page: 1
    },
    pending: {
      first: 0,
      page: 1
    },
    progress: {
      first: 0,
      page: 1
    },
    review: {
      first: 0,
      page: 1
    },
    done: {
      first: 0,
      page: 1
    }
  };  

  var Ticket = Backbone.Model.extend( {
    url: function() {
      return Settings.url('compuzz', 'tickets', {type: ''});
    }
  });

  /**
   * Collection
   */
  var TicketsCollection = Backbone.Collection.extend( {
    url: function() {
      return Settings.url('compuzz', 'tickets', {type: ''});
    },
    model: Ticket
  });

  /**
   * REST API
   */
  var API = {
    getTickets: function(state, rowCount, filters, sidx) {
      var tickets = new TicketsCollection({}),
        defer = $.Deferred(),
        params = {          
          ticketState: state,
          pageSize: rowCount,
          first: state ? pagination[state.toLowerCase()].first : 0,
          sidx: sidx ? sidx : 'creatDate',
          sort: 'desc'
        };

      tickets.url = Settings.url( 'compuzz', 'tickets/lights', _.defaults(params, filters));
      tickets.fetch({
        success: function(data) {
          defer.resolve(data);
        }
      });

      return defer.promise();
    },

    getRowCount: function(state, filters) {
      var defer = $.Deferred(),
        params = {
          ticketState: state
        };
      
      $.ajax({
        url: Settings.url('compuzz', 'tickets/rowCount', _.defaults(params, filters)),
        success: function(count) {
          defer.resolve(count);
        }
      });

      return defer.promise();
    },

    changeState: function(ticketID, state) {
      var defer = $.Deferred(),
        url = 'tickets/' + ticketID + '/updatestate';
      $.ajax({
        url: Settings.url('compuzz', url, {state: state}),
        type: 'PUT',
        contentType: 'application/json',
        complete: function(data) {
          defer.resolve(data.responseJSON);
        }
      });
      return defer.promise();
    },

    getUsers: function() {
      var defer = $.Deferred();
      if (Settings.get('users')) {
        defer.resolve(Settings.get('users'));
      } else {
        $.ajax({
            url: Settings.url('compuzz', 'v2/userpid', { pageSize: 150 }),
            success: function(users) {
              Settings.set('users', users);
                defer.resolve(users);
            }
        });
      }
      return defer.promise();
    },

    assignUser: function(ticketSecId, userSecId) {
      var defer = $.Deferred();

      $.ajax({
        url: Settings.url('compuzz', 'tickets/' + ticketSecId +'/assign', {user: userSecId}),
        type: 'PUT',
        success: function(data) {
          defer.resolve(data);
        }
      });
      return defer.promise();
    },

    unassignUser: function(ticketSecId) {
      var defer = $.Deferred();

      $.ajax({
        url: Settings.url('compuzz', 'tickets/' + ticketSecId +'/unassign'),
        type: 'PUT',
        success: function(data) {
          defer.resolve(data);
        }
      });
      return defer.promise();
    },

    flagTicket: function(ticketID, flag) {
      var defer = $.Deferred(),
        url = 'tickets/' + ticketID + '/updateflagged';

      $.ajax({
        url: Settings.url('compuzz', url, {flagged: flag}),
        type: 'PUT',
        success: function() {
          defer.resolve();
        }
      });
      return defer.promise();
    },

    moveToTopTicket: function(ticketID) {
      var defer = $.Deferred(),
        url = 'tickets/' + ticketID + '/updateontop';

      $.ajax({
        url: Settings.url('compuzz', url, {ontop: true}),
        type: 'PUT',
        success: function() {
          defer.resolve();
        }
      });
      return defer.promise();
    },

    getTicketTime: function(ticketID) {
      var defer = $.Deferred();

      $.ajax({
        url: Settings.url('compuzz', 'tickets/' + ticketID + '/timetracking'),
        type: 'GET',
        success: function(time) {
          defer.resolve(time);
        }
      });
      return defer.promise();
    },

    getTicketDetail: function(ticketID) {
      var defer = $.Deferred();

      $.ajax({
        url: Settings.url('compuzz', 'tickets/' + ticketID),
        type: 'GET',
        success: function(ticket) {
          defer.resolve(ticket);
        }
      });
      return defer.promise();
    },

    getTicketHistoryRowCount: function(ticketSecId, options) {
      options = options || {};
      _.defaults(options, {});
      var params = '';
      return 'tickets/' + ticketSecId + '/history/rowCount' + params;
    },

    getTicketHistory: function(ticketSecId, options) {
      options = options || {};
      return Settings.url('compuzz', 'tickets/' + ticketSecId + '/history', options);
    },

    startTrackTime: function(ticketSecId) {
      var defer = $.Deferred();

      $.ajax({
        url: 'https://local.compuzz.com:8000/switchTicket?task=' + ticketSecId,
        type: 'GET',
        success: function(response) {
          defer.resolve(response);
        },
        error: function(jqXHR, textStatus, erroThrown) {
          defer.reject(textStatus, erroThrown);
        }
      });
      return defer.promise();
    }
  };

  /**
   * Request communications
   */
  App.reqres.setHandler('kanban-tickets:get', function(state, rowCount, params, sidx) {
    return API.getTickets(state, rowCount, params, sidx);
  });

  App.reqres.setHandler('kanban-tickets:row-count', function(state, params) {
    return API.getRowCount(state, params);
  });

  App.reqres.setHandler('kanban-tickets:history-get', function(ticketSecId, params) {
    return API.getTicketHistory(ticketSecId, params);
  });

  App.reqres.setHandler('kanban-tickets:history-row-count', function(ticketSecId, params) {
    return API.getTicketHistoryRowCount(ticketSecId, params);
  });

  App.reqres.setHandler('kanban-tickets:change-state', function(ticketID, state) {
    return API.changeState(ticketID, state);
  });

  App.reqres.setHandler('kanban-tickets:get-users', function() {
    return API.getUsers();
  });

  App.reqres.setHandler('kanban-tickets:assign-users', function(ticketSecId, userSecId) {
    return API.assignUser(ticketSecId, userSecId);
  });

  App.reqres.setHandler('kanban-tickets:unassigned-users', function(ticketSecId) {
    return API.unassignUser(ticketSecId);
  });

  App.reqres.setHandler('kanban-tickets:flagged-ticket', function(ticketSecId, flag) {
    return API.flagTicket(ticketSecId, flag);
  });

  App.reqres.setHandler('kanban-tickets:move-to-top', function(ticketSecId) {
    return API.moveToTopTicket(ticketSecId);
  });

  App.reqres.setHandler('kanban-tickets:get-time', function(ticketSecId) {
    return API.getTicketTime(ticketSecId);
  });

  App.reqres.setHandler('kanban-tickets:get-ticket-details', function(ticketSecId) {
    return API.getTicketDetail(ticketSecId);
  });

  App.reqres.setHandler('kanban-tickets:start-track-time', function(ticketSecId) {
    return API.startTrackTime(ticketSecId);
  });

});
