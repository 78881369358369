/**
 * Created by BFR on 06/12/2019.
 */
define('trackingTabView',[
  'module',
  'marionette',
  'underscore',
  'app',
  'jquery',
  'settings',
  'jqgridView',
  'template!trackingTabTpl'
], function (
  module,
  Marionette,
  _,
  App,
  $,
  Settings,
  JqGridView,
  viewTpl
) {
  'use strict';

  var TrackingTabView = JqGridView.extend({
    template: viewTpl,
    filtersName: 'trackingFilters',

    triggers: {
      'click @ui.createFont': 'onCreateTracking'
    },

    ui: function() {
      return _.extend({
        jqGrid: '#jq-grid-fonts',
        jqGridPager: '#jq-grid-pager-fonts',
        createFont: '.js-add-tracking',
        loader: '.js-loading'
      }, this.gridUi);
    },

    events: function() {
      return _.extend({
        'click .cell-delete': 'onDelete'
      }, this.gridEvents);
    },

    gridOptions: function(data) {
      var defaultsOptions, options;

      this.filtersName = data.type + 'FontsFilters';
      this.sidx = 'userTimestamp.modDate';
      this.sord = 'desc';
      defaultsOptions = this.gridInitOptions(data);
      options = {
        colModel: [
          {
            label: '',
            name: 'secId',
            key: true,
            hidden: true
          }, {
            label: _.i18n('tracking.carrier'),
            name: 'carrier',
            classes: 'cell-link',
            formatter:  this.defaultFormatter,
            search: false,
            index: 'carrier',
            sortable: false
          }, {
            label: _.i18n('tracking.trackingNumber'),
            name: 'trackingNumber',
            classes: 'cell-link',
            formatter:  this.defaultFormatter,
            search: false,
            index: 'trackingNumber',
            sortable: false
          }, {
            label: _.i18n('tracking.trackingStatus'),
            name: 'trackingStatus',
            classes: 'cell-link',
            formatter:  this.defaultFormatter,
            search: false,
            index: 'trackingStatus',
            sortable: false
          }, {
            label: ' ',
            name: 'carrier',
            classes: 'cell-preview jq-icon',
            formatter: this.previewIconFormatter,
            search: false,
            index: 'carrier',
            width: 20
          }, {
            label: ' ',
            name: 'delete',
            search: false,
            classes: 'delete-shared-link delete-link',
            formatter: this.deleteIconFormatter,
            width: 20
          }
        ],

        onSelectRow: function(rowId, bool, e) {
            if (e && !$(e.target).hasClass('cell-delete') && !$(e.target).hasClass('cell-preview') && e.type === 'click') {
                App.navigate('/tracking/' + rowId, {trigger: true});
            }
        }
      };

      options = _.defaults(options, defaultsOptions);

      return options;
    },

    previewIconFormatter: function(cellValue, options, object) {
      var url = '';
      if (object.trackingUrl) {
         url = object.trackingUrl;
      } else {
          switch(cellValue) {
              case 'dpd': url = 'https://tracking.dpd.de/status/fr_FR/parcel/' + object.trackingNumber; break;
              case 'dhl': url = 'https://www.dhl.be/exp-fr/dhl_express/suivi_expedition.html?AWB='+
                  object.trackingNumber+'&brand=DHL'; break;
              case 'bpost': url = 'https://trackapi.bpost.cloud/track/items?itemIdentifier='+
                  object.trackingNumber+'&postalCode=undefined'; break;
              case 'ups': url = 'https://www.ups.com/track?loc=fr_BE&tracknum='+object.trackingNumber+'&requester=WT'; break;
              case 'tnt': url = 'https://tnt.com/express/fr_be/site/outils-expedition/suivi.html?searchType=CON&cons='+
                  object.trackingNumber; break;
          }
      }
      return '<a href="'+url+'" target="_blank"><span class="mdi-action-visibility cell-action cell-preview" ' +
             'data-row-id="' + options.rowId + '"></span></a>';
    },

    checkBoxFormatter: function(cellvalue, options) {
        var checked = cellvalue ? 'checked' : '',
            htmlEl = '<div class="m-l-15">' +
                '<input disabled="disabled" type="checkbox" data-ticket-cid="' + options.rowId +
                '" class="cpz-checkbox js-ticket-urgent" ' + checked + ' ' +
                '></div>';
        return htmlEl;
    },

    onDelete: function(e) {
      var target = $(e.currentTarget),
        fontId = target.data('row-id');

      this.trigger('onDelete', fontId);
    }
  });

  module.exports = TrackingTabView;
});

