
define('template!caccountRequestViewTpl', ['lodash'], function(_) {return function(obj) {
obj || (obj = {});
var __t, __p = '';
with (obj) {
__p += '<div class="content">\n    <div class="vectorization-table">\n        <div class="form-group add-new-block">\n            <a href="#graphical_service_all_in_one/new" class="btn new-vector-button add-btn-text">\n                <i class="mdi-content-add"></i>\n                <span>' +
((__t = ( _.i18n('stats.requests.new') )) == null ? '' : __t) +
'</span>\n            </a>\n        </div>\n      <table id="jq-grid-vector" class="vectorTable" role="grid"></table>\n    </div>\n</div>';

}
return __p
};});

