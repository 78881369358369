
define('template!caccountShareLayoutTpl', ['lodash'], function(_) {return function(obj) {
obj || (obj = {});
var __t, __p = '';
with (obj) {
__p += '<div>\n	<div class="col-lg-6">\n		<div class="share-title">\n			' +
((__t = ( _.i18n("cAccountDetail.share.from") )) == null ? '' : __t) +
'\n		</div>\n		<div class="share-from-container"></div>\n	</div>\n	<div class="col-lg-6">\n		<div class="share-title">\n			' +
((__t = ( _.i18n("cAccountDetail.share.to") )) == null ? '' : __t) +
'\n		</div>\n		<div class="share-to-container"></div>\n	</div>\n</div>';

}
return __p
};});

