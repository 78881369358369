
define('template!changeNamePopupTpl', ['lodash'], function(_) {return function(obj) {
obj || (obj = {});
var __t, __p = '';
with (obj) {
__p += '<div class="form-group">\n    <div class="form-group" data-field-name="name">\n        <label class="col-xs-3 control-label">' +
((__t = ( _.i18n('orders.name') )) == null ? '' : __t) +
'</label>\n        <div class="col-xs-9">\n            <input type="text" class="form-control floating-label js-info-input" data-field-name="name" placeholder="' +
((__t = ( _.i18n('orders.name') )) == null ? '' : __t) +
'" value="' +
((__t = ( name )) == null ? '' : __t) +
'">\n        </div>\n    </div>\n</div>';

}
return __p
};});

