/**
 * Created by BFR on 02/03/2020.
 */
define('sharePrintZonesView',['module',
        'underscore',
        'marionette',
        'sharePrintZoneView'],
  function(module,
           _,
           Marionette,
           SharePrintZoneView){
  'use strict';

  var SharePrintZonesView = Marionette.CollectionView.extend({
    childView: SharePrintZoneView,
    className: 'col-md-12',

    childEvents: {
      'item:selection:change': 'onItemSelectionChange'
    },

    onItemSelectionChange: function() {
      this.trigger('item:selection:change');
    }
  });

  module.exports = SharePrintZonesView;
});
