
define('template!vectorPopupTpl', ['lodash'], function(_) {return function(obj) {
obj || (obj = {});
var __t, __p = '', __j = Array.prototype.join;
function print() { __p += __j.call(arguments, '') }
with (obj) {
__p += '<div class="content">\n  <div class="my-vector-popup-container">\n    <div class="row">\n      <div class="col-xs-12">\n        ';
 if (balance < 20) { ;
__p += '\n        <!-- CREDIT BLOCK -->\n        <div class="check-credit-container">\n          <p class="attention-icon"><i class="mdi-alert-error"></i></p>\n          <p class="general-attention-msg">\n            ' +
((__t = (_.i18n("vectorization.popup.credits.you.have") )) == null ? '' : __t) +
'\n            <span>' +
((__t = ( balance )) == null ? '' : __t) +
'</span>\n            ' +
((__t = (_.i18n("vectorization.popup.credits.left") )) == null ? '' : __t) +
'\n          </p>\n          <p class="common-attention-msg">\n            ' +
((__t = ( _.i18n("vectorization.popup.refill.question") )) == null ? '' : __t) +
'\n          </p>\n          <div class="warning-btn-container">\n            <a class="btn btn-default btn-flat fill-up-b">\n              ' +
((__t = ( _.i18n("common.fill.up") )) == null ? '' : __t) +
'\n            </a>\n            <a class="btn btn-primary continue-b">\n              ' +
((__t = ( _.i18n("common.continue") )) == null ? '' : __t) +
'\n            </a>\n          </div>\n          <a\n              class="pull-right need-help-link present-cr"\n              href="mailto:' +
((__t = ( needHelpEmail )) == null ? '' : __t) +
'?subject=' +
((__t = ( subject )) == null ? '' : __t) +
'">\n            ' +
((__t = ( _.i18n("common.need.help") )) == null ? '' : __t) +
'\n          </a>\n        </div>\n        ';
 } ;
__p += '\n        <!--UPLOAD CONTAINER-->\n        <div class="upload-container"\n             style="';
 if (balance < 20) { ;
__p += ' display:none; ';
 } ;
__p += '">\n          <div class="progress progress-striped hidden">\n            <div class="progress-bar progress-bar-info" style="width: 0%"></div>\n          </div>\n          <div class="drag-and-drop-area">\n            <i class="mdi-av-queue dd-icon"></i>\n            <div class="upload-container-drag-drop dropzone">\n            </div>\n            <a class="btn btn-primary dd-b-upload">\n              <i class="mdi-file-file-upload"></i>' +
((__t = ( _.i18n('common.upload') )) == null ? '' : __t) +
'\n            </a>\n          </div>\n\n        </div>\n        <!--IMAGE PREVIEW-->\n        <div class="preview-container hidden">\n          <div class="row">\n            <div class="col-xs-4">\n              <!-- Image Preview -->\n              <div class="uploaded-box text-center">\n                <img id="previewImg"\n                     src="">\n              </div>\n\n              <!-- Urgency -->\n              <div class="form-group checkbox-group">\n                <div class="checkbox checkbox-primary">\n                  <label>\n                    <input type="checkbox" class="js-ticket-urgent">\n                  </label>\n                  <span class="p-l-5">' +
((__t = ( _.i18n('vectReq.urgency') )) == null ? '' : __t) +
'</span>\n                </div>\n              </div>\n            </div>\n            <div class="col-xs-8">\n              <form>\n                <div class="form-group ticket-name-new">\n                  <input type="text" class="form-control floating-label" placeholder="' +
((__t = (_.i18n('common.subject') )) == null ? '' : __t) +
'">\n                </div>\n                <!--Output File extension-->\n                <div class="form-group output-file-dropdown-region"></div>\n                <!-- Textarea -->\n                <div class="form-group">\n                  <div class="textarea-container">\n                    <div class="p-0 col-xs-12">\n                      <textarea class="form-control textarea-input floating-label" rows="2" id="textArea"  placeholder="' +
((__t = (_.i18n('common.message') )) == null ? '' : __t) +
'"></textarea>\n                    </div>\n                  </div>\n                </div>\n              </form>\n            </div>\n          </div>\n          <div class="row">\n            <div class="col-xs-12">\n              <!-- SAVE / CANCEL -->\n              <div class="buttons-container">\n                <div class="pull-right p-r-20">\n                  <a class="btn btn-flat btn-default" data-dismiss="modal">' +
((__t = ( _.i18n("common.cancel") )) == null ? '' : __t) +
'</a>\n                  <a class="btn btn-primary send-to-d-b">' +
((__t = ( _.i18n('common.validate') )) == null ? '' : __t) +
'</a>\n                </div>\n              </div>\n            </div>\n          </div>\n\n        </div>\n      </div>\n    </div>\n  </div>\n</div>';

}
return __p
};});

