
define('template!productPicturesTabLayoutTpl', ['lodash'], function(_) {return function(obj) {
obj || (obj = {});
var __t, __p = '';
with (obj) {
__p += '<div class="js-new-picture-region f-l"></div>\n<div class="js-pictures-region"></div>\n<div class="js-loader-region"></div>';

}
return __p
};});

