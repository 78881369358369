
define('template!superAdminCreditStatTpl', ['lodash'], function(_) {return function(obj) {
obj || (obj = {});
var __t, __p = '', __j = Array.prototype.join;
function print() { __p += __j.call(arguments, '') }
with (obj) {
__p += '<div class="well table-sa">\n	<div class="row pagin-container">\n		<a class="col-xs-1 btn btn-link prev-pg\n		';
 if (pagin.first === 0) { ;
__p += ' disabled ';
 } ;
__p += '\n		"><i class="mdi-hardware-keyboard-arrow-left"></i></a>\n		<h2 class="col-xs-9 st-superadmin-title">' +
((__t = ( _.i18n("superAdmin.creditsByAccount") )) == null ? '' : __t) +
'</h2>\n		<a class="col-xs-2 btn btn-link next-pg\n		';
 if (pagin.currentPage === pagin.totalPages || (pagin.first + pagin.pageSize) >= pagin.total) { ;
__p += ' disabled ';
 } ;
__p += '\n		"><i class="mdi-hardware-keyboard-arrow-right"></i></a>\n	</div>\n	<table class="table table-striped credit-stat">\n		<thead>\n			<tr>\n				<th>' +
((__t = ( _.i18n("common.account") )) == null ? '' : __t) +
'</th>\n				<th>' +
((__t = ( _.i18n("common.credits") )) == null ? '' : __t) +
'</th>\n			</tr>\n		</thead>\n		<tbody>\n			';
 _.each(creditsStats, function(credit) { ;
__p += '\n			<tr>\n				<td class="sa-td" title="' +
((__t = ( credit.caccount.code )) == null ? '' : __t) +
'">\n                    <a href="' +
((__t = ( '#superAdmin/caccount/' + credit.caccount.secId + '/credits' )) == null ? '' : __t) +
'">' +
((__t = ( credit.caccount.code )) == null ? '' : __t) +
'</a>\n                </td>\n				<td>' +
((__t = ( credit.value )) == null ? '' : __t) +
'</td>\n			</tr>\n			';
 }); ;
__p += '\n			<tr class="sa-pagin-container">\n				<td colspan="2" class="sa-pager-cell">\n					' +
((__t = ( _.i18n("common.page") )) == null ? '' : __t) +
' \n					' +
((__t = ( pagin.currentPage + 1 )) == null ? '' : __t) +
' ' +
((__t = ( _.i18n("common.of") )) == null ? '' : __t) +
' \n					' +
((__t = ( pagin.totalPages )) == null ? '' : __t) +
'\n				</td>\n			</tr>\n		</tbody>\n	</table>\n</div>';

}
return __p
};});

