
define('template!optionMixEditItemTpl', ['lodash'], function(_) {return function(obj) {
obj || (obj = {});
var __t, __p = '', __j = Array.prototype.join;
function print() { __p += __j.call(arguments, '') }
with (obj) {

 _.each(productOptionCategories, function(productOptionCategory) { ;
__p += '\n    ';
 
        var productOptions = productOptionCategory.productOptions; 
        var selectedOption = productOptions[0];
    ;
__p += '\n    <td>\n        ';
 if (productOptions.length === 1) { ;
__p += '\n            <label class="control-label col-xs-12 options-selector dropdown-toggle left-text p-l-5 p-r-5"\n                    data-toggle="dropdown"\n                    aria-expanded="false"\n                    data-category-id="' +
((__t = ( productOptionCategory.id )) == null ? '' : __t) +
'"\n                    ';
 if (!productOptionCategory.mixable) { ;
__p += 'disabled';
 } ;
__p += '>\n            ';
 if (selectedOption.optionType === 'color') { ;
__p += '\n                <div class="color-wrapper pull-left">\n                <div class="color-box chosen-color ';
 if (selectedOption.color1 === '#ffffff') { ;
__p += 'color-white';
 }; ;
__p += '"\n                    style="background-color: ' +
((__t = ( selectedOption.color1 )) == null ? '' : __t) +
';"></div>\n                </div>\n            ';
 }; ;
__p += '\n            <div class="chosen-option-name ellipsed">' +
((__t = ( selectedOption.name )) == null ? '' : __t) +
'</div>\n            </label>\n            ';
 } else { ;
__p += '\n            <div class="btn-group btn-block m-t-0 m-b-0">\n            <button class="btn btn-default col-xs-12 options-selector dropdown-toggle left-text p-l-5 p-r-5"\n                    data-toggle="dropdown"\n                    aria-expanded="false"\n                    data-category-id="' +
((__t = ( productOptionCategory.id )) == null ? '' : __t) +
'"\n                    ';
 if (!productOptionCategory.mixable) { ;
__p += 'disabled';
 } ;
__p += '>\n                ';
 if (selectedOption.optionType === 'color') { ;
__p += '\n                <div class="color-wrapper pull-left">\n                    <div class="color-box chosen-color ';
 if (selectedOption.color1 === '#ffffff') { ;
__p += 'color-white';
 }; ;
__p += '\n                    ';
 if (selectedOption.color1 !== -1 && selectedOption.color2 !== -1 && selectedOption.color3 === -1 && selectedOption.color4 === -1) { ;
__p += ' colorClass2"\n                        style="background-color: ' +
((__t = ( selectedOption.color1 )) == null ? '' : __t) +
';\n                        border-color:transparent transparent ' +
((__t = ( selectedOption.color2 )) == null ? '' : __t) +
' transparent; \n                    ';
 } else if (selectedOption.color1 !== -1 && selectedOption.color2 !== -1 && selectedOption.color3 !== -1 && selectedOption.color4 === -1) { ;
__p += ' colorClass3"\n                        style="background-color: ' +
((__t = ( selectedOption.color1 )) == null ? '' : __t) +
';\n                        border-color:transparent transparent ' +
((__t = ( selectedOption.color2 )) == null ? '' : __t) +
' ' +
((__t = ( selectedOption.color3 )) == null ? '' : __t) +
';\n                    ';
 } else if  (selectedOption.color1 !== -1 && selectedOption.color2 !== -1 && selectedOption.color3 !== -1 && selectedOption.color4 !== -1) { ;
__p += ' colorClass4"\n                        style="background-color: ' +
((__t = ( selectedOption.color1 )) == null ? '' : __t) +
';\n                        border-color:transparent ' +
((__t = ( selectedOption.color2 )) == null ? '' : __t) +
' ' +
((__t = ( selectedOption.color3 )) == null ? '' : __t) +
' ' +
((__t = ( selectedOption.color4 )) == null ? '' : __t) +
';\n                    ';
 } else if (selectedOption.color1 !== -1 && selectedOption.color2 === -1 && selectedOption.color3 === -1 && selectedOption.color4 === -1) { ;
__p += '" style="background-color: ' +
((__t = ( selectedOption.color1 )) == null ? '' : __t) +
' ';
 } ;
__p += '">\n                    </div>\n                </div>\n                ';
 }; ;
__p += '\n                <span class="r-p caret"></span>\n                <div class="chosen-option-name ellipsed">' +
((__t = ( selectedOption.name )) == null ? '' : __t) +
'</div>\n            </button>\n            <ul class="dropdown-menu" role="menu">\n                ';
 _.each(productOptions, function(option, index) { ;
__p += '\n                <li class="dropdown-item option-item ' +
((__t = ( option.optionType )) == null ? '' : __t) +
' ';
 if (option.id === selectedOption.id) { ;
__p += 'selected';
 }; ;
__p += '"\n                    data-option-id="' +
((__t = ( option.id )) == null ? '' : __t) +
'"\n                    data-category-id="' +
((__t = ( productOptionCategory.id )) == null ? '' : __t) +
'"\n                    data-color="' +
((__t = ( option.color1 )) == null ? '' : __t) +
'"><a class="justify-content">\n                    ';
 if (option.optionType === 'color') { ;
__p += '\n                    <div class="color-wrapper pull-left">\n                        <div class="color-box ';
 if (option.color1 === '#ffffff') { ;
__p += 'color-white';
 }; ;
__p += '\n                        ';
 if (option.color1 !== -1 && option.color2 !== -1 && option.color3 === -1 && option.color4 === -1) { ;
__p += ' colorClass2" \n                            style="background-color: ' +
((__t = ( option.color1 )) == null ? '' : __t) +
';\n                            border-color:transparent transparent ' +
((__t = ( option.color2 )) == null ? '' : __t) +
' transparent; \n                        ';
 } else if (option.color1 !== -1 && option.color2 !== -1 && option.color3 !== -1 && option.color4 === -1) { ;
__p += ' colorClass3" \n                            style="background-color: ' +
((__t = ( option.color1 )) == null ? '' : __t) +
';\n                            border-color:transparent transparent ' +
((__t = ( option.color2 )) == null ? '' : __t) +
' ' +
((__t = ( option.color3 )) == null ? '' : __t) +
'; \n                        ';
 } else if  (option.color1 !== -1 && option.color2 !== -1 && option.color3 !== -1 && option.color4 !== -1) { ;
__p += ' colorClass4" \n                            style="background-color: ' +
((__t = ( option.color1 )) == null ? '' : __t) +
';\n                            border-color:transparent ' +
((__t = ( option.color2 )) == null ? '' : __t) +
' ' +
((__t = ( option.color3 )) == null ? '' : __t) +
' ' +
((__t = ( option.color4 )) == null ? '' : __t) +
'; \n                        ';
 } else if (option.color1 !== -1 && option.color2 === -1 && option.color3 === -1 && option.color4 === -1) { ;
__p += '" style="background-color: ' +
((__t = ( option.color1 )) == null ? '' : __t) +
' ';
 } ;
__p += '">\n                        </div>\n                        <span class="option-name ellipsed-tooltip" title="' +
((__t = ( option.name )) == null ? '' : __t) +
'">' +
((__t = ( option.name )) == null ? '' : __t) +
'</span>\n                    </div>\n                    ';
 } else { ;
__p += '\n                    <span class="option-name ellipsed-tooltip" title="' +
((__t = ( option.name )) == null ? '' : __t) +
'">' +
((__t = ( option.name )) == null ? '' : __t) +
'</span>\n                    ';
 } ;
__p += '\n                    <span class="pull-right glyphicon glyphicon-ok ' +
((__t = ( productOptionCategory.id )) == null ? '' : __t) +
'-glyphicon-ok ';
 if ( option.id !== selectedOption.id ) { ;
__p += ' hidden ';
 }; ;
__p += '"></span>\n                </a></li>\n                ';
 }); ;
__p += '\n            </ul>\n            </div>\n        ';
 } ;
__p += '\n    </td>\n';
 }); ;
__p += '\n<td>\n    <div class="quantity-input-block">\n        <input type="text" value="' +
((__t = ( quantity )) == null ? '' : __t) +
'" step="1" class="mix-quantity-input quantity-block left-text form-control">\n    </div>\n</td>\n';

}
return __p
};});

