/**
 * Created by RKL on 21/08/2015.
 */
define('productsListView',[
  'module',
  'jqgridView',
  'app',
  'settings',
  'underscore',
  'template!productsListTpl',
  'productViewSwitcherView',
  'pricePopoverMixin'
], function(
  module,
  JqGridView,
  App,
  Settings,
  _,
  viewTpl,
  ViewSwitcherView,
  pricePopoverMixin
) {
  'use strict';

  var ProductsView =  JqGridView.extend({
    template: viewTpl,
    filtersName: 'productsListFilters',
    regions: {
      viewSwitcher: '.js-view-switcher-region'
    },

    ui: function() {
      return _.extend({
        jqGrid: '#jq-grid-list',
        jqGridPager: '#jq-grid-pager-list'
      }, this.gridUi);
    },

    events: function() {
      return _.extend({}, this.gridEvents);
    },

    serializeData: function() {
      return {type: 'product'};
    },

    onShow: function() {
      this.getRegion('viewSwitcher').show(new ViewSwitcherView({
        currentView: 'table'
      }));
    },

    priceFormatter: function(cellValue, options) {
      if (cellValue === null || cellValue === undefined) { return ''; }

      return '<span class="cell-default" data-row-id="' +
          options.rowId + '"> 50: ' + cellValue.rsPrice50 + '€, 500: '+ cellValue.rsPrice500 +'€</span>';
    },

    onGridFilterChange: function(e) {
        var that = this,
            filters = {},
            value;

        this.triggerClear(e);

        _.each(this.colModel, function(column) {
            if (column.search) {
                if (column.stype === 'select') {
                    value = that.$('select[name="' + column.index + '"]').val();
                    filters[column.index] = value === that.selectDefault ? '' : value;
                } else if (column.sorttype === 'price') {
                    value = that.$('input[name="' + column.index + '"]').val();
                    if (column.index === 'prices' && value) {
                        var p = pricePopoverMixin.parse(value);
                        filters.quantity = p.quantity;
                        filters.priceMin = p.priceMin;
                        filters.priceMax = p.priceMax;
                    }
                } else {
                    value = that.$('input[name="' + column.index + '"]').val();
                    filters[column.index] = value;
                }
            }
        });

        this.filters = filters;
        this.filterChange = true;
        this.updatePagination();
    },

    gridOptions: function(data) {
       var defaultsOptions, options;

      defaultsOptions = this.gridInitOptions(data);
      options = {
        colModel: [
          {label: '', name: 'secId', key: true, hidden: true},
          {
            label: ' ',
            name: 'cataloguePictureUid',
            align: 'left',
            search: false,
            classes: 'product-link image-cell',
            formatter: this.pictureUidFormatter
          },
          {
            label: _.i18n('productList.productCode'),
            name: 'cCode',
            search: true,
            index: 'productCode',
            sorttype: 'integer',
            classes: 'product-link',
            formatter: this.defaultFormatter
          },
          {
            label: _.i18n('productList.productCodeMan'),
            name: 'baseCode',
            search: true,
            index: 'productCode',
            sorttype: 'integer',
            classes: 'product-link',
            formatter: this.defaultFormatter
          },
          {
            label: _.i18n('productList.productName'),
            name: 'name',
            search: true,
            index: 'productName',
            classes: 'product-link',
            formatter: this.defaultFormatter
          },
          {
            label: _.i18n('productList.productManufacturer'),
            name: 'manufacturerCode',
            search: true,
            index: 'manufacturer'/*, stype: 'select'*/,
            classes: 'product-link',
            formatter: this.defaultFormatter
          },
          {
            label: _.i18n('productList.status'),
            name: 'status',
            search: true,
            index: 'status',
            classes: 'product-link',
            formatter: this.defaultFormatter
          },
          {
            label: _.i18n('productList.price'),
            name: 'prices',
            classes: 'tickets-link',
            formatter:  this.priceFormatter,
            search: true,
            index: 'prices',
            sortable: false,
            sorttype: 'price',
            searchrules: {price: true},
            searchoptions: {
                dataInit: pricePopoverMixin.jqGridDataInit
            },
            width: 130
          }
        ],

        serializeGridData: function(postData) {
          if (postData.productCode) {
            postData.code = postData.productCode;
          }

          if (postData.productName) {
            postData.name = postData.productName;
          }

          if (postData.prices) {
            var p = pricePopoverMixin.parse(postData.prices);
            postData.quantity = p.quantity;
            postData.priceMin = p.priceMin;
            postData.priceMax = p.priceMax;
            delete postData.prices;
          } else {
            delete postData.quantity;
            delete postData.priceMin;
            delete postData.priceMax;
          }

          defaultsOptions.serializeGridData.apply(this, arguments);

          return postData;
        },

        onSelectRow: _.bind(function(rowid, a , event) {
          var index = 0; 
          for (var i = 1 ; i < event.currentTarget.rows.length ; i++) {
            var row = event.currentTarget.rows[i];
            if (row.id === rowid) {
              index = i - 1;
              break;
            }
          }
          var productListData = {
            filters: this.filters,
            rowCount: this.pagination.rowCount,
            currentIndex: (this.pagination.currentPage - 1) * this.pagination.pageSize + index
          };  
          Settings.set('productListData', productListData);
          App.trigger('product:show', rowid);
        }, this)
      };

      options = _.defaults(options, defaultsOptions);

      return options;
    }
  });

  module.exports = ProductsView;
});
