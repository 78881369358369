/**
 * Created by OLD on 04/09/2017.
 */
define('ordersKanbanRouter',[
  'require',
  'exports',
  'module',
  'logger',
  'marionette',
  'app',
  'itemPagesNameMixin'
], function(
  require,
  exports,
  module,
  Logger,
  Marionette,
  App,
  ItemPagesNameMixin
) {
  'use strict';

  var OrdersKanbanRouter = Marionette.AppRouter.extend({
    appRoutes: {
      'orders_kanban': 'showView'
    }
  });

  var API = {
    showView: function() {
      require([
        'ordersKanbanController',
        'contentShowController'
      ], function(ShowController, ContentShowController) {
        ContentShowController.showContent().done(function() {
          App.startSubModule('ordersKanbanMain');
          ShowController.showOrdersKanban();
          App.execute('set:active:nav:item', ItemPagesNameMixin.ORDERS_BOARD);
        });
      });
    }
  };

  App.on('orders_kanban:show', function() {
    App.navigate('orders_kanban');
    API.showView();
  });

  App.on('before:start', function() {
    new  OrdersKanbanRouter({
      controller: API
    });
  });

  module.exports = OrdersKanbanRouter;
});
