
define('template!productPriceTabTpl', ['lodash'], function(_) {return function(obj) {
obj || (obj = {});
var __t, __p = '', __j = Array.prototype.join;
function print() { __p += __j.call(arguments, '') }
with (obj) {
__p += '<div class="detailed-info-form-container">\n    <div class="form-horizontal col-xs-6">\n        <div class="form-inputs-container">\n            <div class="form-group" data-field-name="quantity">\n                <label class="col-xs-6 control-label">' +
((__t = ( _.i18n('common.quantity') )) == null ? '' : __t) +
'</label>\n                <div class="col-xs-6">\n                    <input type="number" class="form-control floating-label js-info-input input-quantity" data-field-name="quantity" placeholder="' +
((__t = ( _.i18n('common.quantity') )) == null ? '' : __t) +
'" value="50">\n                </div>\n            </div>\n\n            ';
 for (var i = 0 ; i < printAreas.length ; i++) { ;
__p += '\n                <div class="form-group" data-field-name="printarea-' +
((__t = ( i )) == null ? '' : __t) +
'">\n                    <label class="col-xs-6 control-label">' +
((__t = ( printAreas[i].name )) == null ? '' : __t) +
'</label>\n                    <div class="dropdown col-xs-6">\n                        <button class="form-control dropdown-toggle dropdown__button" type="button" id="dropdownMenu-pz-' +
((__t = ( i )) == null ? '' : __t) +
'"\n                                data-toggle="dropdown" aria-haspopup="true" aria-expanded="true">\n                            <span class="pull-left dropdown__label printzone-' +
((__t = ( i )) == null ? '' : __t) +
'-label">None</span>\n                            <span class="caret pull-right m-t-5 dropdown__caret"></span>\n                        </button>\n                        <ul class="dropdown-menu dropdown-menu-printzone" role="menu" aria-labelledby="dropdownMenu-pz-' +
((__t = ( i )) == null ? '' : __t) +
'">\n                            <li role="presentation"><a role="menuitem" data-index="' +
((__t = ( i )) == null ? '' : __t) +
'" data-pz-id="-1" href="#">None</a></li>\n                            ';
 for (var j = 0 ; j < printAreas[i].printZones.length ; j++) { ;
__p += '\n                                ';
 var pz = printAreas[i].printZones[j]; ;
__p += '\n                                ';
 if (pz.colorModeType === 'spot') { ;
__p += '\n                                    ';
 for (var k = 1 ; k <= pz.maxColors ; k ++) { ;
__p += '\n                                        <li role="presentation"><a role="menuitem" data-index="' +
((__t = ( i )) == null ? '' : __t) +
'"\n                                                       data-nb-color="' +
((__t = ( k )) == null ? '' : __t) +
'"\n                                                       data-pz-id="' +
((__t = ( pz.id )) == null ? '' : __t) +
'"\n                                                       href="#">' +
((__t = ( pz.name )) == null ? '' : __t) +
' (' +
((__t = ( k )) == null ? '' : __t) +
') ' +
((__t = ( pz.width + ' X ' + pz.height + printAreas[i].unit )) == null ? '' : __t) +
'</a></li>\n                                    ';
 } ;
__p += '\n                                ';
 } else { ;
__p += '\n                                    <li role="presentation"><a role="menuitem" data-index="' +
((__t = ( i )) == null ? '' : __t) +
'"\n                                                                data-nb-color="1"\n                                                                data-pz-id="' +
((__t = ( pz.id )) == null ? '' : __t) +
'"\n                                                                href="#">' +
((__t = ( pz.name )) == null ? '' : __t) +
' ' +
((__t = ( pz.width + ' X ' + pz.height + printAreas[i].unit )) == null ? '' : __t) +
'</a></li>\n                                ';
 } ;
__p += '\n                            ';
 } ;
__p += '\n                        </ul>\n                    </div>\n                </div>\n            ';
 } ;
__p += '\n\n            ';
 for (var i = 0 ; i < productOptionCategories.length ; i++) { ;
__p += '\n            <div class="form-group" data-field-name="option-category-' +
((__t = ( i )) == null ? '' : __t) +
'">\n                <label class="col-xs-6 control-label">' +
((__t = ( productOptionCategories[i].name )) == null ? '' : __t) +
'</label>\n                <div class="dropdown col-xs-6">\n                    <button class="form-control dropdown-toggle dropdown__button" type="button" id="dropdownMenu-option-' +
((__t = ( i )) == null ? '' : __t) +
'"\n                            data-toggle="dropdown" aria-haspopup="true" aria-expanded="true">\n                        <span class="pull-left dropdown__label option-' +
((__t = ( i )) == null ? '' : __t) +
'-label">None</span>\n                        <span class="caret pull-right m-t-5 dropdown__caret"></span>\n                    </button>\n                    <ul class="dropdown-menu dropdown-menu-option" role="menu" aria-labelledby="dropdownMenu-option-' +
((__t = ( i )) == null ? '' : __t) +
'">\n                        ';
 for (var j = 0 ; j < productOptionCategories[i].productOptions.length ; j++) { ;
__p += '\n                        <li role="presentation"><a role="menuitem" data-index="' +
((__t = ( i )) == null ? '' : __t) +
'"\n                                                   data-option-id="' +
((__t = ( productOptionCategories[i].productOptions[j].id )) == null ? '' : __t) +
'"\n                                                   href="#">' +
((__t = ( productOptionCategories[i].productOptions[j].name )) == null ? '' : __t) +
'</a></li>\n                        ';
 } ;
__p += '\n                    </ul>\n                </div>\n            </div>\n            ';
 } ;
__p += '\n\n            <div class="row">\n                <div class="pull-right p-r-15">\n                    <button class="btn btn-primary js-confirm">' +
((__t = ( _.i18n('common.confirm') )) == null ? '' : __t) +
'</button>\n                </div>\n            </div>\n        </div>\n    </div>\n\n    <div class="col-lg-6 col-md-8 col-xs-12">\n        <table class="table">\n            <thead>\n            <tr>\n                <th></th>\n                <th>' +
((__t = ( _.i18n('price.buyingPrice') )) == null ? '' : __t) +
'</th>\n                <th>' +
((__t = ( _.i18n('price.resellingPrice') )) == null ? '' : __t) +
'</th>\n                <th>' +
((__t = ( _.i18n('price.marginPrice') )) == null ? '' : __t) +
'</th>\n            </tr>\n            </thead>\n            <tbody>\n            <tr>\n                <td class="">' +
((__t = ( _.i18n('price.shippingCost') )) == null ? '' : __t) +
'</td>\n                <td class="lbl-shipping-cost"></td>\n                <td class="lbl-rs-shipping-cost"></td>\n                <td class="lbl-shipping-margin"></td>\n            </tr>\n            <tr>\n                <td class="">' +
((__t = ( _.i18n('price.productCost') )) == null ? '' : __t) +
'</td>\n                <td class="lbl-product-cost"></td>\n                <td class="lbl-rs-product-cost"></td>\n                <td class="lbl-product-margin"></td>\n            </tr>\n            <tr>\n                <td class="">' +
((__t = ( _.i18n('price.totalProduct') )) == null ? '' : __t) +
'</td>\n                <td class="lbl-total-product"></td>\n                <td class="lbl-rs-total-product"></td>\n                <td class="lbl-mg-total-product"></td>\n            </tr>\n            <tr>\n                <td class="">' +
((__t = ( _.i18n('price.setupCost') )) == null ? '' : __t) +
'</td>\n                <td class="lbl-setup-cost"></td>\n                <td class="lbl-rs-setup-cost"></td>\n                <td class="lbl-setup-margin"></td>\n            </tr>\n            <tr>\n                <td class="">' +
((__t = ( _.i18n('price.printCosts') )) == null ? '' : __t) +
'</td>\n                <td class="lbl-unit-cost"></td>\n                <td class="lbl-rs-unit-cost"></td>\n                <td class="lbl-unit-cost-margin"></td>\n            </tr>\n            <tr>\n                <td class="">' +
((__t = ( _.i18n('price.totalPrint') )) == null ? '' : __t) +
'</td>\n                <td class="lbl-total-print"></td>\n                <td class="lbl-rs-total-print"></td>\n                <td class="lbl-mg-total-print"></td>\n            </tr>\n            <tr>\n                <td class="">' +
((__t = ( _.i18n('price.total') )) == null ? '' : __t) +
'</td>\n                <td class="lbl-total"></td>\n                <td class="lbl-rs-total"></td>\n                <td class="lbl-mg-total"></td>\n            </tr>\n            <tr>\n                <td class="">' +
((__t = ( _.i18n('price.totalNotPrinted') )) == null ? '' : __t) +
'</td>\n                <td class="lbl-total-product"></td>\n                <td class="lbl-rs-total"></td>\n                <td class="lbl-mg-total-notprinted"></td>\n            </tr>\n            </tbody>\n        </table>\n    </div>\n\n    <div class="col-md-8 col-xs-12">\n        <table class="table">\n            <thead>\n            <tr>\n                <th>' +
((__t = ( _.i18n('common.quantity') )) == null ? '' : __t) +
'</th>\n                <th>' +
((__t = ( _.i18n('price.buyingPrice') )) == null ? '' : __t) +
'</th>\n                <th>' +
((__t = ( _.i18n('price.buyingUnitPrice') )) == null ? '' : __t) +
'</th>\n                <th>' +
((__t = ( _.i18n('price.resellingPrice') )) == null ? '' : __t) +
'</th>\n                <th>' +
((__t = ( _.i18n('price.resellingUnitPrice') )) == null ? '' : __t) +
'</th>\n                <th>' +
((__t = ( _.i18n('price.marginPrice') )) == null ? '' : __t) +
'</th>\n            </tr>\n            </thead>\n            <tbody class="table-scale">\n\n            </tbody>\n        </table>\n    </div>\n\n    <div class="loading-block js-loading-block loader"></div>\n</div>';

}
return __p
};});

