define('mailCreateLayoutView',[
  'module',
  'marionette',
  'backbone',
  'jquery',
  'dialogFormView',
  'template!mailCreateLayoutTpl',
  'imageUploadBehavior',
  'savingBehavior',
  'bootbox',
  'underscore',
  'tokenField',
  'app',
  'settings',
  'autocompleteView'
], function(
  module,
  Marionette,
  Backbone,
  $,
  DialogFormView,
  createEditLayoutTpl,
  ImageUploadBehavior,
  SavingBehavior,
  bootbox,
  _,
  tokenField,
  App,
  Settings,
  AutocompleteView
) {
  'use strict';

  var CreateEditLayoutView = DialogFormView.extend({
    template: createEditLayoutTpl,

    ui: {
      input: '.js-info-input',
      jsFormInput: '.js-form-input',
      cancel: '.js-cancel',
      confirm: '.js-confirm'
    },

    events: {
      'click .js-cancel-popup': 'hide',
      'focus @ui.input' : 'onFocus',
      'change @ui.input': 'onInputChange',
      'click .js-confirm': 'onConfirm',
      'change @ui.jsFormInput': 'onInputChange'
    },

    regions: {
        emailsAutoComplete: '.js-select-email-region'
    },

    className: 'profile-create-edit',

    fieldsToValidate: [
      {name: 'to', type: 'multipleEmail'},
      {name: 'subject', type: 'required'},
      {name: 'content', type: 'required'}
    ],

    initialize: function() {
      this.model = new Backbone.Model();
    },

    serializeData: function() {
      var templateData = this.model.toJSON();
      return templateData;
    },

    onShow: function() {
      $.material.init();
      this.initTokenFields();

      var emailsView = new AutocompleteView(
          this.getEmailsAutocompleteOptions()
      );
      this.getRegion('emailsAutoComplete').show(emailsView);
    },

    getEmailsAutocompleteOptions: function() {
      return {
          type: 'remote',
          alwaysOpen: true,
          remote: Settings.url('compuzz', 'v2/mails/emailAddress'),
          valueKey: 'email',
          apiKey: 'email',
          placeholder: _.i18n('selectemail.placeholder'),
          value: null,
          name: 'email',
          onPressKey: true,
          lazyLoad: true,
          keys: {
              query: 'search',
              limit: 'pageSize'
          }
      };
    },

    initTokenFields: function() {
      // these variables are used to fix email input field appearance
      var value = this.$('#emailInput').val(),
          emailTokenCount = value ? value.split(';').length : 0;

      if (emailTokenCount > 0) {
        this.$el.find('[data-field-name="to"] .floating-label').addClass('floating-label-focused');
      }

      this.$('#emailInput').tokenfield({
            createTokensOnBlur: true,
            inputType: 'email',
            delimiter: [';', ',', ' ']
        }).on('tokenfield:createdtoken', _.bind(function(e){
            this.onTokenFieldInputChange(e);
            emailTokenCount++;
        }, this))
        .on('tokenfield:tokenEdited', _.bind(function(e){
            this.onTokenFieldInputChange(e);
        }, this))
        .on('tokenfield:removedtoken', _.bind(function(e){
            this.onTokenFieldInputChange(e);
            emailTokenCount--;
        }, this));

      // fix conflicts between materializecss and tokenfield plugin
      this.$('[data-field-name="email"]').off('focusin').focusin(function() {
        $(this).find('.floating-label').addClass('floating-label-focused selected');
      }).off('focusout').focusout(function() {
        if($(this).find('#emailInput-tokenfield').val() === '' && emailTokenCount === 0) {
          $(this).find('.floating-label').removeClass('floating-label-focused');
        }
        $(this).find('.floating-label').removeClass('selected');
      });
    },

    onTokenFieldInputChange: function(e){
        this.onInputChange(e);
        this.triggerMethod('enable:cancel:confirm');
    },

    onConfirm: function() {
      if(this.validate(this.fieldsToValidate)) {
        this.sendMessage();
      }
    },

    sendMessage: function() {
        var email = {
            mailTo: this.model.get('email'),
            subject: this.model.get('subject'),
            content: this.model.get('content')
        };

        var sendMailRequest = App.request('mails:send', email);

        sendMailRequest.done(_.find(function () {
            this.hide();
        }, this));
    },

    onBeforeDestroy: function() {
      this.$('#emailInput').tokenfield( 'destroy' );
    }
  });

  module.exports = CreateEditLayoutView;
});
