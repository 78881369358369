define('orderDetailsTabController',[
  'module',
  'app',
  'underscore',
  'backbone',
  'orderDetailsTabView',
  'changeStatusPopupView',
  'changeTypePopupView',
  'changeNamePopupView',
  'changeExpShippingDatePopupView',
  'jquery',
  'ordersKanbanCommentPopupView',
  'settings',
  'productSelectDialogView',
  'productConfigDialogView',
  'emptyView',
  'entities/customers/customers',
  'entities/orders',
  'entities/offers',
  'entities/orders_kanban'
], function(
  module,
  App,
  _,
  Backbone,
  DetailsTabView,
  ChangeStatusPopupView,
  ChangeTypePopupView,
  ChangeNamePopupView,
  ChangeExpShippingDatePopupView,
  $,
  CommentPopupView,
  Settings,
  ProductSelectDialog,
  ProductConfigDialog,
  EmptyView
) {
  'use strict';

  var DetailsTabController = {
    showDetailsTab: function(region, orderModel, customer) {
        this.model = orderModel;
        this.currentRegion = region;
        var fetchStatuses = App.request('order:statuses', orderModel.get('commandType') === 'offer');
        var fetchTypes = App.request('order:types', true);
        $.when(fetchStatuses, fetchTypes).done(_.bind(function(statuses, types) {
            var product = App.request('products:model');

            product.fetchByCode(orderModel.get('productCode')).done(_.bind(function() {
                this.view = new DetailsTabView({
                    customer: customer,
                    model: orderModel,
                    statuses: statuses,
                    types: types,
                    product: product
                });

                this.view.listenTo(this.view, 'openChangeStatusPopup', _.bind(this.openChangeStatusPopup, this));
                this.view.listenTo(this.view, 'openChangeTypePopup', _.bind(this.openChangeTypePopup, this, types));
                this.view.listenTo(this.view, 'onEditExpShippingDate', _.bind(this.openChangeExpShippingDatePopup, this));
                this.view.listenTo(this.view, 'onEditProduct', _.bind(this.onEditProduct, this));
                this.view.listenTo(this.view, 'onEditQuantity', _.bind(this.onEditQuantity, this));
                this.view.listenTo(this.view, 'onEditName', _.bind(this.onEditName, this));

                region.show(this.view);
            }, this));

        }, this));
    },

    onEditQuantity: function() {
        this.productConfigDialog = new ProductConfigDialog({
            purchaseOrderId: this.model.get('purchaseOrderId'),
            quantity: this.model.get('quantity')
        });
        this.productConfigDialog.show({ title: _.i18n('dialog.offer.changeProductConfig') });
        this.productConfigDialog.listenTo(this.productConfigDialog, 'product-configured',
            _.bind(this.onProductConfigured, this));
    },

    onEditProduct: function() {
        this.productSelectDialog = new ProductSelectDialog({
            multiSelect: false,
            displayLeaveButton: false
        });
        this.productSelectDialog.listenTo(this.productSelectDialog, 'products-selected', _.bind(this.onProductsSelected, this));
        this.productSelectDialog.show({ title: _.i18n('dialog.offer.selectProduct') });
    },

    onProductsSelected: function(products) {
        products = _.pluck(products, 'rowid');
        $.when(this.model.changeProduct(products[0])).always(_.bind(function() {
            var selectedProduct = App.request('products:model', { secId: products[0] });
            selectedProduct.fetch().done(_.bind(function () {
                selectedProduct.fullFetch(selectedProduct.get('code')).done(_.bind(function () {
                    this.productConfigDialog = new ProductConfigDialog({ product: selectedProduct });
                    this.productSelectDialog.hide();
                    this.productConfigDialog.show({ title: _.i18n('dialog.offer.changeProductConfig') });
                    this.productConfigDialog.listenTo(this.productConfigDialog, 'product-configured',
                        _.bind(this.onProductConfigured, this));
                }, this));
            }, this));
        }, this));
    },

    onProductConfigured: function (configuration) {
      this.currentRegion.show(new EmptyView());
      if (configuration.purchaseOrderId) {
          $.when(this.model.changeQuantity(configuration.quantity)).always(_.bind(function() {
              App.navigate('orders/' + this.model.get('purchaseOrderId') + '?refresh', {trigger: true});
          }, this));
      } else {
          App.request('offer:update-from-configuration',
              this.model.get('purchaseOrderId'),
              configuration
          ).then(_.bind(function () {
              App.navigate('orders/' + this.model.get('purchaseOrderId') + '?refresh', {trigger: true});
          }, this));
      }
      this.productConfigDialog.hide();
    },

    onEditName: function() {
        var popupView = new ChangeNamePopupView({
            name: this.view.model.get('name')
        });
        popupView.show({
            title: _.i18n('name.popup.title'),
            className: 'vectorizationAddNew',
            buttons: {
                cancel: {
                    label: _.i18n('common.cancel'),
                    className: 'btn-default',
                    callback: function () {
                        return true;
                    }
                },
                main: {
                    label: _.i18n('common.save'),
                    className: 'btn btn-primary',
                    callback: _.bind(function () {
                        this.model.set('name', popupView.options.name);
                        $.when(App.request('order:update', {
                            purchaseOrderId: this.model.get('purchaseOrderId'),
                            refCommand: {
                                updateProperties: true,
                                name: popupView.options.name
                            }
                        })).done(_.bind(function() {
                            this.view.refreshName();
                        }, this));
                    }, this)
                }
            }
        });
    },

    openChangeExpShippingDatePopup: function() {
      var popupView = new ChangeExpShippingDatePopupView({
          expShippingDate: this.view.model.get('expShippingDate')
      });
      popupView.show({
          title: _.i18n('expShippingDate.popup.title'),
          className: 'vectorizationAddNew',
          buttons: {
              cancel: {
                  label: _.i18n('common.cancel'),
                  className: 'btn-default',
                  callback: function () {
                      return true;
                  }
              },
              main: {
                  label: _.i18n('common.save'),
                  className: 'btn btn-primary',
                  callback: _.bind(function () {
                      this.model.set('expShippingDate', popupView.options.expShippingDate);
                      $.when(App.request('order:update', {
                          purchaseOrderId: this.model.get('purchaseOrderId'),
                          expShippingDate: popupView.options.expShippingDate
                      })).done(_.bind(function() {
                          this.view.refreshExpDate();
                      }, this));
                  }, this)
              }
          }
      });
    },

    openChangeTypePopup: function(types) {
        var popupView = new ChangeTypePopupView({
            types: new Backbone.Collection(types),
            currentType: this.view.model.get('commandType')
        });
        popupView.show({
            title: _.i18n('commandType.popup.title'),
            className: 'vectorizationAddNew',
            buttons: {
                cancel: {
                    label: _.i18n('common.cancel'),
                    className: 'btn-default',
                    callback: function () {
                        return true;
                    }
                },
                main: {
                    label: _.i18n('common.save'),
                    className: 'btn btn-primary',
                    callback: _.bind(function () {
                        console.log('ok');
                        // this.view.model
                        var selectedType = popupView.dropDown.selectedItem.get('name');
                        this.view.model.set('commandType', selectedType);
                        this.view.model.updateType().done(_.bind(function(){
                            this.view.$el.find('#order-detail-tab-type')
                                .text(this.view.model.get('commandType'));
                        }, this));
                    }, this)
                }
            }
        });
    },

    openChangeStatusPopup: function() {
      this.view.model.getAvailableStatuses().done(_.bind(function(statuses) {
        var popupView = new ChangeStatusPopupView({
              orderStatusCollection: new Backbone.Collection(statuses),
              currentOrderStatus: this.view.model.get('status')
            });

        popupView.show({
          title: _.i18n('orderStatus.popup.title'),
          className: 'vectorizationAddNew',
          buttons: {
            cancel: {
              label: _.i18n('common.cancel'),
              className: 'btn-default',
              callback: function() { return true; }
            },
            main: {
              label: _.i18n('common.save'),
              className: 'btn btn-primary',
              callback: _.bind(function() {

                var statusObj = _.findWhere(this.view.options.statuses.toJSON(),
                    {statusConst: popupView.dropDown.selectedItem.get('statusConst')});
                if (statusObj && statusObj.commentStatus) {
                    var edit = false, comments = this.view.model.get('comments');
                    if (comments && comments.length > 0 && comments[comments.length - 1].notSend === true) {
                        edit = true;
                    }

                    var commentPopupView = new CommentPopupView({
                        model: new Backbone.Model({comment: edit?comments[comments.length - 1].content:''})
                    });
                    commentPopupView.show({
                        title: _.i18n('common.comment'),
                        buttons: {
                            ok: {
                                label: _.i18n('common.ok'),
                                className: 'btn-default',
                                callback: _.bind(function() {
                                    var currentUserPid = Settings.get('currentUser' );
                                    var comment = {
                                        content: commentPopupView.model.get('comment'),
                                        externalId: '',
                                        externalUserId: currentUserPid.externalId?currentUserPid.externalId:'',
                                        msgDate: new Date().getTime(),
                                        title: commentPopupView.model.get('title'),
                                        user: currentUserPid.secId
                                    };

                                    if (edit) {
                                        comments[comments.length - 1] = comment;
                                    } else {
                                        comments.push(comment);
                                    }

                                    App.request('order-kanban:update:comment',
                                        this.view.model.get('purchaseOrderId'),
                                        this.view.model.get('comments')).then(_.bind(function() {
                                        this.view.model.set('status',
                                            popupView.dropDown.selectedItem.get('statusConst'));
                                        this.view.model.updateStatus().done(_.bind(function(){
                                            this.view.$el.find('#order-detail-tab-status')
                                                .text(popupView.dropDown.selectedItem.get('status'));
                                            this.view.render();
                                            App.execute('orders:status:change');
                                        }, this));
                                    }, this));
                                    return true;
                                }, this)
                            }
                        }
                    });
/*
                    App.request('order-kanban:get:comment',
                        this.view.model.get('purchaseOrderId')).then(_.bind(function(comment) {
                        var commentPopupView = new CommentPopupView({
                            model: new Backbone.Model({comment: comment})
                        });
                        commentPopupView.show({
                            title: _.i18n('common.comment'),
                            buttons: {
                                ok: {
                                    label: _.i18n('common.ok'),
                                    className: 'btn-default',
                                    callback: _.bind(function() {
                                        App.request('order-kanban:update:comment',
                                            this.view.model.get('purchaseOrderId'),
                                            commentPopupView.model.get('comment')).then(_.bind(function() {
                                            this.view.model.set('status',
                                                popupView.dropDown.selectedItem.get('statusConst'));
                                            this.view.model.updateStatus().done(_.bind(function(){
                                                this.view.$el.find('#order-detail-tab-status')
                                                    .text(popupView.dropDown.selectedItem.get('status'));
                                                App.execute('orders:status:change');
                                            }, this));
                                        }, this));
                                        return true;
                                    }, this)
                                }
                            }
                        });
                    }, this));*/
                } else {
                    this.view.model.set('status', popupView.dropDown.selectedItem.get('statusConst'));
                    this.view.model.updateStatus().done(_.bind(function(){
                        this.view.$el.find('#order-detail-tab-status').text(popupView.dropDown.selectedItem.get('status'));
                        App.execute('orders:status:change');
                    }, this));
                }

                return true;
              }, this)
            }
          }
        });
      }, this));
    }

  };

  module.exports = DetailsTabController;
});
