
define('template!customerCommonPopupFromTemplateFilterTpl', ['lodash'], function(_) {return function(obj) {
obj || (obj = {});
var __t, __p = '', __j = Array.prototype.join;
function print() { __p += __j.call(arguments, '') }
with (obj) {
__p += '<div class="container-fluid add-new-template-container">\n  <div class="col-sm-12" class="form-group-standardsizes">\n\n    <form class="form-horizontal new-template" role="form">\n      ';
 if(!isGroupHidden) { ;
__p += '\n        <div class="row">\n          <div class="form-group" data-field-name="geometry">\n            <div class="col-sm-3">\n              <label class="control-label">' +
((__t = ( _.i18n("common.templateGroup") )) == null ? '' : __t) +
'</label>\n            </div>\n            <div class="col-sm-9">\n                <div class="js-template-groups-region"></div>\n            </div>\n          </div>\n        </div>\n      ';
 } ;
__p += '\n      <div class="row">\n        <div class="form-group" data-field-name="standard-sizes">\n          <div class="col-sm-3">\n            <label class="control-label">' +
((__t = ( _.i18n("common.templateGroup.standardSizes") )) == null ? '' : __t) +
'</label>\n          </div>\n          <div class="col-sm-9">\n              <div class="js-standard-sizes-region"></div>\n          </div>\n        </div>\n      </div>\n      <div class="row">\n        <div class="form-group geometry-field" data-field-name="geometry">\n          <div class="col-sm-3">\n            <label class="control-label">' +
((__t = ( _.i18n("common.templateGroup.geometry") )) == null ? '' : __t) +
'</label>\n          </div>\n          <div class="col-sm-9">\n              <div class="radio radio-primary col-xs-6">\n                <label class="geometry-radio-b">\n                  <input type="radio" name="optionsRadios" id="optionsRadios1" value="RECT" data-id="0" checked="">\n                  ' +
((__t = ( _.i18n('geometry.rect') )) == null ? '' : __t) +
'\n                </label>\n              </div>\n              <div class="radio radio-primary col-xs-6">\n                <label class="geometry-radio-b">\n                  <input type="radio" name="optionsRadios" id="optionsRadios2" value="ROUND" data-id="1">\n                  ' +
((__t = ( _.i18n('geometry.round') )) == null ? '' : __t) +
'\n                </label>\n              </div>\n          </div>\n        </div>\n      </div>\n\n    </form>\n  </div>\n</div>';

}
return __p
};});

