define('tabsBaseView',[
  'module',
  'marionette',
  'template!tabsBaseTpl',
  'imageUtils',
  'jquery',
  'underscore'
], function(
  module,
  Marionette,
  tabsBaseTpl,
  imageUtils,
  $,
  _
) {
  'use strict';

  var TabsBaseView = Marionette.LayoutView.extend({
    template: tabsBaseTpl,
    tagName: 'div',

    ui: {
      logo: '.js-logo-holder',
      tabs: '.js-tab-layout-tab',
      tabsRegion: '.js-tabs-region',
      header: '.js-tab-layout-header'
    },

    events: {
      'click .js-tab-layout-tab': '_navigate'
    },

    baseEvents: {
        'click .js-tab-layout-tab': '_navigate'
    },

    regions: {
      tabs: '.js-tabs-region',
      header: '.js-header-region'
    },

    serializeData: function() {
      var templateData = this.model.toJSON();
      templateData.tabs = this.options.tabs;
      if (this.model.getName) {
          templateData.name = this.model.getName();
      }
      return templateData;
    },

    onShow: function() {
      var logo;
      if(!this.model.getImageUid) {
        throw 'Add method getImageUid to your model. It\'s required for displaying on header.';
      }
      logo = imageUtils.getLogo(this.model.getImageUid(), this.model.get('code'));
      if(logo) {
        this.ui.logo.css('background-image', 'url(' + logo + ')');
      } else {
        this.ui.logo.addClass('default-upload-image');
      }
      this._setHeight();
      $(window).on('resize', _.bind(this._setHeight, this));
      this.triggerMethod('show:tabs:base');
    },

    onShowActive: function(tabName) {
      this.ui.tabs.each(function(index, tab) {
        var $tab = $(tab);
        if($tab.hasClass('js-' + tabName)) {
          $tab.addClass('active');
        } else {
          $tab.removeClass('active');
        }
      });
    },

    onDestroy: function() {
      $(window).off('resize');
    },

    _navigate: function(e) {
      var $target = $(e.currentTarget);
      if($target.hasClass('active')) {
        return;
      }
      this.triggerMethod('navigate', $target.data('tab-name'));
    },


    _setHeight: _.throttle(function() {
      var windowHeight = $(window).height(),
        headerHeight = $('header').height() || 75,
        detailsHeaderHeight = this.ui.header.outerHeight(),
        height = windowHeight - headerHeight - detailsHeaderHeight - 40;

      this.ui.tabsRegion.height(height);
    }, 500)
  });

  module.exports = TabsBaseView;
});
