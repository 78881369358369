define('productPicturesTabCollectionView',[
  'module',
  'marionette',
  'productPicturesTabPictureItemView'
], function(
  module,
  Marionette,
  PictureItemView
) {
  'use strict';

  var PictureCollectionView = Marionette.CollectionView.extend({
    childView: PictureItemView,
    childViewOptions: function() {
      return {
        productSecId: this.options.productSecId,
        productCode: this.options.productCode
      };
    }
  });

  module.exports = PictureCollectionView;
});
