
define('template!translationsTranslationTpl', ['lodash'], function(_) {return function(obj) {
obj || (obj = {});
var __t, __p = '', __j = Array.prototype.join;
function print() { __p += __j.call(arguments, '') }
with (obj) {
__p += '<td>' +
((__t = ( model.get('code') )) == null ? '' : __t) +
'</td>\n';
 languages.each(function(language){ ;
__p += '\n  ';
 if(language.get('checked')) { ;
__p += '\n    <td>' +
((__t = ( model.get('text').localized[language.get('abbreviation').toLowerCase()] )) == null ? '' : __t) +
'</td>\n  ';
 } ;
__p += '\n';
 }) ;
__p += '\n<td><i class="mdi-action-delete js-delete md-grey-cl"></i></td>\n';

}
return __p
};});

