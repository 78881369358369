define( 'entities/caccounts/attachments',[
  'app',
  'jquery',
  'backbone',
  'settings'
], function(
  App,
  $,
  Backbone,
  Settings
) {
  'use strict';

  /**
   * Model
   */
  var Attachment = Backbone.Model.extend({
    defaults: {
      id: 0,
      downloadLink: null,
      fileLink: null,
      fileName: null
    },

    url: function() {
      return Settings.url('compuzz', 'attachments/caccount');
    },

    deleteUrl: function(type, id) {
      var urlPart;

      if (type === 'caccount') {
        urlPart = 'attachments/caccount/' + id + '/' + this.get('id');
      } else if (type === 'customer') {
        urlPart = 'attachments/users/' + id + '/' + this.get('id');
      } else if (type === 'commandline') {
        urlPart = 'attachments/commandAttachments/' + id + '/' + this.get('id');
      }
      
      return Settings.url('compuzz', urlPart);
    }
  });

  /**
   * Collection
   */
  var Attachments = Backbone.Collection.extend({
    model: Attachment
  });

  /**
   * REST API
   */
  var API = {
    getAttachments: function(type, id) {
      var attachments = new Attachments(),
        urlPart;

      if (type === 'caccount') {
        urlPart = 'attachments/caccount/' + id;
      } else if (type === 'customer') {
        urlPart = 'attachments/users/' + id;
      }
      attachments.url = Settings.url('compuzz', urlPart);

      return attachments;
    },

    getAttachmentsLazy: function(params) {
      var attachments = new Attachments();

      attachments.url = Settings.url('compuzz', 'attachments/', params);

      return attachments;
    },

    getAttachmentsModel: function() {
      return new Attachment();
    },

    getAttachmentsCount: function(params) {
      var defer = $.Deferred(),
        url;

      url = Settings.url('compuzz', 'attachments/rowCount', params);

      $.ajax({
        url: url,
        success: function(resp) {
          defer.resolve(resp);
        }
      });

      return defer.promise();
    },

    uploadAttachment: function(data) {
      var defer = $.Deferred(),
        url, urlPart;

      if (data.type === 'caccount') {
        urlPart = 'attachments/caccount/' + data.id + '/upload';
      } else if (data.type === 'customer') {
        urlPart = 'attachments/users/' + data.id + '/upload';
      } else if (data.type === 'commandline') {
        urlPart = 'attachments/commandAttachments/' + data.id + '/upload';
      }
      url = Settings.url('compuzz', urlPart);

      $.ajax({
        type: 'POST',
        url: url,
        data: data.formdata,
        processData: false,
        contentType: false,
        success: function(resp) {
          defer.resolve(resp);
        }
      });

      return defer.promise();
    }
  };

  /**
   * Request communications
   */
  App.reqres.setHandler('caccount:attachments', function(type, id) {
    return API.getAttachments(type, id);
  });

  App.reqres.setHandler('caccount:attachments-lazy', function(params) {
    return API.getAttachmentsLazy(params);
  });

  App.reqres.setHandler('caccount:attachments-count', function(params) {
    return API.getAttachmentsCount(params);
  });

  App.reqres.setHandler('caccount:attachments-model', function() {
    return API.getAttachmentsModel();
  });

  App.reqres.setHandler('caccount:upload-attachment', function(data) {
    return API.uploadAttachment(data);
  });
});
