/**
 * Created by BFR on 18/02/2020.
 */
define('dynamicTextView',[
    'module',
    'marionette',
    'underscore',
    'settings',
    'bootbox',
    'template!dynamicTextTpl',
    'dynamicTextEditView',
    'glossaryPopupView'
  ], function(
    module, 
    Marionette,
    _,
    Settings,
    bootbox,
    viewTpl,
    DynamicTextEditView,
    GlossaryPopupView
  ) {
    'use strict';
  
    var DynamicTextView = Marionette.ItemView.extend({
      template: viewTpl,
  
      region: {
      },
  
      ui: {

      },
  
      events: {
        'click .btn-edit-dt': 'onEditDtClick',
        'click .btn-edit-glossary': 'onEditGlossaryClick',
        'click .btn-link-glossary': 'onLinkGlossaryClick',
        'click .btn-unlink-glossary': 'onUnLinkGlossaryClick'
      },

      onLinkGlossaryClick: function () {
        this.glossaryPopupView = new GlossaryPopupView({ model: this.model });
        this.glossaryPopupView.show({
          title: _.i18n('dynamicttext.linkToGlossary')
        });
        this.glossaryPopupView.listenTo(
          this.glossaryPopupView, 
          'onSave',
          _.bind(this.onSave, this)
        );
      },

      onUnLinkGlossaryClick: function () {
        bootbox.confirm(_.i18n('common.confirmDelete'), _.bind(function(result) {
          if (result) {
            this.model.set('refGlossary', null);
            this.model.save().done(_.bind(function() {
              this.render();
            }, this)); 
          }
        }, this));
      },

      onEditGlossaryClick: function () {
        this.popupEditView = new DynamicTextEditView({ 
          collection: this.model.getGlossary().get('refDynamicText').getTranslationCollection(),
          model: this.model.getGlossary().get('refDynamicText') 
        });
        this.popupEditView.show({
          title: _.i18n('dynamicttext.editGlossary')
        });
        this.popupEditView.listenTo(
          this.popupEditView, 
          'onSave',
          _.bind(this.onSave, this)
        );
      },

      onEditDtClick: function () {
        this.popupEditView = new DynamicTextEditView({ 
          collection: this.model.getTranslationCollection(),
          model: this.model 
        });
        this.popupEditView.show({
          title: _.i18n('dynamicttext.edit')
        });
        this.popupEditView.listenTo(
          this.popupEditView, 
          'onSave',
          _.bind(this.onSave, this)
        );
      },

      onSave: function () {
        this.render();
      },
  
      serializeData: function () {
        var templateData = this.options.model.toJSON();
        templateData.currentValue = this.options.model.getTextInLocale(Settings.get('lang'));
        templateData.isUseGlossary = this.options.model.isUseGlossary();
        templateData.isUseParentGlossary = this.options.model.isUseParentGlossary();
        templateData.isOverridden = this.options.model.isOverridden();
        return templateData;
      }
    });
  
    module.exports = DynamicTextView;
  });
