
define('template!vectorTariffScaleViewTpl', ['lodash'], function(_) {return function(obj) {
obj || (obj = {});
var __t, __p = '', __j = Array.prototype.join;
function print() { __p += __j.call(arguments, '') }
with (obj) {
__p += '<span title="' +
((__t = ( _.i18n('vectorization.tariffScale') )) == null ? '' : __t) +
'">\n  <select data-ticket-cid="' +
((__t = ( cid )) == null ? '' : __t) +
'">\n    ';
 _.each(tariffScales, function(tariffScaleInner) { ;
__p += '\n      <option value="' +
((__t = ( tariffScaleInner.send )) == null ? '' : __t) +
'" ' +
((__t = ( tariffScale === tariffScaleInner.send ? 'selected' : '' )) == null ? '' : __t) +
'>\n        ' +
((__t = ( tariffScaleInner.label )) == null ? '' : __t) +
'\n      </option>\n    ';
 }) ;
__p += '\n  </select>\n</span>';

}
return __p
};});

