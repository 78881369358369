
define('template!customerCommonPopupFromTemplateItemTpl', ['lodash'], function(_) {return function(obj) {
obj || (obj = {});
var __t, __p = '';
with (obj) {
__p += '<div class="image-texture-and-background">\n  <img class="js-image-holder" src="' +
((__t = ( templateImageUrl )) == null ? '' : __t) +
'"/>\n</div>\n<div class="template-meta-info-container"></div>';

}
return __p
};});

