define('shareRequestView',[
    'module',
    'app',
    'marionette',
    'settings',
    'template!headerShareRequestViewTpl'
], function(
    module,
    App,
    Marionette,
    Settings,
    headerShareRequestViewTpl
) {
    'use strict';

    var ShareRequestView = Marionette.ItemView.extend({
        tagName: 'li',
        template: headerShareRequestViewTpl,

        ui: {
            currentShareRequest: '.currentShareRequest',
            acceptRequest: '.accept-request',
            removeRequest: '.remove-request'
        },

        events: {
            'click @ui.currentShareRequest': 'onClickShareRequest',
            'click @ui.acceptRequest': 'onClickAcceptRequest',
            'click @ui.removeRequest': 'onClickRemoveRequest'
        },

        onClickRemoveRequest: function () {
            this.triggerMethod('share-request:delete');
        },

        onClickAcceptRequest: function () {
            this.triggerMethod('catalogue:share:add');
        },

        onClickShareRequest: function () {
            App.navigate('catalogues/shared/add/' + this.model.get('sharedObject').secId, {trigger: true});
        },

        serializeData: function() {
            var tplData = this.model.toJSON();
            tplData.sharedObject.cAccountLogoUrl = Settings.url('file', tplData.sharedObject.cAccountLogo, {size: 'thumbnail'});
            return tplData;
        }
    });

    module.exports = ShareRequestView;
});
