
define('template!changeExpShippingDatePopupTpl', ['lodash'], function(_) {return function(obj) {
obj || (obj = {});
var __t, __p = '';
with (obj) {
__p += '<div class="form-group">\n    <label class="col-xs-3 control-label">' +
((__t = ( _.i18n("orders.expShippingDate") )) == null ? '' : __t) +
'</label>\n    <div class="col-xs-9">\n        <input type="datepicker" class="form-control date-expshippingdt js-info-input" data-field-name="expShippingDate" placeholder="' +
((__t = ( expShippingDate )) == null ? '' : __t) +
'" value="' +
((__t = ( expShippingDate )) == null ? '' : __t) +
'">\n        <span class="input-group-btn">\n              <span class="caret pull-right m-t-5"></span>\n          </span>\n    </div>\n</div>';

}
return __p
};});

