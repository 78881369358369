define( 'entities/phone/numbers',[
    'app',
    'jquery',
    'underscore',
    'backbone',
    'settings'
  ], function(
    App,
    $,
    _,
    Backbone,
    Settings
  ) {
    'use strict';
  
    /**
     * REST API
     */
    var API = {

        getCollectionUrl: function() {
            return Settings.url('compuzz', 'v2/phonenumbers/');
        },
  
        getRowCountUrl: function() {
            return 'v2/phonenumbers/rowCount';
        },

        getRentCollectionUrl: function() {
            return Settings.url('compuzz', 'v2/phonenumbers/rent/search?countryCode=fr');
        },

        getRentRowCountUrl: function() {
            return 'v2/phonenumbers/rent/search/rowCount?countryCode=fr';
        }
    };

    var PhoneNumberRent = Backbone.Model.extend({
        /*url: function() {
            return Settings.url('compuzz', 'products.json/product/' + this.get('secId'));
        },

        fetchByCode: function(code) {
            this.url = Settings.url('compuzz', 'products.json/' + code);
            return this.fetch();
        },*/

        idAttribute: 'secId'
    });

    var PhoneNumberRentCollection = Backbone.Collection.extend({
        fetch: function(params) {
            this.url = Settings.url('compuzz', '/v2/phonenumbers/rent/search', params);
            return Backbone.Collection.prototype.fetch.call(this);
        },

        getCount: function(params) {
            var that = this;

            that.trigger('count:request');
            return $.ajax({
                url: Settings.url('compuzz', '/v2/phonenumbers/rent/search/rowCount', params),
                success: function(count) {
                    that.trigger('count:sync', count);
                }
            });
        },

        model: PhoneNumberRent
    });
  
    /**
     * Request communications
     */
    App.reqres.setHandler('phone-numbers:row-count-url', function() {
      return API.getRowCountUrl();
    });
  
    App.reqres.setHandler('phone-numbers:get-collection-url', function() {
      return API.getCollectionUrl();
    });

    App.reqres.setHandler('phone-number:row-count-rent-url', function() {
        return API.getRentRowCountUrl();
    });

    App.reqres.setHandler('phone-number:get-collection-rent-url', function() {
        return API.getRentCollectionUrl();
    });

    App.reqres.setHandler('phone-number:collection-rent', function() {
        return new PhoneNumberRentCollection();
    });
  });
