
define('template!dynamicTextTpl', ['lodash'], function(_) {return function(obj) {
obj || (obj = {});
var __t, __p = '', __j = Array.prototype.join;
function print() { __p += __j.call(arguments, '') }
with (obj) {
__p += '<span>' +
((__t = ( currentValue )) == null ? '' : __t) +
'</span>\n';
 if (!isUseGlossary) { ;
__p += '\n    <i class="mdi mdi-editor-mode-edit btn-edit-dt clickable p-l-10" style="height: 20px; font-size: 18px;"></i>\n    <i class="mdi mdi-av-my-library-add btn-link-glossary clickable p-l-10" style="height: 20px; font-size: 18px;"></i>\n';
 } else { ;
__p += '\n    <i class="mdi mdi-av-my-library-books btn-edit-glossary clickable p-l-10" style="height: 20px; font-size: 18px; ' +
((__t = ( isUseParentGlossary ? 'color:red;' : '' )) == null ? '' : __t) +
'"></i>\n    ';
 if (!isUseParentGlossary) { ;
__p += ' \n        <i class="mdi mdi-content-remove-circle-outline btn-unlink-glossary clickable p-l-10" style="height: 20px; font-size: 18px;"></i>\n    ';
 } ;
__p += '\n';
 } ;


}
return __p
};});

