
define('template!customerLogosImagesExistPopupTpl', ['lodash'], function(_) {return function(obj) {
obj || (obj = {});
var __t, __p = '', __j = Array.prototype.join;
function print() { __p += __j.call(arguments, '') }
with (obj) {
__p += '<div>\n	<div class="container-fluid">\n		<div class="panel panel-primary" data-id="0">			\n			<div class="panel-body">\n				<div class="panel-content row">\n					';
 _.each(models, function (attachment) { ;
__p += '\n						<div class="img-container js-attachment m-b-10 m-r-5 " data-attachment-name="' +
((__t = ( attachment.fileName )) == null ? '' : __t) +
'" data-attachment-id="' +
((__t = ( attachment.get("secId") )) == null ? '' : __t) +
'">\n							<div class=\'original-img-wrapper\'>\n								<div class="main text-center">\n									<div class="image-texture-and-background">\n										<img class="image-holder" src="' +
((__t = ( attachment.get("fileLink") )) == null ? '' : __t) +
'">\n									</div>\n								</div>\n							</div>\n						</div>\n					';
 }); ;
__p += '\n				</div>\n			</div>\n		</div>\n	</div>\n</div>';

}
return __p
};});

