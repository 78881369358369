/**
 * Created by OLD on 20/08/2015.
 */
define('caccountAdminsAddView',[
  'module',
  'template!caccountAdminsAddTpl',
  'dialogFormView',
  'settings',
  'rolesMixin'
], function(
  module,
  viewTpl,
  DialogFormView,
  Settings,
  RolesMixin
) {
  'use strict';

  var CAccountsAdminsTabPopupView = DialogFormView.extend({
    template: viewTpl,

    ui: {
      input: '.js-popup-input'
    },

    events: {
      'change @ui.input': 'onInputChange',
      'focus @ui.input': 'onFocus'
    },

    serializeData: function() {
      var templateData = this.model.toJSON();
      templateData.vectorizationTeam = Settings.get('cAccountRoles')[RolesMixin.VECTORIZATOR];
      return templateData;
    }
  });

  module.exports = CAccountsAdminsTabPopupView;
});
