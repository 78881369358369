/**
 * Created by BFR on 25/05/2018.
 */
define('orderPriceTabController',[
  'module',
  'underscore',
  'app',
  'orderPriceTabView',
  'settings',
  'entities/orders'
], function(
  module,
  _,
  App,
  View
) {
  'use strict';

  var ProductPriceController = {
    showPrice: function (region, om) {
        this.model = om;
        this.view = new View({ model: om });
        this.view.listenTo(this.view, 'get-price-for-order', _.bind(this.onGetPriceForOrder, this));
        region.show(this.view);
    },

    onGetPriceForOrder: function(customPrice, forceUpdatePrice, customBuyingPrice, forceUpdateBuyingPrice) {
        if (!forceUpdatePrice) {
            forceUpdatePrice = false;
        }
        this.view.clearElements();
        this.view.ui.blockLoading.show();

        this.model.getPrice(true, false).done(_.bind(function(rsPrice) {
            this.model.getPriceCustom(customPrice, forceUpdatePrice).done(_.bind(function(rsCustom) {
                this.model.getPrice(true, true).done(_.bind(function(buyingPrice) {
                    this.model.getPriceBuyingCustom(customBuyingPrice, forceUpdateBuyingPrice).done(_.bind(function(price) {
                        this.view.refreshRsPrice(rsPrice);
                        this.view.refreshPrice(buyingPrice);
                        this.view.refreshSavedPrice(price);
                        this.view.refreshRsCustomPrice(rsCustom);
                        var productPricePlusShipping = Number(price.productPrice) + Number(price.shippingCost);
                        var rsProductPricePlusShipping = Number(rsCustom.productPrice) + Number(rsCustom.shippingCost);
                        var marg = {
                            totalNotPrintMarg: (Number(rsCustom.total) - productPricePlusShipping).toFixed(2),
                            unitNotPrintMarg: ((Number(rsCustom.total) - productPricePlusShipping) / Number(price.quantity)).toFixed(2),
                            totalTotalProductMarg: (rsProductPricePlusShipping - productPricePlusShipping).toFixed(2),
                            unitTotalProductMarg: ((rsProductPricePlusShipping - productPricePlusShipping) /
                                Number(price.quantity)).toFixed(2),
                            totalProductMarg: (Number(rsCustom.productPrice) - Number(price.productPrice)).toFixed(2),
                            unitProductMarg: ((Number(rsCustom.productPrice) - Number(price.productPrice)) /
                                Number(price.quantity)).toFixed(2),
                            totalShippingMarg: Number(rsCustom.shippingCost - price.shippingCost).toFixed(2),
                            unitShippingMarg: (Number(rsCustom.shippingCost - price.shippingCost) / Number(price.quantity)).toFixed(2),
                            totalUnitMarg: Number(rsCustom.totalUnitCost - price.totalUnitCost).toFixed(2),
                            unitUnitMarg: (Number(rsCustom.totalUnitCost - price.totalUnitCost) / Number(price.quantity)).toFixed(2),
                            totalSetupMarg: Number(rsCustom.totalSetupCost - price.totalSetupCost).toFixed(2),
                            unitSetupMarg: (Number(rsCustom.totalSetupCost - price.totalSetupCost) / Number(price.quantity)).toFixed(2),
                            totalMarg: Number(rsCustom.total - price.total).toFixed(2),
                            unitMarg: Number(rsCustom.totalUnit - price.totalUnit).toFixed(2)
                        };
                        this.view.refreshMargPrice(marg);
                        this.view.refreshScales(rsCustom, price);

                        App.trigger('showHidePriceWarning', Number(rsPrice.total), Number(rsCustom.total));
                    } ,this));
                } ,this));
            } ,this));
        } ,this));
    }
  };

  module.exports = ProductPriceController;
});
