
define('template!caccountContactsPopupTpl', ['lodash'], function(_) {return function(obj) {
obj || (obj = {});
var __t, __p = '';
with (obj) {
__p += '<div class="billingcodes-add-new-container">\n	<div class="container-fluid">\n		<div class="saving-label"></div>\n		<div class="error-label">' +
((__t = ( _.i18n('common.serverError') )) == null ? '' : __t) +
'</div>\n		<form class="form-horizontal col-xs-12" role="form">\n			<div class="form-group" data-field-name="email">\n				<label class="col-xs-3 overflow-hidden control-label">' +
((__t = ( _.i18n('common.email') )) == null ? '' : __t) +
':</label>\n				<div class="col-xs-9">\n					<input\n						class="form-control email js-form-input floating-label"\n						data-field-name="email"\n						placeholder="' +
((__t = ( _.i18n('common.email') )) == null ? '' : __t) +
'"\n						value="' +
((__t = ( email )) == null ? '' : __t) +
'">\n				</div>\n			</div>\n			<div class="form-group" data-field-name="name">\n				<label class="col-xs-3 overflow-hidden control-label">' +
((__t = ( _.i18n('common.name') )) == null ? '' : __t) +
':</label>\n				<div class="col-xs-9">\n					<input\n						class="form-control name js-form-input floating-label"\n						data-field-name="name"\n						placeholder="' +
((__t = ( _.i18n('common.name') )) == null ? '' : __t) +
'"\n						value="' +
((__t = ( name )) == null ? '' : __t) +
'">\n				</div>\n			</div>\n			<div class="form-group" data-field-name="firstName">\n				<label class="col-xs-3 overflow-hidden control-label">' +
((__t = ( _.i18n('common.first.name') )) == null ? '' : __t) +
':</label>\n				<div class="col-xs-9">\n					<input\n						class="form-control firstName js-form-input floating-label"\n						data-field-name="firstName"\n						placeholder="' +
((__t = ( _.i18n('common.first.name') )) == null ? '' : __t) +
'"\n						value="' +
((__t = ( firstName ? firstName : '' )) == null ? '' : __t) +
'">\n				</div>\n			</div>\n			<div class="form-group" data-field-name="lastName">\n				<label class="col-xs-3 overflow-hidden control-label">' +
((__t = ( _.i18n('common.last.name') )) == null ? '' : __t) +
':</label>\n				<div class="col-xs-9">\n					<input\n						class="form-control lastName js-form-input floating-label"\n						data-field-name="lastName"\n						placeholder="' +
((__t = ( _.i18n('common.last.name') )) == null ? '' : __t) +
'"\n						value="' +
((__t = ( lastName ? lastName : '' )) == null ? '' : __t) +
'">\n				</div>\n			</div>\n			<div class="form-group" data-field-name="addressL1">\n				<label class="col-xs-3 control-label overflow-hidden">' +
((__t = ( _.i18n('common.addressL1') )) == null ? '' : __t) +
'</label>\n				<div class="col-xs-9">\n					<input type="text" class="form-control floating-label js-form-input" data-field-name="addressL1" placeholder="' +
((__t = ( _.i18n('common.addressL1') )) == null ? '' : __t) +
'" value="' +
((__t = ( addressL1 ? addressL1 : '' )) == null ? '' : __t) +
'">\n				</div>\n			</div>\n			<div class="form-group" data-field-name="addressL2">\n				<label class="col-xs-3 control-label overflow-hidden">' +
((__t = ( _.i18n('common.addressL2') )) == null ? '' : __t) +
'</label>\n				<div class="col-xs-9">\n					<input type="text" class="form-control floating-label js-form-input" data-field-name="addressL2" placeholder="' +
((__t = ( _.i18n('common.addressL2') )) == null ? '' : __t) +
'" value="' +
((__t = ( addressL2 ? addressL2 : '' )) == null ? '' : __t) +
'">\n				</div>\n			</div>\n			<div class="form-group">\n				<label class="col-xs-3 control-label overflow-hidden">' +
((__t = ( _.i18n('common.countryCode') )) == null ? '' : __t) +
'</label>\n				<div class="col-xs-9">\n					<div class="js-select-countries-region"></div>\n				</div>\n			</div>\n			<div class="form-group">\n				<label class="col-xs-3 control-label overflow-hidden">' +
((__t = ( _.i18n('common.zip') )) == null ? '' : __t) +
' / ' +
((__t = ( _.i18n('common.city') )) == null ? '' : __t) +
'</label>\n				<div class="col-xs-9">\n					<div class="col-xs-6 p-l-0" data-field-name="zip">\n						<input type="text" class="form-control floating-label js-form-input" data-field-name="zip" placeholder="' +
((__t = ( _.i18n('common.zip') )) == null ? '' : __t) +
'" value="' +
((__t = ( zip ? zip : '' )) == null ? '' : __t) +
'">\n					</div>\n					<div class="col-xs-6 p-r-0" data-field-name="city">\n						<input type="text" class="form-control floating-label js-form-input" data-field-name="city" placeholder="' +
((__t = ( _.i18n('common.city') )) == null ? '' : __t) +
'" value="' +
((__t = ( city ? city : '' )) == null ? '' : __t) +
'">\n					</div>\n				</div>\n			</div>\n			<div class="form-group">\n				<label class="col-xs-3 control-label overflow-hidden">' +
((__t = ( _.i18n('customerContact.phone') )) == null ? '' : __t) +
' / ' +
((__t = ( _.i18n('common.fax') )) == null ? '' : __t) +
'</label>\n				<div class="col-xs-9">\n					<div class="col-xs-6 p-l-0" data-field-name="tel">\n						<input type="text" class="form-control floating-label js-form-input" data-field-name="tel" placeholder="phone number" \n						value="' +
((__t = ( tel ? tel : '' )) == null ? '' : __t) +
'">\n					</div>\n					<div class="col-xs-6 p-r-0" data-field-name="fax">\n						<input type="text" class="form-control floating-label js-form-input" data-field-name="fax" placeholder="' +
((__t = ( _.i18n('common.fax') )) == null ? '' : __t) +
'" value="' +
((__t = ( fax ? fax : '' )) == null ? '' : __t) +
'">\n					</div>\n				</div>\n			</div>		      \n			<div class="form-group" data-field-name="comment">\n				<label class="col-xs-3 control-label overflow-hidden">' +
((__t = ( _.i18n('customerContact.comment') )) == null ? '' : __t) +
':</label>\n				<div class="col-xs-9">\n					<textarea class="form-control floating-label js-form-input" rows="4" data-field-name="comment" placeholder="' +
((__t = ( _.i18n('customerContact.comment') )) == null ? '' : __t) +
'">\n						' +
((__t = ( comment ? comment : '' )) == null ? '' : __t) +
'\n					</textarea>\n				</div>\n			</div>\n		</form>\n	</div>\n</div>';

}
return __p
};});

