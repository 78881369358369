
define('template!timetrackerTpl', ['lodash'], function(_) {return function(obj) {
obj || (obj = {});
var __t, __p = '';
with (obj) {
__p += '<div class="content">\n  <div class="row filters-tt">\n  </div>\n  <div class="col-xs-12 calendar-tt">\n  </div>\n</div>';

}
return __p
};});

