
define('template!customerContactsTabTpl', ['lodash'], function(_) {return function(obj) {
obj || (obj = {});
var __t, __p = '';
with (obj) {
__p += '<div class="content">\n	<div class="well customer-contacts jq-grid-table-page">\n	    <div class="customer-contacts-table">\n			<div class="form-group add-new-block">\n				<a class="btn new-contacts-button add-btn-text js-add-new">\n					<i class="mdi-content-add"></i>\n					<span>' +
((__t = ( _.i18n('common.new') )) == null ? '' : __t) +
'</span>\n				</a>\n			</div>\n			<table id="jq-grid-list" class="contactsTable" role="grid"></table>\n			<div id="jq-grid-pager-list"></div>\n		</div>\n	</div>\n</div>';

}
return __p
};});

