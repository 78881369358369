
define('template!productGridPaginationTpl', ['lodash'], function(_) {return function(obj) {
obj || (obj = {});
var __t, __p = '';
with (obj) {
__p += '<div class="row md-grey-cl text-center">\n  <div class="pagination-control display-inline-block">\n    <span class="ui-icon ui-icon-seek-first js-first ' +
((__t = ( hasPrev ? 'pagination-active clickable' : '' )) == null ? '' : __t) +
'"></span>\n    <span class="ui-icon ui-icon-seek-prev js-prev ' +
((__t = ( hasPrev ? 'pagination-active clickable' : '' )) == null ? '' : __t) +
'"></span>\n\n    Page <span class="js-current-page dark-grey-cl">' +
((__t = ( currentPage )) == null ? '' : __t) +
'</span> of ' +
((__t = ( totalPages )) == null ? '' : __t) +
'\n\n    <span class="ui-icon ui-icon-seek-next js-next ' +
((__t = ( hasNext ? 'pagination-active clickable' : '' )) == null ? '' : __t) +
'"></span>\n    <span class="ui-icon ui-icon-seek-end js-last ' +
((__t = ( hasNext ? 'pagination-active clickable' : '' )) == null ? '' : __t) +
'"></span>\n  </div>\n  <div class="f-r p-r-15 pagination-view">View <span class="js-page-index">' +
((__t = ( first + 1 )) == null ? '' : __t) +
'</span> - <span class="js-page-last-index">' +
((__t = ( last )) == null ? '' : __t) +
'</span> of ' +
((__t = ( totalCount )) == null ? '' : __t) +
'</div>\n</div>';

}
return __p
};});

