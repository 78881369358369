/**
 * Created by BFR on 24/02/2020.
 */
define('dynamicTextLocalizedView',[
    'module',
    'marionette',
    'underscore',
    'template!dynamicTextLocalizedTpl'
  ], function(
    module, Marionette,
    _,
    viewTpl
  ) {
    'use strict';
  
    var DynamicTextLocalizedView = Marionette.ItemView.extend({
      template: viewTpl,
  
      region: {
      },
  
      ui: {
        
      },
  
      events: {
        'click .btn-revert-dt': 'onRevertClick',
        'change .js-info-input': 'onInputChange'
      },

      onRevertClick: function(event) {
        var input = $(event.target);
        this.model.get('dt').revertText(input.data('field-name'));
        this.refresh();
      },

      refresh: function() {
        this.model.set('value', this.model.get('dt').getTextInLocale(this.model.get('lang')));
        this.render();
      },

      onInputChange: function(event) {
        var input = $(event.target);
        this.model.get('dt').setTextInLocale(input.data('field-name'), input.val());
        this.refresh();
      },

      onShow: function () {
        
      },
  
      serializeData: function () {
        var templateData = this.options.model.toJSON();
        templateData.revertable = this.options.model.get('dt').isOverridden(this.options.model.get('lang'));
        return templateData;
      }
    });
  
    module.exports = DynamicTextLocalizedView;
  });
