
define('template!superAdminGraphicalRequestsTabTpl', ['lodash'], function(_) {return function(obj) {
obj || (obj = {});
var __t, __p = '';
with (obj) {
__p += '<div class="well container-fluid">\n  <div class="row">\n    <div class="col-xs-12">\n      <form id="mass-upload-dropzone" class="form-horizontal dropzone b-none">\n        <fieldset>\n    <!--  GRAPHICAL SERVICES TYPE -->\n          <div class="form-group">\n            <label class="col-md-2 control-label">' +
((__t = (_.i18n("superadmin.graphicalservice") )) == null ? '' : __t) +
'</label>\n            <div class="col-md-10">\n              <div class="radio radio-primary">\n                <label>\n                  <input type="radio" name="ticketType" id="optionsRadios1" value="vectorization" checked=""><span class="circle"></span><span class="check"></span>\n                  ' +
((__t = (_.i18n("vectorization.title") )) == null ? '' : __t) +
'\n                </label>\n              </div>\n              <div class="radio radio-primary">\n                <label>\n                  <input type="radio" name="ticketType" id="optionsRadios2" value="remove_background"><span class="circle"></span><span class="check"></span>\n                    ' +
((__t = (_.i18n("removebackground.title") )) == null ? '' : __t) +
'\n                </label>\n              </div>\n              <div class="radio radio-primary">\n                <label>\n                  <input type="radio" name="ticketType" id="optionsRadios2" value="conversion"><span class="circle"></span><span class="check"></span>\n                    ' +
((__t = (_.i18n("conversion.title") )) == null ? '' : __t) +
'\n                </label>\n              </div>\n            </div>\n          </div>\n          <!--  OUTPUT FILE -->\n          <div class="form-group">\n            <label class="col-md-2 control-label">' +
((__t = (_.i18n("files.output.format"))) == null ? '' : __t) +
' :</label>\n            <div class="col-md-10">\n              <div class="output-file-dropdown-region"></div>\n            </div>\n          </div>\n          <!--  REQUESTER -->\n          <div class="form-group label-floating">\n            <label class="col-md-2 control-label">' +
((__t = (_.i18n("vectorization.tickets.requester"))) == null ? '' : __t) +
' :</label>\n            <div class="col-md-10">\n              <input type="email" name="requester" class="form-control floating-label" required placeholder="Enter a valid email address">\n            </div>\n          </div>\n          <!--  BUTTONS UPLOAD-->\n          <div class="form-group">\n            <div class="col-md-10 col-md-offset-2">\n              <button type="button" id="add-files" class="btn btn-raised btn-success">' +
((__t = (_.i18n("superadmin.graphicalservice.addfiles") )) == null ? '' : __t) +
'<div class="ripple-container"></div></button>\n              <button type="button" id="submit" class="btn btn-primary">' +
((__t = (_.i18n("superadmin.graphicalservice.startupload") )) == null ? '' : __t) +
'<div class="ripple-container"></div></button>\n              <button type="button" id="cancel" class="btn btn-raised btn-warning">' +
((__t = (_.i18n("superadmin.graphicalservice.cancelupload") )) == null ? '' : __t) +
'<div class="ripple-container"></div></button>\n              <div id="total-progress" class="progress">\n                <div class="progress-bar" style="width: 0%;"></div>\n              </div>\n            </div>\n          </div>\n        </fieldset>\n      </form>\n    </div>\n  </div>\n</div>';

}
return __p
};});

