/**
 * Created by OLD on 09/09/2017.
 */
define('ordersKanbanCardsView',[
  'module',
  'underscore',
  'backbone',
  'marionette',
  'app',
  'jquery',
  'settings',
  'orderKanbanItemCardView',
  'template!ordersKanbanCardsTpl',
  'rolesMixin'
], function(
  module,
  _,
  Backbone,
  Marionette,
  App,
  $,
  Settings,
  ItenView,
  viewTpl,
  RolesMixin
) {
  'use strict';

  var OrdersKanbanCardsView =  Marionette.CompositeView.extend({
    template: viewTpl,
    childViewContainer: '.cards-container',
    childView: ItenView,

    events: {
      'change .showOnlyMyItems': 'onShowOnlyMyItemsChange'
    },

    collectionEvents: {
      'add': 'modelNumberChange',
      'remove': 'modelNumberChange'
    },

    modelNumberChange: function() {
      var counter = $('.counter[data-state="' + this.options.status + '"]');
      counter.html(this.collection.length);
      if (!Settings.get('cAccountRoles')[RolesMixin.DASHBOARD_ORDERS_DESIGN2]) {
        var counterMoney = $('.counter-money[data-state="' + this.options.status + '"]');
        var counterQty = $('.counter-qty[data-state="' + this.options.status + '"]');
        var total = 0;
        var totalPcs = 0;
        for (var i = 0 ; i < this.collection.length ; i++) {
            var m = this.collection.models[i];
            if (m.get('price')) {
                total += m.get('price');
            }
            if (m.get('quantity')) {
                totalPcs += m.get('quantity');
            }
        }
        counterMoney.html(total.toFixed(2) + ' €');
        counterQty.html(totalPcs + ' pcs');
      }
    },

    onShowOnlyMyItemsChange: function(event) {
      Settings.set('showOnlyMine' + this.options.status, event.target.checked, true);
      this.trigger('order-kanban:refresh:cards', this.options.status, event.target.checked);
    },

    initialize: function() {
      Settings.set('card-' + this.options.status, this.collection);
      this.users = this.options.users;
      this.ordersJson = this.collection.toJSON();
    },

    serializeData: function() {
      return {
        state: this.options.status,
        showOnlyMine: this.options.showOnlyMine
      };
    },

    childViewOptions: function() {
      return {
        orders: this.ordersJson,
        state: this.options.status,
        users: this.users
      };
    },

    onShow: function() {
      $.material.init();
      this.modelNumberChange();
    },

    onAttach: function() {
      this._initSortableBoard();
      this._calculateBoardHeight();
    },

    _initSortableBoard: function() {
      var that = this,
        startState, receivedState, cardId;

      $('.sortable-item, .cards').sortable({
        connectWith: '.sortable-item, .cards',
        placeholder: 'highlight',
        opacity: 0.8,
        cursor: 'move',
        start: function(event, ui) {
            var item = $($(ui.item).children()[0]);
            item.addClass('selected');

            $(ui.item.parent().find('.selected')).not(item).each(function() {
                $($(this).parent()).appendTo($(ui.item).find('.multi-select-container')[0]);
          });
        },
        over: function() {
          $(this).parent().find('.cards').addClass('columnHover');
        },
        out: function() {
          $(this).parent().find('.cards').removeClass('columnHover');
        },
        receive: function(event, ui) {
          startState = $(ui.sender).attr('data-state');
          receivedState = $(event.target).attr('data-state');
          cardId = $(ui.item).find('li').attr('data-id');
          that.trigger('order-kanban:update:card', cardId, receivedState);
          $($(ui.item).find('.multi-select-container')[0]).find('li').each(function() {
            that.trigger('order-kanban:update:card', $(this).attr('data-id'), receivedState);
          });
        },
        stop: function(event, ui) {
          $(ui.item.parent().find('.selected')).each(function() {
            $(this).removeClass('selected', {duration:700});
          });

          $(ui.item).find('.multi-select-container').children().each(function() {
          }).appendTo($(ui.item).parent()[0]);
        }
      });
    },

    _calculateBoardHeight: function() {
      var padding = 100; //add padding bottom

      this.boardHeight = $('#states-order-container').height();
      if (this.boardHeight < this.$el.parent().height()) {
        this.boardHeight = this.$el.parent().height();
      }
      $('.cards').css('height', this.boardHeight + padding + 'px');
      $('#states-order-container').css('height', this.boardHeight + padding + 'px');
    }
  });

  module.exports = OrdersKanbanCardsView;
});
