/**
 * Created by RKL on 21/08/2015.
 */
define('offersMainRouter',[
  'require',
  'exports',
  'module',
  'logger',
  'marionette',
  'app',
  'contentShowController',
  'itemPagesNameMixin'
], function(
  require,
  exports,
  module,
  Logger,
  Marionette,
  App,
  ContentShowController,
  ItemPagesNameMixin
) {
  'use strict';

  var OffersRouter = Marionette.AppRouter.extend({
    appRoutes: {
      'offers': 'showView'/*,
      'offers/(customers/:customerSecId/offers/):offerSecId': 'showOfferDefaultTab',
      'offers/(customers/:customerSecId/offers/):offerSecId/details': 'showOfferDetailsTab',
      'offers/(customers/:customerSecId/offers/):offerSecId/composition': 'showOfferCompositionTab',
      'offers/(customers/:customerSecId/offers/):offerSecId/price': 'showOfferPriceTab',
      'offers/(customers/:customerSecId/offers/):offerSecId/attachments': 'showOfferAttachmentsTab',

      'offers/(customers/:customerSecId/producttemplates/):offerSecId': 'showOfferDefaultTab',
      'offers/(customers/:customerSecId/producttemplates/):offerSecId/details': 'showOfferDetailsTab',
      'offers/(customers/:customerSecId/producttemplates/):offerSecId/composition': 'showOfferCompositionTab',

      'offers/customers/:customerSecId/offers/:offerSecId/:tab/produceProofsheet': 'showProduceProofsheet',
      'offers/customers/:customerSecId/offers/:offerSecId/produceProofsheet': 'showProduceProofsheet'*/
    }
  });

  var API = {
    showView: function() {
      require([
        'offersShowController'
      ], function(ShowController) {
        ContentShowController.showContent().done(function() {
          App.startSubModule('offersMain');
          ShowController.showOffers();
          App.execute('set:active:nav:item', ItemPagesNameMixin.OFFERS_LIST);
        });
      });
    }/*,

    showOfferDefaultTab: function(customerSecId, offerSecId) {
      API.showOfferDetailsTab(customerSecId, offerSecId);
    },

    showOfferDetailsTab: function(customerSecId, offerSecId) {
      ContentShowController.showContent().done(function() {
        require([
          'offerTabsLayoutController',
          'offerDetailsTabController'
        ], function(OfferTabsLayoutController, OfferDetailsTabController) {
          var region = App.regions.getRegion('main').currentView.getRegion('content');
          OfferTabsLayoutController.showTabsLayout(region, 'details', offerSecId).done(function(region, offerModel, customer) {
            OfferDetailsTabController.showDetailsTab(region, offerModel, customer);
            if(customerSecId) {
              App.execute('set:active:nav:item', ItemPagesNameMixin.MY_CLIENTS);
            } else {
              App.execute('set:active:nav:item', ItemPagesNameMixin.OFFERS_LIST);
            }
          });
        });
      });
    },

    showOfferCompositionTab: function(customerSecId, offerSecId) {
      ContentShowController.showContent().done(function() {
        require([
          'offerTabsLayoutController',
          'offerCompositionTabController'
        ], function(OfferTabsLayoutController, OfferCompositionTabController) {
          var region = App.regions.getRegion('main').currentView.getRegion('content');
          OfferTabsLayoutController.showTabsLayout(region, 'composition', offerSecId).done(function(region, offerModel) {
            OfferCompositionTabController.showCompositionTab(region, offerModel);
            if(customerSecId) {
              App.execute('set:active:nav:item', ItemPagesNameMixin.MY_CLIENTS);
            } else {
              App.execute('set:active:nav:item', ItemPagesNameMixin.OFFERS);
            }
          });
        });
      });
    },

    showOfferPriceTab: function(customerSecId, offerSecId) {
      ContentShowController.showContent().done(function() {
        require([
          'offerTabsLayoutController',
          'offerPriceTabController'
        ], function(OfferTabsLayoutController, OfferPriceTabController) {
          var region = App.regions.getRegion('main').currentView.getRegion('content');
          OfferTabsLayoutController.showTabsLayout(region, 'price', offerSecId).done(function(region, offerModel) {
            OfferPriceTabController.showPrice(region, offerModel);
            if(customerSecId) {
              App.execute('set:active:nav:item', ItemPagesNameMixin.MY_CLIENTS);
            } else {
              App.execute('set:active:nav:item', ItemPagesNameMixin.OFFERS);
            }
          });
        });
      });
    },

    showOfferAttachmentsTab: function(customerSecId, offerSecId) {
        ContentShowController.showContent().done(function() {
            require([
                'offerTabsLayoutController',
                'commandLineAttachsTabController'
            ], function(OfferTabsLayoutController, CommandLineAttachsTabController) {
                var region = App.regions.getRegion('main').currentView.getRegion('content');
                OfferTabsLayoutController.showTabsLayout(region, 'attachments', offerSecId).done(function(region, offerModel) {
                    CommandLineAttachsTabController.showTab(offerModel, region);
                    if(customerSecId) {
                        App.execute('set:active:nav:item', ItemPagesNameMixin.MY_CLIENTS);
                    } else {
                        App.execute('set:active:nav:item', ItemPagesNameMixin.OFFERS);
                    }
                });
            });
        });
    },

    showProduceProofsheet: function(customerSecId, offerSecId, tab) {
      ContentShowController.showContent().done(function() {
        require([
          'offerTabsLayoutController',
          'produceProofsheetController'
        ], function(OfferTabsLayoutController, ProduceProofsheetController) {
          var region = App.regions.getRegion('main').currentView.getRegion('content'),
            tabName = tab ? tab : 'details';
          OfferTabsLayoutController.showTabsLayout(region, tabName, offerSecId).done(function(region, offerModel) {
            ProduceProofsheetController.showProduceProofsheet(region, offerModel);
          });
        });
      });
    }*/
  };

  App.on('offers:show', function() {
    App.navigate('offers');
    API.showView();
  });

  App.on('before:start', function() {
    new OffersRouter({
      controller: API
    });
  });

  module.exports = OffersRouter;
});
