define('orderStatusCreateEditLayoutView',[
  'module',
  'marionette',
  'backbone',
  'jquery',
  'dialogFormView',
  'template!orderStatusCreateEditLayoutTpl',
  'savingBehavior',
  'bootbox',
  'underscore',
  'app',
  'settings'
], function(
  module,
  Marionette,
  Backbone,
  $,
  DialogFormView,
  createEditLayoutTpl,
  SavingBehavior,
  bootbox,
  _,
  App,
  Settings
) {
  'use strict';

  var CreateEditLayoutView = DialogFormView.extend({
    template: createEditLayoutTpl,

    ui: {
      input: '.js-info-input',
      cancel: '.js-cancel',
      confirm: '.js-confirm',
      displayInKanban: '#displayInKanban',
      validatedStatus: '#validatedStatus',
      commentStatus: '#commentStatus',
      assignUserToOrder: '#assignUserToOrder'
    },

    events: {
      'click .js-cancel-popup': 'hide',
      'focus @ui.input' : 'onFocus',
      'change @ui.input': 'onInputChange',
      'click .js-confirm': 'onConfirm',
      'change @ui.displayInKanban' : 'onChangeDisplayInKanban',
      'change @ui.validatedStatus' : 'onChangeValidatedStatus',
      'change @ui.commentStatus' : 'onChangeCommentStatus',
      'change @ui.assignUserToOrder' : 'onChangeAssignUserToOrder'
    },

    regions: {
    },

    className: 'orderstatus-create-edit',

    behaviors: {
      Saving: {
        behaviorClass: SavingBehavior
      }
    },

    modelEvents: {
      sync: 'onSave',
      error: 'onSaveError'
    },

    fieldsToValidate: [
      {name: 'name', type: 'required'},
      {name: 'code', type: 'required'}
    ],

    initialize: function(options) {
      this.model = options.orderStatusModel.clone();
    },

    serializeData: function() {
      var templateData = this.model.toJSON();
      templateData.newItem = this.options.newItem;
      return templateData;
    },

    onShow: function() {
      var displayInKanban = this.model.get('displayInKanban') || false;
      this.ui.displayInKanban.prop('checked', displayInKanban);

      var commentStatus = this.model.get('commentStatus') || false;
      this.ui.commentStatus.prop('checked', commentStatus);

      var validatedStatus = this.model.get('validatedStatus') || false;
      this.ui.validatedStatus.prop('checked', validatedStatus);

      var assignUserToOrder = this.model.get('assignUserToOrder') || false;
      this.ui.assignUserToOrder.prop('checked', assignUserToOrder);

      $.material.init();
    },

    onConfirm: function() {
      if(this.validate(this.fieldsToValidate)) {
        this.model.save();
      }
    },

    onChangeDisplayInKanban: function() {
      this.model.set('displayInKanban',this.ui.displayInKanban.is(':checked'));
      this.triggerMethod('enable:cancel:confirm');
    },

    onChangeValidatedStatus: function() {
      this.model.set('validatedStatus',this.ui.validatedStatus.is(':checked'));
      this.triggerMethod('enable:cancel:confirm');
    },

    onChangeCommentStatus: function() {
      this.model.set('commentStatus',this.ui.commentStatus.is(':checked'));
      this.triggerMethod('enable:cancel:confirm');
    },

    onChangeAssignUserToOrder: function() {
        this.model.set('assignUserToOrder',this.ui.assignUserToOrder.is(':checked'));
        this.triggerMethod('enable:cancel:confirm');
    },

    onSave: function(model) {
      var modelJSON = model.toJSON();

      this.options.orderStatusModel.set(modelJSON);
      this.triggerMethod('saved');
      App.trigger('orderstatus:header:rerender', modelJSON);
      App.navigate('caccount/' + Settings.get('currentCAccount').secId, {trigger: false});
      if(model.get('tag') !== null && model.get('tag') === 'offer') {
        App.navigate('caccount/' + Settings.get('currentCAccount').secId + '/offerstatus', {trigger: true});
      } else {
        App.navigate('caccount/' + Settings.get('currentCAccount').secId + '/orderstatus', {trigger: true});
      }
      this.hide();
    },

    onSaveError: function(model, response) {
      if(response.status === 409) {
        this.onDuplicateCode();
        this.triggerMethod('hide:label');
      }
    },

    onDuplicateCode: function() {
      this.ui.endUserIdInput.popover('show');
      this.ui.endUserIdInput.addClass('invalid');
      this.ui.endUserIdInput.closest('div[data-field-name=endUserId').addClass('has-error');
    }

  });

  module.exports = CreateEditLayoutView;
});

