define ('gmailUtils',[
    'module',
    'atmosphere',
    'settings',
    'underscore',
    'jquery',
    'logger',
    'marionette',
    'dateUtils'
], function(module, Atmosphere, Settings, _, $, Logger, Marionette, dateConverter) {
    'use strict';

    var instance = null;
    var CLIENT_ID = '1044157018081-qibpa2m59bintgd2j1gjk7ha6puogk2t.apps.googleusercontent.com';
    var API_KEY = 'AIzaSyC4NZnsWK8joARK8bVTNch-G7rQYJw_vC4';

    // Array of API discovery doc URLs for APIs used by the quickstart
    var DISCOVERY_DOCS = ['https://www.googleapis.com/discovery/v1/apis/gmail/v1/rest'];

    // Authorization scopes required by the API; multiple scopes can be
    // included, separated by spaces.
    var SCOPES = 'https://www.googleapis.com/auth/gmail.readonly';

    var GmailUtils = Marionette.Object.extend({
        connected: false,

        init: function() {
            window.gapi.load('client:auth2', _.bind(this.initClient, this));
        },

        initClient: function() {
            window.gapi.client.init({
                apiKey: API_KEY,
                clientId: CLIENT_ID,
                discoveryDocs: DISCOVERY_DOCS,
                scope: SCOPES
            }).then(_.bind(function () {
                // Listen for sign-in state changes.
                window.gapi.auth2.getAuthInstance().isSignedIn.listen(_.bind(this.updateSigninStatus, this));
                // Handle the initial sign-in state.
                this.updateSigninStatus(window.gapi.auth2.getAuthInstance().isSignedIn.get());
            }, this), _.bind(function() {
                //appendPre(JSON.stringify(error, null, 2));
            }, this));
        },

        updateSigninStatus: function(isSignedIn) {
            if (isSignedIn) {
                this.connected = true;
                instance.trigger('gmail_utils:loaded');
            } else {
                instance.trigger('gmail_utils:signInNeeded');
            }
        },

        signIn: function () {
            window.gapi.auth2.getAuthInstance().signIn();
        },

        getMessage: function (messageId, query) {
            var defer = $.Deferred();
            var request = window.gapi.client.gmail.users.messages.get({
                'userId': 'me',
                'id': messageId,
                'format': 'full'
            });
            request.execute(_.bind(function(m) {
                for (var i = 0 ; i < m.payload.headers.length ; i++) {
                    var cur = m.payload.headers[i];
                    switch (cur.name) {
                        case 'From': m.from = cur.value; break;
                        case 'To': m.to = cur.value; break;
                        case 'Subject': m.subject = cur.value; break;
                        case 'Date': m.date = cur.value; break;
                    }
                }
                if (m.from.indexOf(query) !== -1) {
                    m.direction = 'from';
                } else {
                    m.direction = 'to';
                }
                m.content = this.parseBody(m.payload, '');
                m.contentDoc = this.getBody(m.payload);
                m.time = dateConverter.toTimeFormatString(new Date(m.date));
                m.day = dateConverter.toDateFormatString(new Date(m.date));
                defer.resolve(m);
            }, this));

            return defer.promise();
        },

        parseBody: function(item, content) {
            if (item.body.data) {
                content += atob( item.body.data.replace(/-/g, '+').replace(/_/g, '/') );
            } else {
                if (item.parts) {
                    for (var i = 0 ; i < item.parts.length ; i++) {
                        content = this.parseBody(item.parts[i], content);
                    }
                }
            }
            return content;
        },

        listMessages: function(query, callback) {
            var getPageOfMessages = _.bind(function(request, result, completeResult) {
                request.execute(_.bind(function(resp) {
                    result = result.concat(resp.messages);
                    var nextPageToken = resp.nextPageToken;
                    if (nextPageToken) {
                        request = window.gapi.client.gmail.users.messages.list({
                            'userId': 'me',
                            'pageToken': nextPageToken,
                            'q': query
                        });
                        getPageOfMessages(request, result, completeResult);
                    } else {
                        var proms = [];
                        _.each(result, _.bind(function (r) {
                            if (r) {
                                proms.push(this.getMessage(r.id, query));
                            }
                        }, this));
                        Promise.all(proms).then(_.bind(callback, this));
                    }
                }, this));
            }, this);
            var initialRequest = window.gapi.client.gmail.users.messages.list({
                'userId': 'me',
                'q': query
            });
            getPageOfMessages(initialRequest, [], []);
        },

        getBody: function (message) {
            var encodedBody = '';
            if(typeof message.parts === 'undefined' && message.body)
            {
                encodedBody = message.body.data;
            }
            else if (message.parts)
            {
                encodedBody = this.getHTMLPart(message.parts);
            }
            encodedBody = encodedBody.replace(/-/g, '+').replace(/_/g, '/').replace(/\s/g, '');
            return decodeURIComponent(window.escape(window.atob(encodedBody)));
        },

        getHTMLPart: function (arr) {
            for(var x = 0; x <= arr.length; x++)
            {
                if(typeof arr[x].parts === 'undefined')
                {
                    if(arr[x].mimeType === 'text/html')
                    {
                        return arr[x].body.data;
                    }
                }
                else
                {
                    return this.getHTMLPart(arr[x].parts);
                }
            }
            return '';
        },

        connect: function() {
            if (this.connected) {
                instance.trigger('gmail_utils:loaded');
            }
        },

        sendMessage: function (to, subject, message, callback)
        {
            var email = '';

            var headersObj = {
                'To': to,
                'Subject': subject
            };

            for(var header in headersObj)
                email += header += ': '+headersObj[header]+'\r\n';

            email += '\r\n' + message;

            var sendRequest = window.gapi.client.gmail.users.messages.send({
                'userId': 'me',
                'resource': {
                    'raw': window.btoa(email).replace(/\+/g, '-').replace(/\//g, '_')
                }
            });

            return sendRequest.execute(callback);
        },

        getConnectedUser: function() {
            var user;
            try {
                user = window.gapi.auth2.getAuthInstance().currentUser.Ab.w3.U3;
            } catch (err) {
            }
            return user;
        },

        openNewEmailInGmail: function(mailTo, subject, body) {
            this.on('gmail_utils:loaded', _.once(_.bind(function() {
                var user = this.getConnectedUser();
                window.open('https://mail.google.com/mail/u/'+user+'/?view=cm&fs=1&to='+mailTo+'&tf=1&su='+
                    encodeURI(subject)+'&body=' + encodeURI(body));
            }, this)));
            this.connect();
        }
    });

    var getInstance = function() {
        if (!instance) {
            instance = new GmailUtils();
            instance.init();
        }
        return instance;
    };

    module.exports = GmailUtils;
    module.exports.getInstance = getInstance;
});

