
define('template!jqgridActionsButtonFormatterTpl', ['lodash'], function(_) {return function(obj) {
obj || (obj = {});
var __t, __p = '', __j = Array.prototype.join;
function print() { __p += __j.call(arguments, '') }
with (obj) {
__p += '<div class="dropdown jqgrid-actions-button js-dropdown">\n  <i class="mdi-navigation-more-vert clickable js-actions-button btn btn-fab btn-default actions-button-dropdown" data-toggle="dropdown"></i>\n  <ul class="dropdown-menu">\n    ';
 _.each(items, function(item) { ;
__p += '\n    <li class="js-action-item jqgrid-actions-li" data-event="' +
((__t = ( item.event )) == null ? '' : __t) +
'">\n      <div class="display-inline-block js-action-item" data-event="' +
((__t = ( item.event )) == null ? '' : __t) +
'">\n        <p class="vert-align-middle js-action-item ellipsis" data-event="' +
((__t = ( item.event )) == null ? '' : __t) +
'" title="' +
((__t = ( item.text )) == null ? '' : __t) +
'">\n          ';
 if(!dontShowIcons) { ;
__p += '\n            <i class="' +
((__t = ( item.icon )) == null ? '' : __t) +
' vert-align-middle js-action-item" data-event="' +
((__t = ( item.event )) == null ? '' : __t) +
'"></i>\n          ';
 } ;
__p += '\n          <span>' +
((__t = ( item.text )) == null ? '' : __t) +
'</span>\n        </p>\n      </div>\n    </li>\n    ';
 }) ;
__p += '\n  </ul>\n</div>';

}
return __p
};});

