/**
 * Created by RKL on 13/08/2015.
 */
define('caccountFontsTabController',[
  'module',
  'underscore',
  'app',
  'bootbox',
  'caccountFontsTabView',
  'entities/caccounts/fonts',
  'settings'
], function(
  module,
  _,
  App,
  bootbox,
  View
) {
  'use strict';

  var CAccountsFontsTabController = {
    showTab: function(data) {
      var region, view;

      region = data.type === 'caccount'?'caccountFontsTab':'tabContent';
      this.secId = data.type === 'customer' ? data.id : null;
      this.data = data;

      if (region === 'tabContent' ||
          !App.regions.getRegion('main').currentView.getRegion('content').currentView.getRegion(region).currentView) {
        view = new View();
        this.view = view;

        view.listenTo(view, 'onGridLoaded', _.bind(this.onGridLoaded, this));
        view.listenTo(view, 'onUploadFont', _.bind(this.onUploadFont, this));
        view.listenTo(view, 'onDeleteFont', _.bind(this.onDeleteFont, this));

        App.regions.getRegion('main').currentView.getRegion('content').currentView.getRegion(region).show(view);
      }
    },

    onGridLoaded:function() {
      var data = {
          url: App.request('fonts-tab:get-url', this.secId),
          rowCountUrl: App.request('fonts-tab:row-count-url', this.secId),
          type: this.data.type
        };
        
      this.view.displayGrid(data);
    },

    onUploadFont: function () {
      var that = this,
        files = this.view.ui.uploadFont[0].files,
        extension, addFontRequest, formdata;

      if (files && files.length) {
        formdata = new FormData();

        for (var i = 0; i < files.length; i++) {
          extension = files[i].name.split('.').pop().toLowerCase();

          if (extension !== 'ttf' && extension !== 'otf') {
            return;
          }
          formdata.append('file', files[i]);
          formdata.append('filename', files[i].name);
        }
        addFontRequest = App.request(
          'fonts-tab:add-font',
          formdata,
          this.secId
        );
        
        this.view.triggerMethod(
          'showMessage',
          'primary',
          _.i18n('common.loading')
        );
        $.when(addFontRequest)
          .done(function(xhr, error) {
            var message;

            if (xhr.conflicts && xhr.conflicts.length) {
              message = _.i18n('font.duplicated') + '<br>';
              _.each(xhr.conflicts, function(font) {
                message += font.fileName + '<br>';
              });
              bootbox.dialog({
                title: _.i18n('font.AlreadyExistAlertTitle'),
                className: 'fonts-alert',
                message: '<div>' + message + '</div>'
              });
            }
            if (xhr.errors && xhr.errors.length) {
              message = _.i18n('font.unableToImportThisFont') + '<br>';
              _.each(xhr.errors, function(font) {
                message += font.fileName + '<br>';
              });
              bootbox.dialog({
                title: _.i18n('common.request.error'),
                message: '<div>' + message + '</div>'
              });
            }
            that.view.updatePagination();

            if (error) {
              that.view.triggerMethod(
                'errorMessage',
                _.i18n('font.unableToImportThisFont')
              );
            } else {
              that.view.triggerMethod('hideMessage');
            }

            that.view.ui.uploadFont.val('');
          });
      }
    },

    onDeleteFont: function(fontId) {
      var that = this,
        deleteRequest;

      bootbox.confirm(_.i18n('warning.deleteFonts'), function (result) {
        if (result) {
          deleteRequest = App.request('fonts-tab:delete-font', fontId);
          $.when(deleteRequest).done(function() {
            that.view.updatePagination('reloadGrid');
          });
        }
      });
    }
  };

  module.exports = CAccountsFontsTabController;
});
