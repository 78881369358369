/**
 * Created by BFR on 06/12/2019.
 */
define('trackingDetailsController',[
  'module',
  'underscore',
  'app',
  'bootbox',
  'trackingDetailsView',
  'emptyView',
  'trackingDetailTabController',
  'rolesMixin',
  'settings',
  'trackingTabs',
  'entities/tracking'
], function(
  module,
  _,
  App,
  bootbox,
  View,
  EmptyView,
  trackingDetailTabController
) {
  'use strict';

  var TrackingDetailsController = {
    fetchTracking: function(data) {
      var that = this,
          emptyView = new EmptyView(),
          contentRegion = App.regions.getRegion('main').currentView.getRegion('content');

      contentRegion.show(emptyView);

      this.data = data;
      if (this.model && this.model.get('secId') === data.secId) {
        this.showTracking(data);
      } else {
        this.model = App.request('tracking-tab:new-model', {secId: data.secId});
        this.model.fetch().done(function() {
          if (contentRegion) {
            that.showTracking(data);
          }
        });
      }
    },

      showTracking: function(data) {
      var view = new View({model: this.model, data: data});

      this.view = view;
      data.model = this.model;

      App.regions.getRegion('main').currentView.getRegion('content').show(view);

      this.displayTabs(data);

      view.listenTo(view, 'click:tab', _.bind(this.onTabClick, this));
      view.listenTo(App, 'tracking:header:rerender', _.bind(this.onHeaderChange, this));
    },

    onHeaderChange: function (sample) {
      this.view.onHeaderChange(sample);
    },

    displayTabs: function(data) {
      for (var region in this.view.regions) {
        if (App.regions.getRegion('main') && App.regions.getRegion('main').currentView &&
            App.regions.getRegion('main').currentView.getRegion('content') &&
            App.regions.getRegion('main').currentView.getRegion('content').currentView &&
            App.regions.getRegion('main').currentView.getRegion('content').currentView.getRegion(region)) {
          var currentViewRegion = App.regions.getRegion('main').currentView.getRegion('content').currentView.getRegion(region).currentView;
          if(currentViewRegion) {
            currentViewRegion.destroy();
          }
        }
      }

      if (data.tab) {
        switch (data.tab.name) {
          default:
              trackingDetailTabController.showTab(data);
            break;
        }
      } else {
          trackingDetailTabController.showTab(data);
      }

      data.model = this.model;
      this.tab = data.tab;
    },

    onTabClick: function(tab) {
      if (this.data.tab !== tab || this.data.secId) {
        this.data.tab = tab;
        this.data.secId = null;

        this.displayTabs(this.data);
        App.navigate('tracking/' + this.model.get('secId') + '/' + tab.name);
      }
    }
  };

  module.exports = TrackingDetailsController;
});
