/**
 * Created by RKL on 13/08/2015.
 */
define('customerProfileView',[
  'module',
  'customerCreateEditLayoutView',
  'underscore',
  'marionette',
  'template!customerProfileTabTpl'
], function (
  module,
  CreateEditLayoutView,
  _,
  Marionette,
  viewTpl
) {
  'use strict';

  var CustomersInfoView = Marionette.ItemView.extend({
    template: viewTpl,

    modelEvents: {
      change: 'render'
    },

    events: {
      'click .js-edit': '_onEdit'
    },

    serializeData: function () {
      var templateData = this.model.toJSON(),
        secId = this.model.get('secId'),
        countryCode, country, countryCodeDelivery, countryDelivery;

      templateData.viewLess = secId;
      templateData.newItem = this.options.isNew;
      countryCode = this.model.get('countryCode');
      country = _.findWhere(this.options.countries, {code: countryCode});
      templateData.country = country ? country.name : null;

      countryCodeDelivery = this.model.get('refDeliveryAddress').countryCode;
      countryDelivery = _.findWhere(this.options.countries, {code: countryCodeDelivery});
      templateData.countryDelivery = countryDelivery ? countryDelivery.name : null;

      if (templateData.email) {
        var mail = templateData.email;
        templateData.email = '<a href="https://mail.google.com/mail/u/0/?view=cm&to='+mail+'" target="_blank">' +
            mail +'</a></br> '+_.i18n('orderDetail.history.tabTitle')+': ' +
            '<a href="https://mail.google.com/mail/#search/from%3A('+mail+')" target="_blank">' +
            _.i18n('common.received')+'</a>, ' +
            '<a href="https://mail.google.com/mail/#search/to%3A('+mail+')" target="_blank">' +
            _.i18n('common.sent')+'</a>';
      }

      return templateData;
    },

    _onEdit: function() {
      var createEditView = new CreateEditLayoutView({
        customerModel: this.model,
        newItem: false,
        countries: this.options.countries,
        priceLists: this.options.priceLists
      });
      createEditView.show({
        title: _.i18n('customers.edit')
      });
    }

  });

  module.exports = CustomersInfoView;
});
