/**
 * Created by Matteo on 11/07/2015.
 */
define('caccountShowController',[
  'module',
  'underscore',
  'app',
  'caccountShowView',
  'emptyView',
  'caccountDetailsTabController',
  'caccountAdminsTabController',
  'caccountCreditsTabController',
  'caccountInvoicesTabController',
  'caccountAttachsTabController',
  'caccountShareTabController',
  'caccountProfileTabController',
  'caccountOrderStatusTabController',
  'caccountOfferStatusTabController',
  'rolesMixin',
  'settings',
  'cAccountTabs',
  'entities/caccounts'
], function(
  module,
  _,
  App,
  View,
  EmptyView,
  caccountDetailsTabController,
  caccountAdminsTabController,
  caccountCreditsTabController,
  caccountInvoicesTabController,
  caccountAttachmentsTabController,
  caccountShareTabController,
  caccountProfileTabController,
  caccountOrderStatusTabController,
  caccountOfferStatusTabController,
  RolesMixin,
  Settings,
  CAccountTabs
) {
  'use strict';

  var CaccountDetailsController = {
    fetchCaccount: function(data) {
      var that = this,
        emptyView = new EmptyView(),
        contentRegion =  App.regions.getRegion('main').currentView.getRegion('content');

      contentRegion.show(emptyView);

      this.data = data;
      if (this.model && this.model.get('secId') === data.cAccountId) {
        data.model = this.model;
        this.showCAccount(data);
      } else {
        this.model = App.request('caccount:entity', data.cAccountId);
        data.model = this.model;
        if (data.cAccountId === 'new') {
          this.showCAccount(data);
        } else {
          this.model.fetch().done(function() {
            if (contentRegion) {
              that.showCAccount(data);
            }
          });
        }
      }
    },

    showCAccount: function(data) {
      var view = new View({model: this.model, data: data});

      this.view = view;
      App.regions.getRegion('main').currentView.getRegion('content').show(view);
      this.displayTabs(data);

      view.listenTo(view, 'click:tab', _.bind(this.onTabClick, this));
      view.listenTo(App, 'caccount:header:rerender', _.bind(this.onHeaderChange, this));
    },

    onHeaderChange: function (caccount) {
      this.model.set({
        name: caccount.name,
        code: caccount.code,
        phone: caccount.phone,
        contactEmail: caccount.contactEmail,
        logo: caccount.logo
      });
      this.view.onHeaderChange(caccount);
    },

    displayTabs: function(data) {
      var check = data.type === 'caccounts' || data.type === 'subcaccounts',
        roles = Settings.get('cAccountRoles' ),
      controllers = {};
      console.log('ok');
      controllers[ RolesMixin.DASHBOARD_ACCOUNT_ACCOUNT_PROFILE] = caccountProfileTabController;
      controllers[ RolesMixin.DASHBOARD_ACCOUNT_ACCOUNT_ACTIVITY] = caccountDetailsTabController;
      controllers[ RolesMixin.DASHBOARD_ACCOUNT_ACCOUNT_ADMINISTRATOR] = caccountAdminsTabController;
      controllers[ RolesMixin.DASHBOARD_ACCOUNT_ACCOUNT_CREDITS] = caccountCreditsTabController;
      controllers[ RolesMixin.DASHBOARD_ACCOUNT_ACCOUNT_INVOICES] = caccountInvoicesTabController;
      controllers[ RolesMixin.DASHBOARD_ACCOUNT_ACCOUNT_ATTACHMENTS] = caccountAttachmentsTabController;
      controllers[ RolesMixin.DASHBOARD_ACCOUNT_ACCOUNT_SHARE] = caccountShareTabController;
      controllers[ RolesMixin.DASHBOARD_ACCOUNT_ACCOUNT_COMMANDSTATUS] = caccountOrderStatusTabController;
      controllers[ RolesMixin.DASHBOARD_ACCOUNT_ACCOUNT_OFFERSTATUS] = caccountOfferStatusTabController;

      data.model = this.model;
      if (data.id === 'new') {
        caccountProfileTabController.showTab(data);
        return;
      }
      if (data.type === 'caccount' || data.type === 'subcaccounts') {
        if (data.tab) {
          controllers[data.tab.role].showTab(data);
        } else if (roles) {
            _.some(CAccountTabs.myAccountTabs, function (c, index) {
                data.tab = CAccountTabs.myAccountTabs[index];
                if (roles[data.tab.role] === true) {
                    controllers[data.tab.role].showTab(data);
                    return true;
                }
            });
        } else {
          caccountDetailsTabController.showTab(data);
        }
      } else if (check && roles.accountsharecreator) {
        caccountShareTabController.showTab(data);
      }

      this.tab = data.tab;
    },

    onTabClick: function(tab) {
      if (this.data.tab !== tab || this.data.secId) {
        this.data.tab = tab;
        this.data.secId = null;
        this.displayTabs(this.data);
        App.navigate('caccount/' + this.data.cAccountId + '/' + tab.route);
      }
    }
  };

  module.exports = CaccountDetailsController;
});

