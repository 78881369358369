define('productTabsLayoutView',[
  'module',
  'app',
  'marionette',
  'settings',
  'template!productTabsLayoutTpl',
  'backToListBehavior',
  'imageUtils',
  'jquery',
  'underscore',
  'productTabsData',
  'entities/product'
], function(
  module,
  App,
  Marionette,
  Settings,
  productTabsLayoutTpl,
  BackToListBehavior,
  imageUtils,
  $,
  _,
  ProductTabsData
) {
  'use strict';

  var ProductTabsLayoutView = Marionette.LayoutView.extend({
    template: productTabsLayoutTpl,
    tagName: 'div',

    ui: {
      logo: '.js-logo-holder',
      tabs: '.js-tab-layout-tab',
      tabsRegion: '.js-tabs-region',
      header: '.js-tab-layout-header'
    },

    regions: {
      tabs: '.js-tabs-region'
    },

    events: {
      'click .previous-product': 'onPreviousClick',
      'click .next-product': 'onNextClick'
    },

    behaviors: {
      BackToList: {
        behaviorClass: BackToListBehavior,
        redirectUrl: 'products'
      }
    },

    onPreviousClick: function() {
      var index = Settings.get('productListData').currentIndex;
      if (index > 0) {
        index -= 1;
      } else {
        index = Settings.get('productListData').rowCount - 1;
      }
      var requestRs = App.request('products:get-product', index, 
                                        Settings.get('productListData').filters);
      requestRs.done(_.bind(function(p) {
        Settings.get('productListData').currentIndex = index;
        this.destroy();
        App.navigate('product/' + p.secId + '/' + this.tab, {trigger: true});
      }, this));
    },

    onNextClick: function() {
      var index = Settings.get('productListData').currentIndex;
      if (index === (Settings.get('productListData').rowCount - 1)) {
        index = 0;
      } else {
        index += 1;
      }
      var requestRs = App.request('products:get-product', index, 
                              Settings.get('productListData').filters);
      requestRs.done(_.bind(function(p) {
        Settings.get('productListData').currentIndex = index;
        this.destroy();
        App.navigate('product/' + p.secId + '/' + this.tab, {trigger: true});
      }, this));
    },

    serializeData: function () {
      var templateData = this.model.toJSON();
      templateData.tabs = [];

      if (Settings.get('productListData')) {
        templateData.displayNextPrevious = true;
      } else {
        templateData.displayNextPrevious = false;
      }

      _.each(ProductTabsData, function(tab) {
        if (!tab.role || Settings.get('cAccountRoles')[tab.role]) {
          templateData.tabs.push(tab);
        } 
      });
      
      return templateData;
    },

    onShow: function() {
      var logo = imageUtils.getLogo(this.model.get('cataloguePictureUid'), this.model.get('code'));
      if(logo) {
        this.ui.logo.css('background-image', 'url(' + logo + ')');
      } else {
        this.ui.logo.addClass('default-upload-image');
      }
      this._setHeight();
      $(window).on('resize._setHeight', _.bind(this._setHeight, this));
    },

    onShowActive: function(tabName) {
      this.tab = tabName;
      this.ui.tabs.each(function(index, tab) {
        var $tab = $(tab);
        if($tab.hasClass('js-' + tabName)) {
          $tab.addClass('active');
        } else {
          $tab.removeClass('active');
        }
      });
    },

    onDestroy: function() {
      $(window).off('resize._setHeight');
    },

    _setHeight: _.throttle(function() {
      var windowHeight = $(window).height(),
        headerHeight = $('header').height() || 75,
        detailsHeaderHeight = this.ui.header.outerHeight(),
        height = windowHeight - headerHeight - detailsHeaderHeight - 40;

      this.ui.tabsRegion.height(height);
    }, 500)
  });

  module.exports = ProductTabsLayoutView;
});
