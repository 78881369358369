
define('template!optionMixEditCollectionTpl', ['lodash'], function(_) {return function(obj) {
obj || (obj = {});
var __t, __p = '', __j = Array.prototype.join;
function print() { __p += __j.call(arguments, '') }
with (obj) {
__p += '<thead>\n    <tr class="mix-line-0">\n        ';
 _.each(product.productOptionCategories, function(item) { ;
__p += '\n            ';
 if (item.mixable) { ;
__p += '\n                <th class="text-center ellipsed" title="' +
((__t = ( item.name )) == null ? '' : __t) +
'">' +
((__t = ( item.name )) == null ? '' : __t) +
'</th>\n            ';
 } ;
__p += '\n        ';
 }); ;
__p += '\n        <th class="text-center th-price ellipsed" title="' +
((__t = ( _.i18n("quantity") )) == null ? '' : __t) +
'">' +
((__t = ( _.i18n("quantity") )) == null ? '' : __t) +
'</th>\n    </tr>\n</thead>\n<tbody class="childViewOptions">\n</tbody>';

}
return __p
};});

