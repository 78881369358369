
define('template!catalogueMineSharingTabTpl', ['lodash'], function(_) {return function(obj) {
obj || (obj = {});
var __t, __p = '';
with (obj) {
__p += '<div class="content">\n  <div class="well jq-grid-table-page">\n    <h2>' +
((__t = ( _.i18n('catalogues.mine.sharing.title') )) == null ? '' : __t) +
'</h2>\n    <table id="jq-grid-list" role="grid"></table>\n    <div id="jq-grid-pager-list"></div>\n  </div>\n</div>';

}
return __p
};});

