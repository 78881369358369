
define('template!catalogueMineTabLayoutTpl', ['lodash'], function(_) {return function(obj) {
obj || (obj = {});
var __t, __p = '';
with (obj) {
__p += '<div class="with-header">\n  <div class="tab-layout-header">\n    <div class="tab-layout-info row">\n      <div class="logo-container col-left col-lg-2">\n        <!-- Logo -->\n        <div class="original-img-wrapper zoom-img">\n          <div class="main text-center">\n            <div class="logo-holder js-logo-holder"></div>\n          </div>\n        </div>\n      </div>\n      <div class="col-left name-holder js-name-holder m-t-5 col-lg-2">\n        <p class="p-name">' +
((__t = ( name )) == null ? '' : __t) +
'</p>\n\n        <p class="p-code">' +
((__t = ( code )) == null ? '' : __t) +
'</p>\n      </div>\n\n      <span class="back-to-list js-back-to-list"><i class="mdi-content-reply"></i>' +
((__t = ( _.i18n("common.back.to.list") )) == null ? '' : __t) +
'</span>\n    </div>\n    <div class="navbar navbar-default navbar-md row">\n      <div class="navbar-collapse collapse">\n        <ul class="p-l-10 nav navbar-nav">\n          <li class=" js-tab-title js-overview"><a href="#catalogues/mine/' +
((__t = ( secId )) == null ? '' : __t) +
'/overview">' +
((__t = ( _.i18n('menu.overview') )) == null ? '' : __t) +
'</a></li>\n          <li class=" js-tab-title js-products"><a href="#catalogues/mine/' +
((__t = ( secId )) == null ? '' : __t) +
'/products">' +
((__t = ( _.i18n('menu.products') )) == null ? '' : __t) +
'</a></li>\n          <li class=" js-tab-title js-sharing"><a href="#catalogues/mine/' +
((__t = ( secId )) == null ? '' : __t) +
'/sharing">' +
((__t = ( _.i18n('menu.sharing') )) == null ? '' : __t) +
'</a></li>\n        </ul>\n      </div>\n    </div>\n  </div>\n</div>\n<div class="tab-layout-content js-tabs-region height-100"></div>';

}
return __p
};});

