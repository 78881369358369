
define('template!translationOptionTpl', ['lodash'], function(_) {return function(obj) {
obj || (obj = {});
var __t, __p = '';
with (obj) {
__p += '<div class="col-xs-4 text-right">' +
((__t = ( code )) == null ? '' : __t) +
'</div>\n<div class="col-xs-8 name-region"></div>';

}
return __p
};});

