/**
 * Created by RKL on 10/09/2015.
 */
define('dialogFormView',[
  'module',
  'underscore',
  'dialogView',
  'formView',
  'dialogMixin'
], function(
  module,
  _,
  DialogView,
  FormView,
  DialogMixin
) {
  'use strict';

  var DialogFormView = FormView.extend(
    _.extend({}, DialogMixin)
  );

  module.exports = DialogFormView;
});
