/**
 * Created by OLD on 28/05/2019.
 */
define('offersKanbanView',[
  'module',
  'underscore',
  'backbone',
  'marionette',
  'app',
  'settings',
  'notificationCenter',
  'logotoolUtils',
  'template!offersKanbanViewTpl',
  'entities/offers_kanban'
], function (
  module,
  _,
  Backbone,
  Marionette,
  App,
  Settings,
  NotificationCenter,
  logotoolUtils,
  viewTpl
) {
    'use strict';

    var OffersKanbanView = Marionette.LayoutView.extend({
      template: viewTpl,

      triggers: {
        'click @ui.addNewOffer': 'addNewOffer' //Caught by controller
      },

      ui: {
        downloadPdf: '.offers-pdf-download',
        addNewOffer: '.new-offer-button'
      },

      regions: {
        kanbanFilters: '#kanbanoffer-filters-region',
        kanbanBoard: '#kanbanoffer-board-region'
      },

      onShow: function () {
        $.material.init();
        NotificationCenter.getInstance().addListener(
          this,
          NotificationCenter.events.CHANGE_INFO_ORDER,
          this.onMessage
        );
      },

      onMessage: function (response) {
        $.when(App.request('offer-kanban:new:model', response.content))
          .done(_.bind(function (model) {
            _.each(this.options.cardCollections, _.bind(function (item) {
              if (item.collection.get(model.get('poi'))) {
                item.collection.remove(model.get('poi'));
              }
              if (item.status === response.content.status) {
                item.collection.add(model);
              }
            }, this));
          }, this));
      }
    });

    module.exports = OffersKanbanView;
  });
