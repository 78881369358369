/**
 * Created by Matteo on 11/07/2015.
 */
define( 'selectCaccountListView',[
  'module',
  'underscore',
  'jquery',
  'app',
  'marionette',
  'autocompleteView',
  'settings',
  'template!selectCaccountListTpl',
  'moment',
  'tawlkToBehavior'
], function(
  module,
  _,
  $,
  App,
  Marionette,
  AutocompleteView,
  Settings,
  listTpl,
  moment,
  TawlkToBehavior
) {
  'use strict';

  var SelectCAccountListView = Marionette.LayoutView.extend( {
    template: listTpl,
    views: {},

    ui: {
      signin: '.js-sign-in',
      form: 'form.js-form'
    },

    events: {
      'keydown .autocomplete': 'onKeyPress',
      'focus .autocomplete': 'onFocus'
    },

    behaviors: {
      SmartSupp: {
        behaviorClass: TawlkToBehavior,
        visible: true
      }
    },

    regions: {
      selectAccounts: '.select-accounts'
    },

    triggers: {
      'click @ui.signin': 'onSignin'
    },

    serializeData: function() {
      return {
        year: moment().year()
      };
    },

    getAccountsAutocompleteOptions: function() {
      return {
        type: 'remote',
        remote: Settings.url('compuzz', 'sessions.json/token/byuser/caccounts', {env: Settings.compuzzEnv}),
        valueKey: 'code',
        apiKey: 'secId',
        placeholder: _.i18n('selectaccount.placeholder'),
        value: null,
        name: 'accounts',
        onPressKey: true,
        lazyLoad: true,
        keys: {
          query: 'caccountCode',
          limit: 'pageSize'
        },
        callback: _.bind(this.onSelect, this)
      };
    },

    onShow: function() {
      var accountsView = new AutocompleteView(
        this.getAccountsAutocompleteOptions()
      );

      //App.regions.addRegions(this.regions);
      this.getRegion('selectAccounts').show(accountsView);
      $.material.init();
    },

    onKeyPress: function(e) {
      var charCode = (e.which) ? e.which : e.keyCode;

      this.selectPressed = false;
      if (charCode === 13) {
        this.selectPressed = true;

        return false;
      }
    },

    onFocus: function() {
      this.ui.form.removeClass('has-error');
      this.$('.autocomplete').removeClass('invalid');
    },

    onError: function() {
      this.ui.form.addClass('has-error');
      this.$('.autocomplete').addClass('invalid');
    },

    onSelect: function(field, model) {
      this.selectedAccount = model;

      if (this.selectPressed) {
        this.trigger('onSignin');
      }
    }
  });

  module.exports = SelectCAccountListView;
});
