
define('template!customerLogosImagesCollectionTpl', ['lodash'], function(_) {return function(obj) {
obj || (obj = {});
var __t, __p = '';
with (obj) {
__p += '<div>\n	<div class="row templates-return js-return-button">\n		<div class="col-xs-12">\n			<i class="mdi-content-reply icon"></i>\n			<span>' +
((__t = ( _.i18n('common.return') )) == null ? '' : __t) +
'</span>\n		</div>\n		<div class="loading-block js-loading-block">\n			<span class="loading-message js-loading-message label"></span>\n		</div>\n	</div>\n\n	<div class="scroll-container scrollable">\n		<div class="elements-container row"></div>\n		<div class="lazy-load-block">\n			<!-- loader block -->\n			<div class="loader lazy-load-loader">\n			<svg class="circular">\n				<circle class="path" cx="50" cy="50" r="20" fill="none" stroke-width="2" stroke-miterlimit="10"/>\n			</svg>\n		  </div>\n		</div>\n	</div>\n</div>';

}
return __p
};});

