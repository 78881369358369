/**
 * Created by BFR on 09/07/2018.
 */
define('mailsListView',[
  'module',
  'underscore',
  'backbone',
  'jqgridView',
  'dateUtils',
  'urlUtil',
  'app',
  'settings',
  'template!mailsListTpl',
  'mailCreateLayoutView',
  'datePickerPopoverMixin'
], function(
  module,
  _,
  Backbone,
  JqGridView,
  dateConverter,
  UrlUtil,
  App,
  Settings,
  viewTpl,
  CreateLayoutView,
  datePickerPopoverMixin
) {
  'use strict';

  var MailsListView =  JqGridView.extend({
    template: viewTpl,
    filtersName: 'ordersFilters',

    regions: {
      mailDetail: '.mail-detail'
    },

    onShow: function() {
      $.material.init();
    },

    ui: function() {
      return _.extend({
        jqGrid: '#jq-grid-list',
        jqgRow: 'tr.jqgrow',
        jqGridPager: '#jq-grid-pager-list'
      }, this.gridUi);
    },

    events: function() {
      return _.extend({
          'click .js-add-new': 'onCreateNewMailClick'
      }, this.gridEvents);
    },

    serializeData: function () {
      var templateData = {
      };
      return templateData;
    },

    pagination: function() {
        return {
            first: 0,
            rowCount: 0,
            pageSize: 15,
            currentPage: 1
        };
    },

    onCreateNewMailClick: function() {
      var createView = new CreateLayoutView({});
        createView.show({
        title: _.i18n('mails.new')
      });
    },

    gridOptions: function(data) {
      var that = this,
        defaultsOptions = this.gridInitOptions(data),
        options = {
          colModel: [
            {
              label: '',
              name: 'secId',
              key: true,
              hidden: true
            },
            {
              label: _.i18n('common.date'),
              name: 'receivedDate',
              classes: 'mail-link',
              formatter: this.dateTimeFormatter,
              search: false,
              index: 'receivedDate',
              width: 90
            },
            {
              label: _.i18n('mails.subject'),
              name: 'subject',
              classes: 'mail-link',
              formatter: this.defaultFormatter,
              search: false,
              index: 'subject'
            },
            {
              label: _.i18n('mails.mailFrom'),
              name: 'fromDisplayName',
              classes: 'mail-link',
              formatter: this.defaultFormatter,
              search: false,
              index: 'fromDisplayName'
            }
          ],

          serializeGridData: function (postData) {
            if (postData.createDate) {
              postData.dateFrom = datePickerPopoverMixin.parseDateFrom(postData.createDate);
              postData.dateTo = datePickerPopoverMixin.parseDateTo(postData.createDate);
            } else if (!that.sorting) {
              delete postData.dateFrom;
              delete postData.dateTo;
            }
            that.sorting = false;
            defaultsOptions.serializeGridData.apply(this, arguments);
            return postData;
          },

          dateFormatter: function(cellvalue) {
            var date = dateConverter.toDateFormatString(cellvalue),
              time = dateConverter.toTimeFormatString(cellvalue);

            return '<span class="cell-date">' + date + ' ' + time + '</span>';
          },

          gridComplete: function() {
            that.preselectFirstRow();
            defaultsOptions.gridComplete.apply(this, arguments);
          },

          onSelectRow: _.bind(function(rowId, bool, e) {
            if (e && e.type === 'click') {
              this.trigger('mailslist:select', rowId);
            }
          }, this)
        };

      options = _.defaults(options, defaultsOptions);

      return options;
    },

    onPreselectRow: function(rowId) {
      this.ui.jqgRow.removeClass('grid-row-selected');
      this.ui.jqGrid.find('tr#' + rowId).addClass('grid-row-selected');
      this.trigger('mailslist:select', rowId);
    }
  });

  module.exports = MailsListView;
});
