
define('template!changeTypePopupTpl', ['lodash'], function(_) {return function(obj) {
obj || (obj = {});
var __t, __p = '';
with (obj) {
__p += '<div class="form-group order-type-dropdown-region"></div>';

}
return __p
};});

