
define('template!customerTemplatesGroupsItemTpl', ['lodash'], function(_) {return function(obj) {
obj || (obj = {});
var __t, __p = '';
with (obj) {
__p += '<div>\n	<div>\n		<div class="panel panel-default template-group-item ' +
((__t = ( active ? 'active' : '' )) == null ? '' : __t) +
'">\n			<div class="icon-holder">\n				<i class="mdi-file-folder icon">\n					<span class="templates-count text-in-icon">' +
((__t = ( count ? count : 0 )) == null ? '' : __t) +
'</span>\n				</i>\n				\n			</div>\n			<div class="template-group-name">\n				' +
((__t = ( name )) == null ? '' : __t) +
'\n			</div>\n			<div class="template-group-edit">\n				<i class="mdi-editor-mode-edit icon js-edit-icon"></i>\n				<i class="mdi-action-delete icon js-remove-icon"></i>\n			</div>\n		</div>\n	</div>\n</div>';

}
return __p
};});

