define('orderTabsLayoutController',[
  'module',
  'orderTabsLayoutView',
  'app',
  'settings',
  'jquery',
  'underscore',
  'tabsBaseController',
  'orderTabsData',
  'entities/orders',
  'entities/customers/customers'
], function(
  module,
  TabsLayoutView,
  App,
  Settings,
  $,
  _,
  TabBaseController,
  tabs
) {
  'use strict';

  var OrderTabsLayoutController = _.extend(TabBaseController,{
    model: App.request('order:model'),
    View: TabsLayoutView,
    tabs: tabs,

    cleanTabsWithRole: function(tabs) {
      var allowedTabs = [];

      _.each(tabs, function(tab) {
          if(!tab.role || Settings.get('cAccountRoles')[tab.role]) {
              allowedTabs.push(tab);
          }
      });

      return allowedTabs;
    },

    showTabsLayout: function (region, tabName, id) {
      this.tabs = this.cleanTabsWithRole(this.tabs);
      var defer = $.Deferred();
      this.model = App.request('order:model');
      if(this.model.getCode && this.model.getName) {
        this.model.set(this.model.idAttribute, id);
      } else {
        throw 'Model does not have methods getCode and getName. Methods are required for filling in code ' +
        'and name properties which will be shown on the header';
      }
      var refresh = false;

      if (App.getCurrentRoute().indexOf('?refresh')) {
          refresh = true;
          App.navigate(App.getCurrentRoute().split('?')[0], { trigger: false });
      }
      if(this._layoutView && !this._layoutView.isDestroyed && !refresh &&
          this.model.get(this.model.idAttribute) === this._layoutView.model.get(this._layoutView.model.idAttribute)) {
        defer.resolve(this._layoutView.getRegion('tabs'), this._layoutView.model, this._layoutView.options.customer);
        this._layoutView.triggerMethod('show:active', tabName);
        return defer.promise();
      }

      this.model.fetch().done(_.bind(function() {

        this.customer = App.request('customers:entity:end-user-id', this.model.get('endUserId'));
        if (this.model.get('endUserId')) {
            this.customer.fetch().done(_.bind(function(customer) {
                this.loadLayout(region, tabName, customer, defer);
            }, this));
        } else {
            this.loadLayout(region, tabName, this.customer, defer);
        }

      }, this));
      return defer.promise();
    },

    loadLayout: function(region, tabName, customer, defer) {
        var fetchStatuses = App.request('order:statuses', this.model.get('commandType') === 'offer');
        $.when(fetchStatuses).done(_.bind(function(statuses) {
            this.model.set({
                id: this.model.get(this.model.idAttribute),
                code: this.model.getCode()
            });
            this._layoutView = new TabsLayoutView({
                model: this.model,
                tabs: this.tabs,
                customer: customer,
                statuses: statuses
            });
            region.show(this._layoutView);
            this._layoutView.triggerMethod('show:active', tabName);
            var currentHeaderView = this._layoutView.getRegion('header' ).currentView;
            currentHeaderView.listenTo(currentHeaderView, 'duplicateOrder', _.bind(this.duplicateOrder, this));

            defer.resolve(this._layoutView.getRegion('tabs'), this._layoutView.model, customer);
        }, this));
    }  ,

    duplicateOrder: function() {
      this._layoutView.model.duplicate().done(_.bind(function(e){
        App.navigate('orders/' + e.value, {trigger: true});
      }, this));
    }
  });

  App.commands.setHandler('orders:status:change', function(){
    OrderTabsLayoutController._layoutView.getRegion('header' ).currentView.triggerMethod('orderStatusChange');
  });

  module.exports = OrderTabsLayoutController;
});
