define('entities/orders',[
  'app',
  'backbone',
  'settings',
  'jquery',
  'underscore',
  'entities/product'
], function(
  App,
  Backbone,
  Settings,
  $,
  _
) {
  'use strict';

  var Order = Backbone.Model.extend({

    defaults: {
      comments: [],
      agentId: '',
      proofsheetFileUid: '',
      exportFileUid: '',
      expShippingDate: null,
      nbrPackDelivered: 0,
      nbrPackSent: 0,
      toInvoice: false
    },

    fetch: function() {
      this.url = Settings.url('compuzz', 'commands.json/' + this.get('purchaseOrderId'));
      return Backbone.Model.prototype.fetch.call(this);
    },

    getCode: function() {
      return this.get('endUserId');
    },

    getName: function() {
      return this.get('purchaseOrderId');
    },

    getImageUid: function() {
      return this.get('snapshotsUids');
    },

    getAvailableStatuses: function() {
      var that = this,
        params = {
          locale: Settings.get('lang')
        };

      if (this.get('commandType') === 'offer') {
          params.tag = 'offer';
      }

      that.trigger('statuses:request');
      return $.ajax({
        url: Settings.url('compuzz', 'commands.json/command/status', params),
        success: function(resp) {
          that.trigger('statuses:sync', resp);
        }
      });
    },

    updateStatus: function() {
      return $.ajax({
        type: 'POST',
        url: Settings.url( 'compuzz', 'commands.json/' + this.get('purchaseOrderId') + '/status', {
          status:this.get('status')
        }),
        contentType: false,
        dataType: false
      });
    },

    updateType: function() {
        return $.ajax({
            type: 'POST',
            url: Settings.url( 'compuzz', 'commands.json/' + this.get('purchaseOrderId') + '/commandType', {
                type: this.get('commandType')
            }),
            contentType: false,
            dataType: false
        });
    },

    duplicate: function() {
      return $.ajax({
        type: 'POST',
        url: Settings.url( 'compuzz', 'commands.json/duplicate/' + this.get('purchaseOrderId'), null),
        contentType: 'application/json',
        dataType: false
      });
    },

    resetPrice: function() {
        var url = Settings.url('compuzz', 'commands.json/'+this.get('purchaseOrderId')+'/price/v2/reset');
        return $.ajax({
            method: 'POST',
            url: url
        });
    },

    resetBuyingPrice: function() {
      var url = Settings.url('compuzz', 'commands.json/'+this.get('purchaseOrderId')+'/price/v2/reset/buyingPrice');
      return $.ajax({
          method: 'POST',
          url: url
      });
    },

    getPriceBuyingCustom: function(customPrice, forceUpdatePrice) {
      var url = Settings.url('compuzz', 'commands.json/'+this.get('purchaseOrderId')+'/price/v2/custom',
          {
            forceUpdatePrice: forceUpdatePrice,
            buyingPrice: true
          });
      return $.ajax({
          method: 'POST',
          url: url,
          data: JSON.stringify(customPrice),
          contentType: 'application/json'
      });
    },

    getPriceCustom: function(customPrice, forceUpdatePrice) {
      var url = Settings.url('compuzz', 'commands.json/'+this.get('purchaseOrderId')+'/price/v2/custom',
          { forceUpdatePrice: forceUpdatePrice });
      return $.ajax({
          method: 'POST',
          url: url,
          data: JSON.stringify(customPrice),
          contentType: 'application/json'
      });
    },

    getPrice: function(realTime, buyingPrice) {
      var url = Settings.url('compuzz', 'commands.json/'+this.get('purchaseOrderId')+'/price/v2',
          {
              buyingPrice: buyingPrice,
              realTime:realTime
          });
      return $.ajax({
        method: 'POST',
        url: url,
        contentType: 'application/json'
      });
    },

    getImageUrls: function(product) {
      var that = this,
        imageUrls = [];

      _.each(product.get('productPictureSecIds'), function(productPictureSecId) {
        imageUrls.push(Settings.url('x2png', 'orders/' + that.get('purchaseOrderId') + '/products/' + that.get('productCode') +
          '/configurator', {
          productPictureSecId: productPictureSecId
        }));
      });
      that.trigger('image:urls:loaded', imageUrls);
    },

    changeQuantity: function(quantity) {
      var url = Settings.url('compuzz', 'commands.json/'+this.get('purchaseOrderId')+'/quantity', {
          quantity: quantity
      });
      return $.ajax({
          method: 'POST',
          url: url
      });
    },

    changeProduct: function(productSecId) {
      var url = Settings.url('compuzz', 'commands.json/'+this.get('purchaseOrderId')+'/product', {
          productSecId: productSecId
      });
      return $.ajax({
          method: 'PUT',
          url: url
      });
    },

    idAttribute: 'purchaseOrderId'
  });

  var OrderCollection = Backbone.Collection.extend({
    url: function() {
      return Settings.url('compuzz', '/commands.json');
    },
    model: Order
  });

  var API = {

    updateOrder: function(data) {
      var url = Settings.url('compuzz', 'commands.json/update/lite'),
      defer = $.Deferred();
      $.ajax({
        method: 'PUT',
        url: url,
        data: JSON.stringify(data),
        contentType: 'application/json',
        success: function(response) {
          defer.resolve(response);
        },
        complete: function(response) {
          defer.resolve(response);
        }
      });
      return defer.promise();
    },

    getOrderCollectionUrl: function(endUserId) {
      var params = {};

      if (endUserId) {
        params.endUserId = endUserId;
      }

      return Settings.url('compuzz', 'commands.json/advancedSearch', params);
    },

    getAllOrders: function(params) {
      var url = Settings.url('compuzz', 'commands.json/advancedSearch', params),
          defer = $.Deferred();

      $.ajax({
        method: 'get',
        url: url,
        success: function(orders) {
          var ordersList = [];
          _.each(orders, function(order){
            ordersList.push(order.purchaseOrderId);
          });
          defer.resolve(ordersList);
        }
      });

      return defer.promise();
    },

    getOrderExcelUrl: function(params, endUserId) {

      params = params || {};

      if (endUserId) {
        params.endUserId = endUserId;
      }

      return Settings.url('compuzz', 'commands.json/excel', params);
    },

    getOrderPdfUrl: function(params) {

      params = params || {};

      return Settings.url('pdf', '', params);
    },

    getStatuses: function(offer) {
      var result = offer?Settings.get('orderStatusesOffer'):Settings.get('orderStatuses'),
          defer = $.Deferred();

      if (result) {
          defer.resolve(result);
      } else {
          var statuses = new OrderCollection({}),
              params = {
                  locale: Settings.get('lang')
              };

          if (offer) {
              params.tag = 'offer';
          }

          statuses.url = Settings.url( 'compuzz', 'commands.json/command/status', params);
          statuses.fetch( {
              success: _.bind(function(data) {
                  if (offer) {
                      Settings.set('orderStatusesOffer', data);
                  } else {
                      Settings.set('orderStatuses', data);
                  }
                  defer.resolve(data);
              }, this)
          });
      }

      return defer.promise();
    },

    getTypes: function() {
      var defer = $.Deferred();

      defer.resolve([
          {name: 'command'},
          {name: 'offer'}
          /*command,
          corporateTemplate,
          history,
          autoSave,
          autoSaveCorpTemplate,
          notPersonalized,
          offer*/
      ]);

      return defer.promise();
    },

    uploadCustomExport: function(poi, fileInfo) {
      var defer = $.Deferred(),
          url = Settings.url('compuzz', 'commands.json/' + poi + '/customExport/upload');
      $.ajax({
          type: 'POST',
          url: url,
          data: fileInfo.formData,
          processData: false,
          contentType: false,
          complete: function(resp) {
              defer.resolve(resp);
          }
      });
      return defer.promise();
    },

    deleteCustomExport: function(poi) {
      var defer = $.Deferred(),
          url = Settings.url('compuzz', 'commands.json/' + poi + '/customExport');

      $.ajax({
          type: 'DELETE',
          url: url,
          complete: function() {
              defer.resolve();
          }
      });

      return defer.promise();
    },

    uploadCustomProofsheet: function(poi, fileInfo) {
      var defer = $.Deferred(),
          url = Settings.url('compuzz', 'commands.json/' + poi + '/customProofsheet/upload');
      $.ajax({
          type: 'POST',
          url: url,
          data: fileInfo.formData,
          processData: false,
          contentType: false,
          complete: function(resp) {
              defer.resolve(resp);
          }
      });

      return defer.promise();
    },

    deleteCustomProofsheet: function(poi) {
      var defer = $.Deferred(),
          url = Settings.url('compuzz', 'commands.json/' + poi + '/customProofsheet');

      $.ajax({
          type: 'DELETE',
          url: url,
          complete: function() {
              defer.resolve();
          }
      });

      return defer.promise();
    },

    updateUseBackgroundColor: function(poi, compSecId, useBackgroundColor) {
      var defer = $.Deferred(),
          url = Settings.url('compuzz', 'commands.json/' + poi + '/compositions/' + compSecId,
              {
                useBackgroundColor: useBackgroundColor
              });

      $.ajax({
          type: 'PUT',
          url: url,
          complete: function() {
              defer.resolve();
          }
      });

      return defer.promise();
    },

    getOrderCountUrl: function(endUserId) {
      var params = '';

      if (endUserId) {
        params += '?endUserId=' + endUserId;
      }
      return 'commands.json/advancedSearch/rowCount' + params;
    }
  };

  App.reqres.setHandler('order:update', function(orderModel) {
    return API.updateOrder(orderModel);
  });

  App.reqres.setHandler('order:get-collection-url', function(endUserId) {
      return API.getOrderCollectionUrl(endUserId);
  });

  App.reqres.setHandler('order:get-collection-to-page', function(params) {
      return API.getAllOrders(params);
  });

  App.reqres.setHandler('order:get-excel-url', function(params, endUserId) {
      return API.getOrderExcelUrl(params, endUserId);
  });

  App.reqres.setHandler('order:get-pdf-url', function(params) {
      return API.getOrderPdfUrl(params);
  });

  App.reqres.setHandler('order:get-count-url', function(endUserId) {
    return API.getOrderCountUrl(endUserId);
  });

  App.reqres.setHandler('order:statuses', function(formated) {
    return API.getStatuses(formated);
  });

  App.reqres.setHandler('order:types', function(formated) {
    return API.getTypes(formated);
  });

  App.reqres.setHandler('order:model', function(orderJSON) {
    return new Order(orderJSON);
  });

  App.reqres.setHandler('order:upload-custom-export', function(poi, fileinfo) {
    return API.uploadCustomExport(poi, fileinfo);
  });

  App.reqres.setHandler('order:delete-custom-export', function(poi) {
    return API.deleteCustomExport(poi);
  });

  App.reqres.setHandler('order:upload-custom-proofsheet', function(poi, fileinfo) {
    return API.uploadCustomProofsheet(poi, fileinfo);
  });

  App.reqres.setHandler('order:delete-custom-proofsheet', function(poi) {
    return API.deleteCustomProofsheet(poi);
  });

  App.reqres.setHandler('order:update-use-background-color', function(poi, compSecId, useBackgroundColor) {
    return API.updateUseBackgroundColor(poi, compSecId, useBackgroundColor);
  });
});
