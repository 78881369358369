define('env',['module', 'browserUtils'
], function (module, BrowserUtils) {
  'use strict';
  
  var Env = {
    config: {
      defaults: {
        awsWebSocket: 'wss://notification.compuzz.com/beta',
        awsWebSocketSend: 'https://wuk5zs5k5a.execute-api.eu-west-1.amazonaws.com/beta',
        filesPath: 'fileManagement/',
        serverUrl: 'http://localhost:8085/',
        defaultLogo: 'app/styles/css/images/compuzz_logo.png',
        compuzzLogo: 'app/styles/css/images/compuzz.svg',
        notFoundImage: 'app/styles/css/images/404.png',
        catalogueDefaultImage: 'app/styles/css/images/book.png',
        logoToolSubDomain: '',
        editOrderUrl: 'http://localhost:8082/',
        stripeApiKey: 'pk_test_Hob3JsQmZwmbDGoqVT2otTli'
      },
      development: {
        awsWebSocket: 'wss://notification.compuzz.com/beta',
        awsWebSocketSend: 'https://wuk5zs5k5a.execute-api.eu-west-1.amazonaws.com/beta',
        filesPath: 'fileManagement/',
        serverUrl: 'http://localhost:8085/',
        defaultLogo: 'app/styles/css/images/compuzz_logo.png',
        compuzzLogo: 'app/styles/css/images/compuzz.svg',
        notFoundImage: 'app/styles/css/images/404.png',
        catalogueDefaultImage: 'app/styles/css/images/book.png',
        logoToolSubDomain: '',
        editOrderUrl: 'http://localhost:8082/',
        stripeApiKey: 'pk_test_Hob3JsQmZwmbDGoqVT2otTli'
      },
      minified: {
        awsWebSocket: 'wss://notification.compuzz.com/beta',
        awsWebSocketSend: 'https://wuk5zs5k5a.execute-api.eu-west-1.amazonaws.com/beta',
        filesPath: 'fileManagement/',
        serverUrl: 'http://localhost:8085/',
        defaultLogo: 'images/compuzz_logo.png',
        compuzzLogo: 'images/compuzz.svg',
        notFoundImage: 'images/404.png',
        catalogueDefaultImage: 'images/book.png',
        logoToolSubDomain: '',
        editOrderUrl: 'http://localhost:8082/',
        stripeApiKey: 'pk_test_Hob3JsQmZwmbDGoqVT2otTli'
      },
      alpha: {
        awsWebSocket: 'wss://notification.compuzz.com/beta',
        awsWebSocketSend: 'https://wuk5zs5k5a.execute-api.eu-west-1.amazonaws.com/beta',
        filesPath: 'files/',
        serverUrl: 'https://alphadashboard.compuzz.com/',
        compuzzLogo: 'images/compuzz.svg',
        defaultLogo: 'images/compuzz_logo.png',
        notFoundImage: 'images/404.png',
        catalogueDefaultImage: 'images/book.png',
        logoToolSubDomain: '/alpha/',
        editOrderUrl: 'https://alpha.compuzz.com/htmlcompuzz/',
        stripeApiKey: 'pk_test_Hob3JsQmZwmbDGoqVT2otTli'
      },
      beta: {
        awsWebSocket: 'wss://notification.compuzz.com/beta',
        awsWebSocketSend: 'https://wuk5zs5k5a.execute-api.eu-west-1.amazonaws.com/beta',
        filesPath: 'files/',
        serverUrl: 'https://betadashboard.compuzz.com/',
        compuzzLogo: 'images/compuzz.svg',
        defaultLogo: 'images/compuzz_logo.png',
        notFoundImage: 'images/404.png',
        catalogueDefaultImage: 'images/book.png',
        logoToolSubDomain: '/beta/',
        editOrderUrl: 'https://beta.compuzz.com/htmlcompuzz/',
        stripeApiKey: 'pk_test_Hob3JsQmZwmbDGoqVT2otTli'
      },
      igobeta: {
        awsWebSocket: 'wss://notification.compuzz.com/beta',
        awsWebSocketSend: 'https://wuk5zs5k5a.execute-api.eu-west-1.amazonaws.com/beta',
        filesPath: 'files/',
        serverUrl: 'https://igobetadashboard.compuzz.com/',
        compuzzLogo: 'images/compuzz.svg',
        defaultLogo: 'images/compuzz_logo.png',
        notFoundImage: 'images/404.png',
        catalogueDefaultImage: 'images/book.png',
        logoToolSubDomain: '/beta/',
        editOrderUrl: 'https://beta.compuzz.com/htmlcompuzz/',
        stripeApiKey: 'pk_live_bnBxwNGoSIeHKKbUkwRS6obK',
        compuzzProProtocol: '',
        compuzzEnv: 'igo'
      },
      igo: {
        awsWebSocket: 'wss://notification.compuzz.com/prod',
        awsWebSocketSend: 'https://wuk5zs5k5a.execute-api.eu-west-1.amazonaws.com/prod',
        filesPath: 'files/',
        serverUrl: 'https://igodashboard.compuzz.com/',
        compuzzLogo: 'images/compuzz.svg',
        defaultLogo: 'images/compuzz_logo.png',
        notFoundImage: 'images/404.png',
        catalogueDefaultImage: 'images/book.png',
        logoToolSubDomain: '',
        editOrderUrl: 'https://igodashboard.compuzz.com/htmlcompuzz/',
        stripeApiKey: 'pk_live_bnBxwNGoSIeHKKbUkwRS6obK',
        compuzzProProtocol: 'cpzigo',
        compuzzEnv: 'igo'
      },
      adtv2: {
        awsWebSocket: 'wss://notification.compuzz.com/prod',
        awsWebSocketSend: 'https://wuk5zs5k5a.execute-api.eu-west-1.amazonaws.com/prod',
        filesPath: 'files/',
        serverUrl: 'https://dashboard.compuzz.com/',
        compuzzLogo: 'images/compuzz.svg',
        defaultLogo: 'images/compuzz_logo.png',
        notFoundImage: 'images/404.png',
        catalogueDefaultImage: 'images/book.png',
        logoToolSubDomain: '',
        editOrderUrl: 'https://optv2.compuzz.com/htmlcompuzz/',
        stripeApiKey: 'pk_live_bnBxwNGoSIeHKKbUkwRS6obK'
      },
      zaprinta: {
        awsWebSocket: 'wss://notification.compuzz.com/prod',
        awsWebSocketSend: 'https://wuk5zs5k5a.execute-api.eu-west-1.amazonaws.com/prod',
        filesPath: 'files/',
        serverUrl: 'https://zaprinta.compuzz.com/',
        compuzzLogo: 'images/compuzz.svg',
        defaultLogo: 'images/compuzz_logo.png',
        notFoundImage: 'images/404.png',
        catalogueDefaultImage: 'images/book.png',
        logoToolSubDomain: '',
        editOrderUrl: 'https://optv2.compuzz.com/htmlcompuzz/',
        stripeApiKey: 'pk_live_bnBxwNGoSIeHKKbUkwRS6obK',
        compuzzEnv: 'zaprinta'
      },
      lapierre: {
        awsWebSocket: 'wss://notification.compuzz.com/prod',
        awsWebSocketSend: 'https://wuk5zs5k5a.execute-api.eu-west-1.amazonaws.com/prod',
        filesPath: 'files/',
        serverUrl: 'https://lapierre.compuzz.com/',
        compuzzLogo: 'images/compuzz.svg',
        defaultLogo: 'images/compuzz_logo.png',
        notFoundImage: 'images/404.png',
        catalogueDefaultImage: 'images/book.png',
        logoToolSubDomain: '',
        editOrderUrl: 'https://optv2.compuzz.com/htmlcompuzz/',
        stripeApiKey: 'pk_live_bnBxwNGoSIeHKKbUkwRS6obK',
        compuzzEnv: 'lapierre'
      },
      prestigelogo: {
        awsWebSocket: 'wss://notification.compuzz.com/prod',
        awsWebSocketSend: 'https://wuk5zs5k5a.execute-api.eu-west-1.amazonaws.com/prod',
        filesPath: 'files/',
        serverUrl: 'https://prestigelogo.compuzz.com/',
        compuzzLogo: 'images/compuzz.svg',
        defaultLogo: 'images/compuzz_logo.png',
        notFoundImage: 'images/404.png',
        catalogueDefaultImage: 'images/book.png',
        logoToolSubDomain: '',
        editOrderUrl: 'https://optv2.compuzz.com/htmlcompuzz/',
        stripeApiKey: 'pk_live_bnBxwNGoSIeHKKbUkwRS6obK',
        compuzzEnv: 'prestigelogo'
      }
    },

    isProduction: function() {
      return this.getEnvironment() === 'production';
    },

    getEnvironment: function() {
      switch ( BrowserUtils.getHostName() ) {
        case 'localhost':
        case '127.0.0.1':
          return 'localhost';
        case 'alphadashboard.compuzz.com':
          return 'alpha';
        case 'betadashboard.compuzz.com':
          return 'beta';
        case 'dashboard.compuzz.com':
          return 'production';
      }
    }
  };
  module.exports = Env;
});

