define('orderTabsLayoutView',[
  'module',
  'tabsBaseView',
  'template!orderTabsHeaderTpl',
  'autocompleteView',
  'jquery',
  'app',
  'envHandler',
  'backToListBehavior',
  'marionette',
  'underscore',
  'backbone',
  'jqgridActionsButtonFormatterActions',
  'jqgridActionsButtonFormatterView',
  'logotoolUtils',
  'settings',
  'imageUtils',
  'compuzzProUtils',
  'rolesMixin',
  'entities/orders'
], function(
  module,
  TabsBaseView,
  headerTpl,
  AutocompleteView,
  $,
  App,
  EnvironmentHandler,
  BackToListBehavior,
  Marionette,
  _,
  Backbone,
  availableActions,
  ActionButtonsView,
  LogoToolUtils,
  Settings,
  imageUtils,
  CompuzzProUtils,
  RolesMixin
) {
  'use strict';

  var HeaderView = Marionette.LayoutView.extend({
    template: headerTpl,

    inputSelector: '.js-statuses-region input',

    events: {
      'change .js-upload-proofsheet': 'onUploadCustomProofsheet',
      'change .js-upload-export': 'onUploadCustomExport'
    },

    regions: {
      statuses: '.js-statuses-region',
      actions: '.js-action-buttons-region'
    },

    ui: {
      warningPrice: '.warning-price'
    },

    serializeData: function() {
        var templateData = this.model.toJSON();

        templateData.status = this.getStatusLabel();

        templateData.invoiceFileUidLink = this._getDownloadLink(this.model.get('invoiceFileUid'));

        return templateData;
    },

    getStatusLabel: function() {
      var status = this.options.statuses.find(_.bind(function(item){
          return item.get('statusConst') === this.model.get('status');
      }, this));

      if (!status && this.model.get('status')) {
          status =_.i18n('commandstatus.'+this.model.get('status').toLowerCase());
      } else if (status) {
          status = status.get('status');
      }
      return status;
    },

    _getDownloadLink: function (uid) {
        if (uid) {
            return Settings.url('file', uid, {cmds: 'on'});
        } else {
            return '';
        }
    },

    onUploadCustomProofsheet: function(e) {
      $('.loading-block').show();
      var target = e.target,
          fileInfo;

      if (e && e.target && e.target.files && e.target.files[0]) {
          fileInfo = imageUtils.getFileInfo(target, 0);

          if (fileInfo) {
            App.request('order:upload-custom-proofsheet', this.model.get('purchaseOrderId'), fileInfo).then(function() {
              CompuzzProUtils.getInstance().trigger('notificationCenter:compuzzProRefreshComposition');
              $('.loading-block').hide();
            });
          }
      }
    },

    onUploadCustomExport: function(e) {
      $('.loading-block').show();
      var target = e.target,
          fileInfo;

      if (e && e.target && e.target.files && e.target.files[0]) {
          fileInfo = imageUtils.getFileInfo(target, 0);

          if (fileInfo) {
              App.request('order:upload-custom-export', this.model.get('purchaseOrderId'), fileInfo).then(function() {
                  CompuzzProUtils.getInstance().trigger('notificationCenter:compuzzProRefreshComposition');
                  $('.loading-block').hide();
              });
          }
      }
    },

    behaviors: function() {
      var currentRouteSplitted = App.getCurrentRoute().split('/');
      if(_.indexOf(currentRouteSplitted, 'customers') !== -1) {
        currentRouteSplitted.shift();
        currentRouteSplitted.pop();
        this.backToListUrl = currentRouteSplitted.join('/');
      } else {
        if (this.options.model.get('commandType') === 'offer') {
            this.backToListUrl = 'offers';
        } else {
            this.backToListUrl = 'orders';
        }
      }
      return {
        BackToList: {
          behaviorClass: BackToListBehavior,
            redirectUrl: this.backToListUrl
        }
      };
    },

    initialize: function() {
      App.on('showHidePriceWarning', this.showHidePriceWarning, this);
    },

    showHidePriceWarning: function(price1, price2) {
      if (price1 !== null && price2 !== null) {
        if (price1 !== price2) {
          this.ui.warningPrice.show();
        }
        if (price1 > price2) {
          this.ui.warningPrice.css('color', 'red');
        } else {
          this.ui.warningPrice.css('color', 'black');
        }
      } else {
          this.ui.warningPrice.hide();
      }
    },

    destroy: function() {
      App.off('showHidePriceWarning');
    },

    onShow: function() {
      var actionsButtonView = this._getActionButtonsView();

      this.listenTo(actionsButtonView, 'action', this._onAction);

      this.getRegion('actions').show(actionsButtonView);

      if (Settings.get('cAccountRoles')[RolesMixin.DASHBOARD_ORDERS_PRICE]) {
        var savedPrice = this.model.get('savedPrice');
        if (savedPrice && savedPrice.total) {
            var requestRs = this.model.getPrice(true, false);
            $.when(requestRs).done(_.bind(function (rsPrice) {
                this.showHidePriceWarning(Number(rsPrice.total), Number(savedPrice.total));
            }, this));
        }
      }
    },

    onOrderStatusChange: function() {
      this.$el.find('#order-header-status').text(this.getStatusLabel());
    },

    _getActionButtonsView: function() {
      var actions = ['edit', 'export', 'productSheet', 'duplicate', 'uploadCustomProofsheet', 'uploadCustomExport'],
        actionsCollection = new Backbone.Collection();
      _.each(actions, function(action) {
        actionsCollection.push(availableActions[action]);
      });

      return new ActionButtonsView({
        collection: actionsCollection,
        dontShowIcons: true
      });
    },

    _onAction: function(action) {
      switch(action) {
        case 'edit':
          App.request('session:create-session-from-poi', {webServiceUrl: 'compuzz', returnUrl: location.href},
              this.model.get('purchaseOrderId')).done(_.bind(function(session) {
            window.location.href = EnvironmentHandler.getValue(Settings.get('environment'), 'editOrderUrl') +
                '#customization/oloSessionId/' +
                session.sessionId+'/productCode/' +
                this.model.get('productCode') +
                '/purchaseOrderId/' + this.model.get('purchaseOrderId');
          }, this));
          break;
        case 'export':
          var pdfUrl = Settings.url('pdf', '', {
            poi: this.model.get('purchaseOrderId'),
            docType: 'pzi'
          }, true);
          window.open(pdfUrl, '_self');
          break;
        case 'productSheet':
          var pdfRUrl = Settings.url('pdf', '', {
              poi: this.model.get('purchaseOrderId'),
              docType: 'prs'
          }, true);
          window.open(pdfRUrl, '_self');
          break;
        case 'duplicate':
          this.trigger('duplicateOrder');
          break;
        case 'uploadCustomProofsheet':
          this.$('#order-proofsheet-input' ).trigger('click');
          break;
        case 'uploadCustomExport':
          this.$('#order-export-input' ).trigger('click');
          break;
        default :
          throw 'Action is not supported';
      }
    }
  });

  var OrderTabsLayoutView = TabsBaseView.extend({

    events: function() {
        return _.extend({
            'click .p-code': 'onUserCodeClick'
        }, this.baseEvents);
    },

    onUserCodeClick: function() {
      App.navigate('customers/' + this.options.customer.secId, {trigger: true});
    },

    onShowTabsBase: function() {
      this.getRegion('header').show(new HeaderView({
        model: this.model,
        customer: this.options.customer,
        statuses: this.options.statuses
      }));
    },

    onNavigate: function(tabName) {
      var currentRoute = App.getCurrentRoute(),
        routeSplitted = currentRoute.split('/');

      if( _.indexOf(routeSplitted, 'details') !== -1 ||
          _.indexOf(routeSplitted, 'composition') !== -1 ||
          _.indexOf(routeSplitted, 'price') !== -1 ||
          _.indexOf(routeSplitted, 'attachments') !== -1 ||
          _.indexOf(routeSplitted, 'tracking') !== -1) {
        routeSplitted.pop();
        routeSplitted.push(tabName);
      } else {
        routeSplitted.push(tabName);
      }
      App.navigate(routeSplitted.join('/'), {trigger: true});
    }
  });

  module.exports = OrderTabsLayoutView;
});
