define('imageSliderView',[
  'module',
  'marionette',
  'template!imageSliderTpl'
], function(
  module,
  Marionette,
  imageSliderTpl
) {
  'use strict';

  var ImageSliderView = Marionette.ItemView.extend({
    template: imageSliderTpl,

    className: 'cpz-slider-with-thumb',

    ui: {
      slider: '.js-slider',
      sliderNav: '.js-slider-nav'
    },

    serializeData: function() {
      return {
        imageUrls: this.options.imageUrls
      };
    },

    onShow: function() {
      var topArrows = this.options.imageUrls.length < 3;
      this.ui.slider.slick({
        slidesToShow: 1,
        slidesToScroll: 1,
        arrows: topArrows,
        fade: true,
        asNavFor: '.js-slider-nav',prevArrow: '<i class="mdi-hardware-keyboard-arrow-left cpz-slick-prev"></i>',
        nextArrow: '<i class="mdi-hardware-keyboard-arrow-right cpz-slick-next"></i>'
      });
      this.ui.sliderNav.slick({
        slidesToShow: 3,
        slidesToScroll: 1,
        asNavFor: '.js-slider',
        dots: true,
        arrows: true,
        centerMode: true,
        focusOnSelect: true,
        prevArrow: '<i class="mdi-hardware-keyboard-arrow-left cpz-slick-prev"></i>',
        nextArrow: '<i class="mdi-hardware-keyboard-arrow-right cpz-slick-next"></i>'
      });
    },

    onBeforeDestroy: function(){
      this.ui.slider.slick('unslick');
    }
  });

  module.exports = ImageSliderView;
});
