/**
 * Created by RKL on 21/08/2015.
 */
define('offersShowView',[
  'module',
  'underscore',
  'backbone',
  'jqgridView',
  'dateUtils',
  'urlUtil',
  'app',
  'settings',
  'template!offersViewTpl',
  'logotoolUtils',
  'datePickerPopoverMixin',
  'jqgridActionsButtonFormatterActions',
  'jqgridActionsButtonFormatterView',
  'bootbox'
], function(
  module,
  _,
  Backbone,
  JqGridView,
  dateConverter,
  UrlUtil,
  App,
  Settings,
  viewTpl,
  logotoolUtils,
  datePickerPopoverMixin,
  availableActions,
  ActionButtonsView,
  bootbox
) {
  'use strict';

  var OffersView =  JqGridView.extend({
    template: viewTpl,
    filtersName: 'offersFilters',

    regions: {
      offersActions: '.js-action-buttons-region'
    },

    triggers: {
          'click @ui.addNewOffer': 'addNewOffer' //Caught by controller
    },

    onShow: function() {
      $.material.init();
      this.updateExportUrl({});
      this.createActionButtonView();
    },

    ui: function() {
      return _.extend({
        jqGrid: '#jq-grid-list',
        jqGridPager: '#jq-grid-pager-list',
        downloadPdf: '.offers-pdf-download',
          addNewOffer: '.new-offer-button',
        payedByCustomerCheckbox: '.js-ticket-payed-by-customer[type=checkbox]',
        payedToManufacturerCheckbox: '.js-ticket-payed-to-manufacturer[type=checkbox]'
      }, this.gridUi);
    },

    events: function() {
      return _.extend({
        'change @ui.payedByCustomerCheckbox': 'onPayedByCustomerCheckbox',
        'change @ui.payedToManufacturerCheckbox': 'onPayedToManufacturerCheckbox'
      }, this.gridEvents);
    },

    onPayedByCustomerCheckbox: function(e) {
      var that = this,
        $input = $(e.currentTarget),
        offerId = $input.data('offer-cid'),
        checked = $input.is(':checked');

        that.trigger('offer:update', {purchaseOrderId: offerId, payedByCustomer: checked });
    },

    onPayedToManufacturerCheckbox: function(e) {
      var that = this,
        $input = $(e.currentTarget),
        offerId = $input.data('offer-cid'),
        checked = $input.is(':checked');

        that.trigger('offer:update', {purchaseOrderId: offerId, payedToManufacturer: checked });
    },

    serializeData: function () {
      this.selectedOffers = Settings.get('selectedOffers') || [];
      var templateData = {
        'type': 'offers',
        displayCreateBtn: this.options.endUserSecId?true:false
      };
      return templateData;
    },

    gridOptions: function(data) {
      var that = this,
        defaultsOptions = this.gridInitOptions(data),
        options = {
          colModel: [
              {
                  label: ' ',
                  name: 'snapshotsUids',
                  align: 'left',
                  classes: 'offer-link image-cell',
                  formatter: this.pictureUidFormatter,
                  width: 80,
                  search: false
              },{
                  label: _.i18n('offerList.personalized'),
                  name: 'personalized',
                  classes: 'offer-link',
                  formatter: this.personalizedFormatter,
                  search: true,
                  index: 'personalized',
                  stype:'select',
                  searchrules: {select: true},
                  searchoptions: {
                      value: ':'+_.i18n('common.all')+';'+true+':'+_.i18n('common.yes')+';'+false+':'+_.i18n('common.no')
                  },
                  width: 100
              }, {
                label: _.i18n('offerList.payedByCustomer'),
                name: 'payedByCustomer',
                classes: 'offer-link cell-payedByCustomer',
                cellEdit : true,
                formatter: this.payedByCustomerFormatter,
                search: true,
                index: 'payedByCustomer',
                stype:'select',
                searchrules: {select: true},
                searchoptions: {
                  value: ':'+_.i18n('common.all')+';'+true+':'+_.i18n('common.yes')+';'+false+':'+_.i18n('common.no')
                },
                width: 100
              },  {
              label: _.i18n('offerList.payedToManufacturer'),
              name: 'payedToManufacturer',
              cellEdit : true,
              classes: 'offer-link cell-payedToManufacturer',
              formatter: this.payedToManufacturerFormatter,
              search: true,
              index: 'payedToManufacturer',
              stype:'select',
              searchrules: {select: ''},
              searchoptions: {
                value: ':'+_.i18n('common.all')+';'+true+':'+_.i18n('common.yes')+';'+false+':'+_.i18n('common.no')
              },
              width: 100
              },{
              label: _.i18n('offerList.offerNumber'),
              name: 'purchaseOrderId',
              classes: 'offer-link',
              formatter: this.defaultFormatter,
              search: true,
              index: 'purchaseOrderId',
              key: true
            },
            {
              label: _.i18n('offerList.reseller'),
              name: 'cAccountFirstReseller',
              classes: 'offer-link',
              formatter: this.defaultFormatter,
              search: true,
              index: 'cAccountFirstReseller'
            },
            {
              label: _.i18n('offerList.endUser'),
              name: 'endUserId',
              classes: 'offer-link',
              formatter: this.defaultFormatter,
              search: true,
              index: 'endUserId'
            }, {
              label: _.i18n('offerList.offerCreatDate'),
              name: 'createDate',
              classes: 'offer-link',
              formatter: this.dateFormatter,
              search: true,
              index: 'createDate',
              sorttype: 'date', 
              searchrules: {date: true},
              searchoptions: {
                dataInit: datePickerPopoverMixin.jqGridDataInit
              },
              width: 130
            }, {
              label: _.i18n('offerList.productOwner'),
              name: 'productOwner',
              classes: 'offer-link',
              formatter: this.defaultFormatter,
              search: true,
              index: 'productOwner'
            }, {
              label: _.i18n('offerList.productCode'),
              name: 'productCode',
              classes: 'offer-link',
              formatter: this.defaultFormatter,
              search: true,
              index: 'productCode'
            }, {
              label: _.i18n('offerList.productName'),
              name: 'productName',
              classes: 'offer-link',
              formatter: this.defaultFormatter,
              search: true,
              sortable: false,
              index: 'productName'
            }, {
              label: _.i18n('offerList.status'),
              name: 'status',
              classes: 'offer-link',
              search: true,
              index: 'status',
              formatter: this.commandTypeFormatter,
              stype: 'select',
              searchrules: {select: true},
              searchoptions: {
                value: data.statuses,
                defaultValue: that.selectDefault
              }
            }
          ],

          serializeGridData: function (postData) {
            if (postData.status === that.selectDefault) {
              delete postData.status;
            }

            if (postData.createDate) {
              postData.dateFrom = datePickerPopoverMixin.parseDateFrom(postData.createDate);
              postData.dateTo = datePickerPopoverMixin.parseDateTo(postData.createDate);
            } else if (!that.sorting) {
              delete postData.dateFrom;
              delete postData.dateTo;
            }
            that.sorting = false;
            defaultsOptions.serializeGridData.apply(this, arguments);


            return postData;
          },

          dateFormatter: function(cellvalue) {
            var date = dateConverter.toDateFormatString(cellvalue),
              time = dateConverter.toTimeFormatString(cellvalue);

            return '<span class="cell-date">' + date + ' ' + time + '</span>';
          },

          gridComplete: function() {
            var purchaseOrderId = UrlUtil.parseQueryString().purchaseOrderId;
            if(purchaseOrderId) {
              UrlUtil.clearQueryString();
              that.preselectFirstRow();
            }
            defaultsOptions.gridComplete.apply(this, arguments);
            that.checkOffersSelection();
          },

          beforeSelectRow: function(rowid, event) {
            // check if offer selection
            var grid = $(this);
            if (event.ctrlKey || event.altKey) {  // alt instead ctrl for mac os
              that.manageCtrlKey(grid, rowid);
            } else if (event.shiftKey) {
              that.manageShiftKey(grid, rowid);
            } else {
              return true;
            } 
            return false;
          },

          onSelectRow: function(rowId, bool, e) {
            var cellUpdated = that.$('.cell-payedToManufacturer, .cell-payedByCustomer');

            if (e && e.type === 'click' && cellUpdated.has(e.target).length === 0 &&
                (App.getCurrentRoute().lastIndexOf('offers') - App.getCurrentRoute().length) === -6) {
              var route = App.getCurrentRoute() ===  'offers' ? App.getCurrentRoute() : 'offers/' + App.getCurrentRoute();
              route = route.split('offers').join('orders');
              App.navigate(route + '/' + rowId, {trigger: true});
            }
          }
        };

      options = _.defaults(options, defaultsOptions);

      return options;
    },

    /*
     * offers selection with ctrl/shift keys
    */

    manageCtrlKey: function(grid, rowid) {
      var maxSelectedOffersNumber = 100,
          $row = grid.find('#' + rowid);
      if ($row.hasClass('selected')) {
        $row.removeClass('selected');
        this.onRowMultiselect(rowid, false);
      } else {
        if (this.selectedOffers.length === maxSelectedOffersNumber) {
          this.showWarningSelectedNumberOutOfRange();
        } else {
          $row.addClass('selected');
          this.onRowMultiselect(rowid, true);
        }
      }
    },

    manageShiftKey: function(grid, rowid) {
      var firstSelectedOffer = this.getFirstSelectedOffer(),
          lastSelectedRow = grid.find('#' + rowid),
          gridRows = grid.find('tr'),
          lastIndex = gridRows.index(lastSelectedRow),
          lastSelectedOffer = {
            rowid: rowid,
            page: this.pagination.currentPage,
            index: lastIndex
          };

      if (!firstSelectedOffer) firstSelectedOffer = lastSelectedOffer;
      if (firstSelectedOffer.page > lastSelectedOffer.page) {
        this.swapOffers(firstSelectedOffer, lastSelectedOffer);
      } else if (firstSelectedOffer.page === lastSelectedOffer.page){
        if (firstSelectedOffer.index > lastSelectedOffer.index) {
          this.swapOffers(firstSelectedOffer, lastSelectedOffer);
        }
      }
      if (this.checkIfOnlyCurrentPageSelection(firstSelectedOffer, lastSelectedOffer)) {
        this.selectWithoutRequest(firstSelectedOffer, lastSelectedOffer);
      } else {
        this.getCollectionToPage(firstSelectedOffer, lastSelectedOffer);
      }
    },

    checkIfOnlyCurrentPageSelection: function (first, last) {
      return first.page === last.page;
    },

    selectWithoutRequest: function (first, last) {
      var grid = this.ui.jqGrid,
          list = grid.find('tr');

      this.selectedOffers = [];
      list.each(_.bind(function(index, row){
        var $row = $(row),
            rowid = $row.attr('id');
        if (index >= first.index && 
            index <= last.index) {
          this.selectedOffers.push({
            rowid: rowid,
            page: this.pagination.currentPage,
            index: index
          });
        }
      }, this));
      this.checkOffersSelection();
    },

    swapOffers: function(first, last) {
      for (var key in last) {
        var temp = first[key];
        first[key] = last[key];
        last[key] = temp;
      }
    },

    getFirstSelectedOffer: function() {
      var firstSelected,
          lastPage = this.getLastPage(), 
          firstIndex = this.pagination.pageSize;
      if (this.selectedOffers.length === 0) {
        return null;
      } else {
        _.each(this.selectedOffers, function(offer){
           if (offer.page < lastPage) {
            lastPage = offer.page;
            firstIndex = offer.index;
            firstSelected = offer;
           } else if (offer.page === lastPage) {
            if (offer.index < firstIndex) {
              firstSelected = offer;
              firstIndex = offer.index;
            }
           }
        });
        return firstSelected;
      }
    },

    getLastPage: function() {
      var lastPage;
      if (this.selectedOffers.length > 0) {
        lastPage = _.max(this.selectedOffers, function(offer){ 
          return offer.page; 
        }).page;
      } else {
        lastPage = this.pagination.currentPage;
      }
      return lastPage;
    },

    getCollectionToPage: function (first, last) {
      var firstOfferIndex = (first.page - 1 ) * this.pagination.pageSize + (first.index - 1),
          lastOfferIndex = (last.page - 1 ) * this.pagination.pageSize + last.index,
          size = lastOfferIndex - firstOfferIndex || 1,
          params = {
            pageSize: size,
            first: firstOfferIndex,
            _search: false,
            nd: new Date().getTime(),
            rows: size,
            page: 1,
            sord: 'asc'
          };
      if (size > 100) {
        this.showWarningSelectedNumberOutOfRange();
      } else {
        $('#content-blinder').show();
        this.trigger('onLoadSelectedOffers', first, last, params);
      }
    },

    showWarningSelectedNumberOutOfRange: function() {
      bootbox.alert(_.i18n('common.errorOccuredSelectedItemsNumberOutOfRange'), function () {
        return;
      });
    },

    onSelectOffers: function(first, last, offers) {
      var pageSize = this.pagination.pageSize,
          page, offerIndex;

      this.selectedOffers = [];
      _.each(offers, _.bind(function(offer, index) {
        page = Math.floor((index + first.index - 1) / pageSize);
        offerIndex = index + first.index - page * pageSize;
        this.selectedOffers.push({
          rowid: offer,
          page: page + first.page,
          index: offerIndex
        });
      }, this));
      this.checkOffersSelection();
    },

    onRowMultiselect: function(rowid, select) {
      var index,
          grid = this.ui.jqGrid,
          gridList = grid.find('tr'),
          row = grid.find('#' + rowid),
          offer = {
            rowid: rowid,
            page: this.pagination.currentPage,
            index: gridList.index(row)
          };
      if (select) {
        this.selectedOffers.push(offer);
      } else {
        index = this.selectedOffers.indexOf(_.findWhere(this.selectedOffers, {rowid: rowid}));
        this.selectedOffers.splice(index, 1);
      }
      this.onSelectedOffersChanged();
    },

    createActionButtonView: function() {
      this.actionsButtonView = this._getActionButtonsView();
      this.listenTo(this.actionsButtonView, 'action', this._onAction);
      this.getRegion('offersActions').show(this.actionsButtonView);
      this.actionsButtonView.setButtonsState({
        'proofsheet': false,
        'export': false
      });
    },

    _getActionButtonsView: function() {
      var actions = ['proofsheet', 'export'],
        actionsCollection = new Backbone.Collection();
      _.each(actions, function(action) {
        actionsCollection.push(availableActions[action]);
      });

      return new ActionButtonsView({
        collection: actionsCollection,
        dontShowIcons: true
      });
    },

     _onAction: function(action) {
      switch(action) {
        case 'proofsheet':
          this.downloadProofsheets();
          break;
        case 'export':
          this.exportOffers();
          break;
        default :
          throw 'Action is not supported';
      }
    },

    checkOffersSelection: function() {
      var $list = this.ui.jqGrid.find('tr');
      $list.each(_.bind(function (index, item) {
        var $item = $(item);
        if (_.some(this.selectedOffers, {rowid: $item.attr('id')})) {
          $item.addClass('selected');
        } else {
          $item.removeClass('selected');
        }
      }, this));
      this.onSelectedOffersChanged();
    },

    onSelectedOffersChanged: function() {
      if (this.selectedOffers.length > 0) {
        this.actionsButtonView.setButtonsState({
          'proofsheet': true,
          'export': true
        });
      } else {
        this.actionsButtonView.setButtonsState({
          'proofsheet': false,
          'export': false
        });
      }
      Settings.set('selectedOffers', this.selectedOffers);
    },

    downloadProofsheets: function() {
      this.downloadPdf('prf');
    },

    exportOffers: function() {
      this.downloadPdf('pzi');
    },

    downloadPdf: function(docType) {
        var offers = [];
        _.each(this.selectedOffers, function(offer){
          offers.push(offer.rowid);
        });
        window.open(App.request('offer:get-pdf-url', {
            docType: docType,
            poi: offers
        }));
    },

      personalizedFormatter: function(cellvalue, options, rowObj) {
          var checked = rowObj.personalized ? 'checked' : '',
              htmlEl = '<div class="m-l-15">' +
                  '<input disabled="disabled" type="checkbox" data-ticket-cid="' + options.rowId +
                  '" class="cpz-checkbox js-ticket-urgent" ' + checked + ' ' +
                  '></div>';
          return htmlEl;
      },

      payedByCustomerFormatter: function(cellValue, options, rowObj) {
          var checked = rowObj.payedByCustomer ? 'checked' : '',
              htmlEl = '<div class="m-l-15">' +
                  '<input  type="checkbox" data-offer-cid="' + options.rowId +
                  '" class="cpz-checkbox js-ticket-payed-by-customer" ' + checked + ' ' +
                  '></div>';
          return htmlEl;
      },

      payedToManufacturerFormatter: function(cellValue, options, rowObj) {
          var checked = rowObj.payedToManufacturer ? 'checked' : '',
              htmlEl = '<div class="m-l-15">' +
                  '<input type="checkbox" data-offer-cid="' + options.rowId +
                  '" class="cpz-checkbox js-ticket-payed-to-manufacturer" ' + checked + ' ' +
                  '></div>';
          return htmlEl;
      },

    commandTypeFormatter:function(cellValue, options) {
      if (cellValue === null || cellValue === undefined) { cellValue = ''; }

      var statuses = [], statusValue;

      if (options.colModel.searchoptions.value) {
        statuses = options.colModel.searchoptions.value.split(';');
      }

      _.each(statuses, function(status) {
        var st = status.split(':');
        if (st.length === 2) {
          if (st[0] === cellValue) {
            statusValue = st[1];
          }
        }
      });

      if (!statusValue) {
        statusValue = _.i18n('commandstatus.'+cellValue.toLowerCase());
      }

      return '<span class="cell-default" data-row-id="' +
          options.rowId + '">' + statusValue + '</span>';
    },
    updateExportUrl: function(data){
        this.$el.find('.offers-export').attr('href',  App.request('offer:get-excel-url', data));
    },
    onGridFilterChange: function() {
      var that = this,
        filters = {},
        value;

      _.each(this.colModel, function(column) {
        if (column.search) {
          if (column.stype === 'select') {
            value = that.$('select[name="' + column.index + '"]').val();
            filters[column.index] = value === that.selectDefault ? '' : value;
          } else if (column.sorttype === 'date') {
            value = that.$('input[name="' + column.index + '"]').val();
            if (column.index === 'createDate' && value) {
              filters.dateFrom = datePickerPopoverMixin.parseDateFrom(value);
              filters.dateTo = datePickerPopoverMixin.parseDateTo(value);
            }
          } else {
            value = that.$('input[name="' + column.index + '"]').val();
            filters[column.index] = value;
          }
        }
      });

      this.filters = filters;
      this.filterChange = true;
      this.updatePagination();
      this.updateExportUrl(filters);
    }
  });

  module.exports = OffersView;
});
