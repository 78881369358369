define('orderTabsData',[
  'module',
  'underscore',
  'rolesMixin'
], function(
  module,
  _,
  RolesMixin
) {
  'use strict';

  module.exports = [
    {
      name: 'details',
      title: _.i18n('orders.details.tabTitle')
    }, {
      name: 'price',
      title: _.i18n('orders.price.tabTitle'),
      role: RolesMixin.DASHBOARD_ORDERS_PRICE
    }, {
      name: 'composition',
      title: _.i18n('orders.composition.tabTitle')
    }, {
      name: 'attachments',
      title: _.i18n('orders.attachments.tabTitle')
    }, {
      name: 'tracking',
      title: _.i18n('orders.tracking.tabTitle'),
      role: RolesMixin.DASHBOARD_ORDERS_TRACKING
    }
  ];
});
