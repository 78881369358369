/**
 * Created by OLD on 28/05/2019.
 */
define('offerKanbanItemCardView',[
  'module',
  'underscore',
  'marionette',
  'app',
  'settings',
  'moment',
  'imageUtils',
  'template!offersKanbanItemCardTpl',
  'jquery',
  'graphicalRequestKanbanAssignPopupView',
  'rolesMixin',
  'orderUtils'
], function (
  module,
  _,
  Marionette,
  App,
  Settings,
  moment,
  imageUtils,
  viewTpl,
  $,
  PopupView,
  RolesMixin,
  OrderUtils
) {
    'use strict';

    var ItemCardView = Marionette.ItemView.extend({
      template: viewTpl,

      events: {
        'dblclick @ui.card': '_redirectToCardDetails',
        'click @ui.assignTo': '_onAssignTo',
        'click .enduserid-btn': '_onEndUserClick',
        'click @ui.selector': 'onSelectClick',
        'mouseover .js-card-item': 'onMouseOver'
      },

      ui: {
        card: '.js-card-item',
        assignTo: '.js-assign-to',
        selector: '.item-selector-circle',
        selectableItem: '.card-item'
      },

      onSelectClick: function (e) {
        e.preventDefault();
        e.stopPropagation();

        var $template = this.ui.selectableItem,
          selected = $template.hasClass('selected') ? true : false;

        if (selected) {
          $template.removeClass('selected');
        } else {
          $template.addClass('selected');
        }
      },

      initialize: function () {
        this.users = this.options.users;
        this.offers = this.options.offers;
      },

      serializeData: function () {
        var templateData = this.model.toJSON();

        if (templateData.date) {
          if (typeof(templateData.date) === 'object') {
            templateData.date = moment.unix(templateData.date.time / 1000);
          } else {
            templateData.date = moment(templateData.date).format('DD/MM HH:mm');
          }
        }
        templateData.fileUrl = this._getImageLink(this.model.get('snapshotUid'));
        templateData.userImg = this._getUserImg();
        templateData.productName = this.model.get('productName') ? this.model.get('productName') : '';

        if (templateData.expShippingDate) {
          var now = new Date();
          var mDate;
          if (typeof(templateData.expShippingDate) === 'object') {
              mDate = moment.unix(templateData.expShippingDate.time / 1000);
          } else {
              mDate = moment(this.model.get('expShippingDate'));
          }
          templateData.expShippingDate = mDate.format('DD/MM/YYYY');

          if (mDate.diff(now, 'days') <= 1) {
              templateData.dateColor = '#ff0000';
          } else if (mDate.diff(now, 'days') <= 3) {
              templateData.dateColor = '#ff8300';
          } else {
              templateData.dateColor = '#333333';
          }
        }

        if (Settings.get('cAccountRoles')[RolesMixin.DASHBOARD_OFFERS_DESIGN2]) {
          templateData.design2 = true;
        } else {
          templateData.design2 = false;
        }

        templateData.invoiceFileUidLink = this._getDownloadLink(this.model.get('invoiceFileUid'));

        templateData.state = this.options.state;
        var price = this.model.get('price');
        var qty = this.model.get('quantity');
        templateData.quantityPrice = (qty?(qty + 'pcs - '):'') + (price?price.toFixed(2) + '€':'');

        return templateData;
      },

      onShow: function () {
        //$.material.init();
      },

      onMouseOver: function() {
        if (!this.menuLoaded) {
            this.menuLoaded = true;
            OrderUtils._initContextMenu(this);
        }
      },

      _onEndUserClick: function () {
        App.navigate('customers/code/' + this.model.get('endUserId'), { trigger: true });
      },

      _getUserImg: function () {
        var user = this.model.get('refUser');
        if (user && user.logo) {
          return Settings.url('file', user.logo);
        } else if (user && user.logonId) {
          return imageUtils.getLogo(null, user.logonId, null, 'circle');
        } else {
          return null;
        }
      },

      _getDownloadLink: function (uid) {
        if (uid) {
          return Settings.url('file', uid, {cmds: 'on'});
        } else {
          return '';
        }
      },

      _getImageLink: function (snapshot) {
        if (!snapshot) {
          return Settings.get('notFoundImage');
        } else {
          return Settings.url('file', snapshot, { size: 'thumbnail' });
        }
      },

      _redirectToCardDetails: function () {
        if (App.getCurrentRoute() === 'offers_kanban') {
            App.navigate('orders/' + this.model.get('poi'), {trigger: true});
        }
      },

      _onAssignTo: function (e) {
        e.preventDefault();
        var offerId = $(e.currentTarget).attr('data-id'),
          assignedOffer = _.findWhere(this.offers, { poi: offerId }),
          userLogonId = assignedOffer.refUser ? assignedOffer.refUser.logonId : null;

        this._showAssignPopup(offerId, userLogonId);
      },

      _showAssignPopup: function (offerId, userLogonId) {
        var that = this,
          popupView = new PopupView(
            { users: this.users, assignedUser: userLogonId }
          );

        popupView.show({
          title: 'Assign To',
          className: 'vectorizationAssignTo',
          buttons: {
            cancel: {
              label: _.i18n('common.cancel'),
              className: 'btn-default',
              callback: function () { return true; }
            },
            success: {
              label: 'Assign',
              className: 'btn-primary',
              callback: function () {
                if (popupView.selectedUser) {
                  if (popupView.selectedUser.get('secId')) {
                    return that._updateOffer(offerId,
                      popupView.selectedUser.get('secId')
                    );
                  } else {
                    return that._unassignOffer(offerId);
                  }
                } else {
                  return true;
                }
              }
            }
          }
        });
      },

      _updateAssignUser: function (userName, userImg, offerId) {
        var ticket = _.findWhere(this.offers, { poi: offerId }),
          image;

        _.find($('.card-item'), function (card) {
          if ($(card).attr('data-id') === offerId) {
            if ($(card).find('assigned-user-logo').length && userImg && userName) {
              $(card).find('.assigned-user-logo')
                .css('background-image:url(' + userImg + ')');
            } else if (!$(card).find('assigned-user-logo').length && userImg && userName) {
              $(card).find('.assigned-face-block').html(
                '<div class="assigned-user-logo" style="background-image:url(' + userImg + ')"></div>'
              );
            } else if (!$(card).find('assigned-user-logo').length && !userImg && userName) {
              image = imageUtils.getLogo(userImg, userName, 'circle');
              $(card).find('.assigned-face-block').html(
                '<img class="logo-holder js-assigned-user-no-logo" src="' + image + '"/>'
              );
            } else if (!userName && !userImg) {
              image = '<i class="mdi-action-face-unlock"></i>';
              $(card).find('.assigned-face-block').html(image);
            }
          }
        });
        ticket.userImg = userImg;
        ticket.userName = userName;
      },

      _updateOffer: function (offerId, userSecId) {
        var that = this;

        $.when(App.request('offer-kanban:assign-users', offerId, userSecId))
          .done(function (data) {
            var userName = data.refUser.logonId,
              userImg = data.refUser.logo ? Settings.url('file', data.refUser.logo) : null;

            that._updateAssignUser(userName, userImg, offerId);
          });
      },

      _unassignOffer: function (offerId) {
        var that = this;

        $.when(App.request('offer-kanban:unassigned-users', offerId))
          .done(function () {
            that._updateAssignUser(null, null, offerId);
          });
      }
    });

    module.exports = ItemCardView;
  });
