
define('template!detailMailTpl', ['lodash'], function(_) {return function(obj) {
obj || (obj = {});
var __t, __p = '';
with (obj) {
__p += '\n<div class="row">\n    <h2>' +
((__t = ( subject )) == null ? '' : __t) +
'</h2>\n</div>\n<div class="row">\n    <hr class="col-xs-12" />\n    <div class="col-xs-12">\n        ' +
((__t = ( fromDisplayName )) == null ? '' : __t) +
'\n    </div>\n    <div class="col-xs-12">\n        ' +
((__t = ( deliveredToDisplayName )) == null ? '' : __t) +
'\n    </div>\n    <hr class="col-xs-12" />\n</div>\n<div class="row">\n    <div class="col-xs-12">\n        ' +
((__t = ( body )) == null ? '' : __t) +
'\n    </div>\n</div>';

}
return __p
};});

