
define('template!ordersKanbanCardsTpl', ['lodash'], function(_) {return function(obj) {
obj || (obj = {});
var __t, __p = '';
with (obj) {
__p += '<div class="form-group row m-b-0" data-field-name="showOnlyMyItems">\n    <div class="col-xs-2">\n        <input type="checkbox" data-status="' +
((__t = (state.statusConst)) == null ? '' : __t) +
'" style="width: 20px; height: 40px; " class="showOnlyMyItems" ' +
((__t = ( showOnlyMine?'checked':'')) == null ? '' : __t) +
'>\n    </div>\n    <label class="col-xs-10 control-label p-t-15 p-l-0" style="text-align: left;">' +
((__t = ( _.i18n("board.showOnlyMyItems") )) == null ? '' : __t) +
'</label>\n</div>\n<ul data-state="' +
((__t = ( state )) == null ? '' : __t) +
'" class="cards-container sortable-item"></ul>';

}
return __p
};});

