/**
 * Created by OLD on 04/09/2017.
 */
define('ordersKanbanView',[
  'module',
  'underscore',
  'backbone',
  'marionette',
  'app',
  'settings',
  'notificationCenter',
  'logotoolUtils',
  'template!ordersKanbanViewTpl',
  'entities/orders_kanban'
], function(
  module,
  _,
  Backbone,
  Marionette,
  App,
  Settings,
  NotificationCenter,
  logotoolUtils,
  viewTpl
) {
  'use strict';

  var OrdersKanbanView =  Marionette.LayoutView.extend({
    template: viewTpl,    

    ui: {
      downloadPdf: '.orders-pdf-download'
    },

    regions: {
      kanbanFilters: '#kanbanorder-filters-region',
      kanbanBoard: '#kanbanorder-board-region'
    },

    serializeData: function () {      
      var templateData = {};

      templateData.newOrderLogotoolUrl =  logotoolUtils.getLogotoolCataloguesUrl({
        returnUrl: window.location.href,
        endUserSecId: 'new'
      });
      return templateData;
    },

    onShow: function() {
      $.material.init();
      NotificationCenter.getInstance().addListener(
        this, 
        NotificationCenter.events.CHANGE_INFO_ORDER, 
        this.onMessage
      );   
    },

    onMessage: function(response) {
      $.when(App.request('order-kanban:new:model', response.content))
        .done(_.bind(function(model) {
          _.each(this.options.cardCollections, _.bind(function(item) {
            if (item.collection.get(model.get('poi'))) {
              item.collection.remove(model.get('poi'));
            }
            if (item.status === response.content.status) {
              item.collection.add(model);
            }
          }, this));
        }, this));
    }
  });

  module.exports = OrdersKanbanView;
});
