
define('template!caccountAddCreditsChooseMethodPopupTpl', ['lodash'], function(_) {return function(obj) {
obj || (obj = {});
var __t, __p = '';
with (obj) {
__p += '<div class="content">\n    <div class="my-vector-popup-container">\n        <form class="form-horizontal">\n            <fieldset>\n            	<div class="form-group text-center icon-container">\n	                <i class="mdi-action-shopping-basket m-b-10"></i>\n	                <div><span>' +
((__t = ( _.i18n("credit.popup.chooseMethod") )) == null ? '' : __t) +
'</span></div>\n	            </div>\n	            <div class="form-group text-center row">\n	            	<div class="col-md-4 p-r-0 p-l-0">\n                        <div class="btn pay-pal-logo btn-img credit-paypal-btn"></div>\n                    </div>\n                    <div class="col-md-4 p-r-0 p-l-0">\n                        <a class="btn btn-primary stripetransfert-btn">' +
((__t = ( _.i18n("credit.popup.cartTransfert") )) == null ? '' : __t) +
'</a>\n                    </div>\n	            	<div class="col-md-4 p-r-0 p-l-0">\n                        <a class="btn btn-primary banktransfert-btn">' +
((__t = ( _.i18n("credit.popup.bankTransfert") )) == null ? '' : __t) +
'</a>\n                    </div>\n	            </div>\n            </fieldset>\n        </form>\n        <a class="need-help-link" href="mailto:support@compuzz.com">' +
((__t = ( _.i18n("common.need.help") )) == null ? '' : __t) +
'</a>\n    </div>\n</div>';

}
return __p
};});

