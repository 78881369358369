/**
 * Created by OLD on 31/08/2015.
 */
define('messagesAllView',[
  'module',
  'underscore',
  'marionette',
  'app',
  'settings',
  'dateUtils',
  'template!messagesAllTpl',  
  'stateMessages',
  'rolesMixin',
  'zoomPopover',
  'translatePopoverBehavior',
  'entities/tickets'
], function(
  module,
  _,
  Marionette,
  App,
  Settings,
  dateConverter,
  viewTpl,
  StateMessages,
  RolesMixin,
  ZoomPopoverBehavior,
  TranslatePopoverBehavior
) {
  'use strict';

  var MessagesGraphicalRequestAllInOneView =  Marionette.ItemView.extend({
    template: viewTpl,

    events: {
      'click @ui.deleteAttachment': 'onDeleteAttachment',
      'click .btn-duplicate-msg': 'onClickDuplicateMsg'
    },

    ui: {
      toolTip: '[data-toggle="tooltip"]',
      imageCells: '.js-image-cell',
      attachmentFiles: '.js-attachment-file',
      deleteAttachment: '.js-remove-attachment'
    },

    behaviors: {
      ZoomPopover: {
        behaviorClass: ZoomPopoverBehavior,
        manualInit: true,
        container: '.message-container'
      },
      StateMessages: {
        behaviorClass: StateMessages
      },
      TranslatePopHover: {
        behaviorClass: TranslatePopoverBehavior,
        target: '.translation',
        languagesTo: [Settings.get('lang'), 'uk']
      }
    },

    serializeData: function() {
      var templateData = this.model.toJSON();

      templateData.csMsg = this.options.csMsg;
      templateData.time = dateConverter.toTimeFormatString(templateData.date);
      templateData.day = dateConverter.toDateFormatString(templateData.date);
      templateData.content = templateData.content || '';
      _.each(templateData.attachements, function(item) {
        if(item.fileLink) {
          var fileArr = item.fileLink.split('getfile/'),
              fileUID;

          fileArr = fileArr[1].split('?');
          fileUID = fileArr[0];

          item.fileIds = fileUID;
          item.fileLink = Settings.url('base', item.fileLink);
          item.vectorizedLink = Settings.url('x2pdf', 'vectorteam/preparefile', {logoUID: fileUID});
          item.download = Settings.url('compuzz', 'tickets/' + this.model.get('ticketSecId') + '/download/' + fileUID);
        }
      }, this);

      if (templateData.userPid && templateData.userPid.logo) {
        templateData.fileUrl = Settings.url('file', templateData.userPid.logo);
      } else {
        templateData.fileUrl = null;
      }

      templateData.vectorizator = Settings.get('cAccountRoles')[RolesMixin.VECTORIZATOR];
      templateData.showCustomerMsg = Settings.get('cAccountRoles')[RolesMixin.DASHBOARD_GRAPHICALREQUESTS_SHOW_CUSTOMER_MSG];
      return templateData;
    },

    onShow: function() {
      $.material.init();
      this.showAttachments();
      this.ui.toolTip.tooltip({placement:'bottom'});
    },

    onClickDuplicateMsg: function() {
      $.when(App.request('tickets:duplicate-msg-to-cs', this.model.get('ticketSecId'), this.model.get('id'))).
        done(_.bind(function() {
          App.navigate('graphical_service_kanban/' + this.model.get('ticketSecId') + '/messages-cs', { trigger: true });
        }, this));
    },

    onDeleteAttachment: function(e) {
      e.preventDefault();
      var attId = $(e.currentTarget).attr('data-fileid'),
        ticketSecId = this.model.get('ticketSecId'),
        messageSecId = this.model.get('id');

      this.$('.attach-block[data-fileid="' + attId + '"]').remove();
      this.trigger('onDeleteAttachment', attId, ticketSecId, messageSecId);
    },

    showAttachments: function() {
      var that = this,
          counter = 0,
          attachements = this.model.get('attachements');
      _.each(attachements, function(item, index) {
        var imageCell, attachmentFile;
        if(item.fileLink) {
          imageCell = that.ui.imageCells.filter('.js-' + index);
          attachmentFile = that.ui.attachmentFiles.filter('.js-' + index);
          $('<img/>').attr('src', Settings.url('base', item.fileLink)).load(function() {
            $(this).remove();
            imageCell.attr('data-link', Settings.url('base', item.fileLink));
            imageCell.css('background-image', 'url(' + Settings.url('base', item.fileLink) + ')');
            imageCell.show();
            that.triggerMethod('show:image:zoom:popover', item.fileLink, imageCell);
            counter++;
            that.checkAttCounter(attachements,counter);
          }).error(function() {
            counter++;
            that.checkAttCounter(attachements,counter);
            $(this).remove();
            attachmentFile.show();
          });
        }
      });
      if (attachements.length === 0){
        this.trigger('attachedViewShown');
      }
    },

    //trigger it only once for message when images have loaded
    checkAttCounter: function(attachements,counter) {
      var length = attachements.length;
      if (length === counter) {
        this.trigger('attachedViewShown');
      }
    },

    onBeforeDestroy: function() {
      if (this.ui.toolTip.data && this.ui.toolTip.data('tooltip')) {
        this.ui.toolTip.tooltip('destroy');
      }      
    }
  });

  module.exports = MessagesGraphicalRequestAllInOneView;
});
