/**
 * Created by BFR on 02/03/2020.
 */
define('sharePrintAreasView',['module',
        'marionette',
        'sharePrintAreaView'],
  function(module,
           Marionette,
           SharePrintAreaView){
  'use strict';

  var SharePrintAreasView = Marionette.CollectionView.extend({
    childView: SharePrintAreaView,
    className: 'col-md-12 p-t-10',

    childEvents: {
      'item:selection:change': function () {
        this.trigger('item:selection:change');
      }
    }
  });

  module.exports = SharePrintAreasView;
});
