
define('template!productFilterTabTpl', ['lodash'], function(_) {return function(obj) {
obj || (obj = {});
var __t, __p = '', __j = Array.prototype.join;
function print() { __p += __j.call(arguments, '') }
with (obj) {
__p += '<div class="detailed-info-form-container">\n    <div class="row">\n        <div class="col-xs-12 col-md-9 col-lg-6">\n            <div class="row">\n                <div class="col-xs-12">\n                    <select class="cbx-filter-type m-l-15">\n                        ';
 _.each(productFilterTypes, function( productFilterType) { ;
__p += ' \n                            <option value="' +
((__t = ( productFilterType.secId )) == null ? '' : __t) +
'">' +
((__t = ( productFilterType.name )) == null ? '' : __t) +
'</option>\n                        ';
 }); ;
__p += '\n                    </select>\n                    <button disabled class="btn btn-primary disabled-grey js-save-product-type">' +
((__t = ( _.i18n('common.add') )) == null ? '' : __t) +
'</button>\n                </div>\n            </div>\n            <div class="product-filter-types">\n\n            </div>\n            <div class="row">\n                <div class="col-xs-12">\n                    <div class="col-xs-6">\n                        <h2 class="col-xs-12">Filters</h2>\n                    </div>\n                    <div class="col-xs-6">\n                        <h2 class="col-xs-12">Filter values</h2>\n                    </div>\n                </div>\n            </div>\n            <div class="row">\n                <div class="product-filters col-xs-12">\n                </div>\n            </div>\n            <div class="row">\n                <div class="col-xs-12">\n                    <div class="pull-right p-r-15">\n                        <button disabled class="btn btn-default btn-flat cancel-b js-cancel">' +
((__t = ( _.i18n('common.cancel') )) == null ? '' : __t) +
'</button>\n                        <button disabled class="btn btn-primary disabled-grey js-confirm">' +
((__t = ( _.i18n('common.save') )) == null ? '' : __t) +
'</button>\n                    </div>\n                </div>\n            </div>\n        </div>\n        <div class="col-xs-12 col-md-9 col-lg-6">\n            <div class="row">\n                ';
 if (product.imgUrl) { ;
__p += ' \n                    <img class="lazyload" data-src="' +
((__t = ( product.imgUrl )) == null ? '' : __t) +
'"/>\n                ';
 } ;
__p += '\n                <h2>' +
((__t = ( product.name )) == null ? '' : __t) +
'</h2>\n                <p>' +
((__t = ( product.description )) == null ? '' : __t) +
'</p>\n                <hr />\n            </div>\n            ';
 _.each(product.productOptionCategories, function(poc) { ;
__p += '\n                <div class="row">\n                    <div class="col-xs-6">\n                        <span>' +
((__t = ( poc.name )) == null ? '' : __t) +
'</span>\n                    </div>\n                    <div class="col-xs-6">\n                        ';
 _.each(poc.productOptions, function(po) { ;
__p += '\n                            <div class="row">\n                                ';
 if (po.color1 !== -1) { ;
__p += '\n                                    <div style="width: 18px; height: 18px; display: inline-block; background-color: ' +
((__t = ( po.color1 )) == null ? '' : __t) +
'" ></div>\n                                ';
 } ;
__p += '\n                                <span>' +
((__t = ( po.name )) == null ? '' : __t) +
'</span>\n                            </div>\n                        ';
 }); ;
__p += ' \n                    </div>\n                </div>\n            ';
 }); ;
__p += '\n        </div>\n    </div>\n    <div class="loading-block js-loading-block loader"></div>\n</div>';

}
return __p
};});

