define('productTabsLayoutController',[
  'module',
  'app',
  'productTabsLayoutView',
  'jquery',
  'entities/product'
], function(
  module,
  App,
  ProductTabsLayoutView,
  $
) {
  'use strict';

  var ProductTabsLayoutController = {
    _layoutView: undefined,

    showLayout: function(tabName, secId) {
      var that = this,
        defer = $.Deferred(),
        product = App.request('products:model', {secId: secId});

      if(this._layoutView && !this._layoutView.isDestroyed) {
        defer.resolve(this._layoutView.getRegion('tabs'), this._layoutView.model);
        this._layoutView.triggerMethod('show:active', tabName);

        return defer.promise();
      }

      product.fetch().done(function() {
        that._layoutView = new ProductTabsLayoutView({
          model: product
        });
        App.regions.getRegion('main').currentView.getRegion('content').show(that._layoutView);
        that._layoutView.triggerMethod('show:active', tabName);

        defer.resolve(that._layoutView.getRegion('tabs'), that._layoutView.model);
      });

      return defer.promise();
    }
  };

  module.exports = ProductTabsLayoutController;
});
