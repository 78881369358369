/**
 * Created by RKL on 10/09/2015.
 */
define('formView',[
  'module',
  'marionette',
  'underscore',
  'validate',
  'dateUtils'
], function(
  module,
  Marionette,
  _,
  validateUtils,
  dateUtils
) {
  'use strict';

  var FormView = Marionette.LayoutView.extend({

    onInputChange: function(e) {
      var $target = $(e.currentTarget),
        value = $target.val(),
        fieldName = $target.data('field-name'),
        isDate = $target.attr('type') === 'datepicker';

      if(isDate) {
        value = dateUtils.toMillisecondsFormat(value);
      }
      if ($target.hasClass('uppercase-input')) {
        value = value.toUpperCase();
      }

      this.onChange(fieldName, value);
    },

    onShow: function() {
      $.material.init();
    },

    onChange: function(fieldName, value) {
      this.model.set(fieldName, value);
    },

    validate: function(fields, dontShowError) {
      var model = this.model,
        errors = [],
        error;

      this.$el.find('input[min]').each(function(index, input) {
        var $input = $(input);
        error = validateUtils.validateMin(model,
          parseInt($input.attr('min'), 10),
          $input.data('field-name'));
        errors = _.union(errors, error);
      });

      _.each(fields, function (field) {
        if (field.type === 'required') {
          error = validateUtils.validateFields(model, field);
          errors = _.union(errors, error);
        } else if (field.type === 'email') {
          error = validateUtils.validateEmail(model, field);
          errors = _.union(errors, error);
        } else if (field.type === 'number') {
          error = validateUtils.validateNumber(model, field);
          errors = _.union(errors, error);
        } else if (field.type === 'multipleEmail'){
          error = validateUtils.validateMultipleEmail(model, field);
          errors = _.union(errors, error);
        } else if (field.type === 'tel') {
          error = validateUtils.validatePhoneNumber(model, field);
          errors = _.union(errors, error);
        }
      });

      if (!_.isEmpty(errors)) {
        this.onError(errors, dontShowError);
        return false;
      } else {
        return true;
      }
    },

    onError: function(errors, dontShowError) {
      var that = this,
        nameField, formGroup;

      _.each(errors, function (error) {
        nameField = that.$('input[data-field-name="' + error.name + '"]');
        formGroup = that.$('.form-group[data-field-name*="' + error.name + '"]');
        formGroup.addClass('has-error');
        nameField.addClass('invalid');
      });
      if(dontShowError) {
        return;
      }
      this.triggerMethod('errorMessage', _.i18n('warning.mandatoryFields'));
    },

    onFocus: function(e) {
      var target = $(e.currentTarget),
        fieldName = target.data('field-name'),
        formGroup = this.$('.form-group[data-field-name="' + fieldName + '"]');

      formGroup.removeClass('has-error');
      target.removeClass('invalid');
      this.triggerMethod('hideMessage');
    },

    onDestroy: function() {
      if (this.model) {
        this.model.clear();
      }
    }

  });

  module.exports = FormView;
});
