
define('template!caccountOfferStatusTabTpl', ['lodash'], function(_) {return function(obj) {
obj || (obj = {});
var __t, __p = '';
with (obj) {
__p += '<div>\n	<div class="well caccount-font-container jq-grid-table-page">\n		<!-- <span class="loading-message js-loading-message label label-primary"></span> -->\n		<div class="loading-block js-loading-block">\n			<span class="loading-message js-loading-message label"></span>\n		</div>\n		<div class="vectorization-table">\n            <div class="form-group add-new-block">\n                <div class="btn js-add-offerstatus add-btn-text">\n                    <i class="mdi-content-add icon"></i>\n                    <span>' +
((__t = ( _.i18n('orderstatus.new') )) == null ? '' : __t) +
'</span>\n                </div>\n            </div>\n			<table id="jq-grid-fonts" class="vectorTable" role="grid"></table>\n			<div id="jq-grid-pager-fonts"></div>\n		</div>\n	</div>\n</div>\n';

}
return __p
};});

