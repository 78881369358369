/**
 * Created by BFR on 02/03/2020.
 */
define('productShareTabView',[
  'module',
  'marionette',
  'underscore',
  'template!productShareTabTpl',
  'sharePrintAreasView',
  'shareOptionCategoriesView'
], function(
  module, Marionette,
  _,
  viewTpl,
  SharePrintAreasView,
  ShareOptionCategoriesView
) {
  'use strict';

  var ProductShareView = Marionette.LayoutView.extend({
    template: viewTpl,

    regions: {
      sharePrintAreas: '.share-printareas',
      shareOptionCategories: '.share-optioncategories'
    },

    ui: {
      cancel: '.js-cancel',
      confirm: '.js-confirm',
      blockLoading: '.js-loading-block'
    },

    events: {
      'click @ui.cancel': 'onCancelClick',
      'click @ui.confirm': 'onConfirmClick'
    },

    onCancelClick: function() {
      this.model.set(this.backup);
      this.render();
      this.onShow();
    },

    onConfirmClick: function() {
      this.ui.blockLoading.show();
      this.model.save().done(_.bind(function() {
        this.ui.blockLoading.hide();
        this.ui.cancel.prop('disabled', true);
      this.ui.confirm.prop('disabled', true);
      }, this));
    },

    onShow: function () {
      this.sharePrintAreasView = new SharePrintAreasView({ collection: this.model.get('printAreas')});
      this.sharePrintAreasView.listenTo(this.sharePrintAreasView, 'item:selection:change', _.bind(this.onEnableCancelConfirm, this));
      this.getRegion('sharePrintAreas').show(this.sharePrintAreasView);

      this.shareOptionCategoriesView = new ShareOptionCategoriesView({ collection: this.model.get('optionCategories')});
      this.shareOptionCategoriesView.listenTo(this.shareOptionCategoriesView, 'item:selection:change', 
        _.bind(this.onEnableCancelConfirm, this));
      this.getRegion('shareOptionCategories').show(this.shareOptionCategoriesView);
    },

    serializeData: function () {
      this.backup = this.options.model.toJSON();
      var templateData = this.options.model.toJSON();
      return templateData;
    },

    onEnableCancelConfirm: function() {
      this.ui.cancel.prop('disabled', false);
      this.ui.confirm.prop('disabled', false);
    }
  });

  module.exports = ProductShareView;
});
