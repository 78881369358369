
define('template!customerDatasetsPopupTpl', ['lodash'], function(_) {return function(obj) {
obj || (obj = {});
var __t, __p = '';
with (obj) {
__p += '<div class="content">\n  <div class="my-dataset-popup-container">\n    <form class="form-horizontal">\n      <fieldset>\n          <div class="col-xs-12">\n            <label class="control-label col-xs-4 text-left">' +
((__t = ( objectName.name )) == null ? '' : __t) +
'</label>\n            <div class="col-xs-8 objectnames-container">\n            </div>\n          </div>\n      </fieldset>\n    </form>\n  </div>\n</div>';

}
return __p
};});

