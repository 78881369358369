
define('template!caccountCreditsTabTpl', ['lodash'], function(_) {return function(obj) {
obj || (obj = {});
var __t, __p = '', __j = Array.prototype.join;
function print() { __p += __j.call(arguments, '') }
with (obj) {
__p += '<div>\n  <div class="well jq-grid-table-page">\n	<div class="credits-table">\n        <div class="loading-block js-loading-block">\n            <span class="loading-message js-loading-message label"></span>\n        </div>\n        <div class="form-group col-xs-2 credit-info-block">\n        ';
 if (balance || balance === 0) { ;
__p += '\n            <div class="credits-balance panel panel-default col-xs-12">\n                <div class="col-xs-4 number-of-balence">\n                    <span class="credits-balance-value">' +
((__t = ( balance )) == null ? '' : __t) +
'</span>\n                </div>\n                <div class="col-xs-8 balance-label">\n                    <span class="credit-balance-label">' +
((__t = ( _.i18n('credits.balance') )) == null ? '' : __t) +
'</span>\n                </div>\n            </div>\n            ';
 } ;
__p += '\n        </div>\n        <div class="form-group add-new-block">\n            <div class="btn add-btn-text js-add-credit">\n                <i class="mdi-content-add icon"></i>\n                <span>' +
((__t = ( _.i18n('credits.new') )) == null ? '' : __t) +
'</span>\n            </div>\n        </div>\n\n        <table id="jq-grid-credits" role="grid"></table>\n	  <div id="jq-grid-pager-credits"></div>\n	</div>\n  </div>\n</div>';

}
return __p
};});

