/**
 * Created by BFR on 25/05/2018.
 */
define('orderPriceTabView',[
  'module',
  'marionette',
  'jquery',
  'underscore',
  'orderUtils',
  'template!orderPriceTabTpl'
], function(
  module, Marionette,
  $,
  _,
  OrderUtils,
  viewTpl
) {
  'use strict';

  var ProductPriceView = Marionette.ItemView.extend({
    template: viewTpl,

    ui: {
      'lblRsSetupCost': '.lbl-rs-setup-cost',
      'lblRsUnitCost': '.lbl-rs-unit-cost',
      'lblRsTotal': '.lbl-rs-total',
      'lblRsProduct': '.lbl-rs-product-cost',
      'lblRsTotalProduct': '.lbl-rs-total-product',
      'lblRsTotalPrint': '.lbl-rs-total-print',
      'lblRsShippingCost': '.lbl-rs-shipping-cost',
      'lblRsCustomSetupCost': '.lbl-rs-custom-setup-cost',
      'lblRsCustomUnitCost': '.lbl-rs-custom-unit-cost',
      'lblRsCustomTotal': '.lbl-rs-custom-total',
      'lblRsCustomProduct': '.lbl-rs-custom-product-cost',
      'lblRsCustomTotalProduct': '.lbl-rs-custom-total-product',
      'lblRsCustomTotalPrint': '.lbl-rs-custom-total-print',
      'lblRsCustomShippingCost': '.lbl-rs-custom-shipping-cost',
      'lblSavedShippingCost': '.lbl-saved-shipping-cost',
      'lblSavedProductCost': '.lbl-saved-product-cost',
      'lblSavedProduct': '.lbl-saved-total-product',
      'lblSavedSetupCost': '.lbl-saved-setup-cost',
      'lblSavedUnitCost': '.lbl-saved-unit-cost',
      'lblSavedTotalPrintCost': '.lbl-saved-total-print',
      'lblSavedTotal': '.lbl-saved-total',
      'lblSavedTotalProduct': '.lbl-saved-total-product',
      'lblSetupCost': '.lbl-setup-cost',
      'lblUnitCost': '.lbl-unit-cost',
      'lblTotal': '.lbl-total',
      'lblTotalUnit': '.lbl-total-unit',
      'lblShippingCost': '.lbl-shipping-cost',
      'lblMgTotal': '.lbl-mg-total',
      'tableScale': '.table-scale',
      'blockLoading': '.js-loading-block',
      'inputMargins': '.input-margin',
      'lblProductCost': '.lbl-product-cost',
      'lblTotalProduct': '.lbl-total-product',
      'lblTotalPrintCost': '.lbl-total-print',
      'lblUnitCostMargin': '.lbl-unit-cost-margin',
      'lblSetupMargin': '.lbl-setup-margin',
      'lblMgTotalPrint': '.lbl-mg-total-print',
      'lblShippingMargin': '.lbl-shipping-margin',
      'lblProductMargin': '.lbl-product-margin',
      'lblMgTotalProduct': '.lbl-mg-total-product',
      'lblMgTotalNotPrinted': '.lbl-mg-total-notprinted',
      'inputShippingOverride': '.input-shipping-override',
      'inputProductOverride': '.input-product-override',
      'inputPrintsetupOverride': '.input-printsetup-override',
      'inputPrintunitOverride': '.input-printunit-override',
      'inputBShippingOverride': '.input-b-shipping-override',
      'inputBProductOverride': '.input-b-product-override',
      'inputBPrintsetupOverride': '.input-b-printsetup-override',
      'inputBPrintunitOverride': '.input-b-printunit-override',
      'inputAddCostDesc': '.add-cost-desc',
      'inputAddCost': '.add-cost',
      'printOurself': '.print-ourself'
    },

    events: {
      'click .btn-refresh': 'onBtnRefreshClick',
      'click .btn-buying-refresh': 'onBtnRefreshBuyingClick',
      'click .btn-buying-reset': 'onBtnResetBuyingClick',
      'click .btn-reset': 'onBtnResetClick',
      'change @ui.printOurself': 'onChangePrintOurself'
    },

    onChangePrintOurself: function(event) {
        OrderUtils.loadTotalPrintSurface(this.model).then(_.bind(function(surface) {
            var ddl = $(event.target);
            if (ddl.val() !== 'no') {
                var price = OrderUtils.getPrintOurselfPrice(this.model.get('quantity'), ddl.val(), surface);
                this.ui.inputBPrintsetupOverride.val(price.costSetup);
                this.ui.inputBPrintunitOverride.val(price.costUnit);
                this.ui.inputPrintsetupOverride.val(price.setup);
                this.ui.inputPrintunitOverride.val(price.unit);
            } else {
                this.ui.inputBPrintsetupOverride.val(null);
                this.ui.inputBPrintunitOverride.val(null);
                this.ui.inputPrintsetupOverride.val(null);
                this.ui.inputPrintunitOverride.val(null);
            }
        }, this));
    },

    onShow: function () {
      this.trigger('get-price-for-order', null, false, null, false);
      var priceCustom = this.model.get('savedPrice')?this.model.get('savedPrice').priceCustom:null;
      if (priceCustom) {
        if (priceCustom.shippingCost) {
            this.ui.inputShippingOverride.val(priceCustom.shippingCost);
        }
        if (priceCustom.productCost) {
            this.ui.inputProductOverride.val(priceCustom.productCost);
        }
        if (priceCustom.setupCost) {
            this.ui.inputPrintsetupOverride.val(priceCustom.setupCost);
        }
        if (priceCustom.printUnitCost) {
            this.ui.inputPrintunitOverride.val(priceCustom.printUnitCost);
        }
        if (priceCustom.addDesc) {
            this.ui.inputAddCostDesc.val(priceCustom.addDesc);
        }
        if (priceCustom.addCost) {
            this.ui.inputAddCost.val(priceCustom.addCost);
        }
      }
    },

    onBtnResetBuyingClick: function() {
      this.model.resetBuyingPrice().always(_.bind(function() {
          this.ui.inputBShippingOverride.val('');
          this.ui.inputBProductOverride.val('');
          this.ui.inputBPrintsetupOverride.val('');
          this.ui.inputBPrintunitOverride.val('');
          this.trigger('get-price-for-order', null, false, null, false);
      }, this));
    },

    onBtnResetClick: function() {
      this.model.resetPrice().always(_.bind(function() {
            this.ui.inputShippingOverride.val('');
            this.ui.inputProductOverride.val('');
            this.ui.inputPrintsetupOverride.val('');
            this.ui.inputPrintunitOverride.val('');
            this.ui.inputAddCostDesc.val('');
            this.ui.inputAddCost.val('');
          this.trigger('get-price-for-order', null, false, null, false);
      }, this));
    },

    onBtnRefreshBuyingClick: function() {
      var customBuyingPrice = {
          quantity: this.model.get('quantity'),
          shippingCost: this.ui.inputBShippingOverride.val(),
          productCost: this.ui.inputBProductOverride.val(),
          setupCost: this.ui.inputBPrintsetupOverride.val(),
          printUnitCost: this.ui.inputBPrintunitOverride.val(),
          printOurself: (this.ui.printOurself.val()!=='no'?this.ui.printOurself.val():null)
      };
      this.trigger('get-price-for-order', null, false, customBuyingPrice, true);
    },

    onBtnRefreshClick: function() {
      var customPrice = {
          quantity: this.model.get('quantity'),
          shippingCost: this.ui.inputShippingOverride.val(),
          productCost: this.ui.inputProductOverride.val(),
          setupCost: this.ui.inputPrintsetupOverride.val(),
          printUnitCost: this.ui.inputPrintunitOverride.val(),
          addDesc: this.ui.inputAddCostDesc.val(),
          addCost: this.ui.inputAddCost.val()
      };
      this.trigger('get-price-for-order', customPrice, true, null, false);
    },

    serializeData: function () {
      var templateData = this.options.model.toJSON();
      return templateData;
    },

    onChangeOption: function(e){
      e.preventDefault();
      var target = $(e.target),
          index = target.data('index'),
          optionId = target.data('optionId');
      this.$el.find('.option-'+ index +'-label').text(target.text());
        this.currentConfiguration.options[index] = optionId;
    },

    clearElements: function() {
        this.ui.tableScale.empty();
        this.ui.lblRsSetupCost.text('');
        this.ui.lblRsUnitCost.text('');
        this.ui.lblRsTotal.text('');
        this.ui.lblRsProduct.text('');
        this.ui.lblRsTotalProduct.text('');
        this.ui.lblRsTotalPrint.text('');
        this.ui.lblRsShippingCost.text('');

        this.ui.lblRsCustomSetupCost.text('');
        this.ui.lblRsCustomUnitCost.text('');
        this.ui.lblRsCustomTotal.text('');
        this.ui.lblRsCustomProduct.text('');
        this.ui.lblRsCustomTotalProduct.text('');
        this.ui.lblRsCustomTotalPrint.text('');
        this.ui.lblRsCustomShippingCost.text('');

        this.ui.lblSavedShippingCost.text('');
        this.ui.lblSavedProductCost.text('');
        this.ui.lblSavedProduct.text('');
        this.ui.lblSavedSetupCost.text('');
        this.ui.lblSavedUnitCost.text('');
        this.ui.lblSavedTotalPrintCost.text('');
        this.ui.lblSavedTotal.text('');
        this.ui.lblSavedTotalProduct.text('');

        this.ui.lblSavedShippingCost.removeClass('red-price');
        this.ui.lblSavedProductCost.removeClass('red-price');
        this.ui.lblSavedProduct.removeClass('red-price');
        this.ui.lblSavedSetupCost.removeClass('red-price');
        this.ui.lblSavedUnitCost.removeClass('red-price');
        this.ui.lblSavedTotalPrintCost.removeClass('red-price');
        this.ui.lblSavedTotal.removeClass('red-price');
        this.ui.lblSavedTotalProduct.removeClass('red-price');

        this.ui.lblRsCustomSetupCost.removeClass('red-price');
        this.ui.lblRsCustomUnitCost.removeClass('red-price');
        this.ui.lblRsCustomTotal.removeClass('red-price');
        this.ui.lblRsCustomProduct.removeClass('red-price');
        this.ui.lblRsCustomTotalProduct.removeClass('red-price');
        this.ui.lblRsCustomTotalPrint.removeClass('red-price');
        this.ui.lblRsCustomShippingCost.removeClass('red-price');

        this.ui.lblSetupCost.text('');
        this.ui.lblUnitCost.text('');
        this.ui.lblTotal.text('');
        this.ui.lblShippingCost.text('');
        this.ui.lblMgTotal.text('');
        this.ui.lblProductCost.text('');
        this.ui.lblTotalProduct.text('');
        this.ui.lblTotalPrintCost.text('');
        this.ui.lblUnitCostMargin.text('');
        this.ui.lblSetupMargin.text('');
        this.ui.lblMgTotalPrint.text('');
        this.ui.lblShippingMargin.text('');
        this.ui.lblProductMargin.text('');
        this.ui.lblMgTotalProduct.text('');
        this.ui.lblMgTotalNotPrinted.text('');
        this.ui.printOurself.find('option[value="no"]').prop('selected', true);
    },

    refreshRsCustomPrice: function(price) {
      this.ui.lblRsCustomShippingCost.text(parseFloat(price.shippingCost).toFixed(2) + ' € = ' +
          (parseFloat(price.shippingCost) / Number(price.quantity)).toFixed(2) + ' €/pc');
      this.ui.lblRsCustomProduct.text(parseFloat(price.productPrice).toFixed(2) + ' € = ' +
          (parseFloat(price.productPrice) / Number(price.quantity)).toFixed(2) + ' €/pc');
      this.ui.lblRsCustomTotalProduct.text((parseFloat(price.productPrice) + parseFloat(price.shippingCost)).toFixed(2) + ' € = ' +
          ((parseFloat(price.productPrice) + parseFloat(price.shippingCost)) / Number(price.quantity)).toFixed(2) + ' €/pc');
      this.ui.lblRsCustomUnitCost.text(parseFloat(price.totalUnitCost).toFixed(2) + ' € = ' +
          (parseFloat(price.totalUnitCost) / Number(price.quantity)).toFixed(2) + ' €/pc');
      this.ui.lblRsCustomSetupCost.text(parseFloat(price.totalSetupCost).toFixed(2) + ' € = ' +
          (parseFloat(price.totalSetupCost) / Number(price.quantity)).toFixed(2) + ' €/pc');
      this.ui.lblRsCustomTotalPrint.text((parseFloat(price.totalUnitCost) + parseFloat(price.totalSetupCost)).toFixed(2) + ' € = ' +
          ((parseFloat(price.totalUnitCost) + parseFloat(price.totalSetupCost)) / Number(price.quantity)).toFixed(2) + ' €/pc');
      this.ui.lblRsCustomTotal.text(parseFloat(price.total).toFixed(2) + ' € = ' +
          (parseFloat(price.total).toFixed(2) / Number(price.quantity)).toFixed(2) + ' €/pc');

      if (this.ui.lblRsCustomSetupCost.text() !== this.ui.lblRsSetupCost.text()) {
          this.ui.lblRsCustomSetupCost.addClass('red-price');
      }
      if (this.ui.lblRsCustomUnitCost.text() !== this.ui.lblRsUnitCost.text()) {
          this.ui.lblRsCustomUnitCost.addClass('red-price');
      }
      if (this.ui.lblRsCustomTotal.text() !== this.ui.lblRsTotal.text()) {
          this.ui.lblRsCustomTotal.addClass('red-price');
      }
      if (this.ui.lblRsCustomProduct.text() !== this.ui.lblRsProduct.text()) {
          this.ui.lblRsCustomProduct.addClass('red-price');
      }
      if (this.ui.lblRsCustomTotalProduct.text() !== this.ui.lblRsTotalProduct.text()) {
          this.ui.lblRsCustomTotalProduct.addClass('red-price');
      }
      if (this.ui.lblRsCustomTotalPrint.text() !== this.ui.lblRsTotalPrint.text()) {
          this.ui.lblRsCustomTotalPrint.addClass('red-price');
      }
      if (this.ui.lblRsCustomShippingCost.text() !== this.ui.lblRsShippingCost.text()) {
          this.ui.lblRsCustomShippingCost.addClass('red-price');
      }

      this.ui.blockLoading.hide();
    },

    refreshRsPrice: function(price) {
      this.ui.lblRsShippingCost.text(parseFloat(price.shippingCost).toFixed(2) + ' € = ' +
          (parseFloat(price.shippingCost) / Number(price.quantity)).toFixed(2) + ' €/pc');
      this.ui.lblRsProduct.text(parseFloat(price.productPrice).toFixed(2) + ' € = ' +
          (parseFloat(price.productPrice) / Number(price.quantity)).toFixed(2) + ' €/pc');
      this.ui.lblRsTotalProduct.text((parseFloat(price.productPrice) + parseFloat(price.shippingCost)).toFixed(2) + ' € = ' +
          ((parseFloat(price.productPrice) + parseFloat(price.shippingCost)) / Number(price.quantity)).toFixed(2) + ' €/pc');
      this.ui.lblRsUnitCost.text(parseFloat(price.totalUnitCost).toFixed(2) + ' € = ' +
          (parseFloat(price.totalUnitCost) / Number(price.quantity)).toFixed(2) + ' €/pc');
      this.ui.lblRsSetupCost.text(parseFloat(price.totalSetupCost).toFixed(2) + ' € = ' +
          (parseFloat(price.totalSetupCost) / Number(price.quantity)).toFixed(2) + ' €/pc');
      this.ui.lblRsTotalPrint.text((parseFloat(price.totalUnitCost) + parseFloat(price.totalSetupCost)).toFixed(2) + ' € = ' +
          ((parseFloat(price.totalUnitCost) + parseFloat(price.totalSetupCost)) / Number(price.quantity)).toFixed(2) + ' €/pc');
      this.ui.lblRsTotal.text(parseFloat(price.total).toFixed(2) + ' € = ' +
          (parseFloat(price.total).toFixed(2) / Number(price.quantity)).toFixed(2) + ' €/pc');
      this.ui.blockLoading.hide();
    },

    refreshPrice: function(price) {
      this.ui.lblTotalPrintCost.text((parseFloat(price.totalSetupCost) +
          parseFloat(price.totalUnitCost)).toFixed(2) + ' € = ' +
          ((parseFloat(price.totalSetupCost) + parseFloat(price.totalUnitCost)) / Number(price.quantity)).toFixed(2) + ' €/pc');
      this.ui.lblTotalProduct.text((parseFloat(price.productPrice) + parseFloat(price.shippingCost)).toFixed(2) + ' € = ' +
          ((parseFloat(price.productPrice) + parseFloat(price.shippingCost)) / Number(price.quantity)).toFixed(2) + ' €/pc');
      this.ui.lblProductCost.text(parseFloat(price.productPrice).toFixed(2) + ' € = ' +
          (parseFloat(price.productPrice).toFixed(2) / Number(price.quantity)).toFixed(2) + ' €/pc');
      this.ui.lblSetupCost.text(parseFloat(price.totalSetupCost).toFixed(2) + ' € = ' +
          (parseFloat(price.totalSetupCost).toFixed(2) / Number(price.quantity)).toFixed(2) + ' €/pc');
      this.ui.lblUnitCost.text(parseFloat(price.totalUnitCost).toFixed(2) + ' € = ' +
          (parseFloat(price.totalUnitCost).toFixed(2) / Number(price.quantity)).toFixed(2) + ' €/pc');
      this.ui.lblTotal.text(parseFloat(price.total).toFixed(2) + ' € = ' +
          (parseFloat(price.total).toFixed(2) / Number(price.quantity)).toFixed(2) + ' €/pc');
      this.ui.lblShippingCost.text(parseFloat(price.shippingCost).toFixed(2) + ' € = ' +
          (parseFloat(price.shippingCost).toFixed(2) / Number(price.quantity)).toFixed(2) + ' €/pc');

      this.ui.blockLoading.hide();
    },

    refreshSavedPrice: function(price) {
      this.ui.lblSavedTotalPrintCost.text((parseFloat(price.totalSetupCost) +
          parseFloat(price.totalUnitCost)).toFixed(2) + ' € = ' +
          ((parseFloat(price.totalSetupCost) + parseFloat(price.totalUnitCost)) / Number(price.quantity)).toFixed(2) + ' €/pc');
      this.ui.lblSavedTotalProduct.text((parseFloat(price.productPrice) + parseFloat(price.shippingCost)).toFixed(2) + ' € = ' +
          ((parseFloat(price.productPrice) + parseFloat(price.shippingCost)) / Number(price.quantity)).toFixed(2) + ' €/pc');
      this.ui.lblSavedProductCost.text(parseFloat(price.productPrice).toFixed(2) + ' € = ' +
          (parseFloat(price.productPrice).toFixed(2) / Number(price.quantity)).toFixed(2) + ' €/pc');
      this.ui.lblSavedSetupCost.text(parseFloat(price.totalSetupCost).toFixed(2) + ' € = ' +
          (parseFloat(price.totalSetupCost).toFixed(2) / Number(price.quantity)).toFixed(2) + ' €/pc');
      this.ui.lblSavedUnitCost.text(parseFloat(price.totalUnitCost).toFixed(2) + ' € = ' +
          (parseFloat(price.totalUnitCost).toFixed(2) / Number(price.quantity)).toFixed(2) + ' €/pc');
      this.ui.lblSavedTotal.text(parseFloat(price.total).toFixed(2) + ' € = ' +
          (parseFloat(price.total).toFixed(2) / Number(price.quantity)).toFixed(2) + ' €/pc');
      this.ui.lblSavedShippingCost.text(parseFloat(price.shippingCost).toFixed(2) + ' € = ' +
          (parseFloat(price.shippingCost).toFixed(2) / Number(price.quantity)).toFixed(2) + ' €/pc');

        if (price.priceCustom) {
            if (price.priceCustom.shippingCost) {
                this.ui.inputBShippingOverride.val(price.priceCustom.shippingCost);
            }
            if (price.priceCustom.productCost) {
                this.ui.inputBProductOverride.val(price.priceCustom.productCost);
            }
            if (price.priceCustom.setupCost) {
                this.ui.inputBPrintsetupOverride.val(price.priceCustom.setupCost);
            }
            if (price.priceCustom.printUnitCost) {
                this.ui.inputBPrintunitOverride.val(price.priceCustom.printUnitCost);
            }
            if (price.priceCustom.printUnitCost) {
                this.ui.inputBPrintunitOverride.val(price.priceCustom.printUnitCost);
            }
            if (price.priceCustom.printOurself) {
                this.ui.printOurself.find('option[value="'+price.priceCustom.printOurself+'"]').prop('selected', true);
            }
        }

        if (this.ui.lblSavedTotalPrintCost.text() !== this.ui.lblTotalPrintCost.text()) {
            this.ui.lblSavedTotalPrintCost.addClass('red-price');
        }
        if (this.ui.lblSavedTotalProduct.text() !== this.ui.lblTotalProduct.text()) {
            this.ui.lblSavedTotalProduct.addClass('red-price');
        }
        if (this.ui.lblSavedProductCost.text() !== this.ui.lblProductCost.text()) {
            this.ui.lblSavedProductCost.addClass('red-price');
        }
        if (this.ui.lblSavedSetupCost.text() !== this.ui.lblSetupCost.text()) {
            this.ui.lblSavedSetupCost.addClass('red-price');
        }
        if (this.ui.lblSavedUnitCost.text() !== this.ui.lblUnitCost.text()) {
            this.ui.lblSavedUnitCost.addClass('red-price');
        }
        if (this.ui.lblSavedTotal.text() !== this.ui.lblTotal.text()) {
            this.ui.lblSavedTotal.addClass('red-price');
        }
        if (this.ui.lblSavedShippingCost.text() !== this.ui.lblShippingCost.text()) {
            this.ui.lblSavedShippingCost.addClass('red-price');
        }

      this.ui.blockLoading.hide();
    },

    refreshMargPrice: function(marg) {
      this.ui.lblMgTotalProduct.text(parseFloat(marg.totalTotalProductMarg).toFixed(2) + ' €' + ' = ' +
          parseFloat(marg.unitTotalProductMarg).toFixed(2) + ' €/pc');
      this.ui.lblProductMargin.text(parseFloat(marg.totalProductMarg).toFixed(2) + ' €' + ' = ' +
          parseFloat(marg.unitProductMarg).toFixed(2) + ' €/pc');
      this.ui.lblShippingMargin.text(parseFloat(marg.totalShippingMarg).toFixed(2) + ' €' + ' = ' +
          parseFloat(marg.unitShippingMarg).toFixed(2) + ' €/pc' );
      this.ui.lblMgTotalPrint.text((parseFloat(marg.totalUnitMarg) + parseFloat(marg.totalSetupMarg)).toFixed(2) +
          ' €' + ' = ' + (parseFloat(marg.unitUnitMarg) + parseFloat(marg.unitSetupMarg)).toFixed(2) + ' €/pc' );
      this.ui.lblSetupMargin.text(parseFloat(marg.totalSetupMarg).toFixed(2) + ' €' + ' = ' +
          parseFloat(marg.unitSetupMarg).toFixed(2) + ' €/pc' );
      this.ui.lblUnitCostMargin.text(parseFloat(marg.totalUnitMarg).toFixed(2) + ' €' + ' = ' +
          parseFloat(marg.unitUnitMarg).toFixed(2) + ' €/pc' );
      this.ui.lblMgTotal.text(parseFloat(marg.totalMarg).toFixed(2) + ' €' + ' = ' +
          parseFloat(marg.unitMarg).toFixed(2) + ' €/pc' );
      this.ui.lblMgTotalNotPrinted.text(parseFloat(marg.totalNotPrintMarg).toFixed(2) + ' €' + ' = ' +
          parseFloat(marg.unitNotPrintMarg).toFixed(2) + ' €/pc');
      this.ui.blockLoading.hide();
    },

    refreshScales: function(rsPrice, price) {
      this.ui.tableScale.empty();
      _.each(rsPrice.scale, _.bind(function(scale, index) {
        var margin = (Number(scale.total) - Number(price.scale[index].total));
        var scaleHtml = '<tr>' +
            '<td class="">' + scale.quantity + '</td>' +
            '<td class="">' + price.scale[index].total + ' €</td>' +
            '<td class="">' + parseFloat(price.scale[index].totalUnit).toFixed(2) + ' €</td>' +
            '<td class="">' + parseFloat(scale.total).toFixed(2) + ' €</td>' +
            '<td class="">' + parseFloat(scale.totalUnit).toFixed(2) + ' €</td>' +
            '<td class="">' + margin.toFixed(2) + ' €</td>' +
            '</tr>';
        this.ui.tableScale.append($(scaleHtml));
      }, this));
      this.ui.blockLoading.hide();
    }
  });

  module.exports = ProductPriceView;
});
