
define('template!caccountAddShareTpl', ['lodash'], function(_) {return function(obj) {
obj || (obj = {});
var __t, __p = '';
with (obj) {
__p += '<div>\n  <form>\n    <div class="container-fluid">\n      <div class="row form-group text-center">\n        <div class="col-sm-12 select-caccount">\n        </div>\n      </div>\n    </div>\n  </form>\n</div>';

}
return __p
};});

