
define('template!caccountListTpl', ['lodash'], function(_) {return function(obj) {
obj || (obj = {});
var __t, __p = '';
with (obj) {
__p += '<div class="content">\n  <div class="well jq-grid-table-page">\n  	<h2>' +
((__t = ( _.i18n('account.detail.subaccount.list') )) == null ? '' : __t) +
'</h2>\n    <div class="sub-caccounts-table">\n      <div class="form-group add-new-block">\n        <a href="#' +
((__t = ( type )) == null ? '' : __t) +
'/new" class="btn new-cAccount-button add-btn-text">\n          <i class="mdi-content-add"></i>\n          <span>' +
((__t = ( _.i18n('account.new') )) == null ? '' : __t) +
'</span>\n        </a>\n      </div>      \n      <table id="jq-grid-list" class="cacountTable" role="grid"></table>\n      <div id="jq-grid-pager-list"></div>\n    </div>\n  </div>\n</div>';

}
return __p
};});

