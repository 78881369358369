/**
 * Created by RKL on 21/08/2015.
 */
define('ordersMainRouter',[
  'require',
  'exports',
  'module',
  'logger',
  'marionette',
  'app',
  'contentShowController',
  'itemPagesNameMixin'
], function(
  require,
  exports,
  module,
  Logger,
  Marionette,
  App,
  ContentShowController,
  ItemPagesNameMixin
) {
  'use strict';

  var OrdersRouter = Marionette.AppRouter.extend({
    appRoutes: {
      'orders': 'showView',
      'orders/(customers/:customerSecId/orders/):orderSecId': 'showOrderDefaultTab',
      'orders/(customers/:customerSecId/orders/):orderSecId/details': 'showOrderDetailsTab',
      'orders/(customers/:customerSecId/orders/):orderSecId/composition': 'showOrderCompositionTab',
      'orders/(customers/:customerSecId/orders/):orderSecId/price': 'showOrderPriceTab',
      'orders/(customers/:customerSecId/orders/):orderSecId/attachments': 'showOrderAttachmentsTab',
      'orders/(customers/:customerSecId/orders/):orderSecId/tracking': 'showOrderTrackingTab',

      'orders/(customers/:customerSecId/producttemplates/):orderSecId': 'showOrderDefaultTab',
      'orders/(customers/:customerSecId/producttemplates/):orderSecId/details': 'showOrderDetailsTab',
      'orders/(customers/:customerSecId/producttemplates/):orderSecId/composition': 'showOrderCompositionTab',

      'orders/customers/:customerSecId/orders/:orderSecId/:tab/produceProofsheet': 'showProduceProofsheet',
      'orders/customers/:customerSecId/orders/:orderSecId/produceProofsheet': 'showProduceProofsheet'
    }
  });

  var API = {
    showView: function() {
      require([
        'ordersShowController'
      ], function(ShowController) {
        ContentShowController.showContent().done(function() {
          App.startSubModule('ordersMain');
          ShowController.showOrders();
          App.execute('set:active:nav:item', ItemPagesNameMixin.ORDERS_LIST);
        });
      });
    },

    showOrderDefaultTab: function(customerSecId, orderSecId) {
      API.showOrderDetailsTab(customerSecId, orderSecId);
    },

    showOrderDetailsTab: function(customerSecId, orderSecId) {
      ContentShowController.showContent().done(function() {
        require([
          'orderTabsLayoutController',
          'orderDetailsTabController'
        ], function(OrderTabsLayoutController, OrderDetailsTabController) {
          var region = App.regions.getRegion('main').currentView.getRegion('content');
          OrderTabsLayoutController.showTabsLayout(region, 'details', orderSecId).done(function(region, orderModel, customer) {
            OrderDetailsTabController.showDetailsTab(region, orderModel, customer);
            if(customerSecId) {
              App.execute('set:active:nav:item', ItemPagesNameMixin.MY_CLIENTS);
            } else {
              if (orderModel.get('commandType') === 'offer') {
                  App.execute('set:active:nav:item', ItemPagesNameMixin.OFFERS);
              } else {
                  App.execute('set:active:nav:item', ItemPagesNameMixin.ORDERS);
              }
            }
          });
        });
      });
    },

    showOrderCompositionTab: function(customerSecId, orderSecId) {
      ContentShowController.showContent().done(function() {
        require([
          'orderTabsLayoutController',
          'orderCompositionTabController'
        ], function(OrderTabsLayoutController, OrderCompositionTabController) {
          var region = App.regions.getRegion('main').currentView.getRegion('content');
          OrderTabsLayoutController.showTabsLayout(region, 'composition', orderSecId).done(function(region, orderModel) {
            OrderCompositionTabController.showCompositionTab(region, orderModel);
            if(customerSecId) {
              App.execute('set:active:nav:item', ItemPagesNameMixin.MY_CLIENTS);
            } else {
              if (orderModel.get('commandType') === 'offer') {
                  App.execute('set:active:nav:item', ItemPagesNameMixin.OFFERS);
              } else {
                  App.execute('set:active:nav:item', ItemPagesNameMixin.ORDERS);
              }
            }
          });
        });
      });
    },

    showOrderPriceTab: function(customerSecId, orderSecId) {
      ContentShowController.showContent().done(function() {
        require([
          'orderTabsLayoutController',
          'orderPriceTabController'
        ], function(OrderTabsLayoutController, OrderPriceTabController) {
          var region = App.regions.getRegion('main').currentView.getRegion('content');
          OrderTabsLayoutController.showTabsLayout(region, 'price', orderSecId).done(function(region, orderModel) {
            OrderPriceTabController.showPrice(region, orderModel);
            if(customerSecId) {
              App.execute('set:active:nav:item', ItemPagesNameMixin.MY_CLIENTS);
            } else {
                if (orderModel.get('commandType') === 'offer') {
                    App.execute('set:active:nav:item', ItemPagesNameMixin.OFFERS);
                } else {
                    App.execute('set:active:nav:item', ItemPagesNameMixin.ORDERS);
                }
            }
          });
        });
      });
    },

    showOrderAttachmentsTab: function(customerSecId, orderSecId) {
        ContentShowController.showContent().done(function() {
            require([
                'orderTabsLayoutController',
                'commandLineAttachsTabController'
            ], function(OrderTabsLayoutController, CommandLineAttachsTabController) {
                var region = App.regions.getRegion('main').currentView.getRegion('content');
                OrderTabsLayoutController.showTabsLayout(region, 'attachments', orderSecId).done(function(region, orderModel) {
                    CommandLineAttachsTabController.showTab(orderModel, region);
                    if(customerSecId) {
                        App.execute('set:active:nav:item', ItemPagesNameMixin.MY_CLIENTS);
                    } else {
                        if (orderModel.get('commandType') === 'offer') {
                            App.execute('set:active:nav:item', ItemPagesNameMixin.OFFERS);
                        } else {
                            App.execute('set:active:nav:item', ItemPagesNameMixin.ORDERS);
                        }
                    }
                });
            });
        });
    },

    showOrderTrackingTab: function(customerSecId, orderSecId) {
        ContentShowController.showContent().done(function() {
            require([
                'orderTabsLayoutController',
                'trackingTabController'
            ], function(OrderTabsLayoutController, TrackingTabController) {
                var region = App.regions.getRegion('main').currentView.getRegion('content');
                OrderTabsLayoutController.showTabsLayout(region, 'tracking', orderSecId).done(function(region, orderModel) {
                    TrackingTabController.showTab(orderModel, region);
                    if(customerSecId) {
                        App.execute('set:active:nav:item', ItemPagesNameMixin.MY_CLIENTS);
                    } else {
                        if (orderModel.get('commandType') === 'offer') {
                            App.execute('set:active:nav:item', ItemPagesNameMixin.OFFERS);
                        } else {
                            App.execute('set:active:nav:item', ItemPagesNameMixin.ORDERS);
                        }
                    }
                });
            });
        });
    },

    showProduceProofsheet: function(customerSecId, orderSecId, tab) {
      ContentShowController.showContent().done(function() {
        require([
          'orderTabsLayoutController',
          'produceProofsheetController'
        ], function(OrderTabsLayoutController, ProduceProofsheetController) {
          var region = App.regions.getRegion('main').currentView.getRegion('content'),
            tabName = tab ? tab : 'details';
          OrderTabsLayoutController.showTabsLayout(region, tabName, orderSecId).done(function(region, orderModel) {
            ProduceProofsheetController.showProduceProofsheet(region, orderModel);
          });
        });
      });
    }
  };

  App.on('orders:show', function() {
    App.navigate('orders');
    API.showView();
  });

  App.on('before:start', function() {
    new OrdersRouter({
      controller: API
    });
  });

  module.exports = OrdersRouter;
});
