
define('template!customerDatasetsCollectionItemTpl', ['lodash'], function(_) {return function(obj) {
obj || (obj = {});
var __t, __p = '', __j = Array.prototype.join;
function print() { __p += __j.call(arguments, '') }
with (obj) {
__p += '<div class="form-control">\n	<div class="input-group col-xs-12 show-on-hover">\n		<input type="text" class="form-control js-popup-input" \n		data-field-name="' +
((__t = ( model.objectName.name )) == null ? '' : __t) +
'" \n		data-secid="' +
((__t = ( model.secId )) == null ? '' : __t) +
'"\n		data-objname-secid="' +
((__t = ( model.objectName.secId )) == null ? '' : __t) +
'"\n		placeholder=""\n		value="' +
((__t = ( model.value )) == null ? '' : __t) +
'">\n		';
 if (!first) { ;
__p += '\n		<span class="input-group-btn clickable">\n			<i class="mdi-action-delete js-delete-btn"></i>\n		</span>\n		';
 } ;
__p += '\n	</div>\n</div>\n';

}
return __p
};});

