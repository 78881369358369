/**
 * Created by BFR on 05/03/2020.
 */
define('productFilterTabController',[
  'module',
  'underscore',
  'app',
  'productFilterTabView',
  'entities/product',
  'entities/product_filter'
], function(
  module,
  _,
  App,
  View
) {

    'use strict';
  var ProductFiltersController = {
      
    showDetails: function (region, pm) {
        var product = App.request('products:model', {secId: pm.get('secId')});
        this.model = product;

        product.fetch().done(_.bind(function() {
          var productFilters = App.request('products-filter:collection');
          productFilters.fetch(pm.get('secId')).done(_.bind(function() {
            App.request('products-filter:get-filter-types', { secId: pm.get('secId') }).then(function(productFilterTypes) {
              this.view = new View({ 
                collection: productFilters, 
                productFilterTypes:productFilterTypes,
                product: product 
              });
              region.show(this.view);
            });
          }, this));
        }, this));
    }
  };

  module.exports = ProductFiltersController;
});
