/**
 * Created by GSP on 4/08/2015.
 */
define('caccountsMainRouter',[
  'require',
  'underscore',
  'exports',
  'module',
  'marionette',
  'app',
  'itemPagesNameMixin',
  'cAccountTabs'
], function(
  require,
  _,
  exports,
  module,
  Marionette,
  App,
  ItemPagesNameMixin,
  CAccountTabs
) {
  'use strict';

  var CaccountsRouter = Marionette.AppRouter.extend({
    appRoutes: {
      'subcaccounts(/:id)(/:tab)(/:secId)': 'showSubAccounts',
      'caccount/:id/:tab/payment(/:s)(?credit=:c&paymentId=:p&token=:t&PayerID=:pa)':
          'showAddCreditsReturn',
      'caccount/:id(/:tab)(/:secId)(/:reload)': 'showCurrentAccount',
      'caccounts(/:id)': 'showCAccountsList',
      'graphicalIdentity(/:id)(/:tab)': 'showGraphicalIdentity',
      'mails': 'showMails'
    }
  });

  var API = {
      showCAccount: function(cAccountId, tab, secId, reload) {
          require([
              'caccountShowController',
              'caccountListController',
              'contentShowController'
          ], function(
              ShowController,
              ListController,
              ContentShowController
              ) {
              ContentShowController.showContent().done(function() {
                  var data = {
                      cAccountId: cAccountId,
                      id: cAccountId,
                      tab: _.findWhere(CAccountTabs.myAccountTabs, {name: tab}),
                      secId: secId,
                      reload: reload !== null,
                      type: API.accountType
                  };

                  if (cAccountId) {
                      ShowController.fetchCaccount(data);
                  } else {
                      ListController.showCaccountList(data);
                  }

                  App.execute('set:active:nav:item', ItemPagesNameMixin.MY_ACCOUNT);
              });
          });
      },

      showGraphicalId: function(cAccountId, tab, secId, reload){
          require([
              'caccountGraphicalIdController',
              'contentShowController'
          ], function(
              ShowController,
              ContentShowController
              ) {
              ContentShowController.showContent().done(function() {
                  var data = {
                      cAccountId: cAccountId,
                      id: cAccountId,
                      tab: _.findWhere(CAccountTabs.graphicalIdTabs, {name: tab}),
                      secId: secId,
                      reload: reload !== null,
                      type: API.accountType
                  };

                  ShowController.fetchCaccount(data);

                  App.execute('set:active:nav:item', ItemPagesNameMixin.GRAPHICAL_ID);
              });
          });
      },

      showRedirectPaypal: function(caId,tab, state, cId, payment, token, payer) {
          require([
              'caccountShowController',
              'contentShowController'
          ], function(
              ShowController,
              ContentShowController
              ) {
              ContentShowController.showContent().done(function() {
                  var data = {
                      cAccountId: caId,
                      state:state,
                      id: caId,
                      tab: _.findWhere(CAccountTabs.myAccountTabs, {name: tab}),
                      type: API.accountType,
                      creditId: cId,
                      paymentId: payment,
                      paypalTokenId: token,
                      payerId: payer
                  };

                  if (caId) {
                      ShowController.fetchCaccount(data);
                  }

                  App.execute('set:active:nav:item', ItemPagesNameMixin.MY_ACCOUNT);
              });
          });
      },

    showSubAccounts: function(cAccountId, tab, secId) {
      this.accountType = 'subcaccounts';
      this.showCAccount(cAccountId, tab, secId);
    },

    showCurrentAccount: function(cAccountId, tab, secId, reload) {
      if (secId === 'reload') {
        reload = true;
        secId = undefined;
      }
      this.accountType = 'caccount';
      this.showCAccount(cAccountId, tab, secId, reload);
    },

    showCAccountsList: function(cAccountId) {
          this.accountType =  this.accountType || 'caccounts';
          this.showCAccount(cAccountId);
    },

    showGraphicalIdentity: function(cAccountId, tab) {
          this.accountType = this.accountType || 'caccount';
          this.showGraphicalId(cAccountId, tab);
    },

    showMails: function() {
        require([
            'mailsListController',
            'contentShowController'
        ], function(
            MailsListController,
            ContentShowController
        ) {
            ContentShowController.showContent().done(function() {
                MailsListController.showMails();
                App.execute('set:active:nav:item', ItemPagesNameMixin.MAILS);
            });
        });
    },

    showAddCreditsReturn: function(cAccountId,tab, state, cId, pay, token, payer) {
        this.accountType = this.accountType || 'caccount';
        this.showRedirectPaypal(cAccountId,tab, state, cId, pay, token, payer);
    }
  };

  App.on('cAccount:show', function(cAccountId, tab, accountType) {
    var tabLink = tab ? '/' + tab : '',
      accountLink = cAccountId ? '/' + cAccountId : '',
      link;

    API.accountType = accountType ? accountType: API.accountType;
    link = API.accountType ? API.accountType : 'caccount';

    App.navigate(link + accountLink + tabLink);
    API.showCAccount(cAccountId, tab);
  });

  App.on('before:start', function() {
    new CaccountsRouter({
      controller: API
    });
  });

  module.exports = CaccountsRouter;
});

