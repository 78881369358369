
define('template!productConfigDialogTpl', ['lodash'], function(_) {return function(obj) {
obj || (obj = {});
var __t, __p = '', __j = Array.prototype.join;
function print() { __p += __j.call(arguments, '') }
with (obj) {
__p += '\n<div class="container-fluid">\n    <div class="container-fluid">\n        <div class="form-product-config-container form-horizontal col-xs-12">\n            <div class="form-inputs-container">\n                ';
 if (quantityOnly || !mixable) { ;
__p += ' \n                    <div class="form-group" data-field-name="quantity">\n                        <label class="col-xs-6 control-label">' +
((__t = ( _.i18n('common.quantity') )) == null ? '' : __t) +
'</label>\n                        <div class="col-xs-6">\n                            <input type="number" class="form-control floating-label js-info-input input-quantity" data-field-name="quantity" placeholder="' +
((__t = ( _.i18n('common.quantity') )) == null ? '' : __t) +
'" value="' +
((__t = ( quantity )) == null ? '' : __t) +
'">\n                        </div>\n                    </div>\n                ';
 } ;
__p += '\n                ';
 if (!quantityOnly) { ;
__p += '\n                    ';
 for (var i = 0 ; i < product.printAreas.length ; i++) { ;
__p += '\n                    <div class="form-group" data-field-name="printarea-' +
((__t = ( i )) == null ? '' : __t) +
'" style="height:40px;">\n                        <label class="col-xs-6 control-label">' +
((__t = ( product.printAreas[i].name )) == null ? '' : __t) +
'</label>\n                        <div class="dropdown col-xs-6" >\n                            <button class="form-control dropdown-toggle dropdown__button" type="button" id="dropdownMenu-pz-' +
((__t = ( i )) == null ? '' : __t) +
'"\n                                    data-toggle="dropdown" aria-haspopup="true" aria-expanded="true">\n                                <span class="pull-left dropdown__label printzone-' +
((__t = ( i )) == null ? '' : __t) +
'-label">None</span>\n                                <span class="caret pull-right m-t-5 dropdown__caret"></span>\n                            </button>\n                            <ul class="dropdown-menu dropdown-menu-printzone" role="menu" aria-labelledby="dropdownMenu-pz-' +
((__t = ( i )) == null ? '' : __t) +
'">\n                                <li role="presentation"><a role="menuitem" data-index="' +
((__t = ( i )) == null ? '' : __t) +
'" data-pz-id="-1" href="#">None</a></li>\n                                ';
 for (var j = 0 ; j < product.printAreas[i].printZones.length ; j++) { ;
__p += '\n                                ';
 var pz = product.printAreas[i].printZones[j]; ;
__p += '\n                                ';
 if (pz.colorModeType === 'spot') { ;
__p += '\n                                ';
 for (var k = 1 ; k <= pz.maxColors ; k ++) { ;
__p += '\n                                <li role="presentation"><a role="menuitem" data-index="' +
((__t = ( i )) == null ? '' : __t) +
'"\n                                                           data-nb-color="' +
((__t = ( k )) == null ? '' : __t) +
'"\n                                                           data-pz-id="' +
((__t = ( pz.id )) == null ? '' : __t) +
'"\n                                                           href="#">' +
((__t = ( pz.name )) == null ? '' : __t) +
' (' +
((__t = ( k )) == null ? '' : __t) +
')</a></li>\n                                ';
 } ;
__p += '\n                                ';
 } else { ;
__p += '\n                                <li role="presentation"><a role="menuitem" data-index="' +
((__t = ( i )) == null ? '' : __t) +
'"\n                                                           data-nb-color="1"\n                                                           data-pz-id="' +
((__t = ( pz.id )) == null ? '' : __t) +
'"\n                                                           href="#">' +
((__t = ( pz.name )) == null ? '' : __t) +
'</a></li>\n                                ';
 } ;
__p += '\n                                ';
 } ;
__p += '\n                            </ul>\n                        </div>\n                    </div>\n                    ';
 } ;
__p += '\n                    \n                    ';
 for (var i = 0 ; i < product.productOptionCategories.length ; i++) { ;
__p += '\n                        ';
 if (!product.productOptionCategories[i].mixable) { ;
__p += '\n                            <div class="form-group" data-field-name="option-category-' +
((__t = ( i )) == null ? '' : __t) +
'" style="height:40px;">\n                                <label class="col-xs-6 control-label">' +
((__t = ( product.productOptionCategories[i].name )) == null ? '' : __t) +
'</label>\n                                <div class="dropdown col-xs-6">\n                                    <button class="form-control dropdown-toggle dropdown__button" type="button" id="dropdownMenu-option-' +
((__t = ( i )) == null ? '' : __t) +
'"\n                                            data-toggle="dropdown" aria-haspopup="true" aria-expanded="true">\n                                        <span class="pull-left dropdown__label option-' +
((__t = ( i )) == null ? '' : __t) +
'-label">None</span>\n                                        <span class="caret pull-right m-t-5 dropdown__caret"></span>\n                                    </button>\n                                    <ul class="dropdown-menu dropdown-menu-option" role="menu" aria-labelledby="dropdownMenu-option-' +
((__t = ( i )) == null ? '' : __t) +
'">\n                                        ';
 for (var j = 0 ; j < product.productOptionCategories[i].productOptions.length ; j++) { ;
__p += '\n                                        <li role="presentation"><a role="menuitem" data-index="' +
((__t = ( i )) == null ? '' : __t) +
'"\n                                                                   data-option-id="' +
((__t = ( product.productOptionCategories[i].productOptions[j].id )) == null ? '' : __t) +
'"\n                                                                   href="#">' +
((__t = ( product.productOptionCategories[i].productOptions[j].name )) == null ? '' : __t) +
'</a></li>\n                                        ';
 } ;
__p += '\n                                    </ul>\n                                </div>\n                            </div>\n                        ';
 } ;
__p += '\n                    ';
 } ;
__p += '\n\n                    ';
 if (mixable) { ;
__p += ' \n                        <div class="mix-table table-responsive row padding-all-0">\n                            <div class="row mix-table-body">\n\n                            </div>\n                            <div class="btn btn-orange custom-second-color add-mix-line">\n                                <i class="glyphicon glyphicon-plus"></i>\n                                ' +
((__t = ( _.i18n('perso.addOption') )) == null ? '' : __t) +
'\n                            </div>\n                        </div>\n                    ';
 } ;
__p += '\n                ';
 } ;
__p += '\n            </div>\n        </div>\n    </div>\n\n    <!-- SAVE / CANCEL -->\n    <div class="buttons-container">\n        <div class="form-group pull-right">\n            <a class="btn btn btn-s-md-cancel btn-primary " data-dismiss="modal">' +
((__t = ( _.i18n("common.cancel") )) == null ? '' : __t) +
'</a>\n            <a class="btn btn btn-s-md-save btn-primary js-confirm btn-s-md-save">' +
((__t = ( _.i18n("common.save") )) == null ? '' : __t) +
'</a>\n        </div>\n    </div>\n</div>';

}
return __p
};});

