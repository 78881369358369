
define('template!graphicalRequestAllInOnePopupTpl', ['lodash'], function(_) {return function(obj) {
obj || (obj = {});
var __t, __p = '', __j = Array.prototype.join;
function print() { __p += __j.call(arguments, '') }
with (obj) {
__p += '<div class="content">\n  <div class="my-vector-popup-container">\n\n    <div id="graphicalwizard" class="form-wizard form-wizard-horizontal">\n      <form class="form floating-label">\n        <div class="form-wizard-nav">\n          <div class="progress wizard-progress" style="width: 90%;">\n            <div class="progress-bar progress-bar-primary wizard-progress-line" style="width: 0%;"></div>\n          </div>\n          <ul class="nav nav-justified nav-pills wizard-navigation">\n            <li class="active">\n              <a href="#tab1" data-toggle="tab" aria-expanded="true">\n                <span class="step">1</span>\n                <span class="title">' +
((__t = ( _.i18n('menu.graphicalRequests') )) == null ? '' : __t) +
'</span>\n              </a>\n            </li>\n            <li class="">\n              <a href="#tab2" data-toggle="tab" aria-expanded="false">\n                <span class="step">2</span>\n                <span class="title">' +
((__t = ( _.i18n('common.upload') )) == null ? '' : __t) +
'</span>\n              </a>\n            </li>\n            <li class="">\n              <a href="#tab3" data-toggle="tab" aria-expanded="false">\n                <span class="step">3</span>\n                <span class="title">' +
((__t = ( _.i18n('settings') )) == null ? '' : __t) +
'</span>\n              </a>\n            </li>\n            <li class="">\n              <a href="#tab4" data-toggle="tab" aria-expanded="false">\n                <span class="step">4</span>\n                <span class="title">' +
((__t = ( _.i18n('common.confirm') )) == null ? '' : __t) +
'</span>\n              </a>\n            </li>\n          </ul>\n        </div>\n        <!--end .form-wizard-nav -->\n\n        <div class="tab-content clearfix">\n\n          <div class="tab-pane active" id="tab1">\n            <br><br>\n\n            <div class="graphical-type-selection-container">\n\n              <!--VECTORIZATION BLOCK-->\n              <div class="col-xs-3 graphical-type-block vectorization">\n                <div class="radio-container">\n                  <div class="radio">\n                    <label>\n                        <input class="conversion-type-chooser" type="radio" name="conversion-type" value="' +
((__t = ( conversionTypes.vector )) == null ? '' : __t) +
'" ';
 if (choosenConversionType === conversionTypes.vector) { ;
__p += 'checked=""';
 } ;
__p += ' >\n                        <span class="circle"></span>\n                        <span class="check"></span>\n                      </label>\n                  </div>\n                </div>\n                <div class="icon-container">\n                  <div class="graphical-vectorization"></div>\n                </div>\n                <div class="label-container">\n                  <span>' +
((__t = ( _.i18n('common.vectorization') )) == null ? '' : __t) +
'</span>\n                </div>\n              </div>\n\n              <!--FILE CONVERSION BLOCK-->\n              <div class="col-xs-3 graphical-type-block file-conversion">\n                <div class="radio-container">\n                  <div class="radio">\n                    <label>\n                        <input class="conversion-type-chooser" type="radio" name="conversion-type" value="' +
((__t = ( conversionTypes.convert )) == null ? '' : __t) +
'" ';
 if (choosenConversionType === conversionTypes.convert) { ;
__p += 'checked=""';
 } ;
__p += ' >\n                        <span class="circle"></span>\n                        <span class="check"></span>\n                      </label>\n                  </div>\n                </div>\n                <div class="icon-container">\n                  <div class="graphical-conversion"></div>\n                </div>\n                <div class="label-container">\n                  <span>' +
((__t = ( _.i18n('common.fileConversion') )) == null ? '' : __t) +
'</span>\n                </div>\n              </div>\n\n              <!--CLIPPING BLOCK-->\n              <div class="col-xs-3 graphical-type-block clipping">\n                <div class="radio-container">\n                  <div class="radio">\n                    <label>\n                        <input class="conversion-type-chooser" type="radio" name="conversion-type" value="' +
((__t = ( conversionTypes.remove )) == null ? '' : __t) +
'" ';
 if (choosenConversionType === conversionTypes.remove) { ;
__p += 'checked=""';
 } ;
__p += ' >\n                        <span class="circle"></span>\n                        <span class="check"></span>\n                      </label>\n                  </div>\n                </div>\n                <div class="icon-container">\n                  <div class="graphical-clipping"></div>\n                </div>\n                <div class="label-container">\n                  <span>' +
((__t = ( _.i18n('common.clipping') )) == null ? '' : __t) +
'</span>\n                </div>\n              </div>\n\n              <!--COLORIZATION-->\n              <div class="col-xs-3 graphical-type-block colorization">\n                <div class="radio-container">\n                  <div class="radio">\n                    <label>\n                          <input class="conversion-type-chooser" type="radio" name="conversion-type" value="' +
((__t = ( conversionTypes.colorization )) == null ? '' : __t) +
'" ';
 if (choosenConversionType === conversionTypes.colorization) { ;
__p += 'checked=""';
 } ;
__p += ' >\n                          <span class="circle"></span>\n                          <span class="check"></span>\n                        </label>\n                  </div>\n                </div>\n                <div class="icon-container">\n                  <div class="graphical-colorization"></div>\n                </div>\n                <div class="label-container">\n                  <span>' +
((__t = ( _.i18n('common.colorization') )) == null ? '' : __t) +
'</span>\n                </div>\n              </div>\n            </div>\n\n          </div>\n          <!--end #tab1 -->\n\n          <div class="tab-pane" id="tab2">\n\n            <div class="upload-container">\n              <div class="drag-and-drop-area">\n                <div class="preview-container">\n                  <!-- Image Preview -->\n                  <div class="uploaded-box text-center">\n                    <img id="previewImg" src="">\n                  </div>\n                </div>\n                <i class="mdi-av-queue dd-icon"></i>\n                <div class="upload-container-drag-drop dropzone">\n                </div>\n                <a class="btn btn-primary dd-b-upload">\n                  <i class="mdi-file-file-upload"></i>\n                  <span class="first-upload">' +
((__t = ( _.i18n('common.upload') )) == null ? '' : __t) +
'</span>\n                  <span class="another-upload">' +
((__t = ( _.i18n('common.upload.other') )) == null ? '' : __t) +
'</span>\n                </a>\n                <div class="progress progress-striped upload-progress hidden">\n                  <div class="progress-bar progress-bar-info" style="width: 0%"></div>\n                </div>\n              </div>\n              <span id="upload-error" class="help-block">' +
((__t = ( _.i18n('error.upload.first') )) == null ? '' : __t) +
'</span>\n              <div class="upload-more-files-container text-center">\n                <span class="upload-more-files-label">\n                  ' +
((__t = ( _.i18n('common.upload_more_than_20_files') )) == null ? '' : __t) +
'\n                </span>\n                <p class="upload-more-files-link">\n                  <a target="_blank" href="https://compuzz.wetransfer.com" class="upload-more-files-link">\n                    ' +
((__t = ( _.i18n('common.link_compuzz_wetransfer') )) == null ? '' : __t) +
'\n                  </a>                  \n                </p>\n              </div>\n            </div>\n\n          </div>\n          <!--end #tab2 -->\n\n          <div class="tab-pane" id="tab3">\n            <br><br>\n\n            <div class="request-settings third-step">\n              <form>\n                <div class="form-group ticket-name-new">\n                  <input maxlength="30" type="text" class="form-control floating-label" placeholder="' +
((__t = (_.i18n('common.subject') )) == null ? '' : __t) +
'">\n                </div>\n                <!--Output File extension-->\n                <span class="file-extension-label">' +
((__t = (_.i18n('files.output.format'))) == null ? '' : __t) +
'</span>\n                <div class="form-group output-file-dropdown-region"></div>\n                <!-- Textarea -->\n                <div class="form-group textarea-container">\n                  <textarea class="form-control textarea-input floating-label" rows="2" placeholder="' +
((__t = (_.i18n('common.message') )) == null ? '' : __t) +
'"></textarea>\n                </div>\n\n                <!-- OUTPUT COLOR BLOCK -->\n                <div class="outputcolor-container" style="' +
((__t = ( (choosenConversionType === conversionTypes.colorization ? '' : 'display: none;') )) == null ? '' : __t) +
'">\n                  <div class="form-group textarea-container">\n                    <textarea class="form-control textarea-input-coloroutput floating-label" rows="2" placeholder="' +
((__t = (_.i18n('graphical.outputcolor.detail') )) == null ? '' : __t) +
'"></textarea>\n                  </div>\n                </div>\n              </form>\n              <!-- Urgency -->\n              <div class="form-group">\n                <div class="checkbox checkbox-primary urgency">\n                  <label>\n                    <input type="checkbox" class="js-ticket-urgent">\n                  </label>\n                  <span class="p-l-5">' +
((__t = ( _.i18n('vectReq.urgency') )) == null ? '' : __t) +
'</span>\n                </div>\n              </div>\n            </div>\n\n          </div>\n          <!--end #tab3 -->\n\n          <div class="tab-pane" id="tab4">\n\n            <!--IMAGE PREVIEW-->\n            <div class="preview-container">\n              <div class="row">\n                <div class="col-xs-4 p-t-15">\n                  <!-- Image Preview -->\n                  <div class="uploaded-box text-center">\n                    <img id="previewImg" src="">\n                  </div>\n\n                </div>\n                <div class="col-xs-8 p-t-30">\n                  <form>\n                    <div class="form-group ticket-name-new-confirmed">\n                      <input type="text" class="form-control floating-label" placeholder="' +
((__t = (_.i18n('common.subject') )) == null ? '' : __t) +
'" readonly>\n                    </div>\n                    <!--Output File extension-->\n                    <span class="file-extension-label">' +
((__t = (_.i18n('files.output.format'))) == null ? '' : __t) +
'</span>\n                    <div class="form-group output-file-dropdown-region-confirm"></div>\n                  </form>\n                </div>\n                <div class="col-xs-12 p-t-15 message-container">\n                  <!-- Message Textarea -->\n                  <div class="form-group">\n                    <div class="textarea-container">\n                      <div class="p-0 col-xs-12">\n                        <textarea class="form-control textarea-input-confirmed floating-label" rows="2" placeholder="' +
((__t = (_.i18n('common.message') )) == null ? '' : __t) +
'"\n                          readonly></textarea>\n                      </div>\n                    </div>\n                  </div>\n                </div>\n                <!-- OUTPUT COLOR BLOCK -->\n                <div class="col-xs-12 p-t-15 outputcolor-container" style="' +
((__t = ( (choosenConversionType === conversionTypes.colorization ? '' : 'display: none;') )) == null ? '' : __t) +
'">\n                  <div class="form-group">\n                    <div class="textarea-container">\n                      <div class="p-0 col-xs-12">\n                        <textarea class="form-control textarea-input-outputcolor-confirmed floating-label" rows="2" placeholder="' +
((__t = (_.i18n('graphical.outputcolor.detail') )) == null ? '' : __t) +
'"\n                          readonly></textarea>\n                      </div>\n                    </div>\n                  </div>\n                </div>\n              </div>\n              <!-- Urgency -->\n              <div class="form-group checkbox-group">\n                <div class="checkbox checkbox-primary urgency">\n                  <label>\n                    <input type="checkbox" class="js-ticket-urgent disabled" disabled>\n                  </label>\n                  <span class="p-l-5">' +
((__t = ( _.i18n('vectReq.urgency') )) == null ? '' : __t) +
'</span>\n                </div>\n              </div>\n            </div>\n\n          </div>\n          <!--end #tab4 -->\n        </div>\n        <!--end .tab-content -->\n        <ul class="pager wizard">\n          <li class="first disabled m-r-5">\n            <a class="btn-raised" href="javascript:void(0);">\n              ' +
((__t = ( _.i18n('first') )) == null ? '' : __t) +
'\n            </a>\n          </li>\n          <li class="previous disabled">\n            <a class="btn-raised" href="javascript:void(0);">\n              ' +
((__t = ( _.i18n('previous') )) == null ? '' : __t) +
'\n            </a>\n          </li>\n          <li class="next">\n            <a class="btn-raised" href="javascript:void(0);">\n              ' +
((__t = ( _.i18n('next') )) == null ? '' : __t) +
'\n            </a>\n          </li>\n          <li class="confirm send-to-d-b hidden ">\n            <a class="btn-raised" href="javascript:void(0);">\n              ' +
((__t = ( _.i18n('common.validate') )) == null ? '' : __t) +
'\n            </a>\n          </li>\n          <li class="cancel hidden">\n            <a class="btn-raised" href="javascript:void(0);" data-dismiss="modal">\n              ' +
((__t = ( _.i18n("common.cancel") )) == null ? '' : __t) +
'\n            </a>\n          </li>\n        </ul>\n      </form>\n    </div>\n  </div>\n</div>';

}
return __p
};});

