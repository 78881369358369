define('detailMailView',[
    'module',
    'marionette',
    'template!detailMailTpl'
], function(
    module,
    Marionette,
    detailMailTpl
) {
    'use strict';

    var DetailMailView = Marionette.LayoutView.extend({
        template: detailMailTpl,

        ui: {

        },

        events: {
        },

        regions: {

        },

        serializeData: function() {
            return this.model.toJSON();
        }
    });

    module.exports = DetailMailView;
});
